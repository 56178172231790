import { createSlice } from '@reduxjs/toolkit';
import {getSurveyQuestionsSucceededAction, holdSurveyAnswersAction, putSurveyAnswersSucceededAction} from './Actions';
import { surveyQuestionsInitialState } from './State';
import {
  getSurveyQuestionsSucceededReducer, holdSurveyAnswerReducer, putSurveyAnswersSucceededReducer,
} from './Reducers';

export const surveyQuestionSlice = createSlice({
  name: 'surveyQuestions',
  initialState: surveyQuestionsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSurveyQuestionsSucceededAction, getSurveyQuestionsSucceededReducer)
      .addCase(putSurveyAnswersSucceededAction, putSurveyAnswersSucceededReducer)
      .addCase(holdSurveyAnswersAction, holdSurveyAnswerReducer);
  },
});
