import React, { FunctionComponent, useState } from 'react';
import styles from './drawer.module.scss';
import { useLocation } from 'react-router-dom';
import { Route } from '@hec/models';
import classnames from 'classnames';
import { Text, Conditional, DrawerToggle } from '../../v2';
import {useTranslation} from "react-i18next";

export interface DrawerProps {
  routes: Route[];
  isOpen?: boolean;
  onToggleFullscreen?: () => void;
}

const getActiveRoute = (
  routes: Route[],
  pathname: string
): Route | undefined => {
  const activeRouteSearch = routes.filter((route) => route.path === pathname);
  if (activeRouteSearch.length > 0) {
    return activeRouteSearch[0];
  }
  return;
};

export const Drawer: FunctionComponent<DrawerProps> = ({
  routes,
  isOpen,
  onToggleFullscreen,
}) => {
  const { t } = useTranslation();

  const { pathname } = useLocation();
  const activeRoute = getActiveRoute(routes, pathname);
  const [isOpenInner, setIsOpenInner] = useState<boolean>(true);
  // const {trackPageView} = useMatomo();

  const open = () => {
    if (typeof isOpen !== 'undefined') {
      return isOpen;
    } else {
      return isOpenInner;
    }
  };

  // React.useEffect(() => {
  //   trackPageView({
  //     documentTitle: activeRoute?.name,
  //     href: window.document.location.href
  //   })
  // }, [activeRoute?.path, activeRoute?.name])

  return (
    <div
      className={classnames(styles.drawer, {
        [`${styles.open}`]: open(),
      })}
      style={{ display: open() ? 'flex' : 'none' }}
    >
      <div className={styles.clearfix} />
      {activeRoute &&
        <div className={styles.content}>
          <Conditional condition={activeRoute.displaySettings.displayTitleInDrawer}>
            <Text
              as={'h1'}
              className={activeRoute.displaySettings.titleNoMarginBottom ? styles['title-no-margin-bottom'] : styles.title}
              style={activeRoute.displaySettings.centerTitle ? { textAlign: "center" } : {} }
            >
              {t(activeRoute.translationKey!)}
            </Text>
          </Conditional>
          {activeRoute?.sideDrawerContent}
        </div>
      }
    </div>
  );
};
