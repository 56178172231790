import {
  AppConfiguration,
  ApplicationService,
  SerializableValue,
  SurveyAnswerViewModel,
  SurveyQuestionType
} from "@hec/models";
import axios, {AxiosInstance} from "axios";
import {BaseDalService} from "@hec/core";


export class SurveyAnswerService extends BaseDalService implements ApplicationService {
  private forPro = false;
  private client: AxiosInstance;
  public static override serviceName = Symbol('SurveyAnswerService');

  constructor() {
    super();
    this.client = axios.create({});
  }


  override configure(configuration: AppConfiguration) {
    this.client = axios.create({
      baseURL: `${configuration.baseUrl}/api`,
    });

    this.useDateTimeConverters(this.client);
    this.useEnumConverters(this.client);
    this.useBearerAuthentication(this.client, configuration);
    this.forPro = !!configuration.login?.refreshToken;
  }


  getName(): symbol {
    return SurveyAnswerService.serviceName;
  }

  static override getName(): symbol {
    return SurveyAnswerService.serviceName;
  }

  async put(orderId: string, configurationCode: string, answers: SurveyAnswerViewModel[]): Promise<SurveyAnswerViewModel[]> {
    if(!this.forPro) {
      this.configureAuthInterceptor(this.client, configurationCode);
    }

    const fileTypes = [
      SurveyQuestionType.File,
      SurveyQuestionType.ImageThreeDimensions,
      SurveyQuestionType.ImageTwoDimensions,
    ]



    for (const answer of answers) {
      if(fileTypes.indexOf(answer.surveyQuestionType) !== -1) {
        if (answer.value != null && (answer.value instanceof FileList)) {
          const uploadResult = (await this.uploadFile(orderId, answer.value));
          answer.value = uploadResult;
        } else {
          answer.value = null;
        }
      }
    }



    const response = await this.client.put(`/Orders/${orderId}/SurveyAnswers`, answers);


    if (response.status === 200) {
      return response.data as SurveyAnswerViewModel[];
    }

    throw new Error(`SurveyAnswerService: get resulted in ${response.status}: ${response.statusText}`);
  }

  private getFileExtension(filename: string) {
    return filename.split('.').pop();
  }

  async uploadFile(orderId: string, files: FileList): Promise<SerializableValue | null> {
    if (!this.forPro) {
      return null;
    }

    const file: File | undefined = files[0];
    if (!file) {
      return null;
    }
    const fileName = file.name;

    const response = await this.client.post(`/Orders/${orderId}/SurveyAnswers/file-upload`, {fileExtension: this.getFileExtension(fileName)});

    if (response.status != 200) {
      return null;
    }
    const oneTimeUploadUrl: string = response.data.oneTimeUploadUrl;
    const destinationUrl: string = response.data.destinationUrl;

    try {
      await this.uploadFileToS3(oneTimeUploadUrl, file);
      return {
        value: destinationUrl
      } as SerializableValue;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async uploadFileToS3(presignedUrl: string, file: File): Promise<void> {
    try {
      const response = await axios.put(presignedUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
      });

      if (response.status === 200) {
        console.log('File upload successful.');
      } else {
        console.error('File upload failed:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error during file upload:', error);
    }
  }
}
