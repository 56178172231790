import React, {FunctionComponent} from "react";
import styles from "./frames-configurator.module.scss";
import {AddFrameButton} from "./AddFrameButton";
import {FrameDisplay} from "./FrameDisplay";
import {Conditional, Text} from "@hec/components/v2";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
  prepareChangeFrameCopiedFromAction,
  prepareAddNewFrameAction,
  removeFrameAction,
  swapFrameOrderAction,
  prepareUpdateFrameSizeAction,
  getFaceConfiguration,
  ConfirmPromptModalData,
  storeUiStatusAction,
  getDefaultFrameSelector,
  shrinkToSizeConfigurationToMinimumSizeRequiredForFramesAction,
  prepareUpdateMeasurementsAction
} from "@hec/dal";
import {ConfigurationTemplateDto, FrameDto} from "@hec/api-dtos";
import {DormerModalContentKey, TranslationKeys} from "@hec/models";

interface FramesConfiguratorProps {
  configuredFrames: FrameDto[];
  selectedConfigurationTemplate: ConfigurationTemplateDto;
  currentTotalWidth: number;
}

export const FramesConfigurator: FunctionComponent<FramesConfiguratorProps> = ({
                                                                                 configuredFrames,
                                                                                 selectedConfigurationTemplate,
                                                                                 currentTotalWidth
                                                                               }) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const faceConfiguration = useSelector(getFaceConfiguration);
  const defaultFrame: FrameDto | null = useSelector(getDefaultFrameSelector);

  const strictSizeWarningFeaturFlag: boolean = selectedConfigurationTemplate?.strictSizeWarning ?? false;

  if (faceConfiguration === null) {
    return <></>;
  }


  const width = defaultFrame?.width ?? 0;

  const addNewFrame = () => {
    if (freeSpace < width && strictSizeWarningFeaturFlag) {
      const modalData: ConfirmPromptModalData = {
        message: `U heeft nog ${freeSpace}mm over, maar het kozijn wat u gaat toevoegen is ${width} mm breed, weet u zeker dat u uw dakkappel breder wil maken?`,
        onAccept: () => {
          dispatch(prepareAddNewFrameAction({sort: configuredFrames.length}));
        }
      }

      dispatch(storeUiStatusAction({
        modalOpen: true,
        modalContentKey: DormerModalContentKey.CONFIRMATION_PROMPT,
        modalData,
      }))
    } else {
      dispatch(prepareAddNewFrameAction({sort: configuredFrames.length}));
    }
  };

  const removeFrame = (sort: number) => {
    dispatch(removeFrameAction({sort: sort}));
  };

  const changeFrameCopiedFrom = (sort: number, copiedFromId: string) => {
    dispatch(prepareChangeFrameCopiedFromAction({sort: sort, copiedFromId: copiedFromId}));
  };

  const swapFrameOrder = (sort1: number, sort2: number) => {
    dispatch(swapFrameOrderAction({sort1: sort1, sort2: sort2}));
  };

  const updateFrameSize = (sort: number, width: number | null, height: number | null) => {
    dispatch(prepareUpdateFrameSizeAction({sort: sort, width: width, height: height}));
  }

  const faceConfigurationWidth = faceConfiguration?.width ?? 0;

  const freeSpace = faceConfigurationWidth - currentTotalWidth;

  const expandRequested = (sort: number, frame: FrameDto) => {
    const maxWidth = frame.maxWidth ?? Infinity;
    const maxFrameWidth = maxWidth >= frame.width ? maxWidth : Infinity;
    const width = Math.min(maxFrameWidth, (frame.width + freeSpace));

    dispatch(prepareUpdateFrameSizeAction({sort: frame.sort ?? sort, width, height: frame.height}));
  }

  const shrinkableSize = faceConfigurationWidth - freeSpace;

  return (
    <div className={styles.frameConfigurator}>
      {configuredFrames.map((frame, index) => {
        return <FrameDisplay key={index}
                             frame={frame}
                             frameCount={configuredFrames.length}
                             removeFrame={removeFrame}
                             changeFrameCopiedFrom={changeFrameCopiedFrom}
                             swapFrameOrder={swapFrameOrder}
                             updateFrameSize={updateFrameSize}
                             expandRequested={freeSpace > 0 ? () => expandRequested(index, frame) : undefined}
        />;
      })}

      <Conditional condition={currentTotalWidth < (faceConfiguration?.width ?? 0)}>
        <div className={styles.spareSpace}>
          <Text as={"span"} className={styles.spareSpaceText}>
            {`${t(TranslationKeys.dormer.pages.framesPage.freeSpace)} ${freeSpace}${t(TranslationKeys.dormer.measurementUnit)}`}
          </Text>
          <Conditional condition={configuredFrames.length > 0}>
            <Text as={"span"} className={styles.spareSpaceShrinkText} onClick={() => {
              dispatch(prepareUpdateMeasurementsAction({
                width: shrinkableSize,
                height: null
              }))}}>
              {t(TranslationKeys.dormer.pages.framesPage.shrinkFreeButtonSpace, 'Krimp')}
            </Text>
          </Conditional>
        </div>
      </Conditional>
      {/* Check if dormer width exceeds maximum width */}
      {(selectedConfigurationTemplate.maximumWidth !== null) &&
        <Conditional condition={currentTotalWidth > selectedConfigurationTemplate.maximumWidth}>
          <div className={styles.warning}>
            <Text as={"span"} className={styles.warningText}>
              {`${t(TranslationKeys.dormer.pages.framesPage.exceededSpace)} ${currentTotalWidth - selectedConfigurationTemplate.maximumWidth}${t(TranslationKeys.dormer.measurementUnit)}`}
            </Text>
          </div>
        </Conditional>
      }

      <AddFrameButton addNewFrame={addNewFrame}/>
    </div>
  );
};
