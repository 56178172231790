import React, { FunctionComponent, Suspense } from 'react';
import { useGLTF } from '@react-three/drei';
import { IsRoofLightProduct, RoofLightPlacement, RoofLightProduct } from '@hec/models';
import { HomeExtensionDto } from '../../HomeExtensionDto';
import { Vector3 } from 'three';
import { DEFAULT_WALL_THICKNESS } from "@hec/components/v1";

export interface RoofLightProps {
  homeExtensionDto: HomeExtensionDto;
}

export const RoofLight: FunctionComponent<RoofLightProps> = ({ homeExtensionDto }) => {
  const roofLightProduct = homeExtensionDto.getExtraByType(IsRoofLightProduct) as RoofLightProduct | undefined;
  const gltf = roofLightProduct && roofLightProduct.drawableObject ? useGLTF(roofLightProduct.drawableObject?.locationUrl, true) : null;
  const [extensionWidth, extensionHeight, extensionDepth] = homeExtensionDto.getMeasurements().toArray()
  let roofLightWidth = null;
  let roofLightDepth = null;
  let roofLightHeight = null;
  let roofLightZPosition = 0;
  if (roofLightProduct && roofLightProduct.drawableObject) {
    roofLightWidth = roofLightProduct.drawableObject.width;
    roofLightDepth = roofLightProduct.drawableObject.depth;
    roofLightHeight = roofLightProduct.drawableObject.height;
    if (roofLightProduct.drawableObject.specification.roofLightPlacementType === RoofLightPlacement.AGAINST_WALL) {
      roofLightZPosition = roofLightDepth - (DEFAULT_WALL_THICKNESS / 2);
    } else {
      roofLightZPosition = ((extensionDepth - DEFAULT_WALL_THICKNESS) + roofLightDepth) / 2;
    }
  }
  return (gltf && roofLightWidth && roofLightDepth && roofLightHeight) ? (
    <group position={new Vector3((extensionWidth - roofLightWidth) / 2, extensionHeight, roofLightZPosition)}>
      <Suspense fallback={<group />}>
        <primitive object={gltf.scene} />
      </Suspense>
    </group>
  ) : <group />
};
