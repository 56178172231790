import { Canvas, useFrame } from '@react-three/fiber';
import {FunctionComponent, RefObject, useRef, useState} from "react";
import {ConfigurationTemplateDto} from "@hec/api-dtos";
// import {Canvas, useFrame} from "react-three-fiber"

import { Box } from '@react-three/drei';
import styles from './PlaceHolder.module.scss';
import {BufferGeometry, Color, Group, Material, Mesh} from "three";



export const PlaceHolder: FunctionComponent = () => {
  const cubeRef = useRef<any>();
  const [hue, setHue] = useState<number>(0); // Initial hue value

  useFrame(() => {
    if (cubeRef.current) {
      cubeRef.current.rotation.x += 0.01;
      cubeRef.current.rotation.y += 0.01;

      // Calculate the next hue (cycle through the color spectrum)
      const nextHue = (hue + 1) % 360;
      setHue(nextHue);

      // Convert hue to RGB color
      const color = new Color(`hsl(${nextHue}, 100%, 50%)`);

      // Apply the new color to the cube's material
      cubeRef.current.material.color = color;
    }
  });

  return (
    <Box ref={cubeRef} args={[4, 4, 4]} position={[0, 0, 0]}>
      {/*<meshBasicMaterial attach="material" color={`#${color.toString(16)}`} />*/}
    </Box>
  );
}
