export enum SurveyQuestionType {
  WholeNumber = 'WholeNumber',
  Decimal = 'Decimal',
  Toggle = 'Toggle',
  Date = 'Date',
  Text = 'Text',
  MultilineText = 'MultilineText',
  File = 'File',
  DropDown = 'DropDown',
  ImageTwoDimensions = 'ImageTwoDimensions',
  ImageThreeDimensions = 'ImageThreeDimensions',
}
