import { select } from 'redux-saga/effects';
import { HomeExtensionRootState } from '../../../';
import {
  HomeConfigurationState,
  ProductionMaterialType,
  IsFrameProduct,
  IsProductWithinWidthAndDepthThreshold,
  IsProductWithSameColor,
  IsProductWithSameMaterial,
  IsProductWithSameName,
} from '@hec/models';

export function* pickSuitableFrameSizeHandler(
  name?: string,
  color?: string,
  width?: number,
  material?: ProductionMaterialType) {
  let innerWidth: number;
  const { measurements, products }: HomeConfigurationState = yield select((state: HomeExtensionRootState) => state.homeConfigurationReducer);
  if (width) {
    innerWidth = width;
  } else {
    innerWidth = measurements.width;
  }
  // Check if a frame exists with the right properties to fit the home extension
  return products
    .filter(IsFrameProduct)
    .filter(IsProductWithinWidthAndDepthThreshold(innerWidth))
    .filter(IsProductWithSameName(name))
    .filter(IsProductWithSameMaterial(material))
    .find(IsProductWithSameColor(color));
}
