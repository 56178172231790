import { createSlice } from '@reduxjs/toolkit';
import {
  getDormerMaterialsSucceededAction,
  selectDormerMaterialAction,
} from './Actions';
import { dormerMaterialsInitialState } from './State';
import {
  getDormerMaterialsSucceededReducer,
  selectDormerMaterialReducer,
} from './Reducers';

export const dormerMaterialsSlice = createSlice({
  name: 'dormerMaterials',
  initialState: dormerMaterialsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDormerMaterialsSucceededAction, getDormerMaterialsSucceededReducer)
      .addCase(selectDormerMaterialAction, selectDormerMaterialReducer)
  },
});
