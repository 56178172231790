export const splitByProperty = <T>(array: Array<T>, property: (x: T) => string): Record<string, Array<T>> =>
  array.reduce(
    (memo: { [key: string]: Array<T> }, x: T) => {
      if (!memo[property(x)]) {
        memo[property(x)] = [];
      }
      memo[property(x)].push(x);
      return memo;
    },
    {},
  );
