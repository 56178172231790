import { createAction } from '@reduxjs/toolkit';
import {ClientInformationViewModel, OfferingType} from "@hec/api-dtos";

interface GetClientInformationSucceededActionProps extends GetClientInformationActionProps {
  clientInformation: ClientInformationViewModel;
}

interface GetClientInformationActionProps {
  configurationId: string
  offeringType: OfferingType
}

export const getClientInformationAction = createAction<GetClientInformationActionProps>('getClientInformationAction');
export const getClientInformationSucceededAction = createAction<GetClientInformationSucceededActionProps>('getClientInformationSucceededAction');
