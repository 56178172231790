import { PublicConfiguratorApi } from '@hec/models';

declare global {
  interface Window {
    HomeExtensionConfigurator: PublicConfiguratorApi;
  }
}

export const CloseApp = () => {
  if (window) {
    window.HomeExtensionConfigurator.close();
  }
};
