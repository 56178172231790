import React, { useState } from 'react';
import styles from './form.module.scss';
import { FormField } from './FormField';
import { AutoCompleteAddress } from './AutoCompleteAddress';
import { Input } from './Input';
import {DormerModalContentKey, TranslationKeys,} from '@hec/models';
import {Button, Conditional, Text} from '../index';
import {TextArea} from './TextArea';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormSelect } from './FormSelect';
import { countryCodes } from '@hec/models';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import {
  getClientInformationSelector,
  getOrderSelector,
  putCustomerInformationAction,
  storeUiStatusAction
} from "@hec/dal";
import {PutCustomerInformationViewModel} from "@hec/api-dtos";
import {getContrastColor} from "@hec/utils";

// TODO: HEC-106
const getIso3166ThreeLetterCountryCodeFromTwoLetterCountryCode = (
  twoLetterCountryCode?: string
): string => {
  const converted = twoLetterCountryCode
    ? twoLetterCountryCode.toLowerCase()
    : undefined;
  switch (converted) {
    case 'be':
      return 'BEL';
    case 'de':
      return 'DEU';
    default:
      return 'NLD';
  }
};

export const RequestQuotationForm = () => {
  const dispatch = useDispatch();
  const { trackEvent, pushInstruction } = useMatomo();
  const { t } = useTranslation();

  const defaultPhoneNumberPrefix = '+31';
  const clientInformation = useSelector(getClientInformationSelector);
  const order = useSelector(getOrderSelector);
  const [hasAcceptedPrivacyPolicy, setHasAcceptedPrivacyPolicy] = useState((clientInformation?.privacyPolicyUrl ?? '') === '');
  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [emailAddressError, setEmailAddressError] = useState(false);
  const [phoneNumberPrefix, setPhoneNumberPrefix] = useState(defaultPhoneNumberPrefix);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [description, setDescription] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [company, setCompany] = useState('');
  const [country, setCountry] = useState<string | undefined>('Nederland');
  const [twoLetterCountryCode, setTwoLetterCountryCode] = useState<string | undefined>('NLD');
  const [city, setCity] = useState<string | undefined>('');
  const [postalCode, setPostalCode] = useState<string | undefined>('');
  const [streetName, setStreetName] = useState<string | undefined>('');

  if (order === null || clientInformation === null) {
    return <></>;
  }

  const onSubmit = async () => {
    try {
      setFirstNameError(!firstName);
      setLastNameError(!lastName);
      setEmailAddressError(!emailAddress);
      setPhoneNumberError(!phoneNumber);
      const hasErrors = !firstName || !lastName || !emailAddress || !phoneNumber;

      const countryCode = getIso3166ThreeLetterCountryCodeFromTwoLetterCountryCode(twoLetterCountryCode);

      if (!hasErrors) {
        const customerInformationDto: PutCustomerInformationViewModel = {
          id: null,
          clientConfigurationId: clientInformation.clientConfigurationId,
          orderId: order.id,
          countryCode,
          city: city as string,
          postalCode: postalCode as string,
          lastName,
          firstName,
          emailAddress,
          address: streetName as string,
          companyName: company,
          phoneNumber: phoneNumberPrefix + phoneNumber,
          description: description,
        };

        pushInstruction('setUserId', emailAddress);
        trackEvent({
          category: 'interaction',
          action: 'request-quotation',
          href: window.document.location.href,
        });

        dispatch(putCustomerInformationAction(customerInformationDto));

        dispatch(storeUiStatusAction({modalOpen: false, modalContentKey: DormerModalContentKey.NONE, modalData: {}}));
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <form action="" className={styles.form} onClick={(e) => e.preventDefault()}>
      <Text as={'h4'} className={styles.title}>
        {t(TranslationKeys.modals.quotationModal.title)}
      </Text>
      <FormField
        label={t(
          TranslationKeys.modals.quotationModal.form.firstName,
          'Voornaam'
        )}
        errors={
          firstNameError
            ? [
              t(
                TranslationKeys.modals.quotationModal.form.firstNameError,
                'voornaam is vereist'
              ),
            ]
            : undefined
        }
      >
        <Input
          type="text"
          name="first-name"
          placeholder="Jan"
          className={lastNameError ? styles.error : ''}
          onChange={(e) => setFirstName((e.target as any).value)}
        />
      </FormField>

      <FormField
        label={t(
          TranslationKeys.modals.quotationModal.form.lastName,
          'Achternaam'
        )}
        errors={
          lastNameError
            ? [
              t(
                TranslationKeys.modals.quotationModal.form.lastNameError,
                'achternaam is vereist'
              ),
            ]
            : undefined
        }
      >
        <Input
          type="text"
          name="last-name"
          placeholder="Janssen"
          className={firstNameError ? styles.error : ''}
          onChange={(e) => setLastName((e.target as any).value)}
        />
      </FormField>

      <FormField
        label={t(
          TranslationKeys.modals.quotationModal.form.companyName,
          'Bedrijfsnaam'
        )}
        optional
      >
        <Input
          type="text"
          name="company"
          placeholder="Jansen BV."
          onChange={(e) => setCompany((e.target as any).value)}
        />
      </FormField>

      <FormField>
        <AutoCompleteAddress
          addressLabel={t(TranslationKeys.modals.quotationModal.form.address)}
          cityLabel={t(TranslationKeys.modals.quotationModal.form.city)}
          countryLabel={t(TranslationKeys.modals.quotationModal.form.country)}
          postalCodeLabel={t(
            TranslationKeys.modals.quotationModal.form.postalCode
          )}
          onChange={(
            streetName?: string,
            postalCode?: string,
            city?: string,
            country?: string,
            twoLetterCountryCode?: string
          ) => {
            setStreetName(streetName);
            setPostalCode(postalCode);
            setCity(city);
            setCountry(country);
            setTwoLetterCountryCode(twoLetterCountryCode);
          }}
        />
      </FormField>

      <FormField
        label="Email"
        errors={
          emailAddressError
            ? [
              t(
                TranslationKeys.modals.quotationModal.form.emailError,
                'email is vereist'
              ),
            ]
            : undefined
        }
      >
        <Input
          type="text"
          name="email"
          placeholder="J.Janssen@contoso.net"
          className={emailAddressError ? styles.error : ''}
          onChange={(e) => setEmailAddress((e.target as any).value)}
        />
      </FormField>

      <FormField
        label={t(
          TranslationKeys.modals.quotationModal.form.phoneNumber,
          'Mobiel telefoonnummer'
        )}
        errors={
          phoneNumberError
            ? [
              t(
                TranslationKeys.modals.quotationModal.form.phoneNumberError,
                'telefoonnumer is vereist'
              ),
            ]
            : undefined
        }
        className={styles['-two-inputs']}
      >
        <div style={{ display: 'flex' }}>
          <FormSelect
            style={{ width: 88 }}
            onChange={(value: string) => {
              setPhoneNumberPrefix(value);
            }}
            name="mobile-start"
            selected={phoneNumberPrefix}
            options={countryCodes}
          />
          <Input
            type="number"
            name="phoneNumber"
            placeholder="612345678"
            className={phoneNumberError ? styles.error : ''}
            onChange={(e) => setPhoneNumber((e.target as any).value)}
          />
        </div>
      </FormField>

      <Text as={'h4'} className={styles.subtitle}>
        {t(TranslationKeys.modals.quotationModal.questionAreaTitle)}
      </Text>
      <FormField
        label={t(TranslationKeys.generic.description, 'Omschrijving')}
        className={styles['-two-inputs']}
        optional
      >
        <TextArea
          placeholder={t(
            TranslationKeys.modals.quotationModal.questionPlaceHolder
          )}
          onChange={(e) => setDescription((e.target as any).value)}
        ></TextArea>
      </FormField>
      <Conditional condition={(clientInformation.privacyPolicyUrl ?? '').length > 0}>
        <label
          onClick={() => setHasAcceptedPrivacyPolicy(!hasAcceptedPrivacyPolicy)}
        >
          <input
            type="checkbox"
            checked={hasAcceptedPrivacyPolicy}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={() =>
              setHasAcceptedPrivacyPolicy(!hasAcceptedPrivacyPolicy)
            }
          />
          <label className={styles.privacyPolicyLabel}>
            <Text>
              Ik ga akkoord met het{' '}
              <u
                onClick={() => {
                  if (clientInformation.privacyPolicyUrl) {
                    window.open(clientInformation.privacyPolicyUrl);
                  }
                }}
              >
                privacy policy
              </u>
            </Text>
          </label>
        </label>
      </Conditional>
      <Button
        size={'large'}
        style={{
          backgroundColor: clientInformation.primaryColor,
          color: getContrastColor(clientInformation.primaryColor),
        }}
        as={<div />}
        disabled={!hasAcceptedPrivacyPolicy}
        onClick={() => {
          onSubmit();
        }}
      >
        {t(TranslationKeys.pages.summaryPage.cta, 'Aanvraag versturen')}
      </Button>
    </form>
  );
};
