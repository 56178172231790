import React, {
  CSSProperties, FocusEventHandler,
  Fragment,
  FunctionComponent, KeyboardEventHandler,
  MouseEventHandler, Ref,
  SyntheticEvent, TouchEventHandler, useRef,
  useState
} from 'react';
import { AutocompletePrediction } from 'react-places-autocomplete';
import debounce from 'lodash/debounce';
import { GeocoderStatus } from '@hec/models';


export interface Suggestion {
  id: string;
  active: boolean;
  index: number;
  description: AutocompletePrediction["description"];
  placeId: AutocompletePrediction["place_id"];
  formattedSuggestion: {
    mainText: AutocompletePrediction["structured_formatting"]["main_text"];
    secondaryText: AutocompletePrediction["structured_formatting"]["secondary_text"];
  };
  matchedSubstrings: AutocompletePrediction["matched_substrings"];
  terms: AutocompletePrediction["terms"];
  types: AutocompletePrediction["types"];
}

export const compose = <T extends Function>(...fns: Array<T|undefined>) => (...args: unknown[]) => {
  fns.forEach(fn => fn && fn(...args));
};
export interface SuggestionProps extends Object {
  onKeyDown?: MouseEventHandler;
  className?: string;
  style?: CSSProperties;
  onBlur?: MouseEventHandler;
  onMouseEnter?: MouseEventHandler;
  onMouseUp?: MouseEventHandler;
  onMouseLeave?: MouseEventHandler;
  onMouseDown?: MouseEventHandler;
  onTouchStart?: TouchEventHandler;
  onTouchEnd?: TouchEventHandler;
  onClick?: MouseEventHandler;
}
export interface InputProps extends Object {
  value?: string;
  placeholder?: string;
  className?: string;
  onChange?: (ev: { target: { value: string }}) => void;
  onBlur?: FocusEventHandler;
  onKeyDown?: KeyboardEventHandler;
  disabled?: boolean;
}
export interface PlacesAutoCompleteProps {
  onChange?: ((value?: string) => void) | undefined;
  value?: string | undefined;
  fetchPredictionsCallback?: (address?: string) => Promise<{ status: GeocoderStatus, results: AutocompletePrediction[] }>;
  onError?: ((status: string, clearSuggestion: () => void) => void) | undefined;
  onSelect?: ((suggestion: Suggestion) => void) | undefined;
  debounceAmount?: number | undefined;
  shouldFetchSuggestions?: boolean;
  highlightFirstSuggestion?: boolean;
  children: (opts: Readonly<{
    loading: boolean;
    suggestions: ReadonlyArray<Suggestion>;
    getInputProps: (options?: InputProps) => {
      type: string;
      autoComplete: string;
      className?: string;
      role: string;
      'aria-autocomplete': "list" | "none" | "inline" | "both" | undefined;
      'aria-expanded': boolean;
      'aria-activedescendant': string | undefined;
      disabled?: boolean;
      ref?: Ref<HTMLInputElement>;
      onKeyDown: KeyboardEventHandler;
      onBlur: React.FocusEventHandler;
      value: string | undefined;
      onChange: (ev: SyntheticEvent<HTMLInputElement>) => void;
    };
    getSuggestionItemProps: (suggestion: Suggestion, options?: SuggestionProps) => {
      key: string;
      id: string | undefined;
      role: string;
      onMouseEnter: MouseEventHandler;
      onMouseLeave: MouseEventHandler;
      onMouseDown: MouseEventHandler;
      onMouseUp: MouseEventHandler;
      onTouchStart: TouchEventHandler;
      onTouchEnd: TouchEventHandler;
      onClick: MouseEventHandler;
    };
  }>) => React.ReactNode;
}

// transform snake_case to camelCase
const formattedSuggestion = (structured_formatting: { main_text: string, secondary_text: string }) => ({
  mainText: structured_formatting.main_text,
  secondaryText: structured_formatting.secondary_text,
});

export const PlacesAutoComplete: FunctionComponent<PlacesAutoCompleteProps> =
  (
    {
      onSelect,
      onChange,
      children,
      value,
      onError,
      fetchPredictionsCallback,
      shouldFetchSuggestions = true,
      highlightFirstSuggestion = true,
      debounceAmount = 200,
    },
  ) => {
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [mousedownOnSuggestion, setMousedownOnSuggestion] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [userInputValue, setUserInputValue] = useState<string>();

  const getActiveSuggestionId = () => {
    const activeSuggestion = suggestions.find(suggestion => suggestion.active);
    return activeSuggestion
      ? `PlacesAutocomplete__suggestion-${activeSuggestion.placeId}`
      : undefined;
  };

  const fetchPredictions = async (value?: string) => {
    const result = fetchPredictionsCallback ? await fetchPredictionsCallback(value) : undefined;
    if (result) {
      autocompleteCallback(result.results, result.status);
    }
  }

  const getActiveSuggestion = () => {
    return suggestions.find(suggestion => suggestion.active);
  };

  const clearActive = () => {
    setSuggestions(_suggestions => {
      return _suggestions
        .map(suggestion => ({ ...suggestion, active: false,}));
    });
  };

  const handleSuggestionMouseLeave = () => {
    setMousedownOnSuggestion(false);
    clearActive();
  };

  const handleSuggestionMouseDown: MouseEventHandler = (event: SyntheticEvent) => {
    event.preventDefault();
    setMousedownOnSuggestion(true);
  };

  const autocompleteCallback = (predictions: AutocompletePrediction[], status: GeocoderStatus) => {
    setLoading(false);
    if (status !== GeocoderStatus.OK && onError) {
      onError(status, clearSuggestions);
      return;
    }
    setSuggestions(predictions.map((p, idx) => ({
      id: `${p.place_id}_${idx}`,
      description: p.description,
      placeId: p.place_id,
      active: highlightFirstSuggestion && idx === 0 ? true : false,
      index: idx,
      formattedSuggestion: formattedSuggestion(p.structured_formatting),
      matchedSubstrings: p.matched_substrings,
      terms: p.terms,
      types: p.types,
    })));
  };

  const handleSuggestionMouseUp = () => {
    setMousedownOnSuggestion(false);
  };

  const handleSuggestionTouchStart = () => {
    setMousedownOnSuggestion(true);
  };

  const handleSuggestionMouseEnter = (index: number, event: SyntheticEvent) => {
    setActiveAtIndex(index);
  };

  const setActiveAtIndex = (index: number) => {
    setSuggestions(_suggestions => {
      return _suggestions.map((suggestion, idx) => {
        if (idx === index) {
          return { ...suggestion, active: true };
        } else {
          return { ...suggestion, active: false };
        }
      })
    });
  };

  const clearSuggestions = () => {
    setSuggestions([]);
  };

  const handleSelect = (suggestion: Suggestion) => {
    clearSuggestions();
    if (onSelect) {
      onSelect(suggestion);
    }
    if (onChange) {
      onChange(suggestion.description);
    }
  };

  const handleSuggestionClick = (suggestion?: Suggestion, event?: SyntheticEvent) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    if (suggestion) {
      handleSelect(suggestion);
      setTimeout(() => {
        setMousedownOnSuggestion(false)
      });
    }
  };

  const selectActiveAtIndex = (index: number) => {
    const activeSuggestion = suggestions.find(
      suggestion => suggestion.index === index
    );
    if (activeSuggestion) {
      const activeName = activeSuggestion.description;
      setActiveAtIndex(index);
      if (onChange) {
        onChange(activeName);
      }
    }
  };

  const selectUserInputValue = () => {
    clearActive();
    if (onChange) {
      onChange(userInputValue);
    }
  };

  const handleUpKey = () => {
    if (suggestions.length === 0) {
      return;
    }

    const activeSuggestion = getActiveSuggestion();

    if (activeSuggestion === undefined) {
      selectActiveAtIndex(suggestions.length - 1);
    } else if (activeSuggestion.index === 0) {
      selectUserInputValue();
    } else {
      selectActiveAtIndex(activeSuggestion.index - 1);
    }
  };

  const handleEnterKey = () => {
    const activeSuggestion = getActiveSuggestion();
    if (activeSuggestion === undefined) {
      //handleSelect(value, undefined);
    } else {
      handleSelect(activeSuggestion);
    }
  };

  const handleDownKey = () => {
    if (suggestions.length === 0) {
      return;
    }

    const activeSuggestion = getActiveSuggestion();
    if (activeSuggestion === undefined) {
      selectActiveAtIndex(0);
    } else if (activeSuggestion.index === suggestions.length - 1) {
      selectUserInputValue();
    } else {
      selectActiveAtIndex(activeSuggestion.index + 1);
    }
  };

  const handleInputKeyDown = (event: SyntheticEvent) => {
    /* eslint-disable indent */
    switch ((event as unknown as KeyboardEvent).key) {
      case 'Enter':
        event.preventDefault();
        handleEnterKey();
        break;
      case 'ArrowDown':
        event.preventDefault(); // prevent the cursor from moving
        handleDownKey();
        break;
      case 'ArrowUp':
        event.preventDefault(); // prevent the cursor from moving
        handleUpKey();
        break;
      case 'Escape':
        clearSuggestions();
        break;
    }
    /* eslint-enable indent */
  };

  const getSuggestionItemProps = (suggestion: Suggestion, options?: SuggestionProps) => {
    const _handleSuggestionMouseEnter = (event: SyntheticEvent) => handleSuggestionMouseEnter(suggestion.index, event);
    const _handleSuggestionClick = (event?: SyntheticEvent) => handleSuggestionClick(suggestion, event);
    return {
      ...options,
      key: suggestion.id,
      id: getActiveSuggestionId(),
      role: 'option',
      onMouseEnter: options ? compose(_handleSuggestionMouseEnter, options.onMouseEnter): _handleSuggestionMouseEnter,
      onMouseLeave: options ? compose(handleSuggestionMouseLeave, options.onMouseLeave): handleSuggestionMouseLeave,
      onMouseDown: options ? compose(handleSuggestionMouseDown, options.onMouseDown): handleSuggestionMouseDown,
      onMouseUp: options ? compose(handleSuggestionMouseUp, options.onMouseUp): handleSuggestionMouseUp,
      onTouchStart: options ? compose(handleSuggestionTouchStart, options.onTouchStart): handleSuggestionTouchStart,
      onTouchEnd: options ? compose(handleSuggestionMouseUp, options.onTouchEnd): handleSuggestionMouseUp,
      onClick: options ? compose(_handleSuggestionClick, options.onClick): _handleSuggestionClick,
    };
  };

  const debouncedFetchPredictions = useRef(debounce(
    fetchPredictions,
    debounceAmount
  )).current;

  const handleInputOnBlur = () => {
    if (!mousedownOnSuggestion) {
      clearSuggestions();
    }
  };

  const handleInputChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    if (onChange) {
      onChange(value);
    }
    setUserInputValue(value);
    if (!value) {
      clearSuggestions();
      return;
    }
    if (shouldFetchSuggestions) {
      debouncedFetchPredictions(value);
    }
  };

  const getInputProps = (options?: InputProps) => {
    if (options && options.hasOwnProperty('value')) {
      throw new (Error as any)(
        '[react-places-autocomplete]: getInputProps does not accept `value`. Use `value` prop instead'
      );
    }

    if (options && options.hasOwnProperty('onChange')) {
      throw new (Error as any)(
        '[react-places-autocomplete]: getInputProps does not accept `onChange`. Use `onChange` prop instead'
      );
    }

    const defaultInputProps = {
      type: 'text',
      autoComplete: 'off',
      role: 'combobox',
      'aria-autocomplete': "list" as "list",
      'aria-expanded': suggestions.length > 0,
      'aria-activedescendant': getActiveSuggestionId(),
    };

    return {
      ...defaultInputProps,
      ...options,
      value,
      onKeyDown: options ? (compose<KeyboardEventHandler>(handleInputKeyDown, options.onKeyDown) as KeyboardEventHandler) : handleInputKeyDown,
      onBlur: options ? compose<FocusEventHandler>(handleInputOnBlur, options.onBlur) : handleInputOnBlur,
      onChange: (event: SyntheticEvent<HTMLInputElement>) => {
        handleInputChange(event);
      },
    };
  };
  return (
    <Fragment>
      {children({
        loading,
        suggestions,
        getInputProps,
        getSuggestionItemProps,
      })}
    </Fragment>
  );
};
