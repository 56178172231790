import { HomeExtensionClientConfiguration } from '@hec/models';

export const APPLY_CLIENT_CONFIGURATION = 'APPLY_CLIENT_CONFIGURATION';
export const REDUCER_APPLY_CLIENT_CONFIGURATION = 'REDUCER_APPLY_CLIENT_CONFIGURATION';

export const applyClientConfiguration = (clientConfiguration: HomeExtensionClientConfiguration) => ({
  type: APPLY_CLIENT_CONFIGURATION,
  data: {
    clientConfiguration,
  },
});
