import React, { FunctionComponent } from 'react';
import styles from './header.module.scss';
import { HomeExtensionClientConfiguration } from '@hec/models';
import { CostAggregate } from '../CostAggregate';
import { Conditional } from '../Conditional';
import classnames from 'classnames';

export interface HeaderProps {
  clientConfiguration?: HomeExtensionClientConfiguration;
  totalPrice?: number;
  fullScreen?: boolean;
  hidePrice?: boolean;
  showCostAggregate?: boolean;
}

export const Header: FunctionComponent<HeaderProps> = ({
  clientConfiguration,
  totalPrice,
  fullScreen = false,
  hidePrice = false,
  showCostAggregate = true
}) => (
  <div className={styles.header}>
    <a href="/" className={styles['backlink-container']}>
      <Conditional
        condition={clientConfiguration && typeof clientConfiguration.logoUrl !== 'undefined'}
      >
        <div style={{ backgroundImage: `url('${clientConfiguration?.logoUrl}')`}} className={styles.logo} />
      </Conditional>
    </a>
    <Conditional condition={typeof totalPrice !== 'undefined' && showCostAggregate}>
      <div
        className={
          classnames(
            styles['cost-aggregate'],
            {
              [`${styles.hidden}`]: fullScreen
            },
          )
        }
      >
        <CostAggregate
          palette={{
            primaryColor: clientConfiguration?.primaryColor ?? 'black',
            secondaryColor: clientConfiguration?.secondaryColor ?? 'white'
          }}
          hidden={fullScreen}
          hidePrice={hidePrice}
          totalPrice={totalPrice as number}
        />
      </div>
    </Conditional>
  </div>
);
