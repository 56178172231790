import { NotificationType } from '@hec/models';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REDUCER_ADD_NOTIFICATION = 'REDUCER_ADD_NOTIFICATION';

export const AddNotification = (
  message: string,
  duration = 3000,
  shouldBeTranslated = true,
  type: NotificationType = NotificationType.INFO
) => ({
  type: REDUCER_ADD_NOTIFICATION,
  data: {
    message,
    duration,
    shouldBeTranslated,
    type,
  },
});
