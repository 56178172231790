import { FunctionComponent, useState } from 'react';
import { getRandomColor } from '../Utilities';
import { Color, MathUtils, RepeatWrapping } from 'three';
import {
  GARDEN_TILING_DEPTH,
  GRASS_DEPTH,
  GroundDimensions,
} from './CalculateGroundDimensions';
import { useFrame, useThree } from '@react-three/fiber';
import { useTexture } from '@react-three/drei';

interface FenceProps {
  groundDimensions: GroundDimensions;
}

const cdnUrl =
  'https://cdn.aanbouwconfigurator.com/static-assets/generic/garden/FineWood_v3.jpg';

export const Fence: FunctionComponent<FenceProps> = ({
  groundDimensions,
}: FenceProps) => {
  const color = new Color('rgb(149, 76, 14)');

  const [hideEastWall, setHideEastWall] = useState(false);
  const [hideSouthWall, setHideSouthWall] = useState(false);
  const [hideWestWall, setHideWestWall] = useState(false);
  const [lastCheckedAtElapsedTime, setLastCheckedAtElapsedTime] = useState(0);

  const {
    tileWidth,
    westFenceDepth,
    zPositionWestFence,
    eastFenceDepth,
    zPositionEastFence,
    southFenceWidth,
    zPositionSouthFence,
  } = groundDimensions;

  const southDegreeDeadZone = 0.11;

  const map = useTexture(cdnUrl);
  map.wrapS = RepeatWrapping;
  map.wrapT = RepeatWrapping;

  const getMap = (length: number, height: number, index: number) => {
    const innerMap = map.clone();
    const seed = length * height * index * 10;
    //.seededRandom
    const rngMax = 4;

    const randomResult = MathUtils.seededRandom(seed);
    // random number between 1 and 8
    const rng = Math.floor(randomResult * rngMax);

    // random boolean
    const mirror = randomResult < 0.5;

    innerMap.offset.set(rng / rngMax, (rng / rngMax) * 2);
    const repeatX = length / 2;
    const repeatY = height / 2;

    innerMap.repeat.set(
      mirror ? -repeatX : repeatX,
      mirror ? -repeatY : repeatY
    );

    return innerMap;
  };

  useFrame(({ camera, clock }, delta, frame) => {
    // Check the rotation of the user every so many seconds
    const checkEveryAmountOfElapsedTime = 0.25;
    const elapsedTime = clock.elapsedTime;

    if (
      lastCheckedAtElapsedTime + checkEveryAmountOfElapsedTime <
      elapsedTime
    ) {
      setLastCheckedAtElapsedTime(elapsedTime);

      const z = MathUtils.radToDeg(camera.rotation.z);
      const hideSouthWallInner =
        (z < southDegreeDeadZone && z > 0) ||
        (z > -southDegreeDeadZone && z < 0);
      const hideEastAndSouthWallInner = z > southDegreeDeadZone && z < 90;
      const hideWestAndSouthWallInner = z < -southDegreeDeadZone && z > -90;

      const hideSouthWallPrecondition =
        hideSouthWallInner ||
        hideEastAndSouthWallInner ||
        hideWestAndSouthWallInner;
      if (hideSouthWallPrecondition && !hideSouthWall) {
        setHideSouthWall(true);
      } else if (!hideSouthWallPrecondition && hideSouthWall) {
        setHideSouthWall(false);
      }

      if (hideEastAndSouthWallInner && !hideEastWall) {
        setHideEastWall(true);
      } else if (!hideEastAndSouthWallInner && hideEastWall) {
        setHideEastWall(false);
      }

      if (hideWestAndSouthWallInner && !hideWestWall) {
        setHideWestWall(true);
      } else if (!hideWestAndSouthWallInner && hideWestWall) {
        setHideWestWall(false);
      }
    }
  });

  const totalWidth = tileWidth;

  const getFenceSection = (height: number, length: number, index: number) => {
    // const topMap = offsetMaps[Math.floor(Math.random() * offsetMaps.length)];
    // const bottomMap = offsetMaps[Math.floor(Math.random() * offsetMaps.length)];

    return (
      <group position={[0, height, 0]}>
        <mesh receiveShadow castShadow>
          <boxGeometry args={[0.03, 0.2, length]} />
          <meshStandardMaterial
            map={getMap(length, 0.2, index)}
            // color={color ?? getRandomColor()}
            attach="material"
          />
        </mesh>
        <mesh receiveShadow castShadow position={[0.04, 0.15, 0]}>
          <boxGeometry args={[0.03, 0.2, length]} />
          <meshStandardMaterial
            map={getMap(length, 0.2, index)}
            // color={color ?? getRandomColor()}
            attach="material"
          />
        </mesh>
      </group>
    );
  };

  const heightAmount = 7;

  const eastFence = Array.from(
    Array(hideEastWall ? 1 : heightAmount).keys()
  ).map((x) => {
    return getFenceSection(x * 0.3, eastFenceDepth, x);
  });
  const westFence = Array.from(
    Array(hideWestWall ? 1 : heightAmount).keys()
  ).map((x) => {
    return getFenceSection(x * 0.3, westFenceDepth, x);
  });

  const southFence = Array.from(
    Array(hideSouthWall ? 1 : heightAmount).keys()
  ).map((x) => {
    return getFenceSection(x * 0.3, southFenceWidth, x);
  });

  return (
    <group>
      <group
        position={[
          totalWidth + -0.06,
          0,
          zPositionEastFence + eastFenceDepth / 2,
        ]}
      >
        {eastFence}
      </group>
      <group position={[0, 0, zPositionWestFence + westFenceDepth / 2]}>
        {westFence}
      </group>

      <group
        rotation={[0, -Math.PI / 2, 0]}
        position={[southFenceWidth / 2, 0, zPositionSouthFence]}
      >
        {southFence}
      </group>
    </group>
  );
};
