import React, { FunctionComponent } from 'react';

export interface IconArrowDownProps {
}

export const IconArrowDown: FunctionComponent<IconArrowDownProps> = ({}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 7" style={{ transform: 'rotate(45deg)' }}>
      <path
        d="M403.77,133l.31-.31a.18.18,0,0,0,0-.27l-2.85-2.86,2.85-2.86a.18.18,0,0,0,0-.27l-.31-.32a.2.2,0,0,0-.27,0l-3.31,3.31a.2.2,0,0,0,0,.27L403.5,133A.18.18,0,0,0,403.77,133Z"
        transform="translate(-400.14, -126.07)"
      />
    </svg>
  );
};
