import { createSlice } from '@reduxjs/toolkit';
import { addNotificationAction, removeNotificationAction} from './Actions';
import {notificationInitialState} from './State';
import {
  addNotificationReducer,
  removeNotificationReducer,
} from './Reducers';

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState: notificationInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addNotificationAction, addNotificationReducer)
      .addCase(removeNotificationAction, removeNotificationReducer)
  },
});
