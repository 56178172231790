import {createAction} from "@reduxjs/toolkit";


interface PrepareUpdateMeasurementsActionProps {
  width: number | null;
  height: number | null;
}
export const prepareUpdateMeasurementsAction = createAction<PrepareUpdateMeasurementsActionProps>('prepareUpdateMeasurements');


type UpdateMeasurementsActionProps = PrepareUpdateMeasurementsActionProps;
export const updateMeasurementsAction = createAction<UpdateMeasurementsActionProps>('updateMeasurements');

