import {FunctionComponent} from "react";
import {
  FaceDto,
  FrameDto,
  FrameSurroundingRodDto,
  FrameVariation,
  SectionFragmentDto,
  SectionFragmentType
} from "@hec/api-dtos";
import {fromMmToRenderingMeters, getTotalSplinterWidth} from "@hec/core";
import {SingularOpeningWindowTypeFrame} from "./SingularOpeningWindowTypeFrame";
import {SingularFillerPanelFrame} from "./SingularFillerPanelFrame";
import {FRAME_DEPTH_OFFSET, FRAME_SURROUNDING_ROD_THICKNESS} from "../../../../constants";
import {SingularWindowTypeFrame} from "./SingularWindowTypeFrame";
import { SingularCenterPivotWindowTypeFrame} from "./SingularCenterPivotWindowTypeFrame";
import {Base3dProps} from "../Base3dProps";


// export interface SectionFragmentsFrameRendererProps {
//   face: FaceDto;
// }
export interface SectionFragmentsFrameRendererProps extends Base3dProps {
  sectionFragments: SectionFragmentDto[];
  frameSurroundingRod: FrameSurroundingRodDto | undefined;
}




export const SectionFragmentsFrameRenderer: FunctionComponent<SectionFragmentsFrameRendererProps> = ({sectionFragments, visualizationSettings, frameSurroundingRod}: SectionFragmentsFrameRendererProps) => {
  const frames = sectionFragments.filter(x => x.sectionFragmentType === SectionFragmentType.Frame);
  const amountOfFrames = frames.length;


  const frameSurroundingRodThickness = fromMmToRenderingMeters(visualizationSettings.sizeFrameSurroundingRod);

  const widthAmountToSubtractForSurroundingRodsPerFrame = (((frameSurroundingRodThickness * 2) / amountOfFrames));


  const renderedFrames = (frames as FrameDto[]).map((frame) => {
    const index = frames.indexOf(frame);

    const previousSort = (frame.sort ?? 0) - 1;
    const previousFrameWidths: number[] = [];

    for (let i = previousSort; i >= 0; i = i - 1) {
      const frame = frames.find(x => x.sort === i);
      previousFrameWidths.push(frame?.width ?? 0);
    }

    let xFrameOffset = fromMmToRenderingMeters(previousFrameWidths.reduce((accumulator, currentValue) => {
      return accumulator + currentValue
    },0));


    xFrameOffset += frameSurroundingRodThickness;
    xFrameOffset -= (widthAmountToSubtractForSurroundingRodsPerFrame) * (index);


    const variation: FrameVariation = frame.variation!;
    const groupKey = `frame-${frame.sort}`;


    if (variation === FrameVariation.TiltAndTurnWindowRight || variation === FrameVariation.TiltAndTurnWindowLeft) {
      return <group position={[xFrameOffset,0,0]}>
        <SingularOpeningWindowTypeFrame
          amountOfFrames={amountOfFrames}
          frame={frame as FrameDto}
          visualizationSettings={visualizationSettings}
        />
      </group>

    } else if (variation === FrameVariation.Window) {
         return <group key={groupKey} position={[xFrameOffset,0,0]}>
           <SingularWindowTypeFrame
            amountOfFrames={amountOfFrames}
            frame={frame as FrameDto}
            visualizationSettings={visualizationSettings}
            frameSurroundingRod={frameSurroundingRod}
          />
        </group>
    } else if (variation === FrameVariation.Panel && frame.width > 0) {
      return <group key={groupKey} position={[xFrameOffset,0,0]}>
        <SingularFillerPanelFrame
          frame={frame as FrameDto}
          amountOfFrames={amountOfFrames}
          visualizationSettings={visualizationSettings}
        />
      </group>
    } else if (variation === FrameVariation.CenterPivotWindow && frame.width > 0) {
      return <group key={groupKey} position={[xFrameOffset,0,0]}>
        <SingularCenterPivotWindowTypeFrame
          frame={frame as FrameDto}
          amountOfFrames={amountOfFrames}
          visualizationSettings={visualizationSettings}
        />
      </group>
    } else if (variation === FrameVariation.TopHungWindow && frame.width > 0) {
      // Looks the same as CenterPivotWindow
      return <group key={groupKey} position={[xFrameOffset,0,0]}>
        <SingularCenterPivotWindowTypeFrame
          frame={frame as FrameDto}
          amountOfFrames={amountOfFrames}
          visualizationSettings={visualizationSettings}
        />
      </group>
    }

    return <></>

  });


  return <group position={[0, 0, FRAME_DEPTH_OFFSET]}>
    {renderedFrames}
  </group>
}
