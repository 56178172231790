import React, {FunctionComponent} from "react";
import {DrawRectangle} from "../Utils";

interface PanelProps {
  x: number,
  y: number,
  width: number,
  height: number,
}

export const Window: FunctionComponent<PanelProps> = ({x, y, width, height}) => {

  return <DrawRectangle x={x} y={y} width={width} height={height} fill={'#EEF8FF'}/>
}
