import React, {FunctionComponent, useState} from "react";
import {useTranslation} from "react-i18next";
import {
  OptionItem,
  OptionList,
  SidebarFooter
} from "@hec/components/v2";
import {useDispatch, useSelector} from "react-redux";
import {
  getClientInformationSelector, getConfigurationTemplatesSelector, getExtraInformationForKeySelector,
  getOrderAction, getSelectedConfigurationTemplateSelector,
  selectConfigurationTemplateAction
} from "@hec/dal";
import {ConfigurationTemplateModelType} from "@hec/api-dtos";
import styles from './template.module.scss';
import {TranslationKeys} from "@hec/models";
import {RoutePaths} from "../../routing";
import {ExtraInformationForKeyRenderer} from "../../components";

export const TemplateSideBarContent: FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const clientInformation = useSelector(getClientInformationSelector);
  const selectedConfigurationTemplate = useSelector(getSelectedConfigurationTemplateSelector);
  const configurationTemplates = useSelector(getConfigurationTemplatesSelector);

  const [selectedConfigurationTemplateId, setSelectedConfigurationTemplateId] = useState<string | null>(selectedConfigurationTemplate ? selectedConfigurationTemplate.id : null);

  if (clientInformation === null) {
    return <></>;
  }

  return (
    <>
      <div className={styles.sections}>
        <ExtraInformationForKeyRenderer
          extraInformationKey={'TemplatesStepExtraInformation'}
        />
        <OptionList
          items={configurationTemplates.map(configurationTemplate => {
            let image: JSX.Element = <></>;
            if (configurationTemplate.modelType === ConfigurationTemplateModelType.Oncoming) {
              image = (
                <svg width="39" height="27" fill={'none'} xmlns="http://www.w3.org/2000/svg">
                  <path clipRule="evenodd" d="M4.112 26h30.776V6.664H4.112V26Z" stroke="#231F20" strokeWidth="1.416"/>
                  <path d="M19.5 6.664V26" stroke="#231F20" strokeWidth="1.416"/>
                  <path clipRule="evenodd" d="M1 6.664h37V1H1v5.664Z" stroke="#231F20" strokeWidth="1.416"/>
                </svg>
              );
            }
            if (configurationTemplate.modelType === ConfigurationTemplateModelType.Deviator) {
              image = (
                <svg width="44" height="27" fill={'none'} xmlns="http://www.w3.org/2000/svg">
                  <path clipRule="evenodd" d="M6.67 26h30.61V6.67H6.67V26Z" stroke="#231F20" strokeWidth="1.415"/>
                  <path d="M21.975 6.67V26" stroke="#231F20" strokeWidth="1.415"/>
                  <path clipRule="evenodd" d="M37.28 1.01H6.72V1H1v24.99h5.72V6.67h30.56V26H43V1.01h-5.72Z" stroke="#231F20" strokeWidth="1.415"/>
                </svg>
              );
            }
            return {
              id: configurationTemplate.id,
              name: configurationTemplate.name,
              imageSvg: image,
              help: configurationTemplate.helpText,
            } as OptionItem
          })}
          selectedItem={selectedConfigurationTemplateId}
          setSelectedItem={(itemId) => {
            setSelectedConfigurationTemplateId(itemId);
            dispatch(selectConfigurationTemplateAction({configurationTemplateId: itemId}));
          }}
          primaryColor={clientInformation.primaryColor}
        />
      </div>

      <SidebarFooter
        nextRoutePath={RoutePaths.MeasurementsPath}
        nextRouteCTA={t(TranslationKeys.dormer.pages.measurementsPage.cta)}
        onGoNext={() => {
          dispatch(getOrderAction())
        }}
        nextRouteError={selectedConfigurationTemplateId ? '' : t(TranslationKeys.dormer.pages.templatePage.noTemplateSelectedError)}
        clientInformation={clientInformation}
      />
    </>
  );
};
