import { AnyAction } from 'redux';
import { put, select } from 'redux-saga/effects';
import {
  REDUCER_SET_MEASUREMENTS,
  REDUCER_REMOVE_PRODUCT,
  REDUCER_LIMBO_ACTIONS,
  REDUCER_PROMPT_CONFIGURATION_CHANGE,
  RECALCULATE_PRICE, selectProduct, selectProducts,
} from '../../../';
import { HomeExtensionRootState } from '../../../';
import { pickSuitableFrameSizeHandler } from './PickSuitableFrameSizeHandler';
import {
  FrameProduct, HasId,
  HomeConfigurationState,
  IsCladdingProduct,
  IsFrameProduct, IsRoofLightProduct, Product, RoofLightProduct,
} from '@hec/models';
import { pickSuitableRoofLightSizeHandler } from './PickSuitableRoofLightSizeHandler';

export function* SetMeasurementsHandler(action: AnyAction) {
  const { selectedProducts, products }: HomeConfigurationState =  yield select(
    (state: HomeExtensionRootState) => state.homeConfigurationReducer);

  const previouslySelectedFrame = selectedProducts.find(IsFrameProduct);
  const previouslySelectedRoofLight = selectedProducts.find(IsRoofLightProduct);
  const suitableNextFrame: FrameProduct|undefined = yield pickSuitableFrameSizeHandler(
    previouslySelectedFrame ? previouslySelectedFrame.name : undefined,
    previouslySelectedFrame ? previouslySelectedFrame.drawableObject.color : undefined,
    action.data.measurements.width,
    previouslySelectedFrame ? previouslySelectedFrame.productionMaterialType : undefined,
  );

  const suitableNextRoofLight: RoofLightProduct|undefined = yield pickSuitableRoofLightSizeHandler(
    (previouslySelectedRoofLight && previouslySelectedRoofLight.drawableObject) ? previouslySelectedRoofLight.drawableObject.specification : undefined,
    (previouslySelectedRoofLight && previouslySelectedRoofLight.drawableObject) ? previouslySelectedRoofLight.productionMaterialType : undefined,
    (previouslySelectedRoofLight && previouslySelectedRoofLight.drawableObject) ? previouslySelectedRoofLight.drawableObject.color : undefined,
    action.data.measurements.width,
    action.data.measurements.depth,
  );

  const baseActions: AnyAction[] = [
    { type: REDUCER_SET_MEASUREMENTS, data: action.data },
  ];

  const conflictedProducts: Product[] = [];
  const productsAfterResize: Product[] = [];

  if (!suitableNextFrame && previouslySelectedFrame) {
    conflictedProducts.push(previouslySelectedFrame);
  } else if (suitableNextFrame) {
    productsAfterResize.push(suitableNextFrame);
  }

  if (!suitableNextRoofLight && previouslySelectedRoofLight) {
    conflictedProducts.push(previouslySelectedRoofLight);
  } else if (suitableNextRoofLight) {
    productsAfterResize.push(suitableNextRoofLight);
  }

  if (conflictedProducts.length === 0) {
    const actions = [
      ...baseActions,
      selectProducts(productsAfterResize),
    ];
    const selectedCladdingProduct = selectedProducts.find(IsCladdingProduct);
    if (selectedCladdingProduct) {
      const selectedOriginal = products.find(HasId(selectedCladdingProduct.id));
      if (selectedOriginal) {
        actions.push(selectProduct(selectedOriginal));
      }
    }
    for (const a of [...actions, { type: RECALCULATE_PRICE }]) {
      yield put(a);
    }
  } else if (conflictedProducts.length > 0) {
    const actions = [
      ...baseActions,
      ...conflictedProducts.map((p) => ({ type: REDUCER_REMOVE_PRODUCT, data: { product: p }})),
      selectProducts(productsAfterResize),
    ];
    const selectedCladdingProduct = selectedProducts.find(IsCladdingProduct);
    if (selectedCladdingProduct) {
      const selectedOriginal = products.find(HasId(selectedCladdingProduct.id));
      if (selectedOriginal) {
        actions.push(selectProduct(selectedOriginal));
      }
    }
    actions.push({ type: RECALCULATE_PRICE });
    yield put({ type: REDUCER_LIMBO_ACTIONS, data: {
      actions,
        limboKey: action.type,
      }}
    );
    yield put({ type: REDUCER_PROMPT_CONFIGURATION_CHANGE, data: {
        conflictedProducts,
        limboKey: action.type,
      }})
  } else {
    const selectedCladdingProduct = selectedProducts.find(IsCladdingProduct);
    if (selectedCladdingProduct) {
      const selectedOriginal = products.find(HasId(selectedCladdingProduct.id));
      if (selectedOriginal) {
        baseActions.push(selectProduct(selectedOriginal));
      }
    }
    for (const a of [...baseActions, { type: RECALCULATE_PRICE }]) {
      yield put(a);
    }
  }
}
