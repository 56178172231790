export const countries = [
  {
    value: 'Brazilië',
    label: 'Brazilië',
  },
  {
    value: 'België',
    label: 'België',
  },
  {
    value: 'Bulgarije',
    label: 'Bulgarije',
  },
  {
    value: 'Canada',
    label: 'Canada',
  },
  {
    value: 'Cyprus',
    label: 'Cyprus',
  },
  {
    value: 'Duitsland',
    label: 'Duitsland',
  },
  {
    value: 'Engeland',
    label: 'Engeland',
  },
  {
    value: 'Finland',
    label: 'Finland',
  },
  {
    value: 'Frankrijk',
    label: 'Frankrijk',
  },
  {
    value: 'Gibraltar',
    label: 'Gibraltar',
  },
  {
    value: 'Hongarije',
    label: 'Hongarije',
  },
  {
    value: 'Griekenland',
    label: 'Griekenland',
  },
  {
    value: 'India',
    label: 'India',
  },
  {
    value: 'Luxemburg',
    label: 'Luxemburg',
  },
  {
    value: 'Malta',
    label: 'Malta',
  },
  {
    value: 'Nederland',
    label: 'Nederland',
  },
  {
    value: 'Spanje',
    label: 'Spanje',
  },
  {
    value: 'Polen',
    label: 'Polen',
  },
  {
    value: 'Portugal',
    label: 'Portugal',
  },
  {
    value: 'Verenigde Staten',
    label: 'Verenigde Staten',
  },
];
