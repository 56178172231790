import React, {FunctionComponent, useState} from "react";
import styles from "./selectable-frame-selector.module.scss";
import {Conditional, Text} from "@hec/components/v2";
import {useTranslation} from "react-i18next";
import {FRAME_VARIATION_DATA, FrameVariationData} from "../FrameVariations";
import {OutsideAlerter} from "@hec/components/v2";
import {useSelector} from "react-redux";
import {FrameDto} from "@hec/api-dtos";
import {getFaceConfiguration, getSelectableFramesSelector, getSplinter, getStubbed} from "@hec/dal";
import {TranslationKeys} from "@hec/models";

interface SelectableFrameSelectorProps {
  frame: FrameDto;
  changeFrameCopiedFrom: (sort: number, copiedFromId: string) => void;
}

export const SelectableFrameSelector: FunctionComponent<SelectableFrameSelectorProps> = ({frame, changeFrameCopiedFrom}) => {
  const { t } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const faceConfiguration = useSelector(getFaceConfiguration)!;
  const selectableFrames = useSelector(getSelectableFramesSelector);
  const selectedFrameVariation: FrameVariationData | undefined = FRAME_VARIATION_DATA.find((selectableFrame) => selectableFrame.variation === frame.variation)!;
  const splinter = useSelector(getSplinter);
  const stubbed = useSelector(getStubbed);

  if (faceConfiguration === null || splinter === null || stubbed === null) {
    return <></>;
  }

  const desiredFrameHeight = faceConfiguration.height! - (splinter.height * 2) - stubbed.height;

  const openDropdown = () => {
    setDropdownOpen(true);
  }
  const closeDropdown = () => {
    setDropdownOpen(false);
  }

  return (
    <OutsideAlerter callback={closeDropdown}>
      <div className={styles.container}>
        <button className={styles.currentFrameDisplay} onClick={openDropdown}>
          <div className={styles.currentFrameDisplayLeft}>
            {selectedFrameVariation != undefined ? selectedFrameVariation.icon() : <></>}
          </div>
          <div className={styles.currentFrameDisplayCenter}>
            <Text as={'h6'}>
              {t(frame.name)}
            </Text>
            <Text as={'h6'} className={styles.price}>
              {/*<Conditional condition={frame.priceCalculationStrategyId !== null}>*/}
              {/*  {`€${frame.priceCalculationStrategy},-`}*/}
              {/*</Conditional>*/}
              {/*<Conditional condition={frame.priceCalculationStrategyId == null}>*/}
              {/*  {t(TranslationKeys.dormer.noPrice)}*/}
              {/*</Conditional>*/}
            </Text>
          </div>
          <div className={styles.currentFrameDisplayRight}>
            <svg width="10" height="6" xmlns="http://www.w3.org/2000/svg">
              <path d="m9.92.553-.447-.47a.262.262 0 0 0-.384 0L5 4.366.91.084a.262.262 0 0 0-.383 0L.08.553a.295.295 0 0 0 0 .402l4.728 4.961a.262.262 0 0 0 .384 0L9.92.956a.295.295 0 0 0 0-.403Z"/>
            </svg>
          </div>
        </button>

        <div className={`${styles.dropdown} ${dropdownOpen ? styles.dropdownOpen : ''}`}>
          {selectableFrames.map((selectableFrame, index) => {
            const frameVariationData: FrameVariationData | undefined = FRAME_VARIATION_DATA.find((frameVariation) => frameVariation.variation === selectableFrame.variation)!;

            let isVariationAvailable = false;
            if (selectableFrame.supportedSizes.length > 0) {
              if (selectableFrame.supportedSizes.some(supportedSize => supportedSize.height === desiredFrameHeight)) {
                isVariationAvailable = true;
              }
              if (selectableFrame.supportedSizes.some(supportedSize => supportedSize.height === null)) {
                isVariationAvailable = true;
              }
            } else {
              const isMinHeightInfinite = selectableFrame.minHeight === null;
              const isMaxHeightInfinite = selectableFrame.maxHeight === null;

              if (isMinHeightInfinite && isMaxHeightInfinite) {
                // Both minHeight and maxHeight are infinite
                isVariationAvailable = true;
              } else if (isMinHeightInfinite && desiredFrameHeight <= selectableFrame.maxHeight!) {
                // Only minHeight is infinite
                isVariationAvailable = true;
              } else if (isMaxHeightInfinite && desiredFrameHeight >= selectableFrame.minHeight!) {
                // Only maxHeight is infinite
                isVariationAvailable = true;
              } else if (
                desiredFrameHeight >= selectableFrame.minHeight! &&
                desiredFrameHeight <= selectableFrame.maxHeight!
              ) {
                // Both minHeight and maxHeight are defined and desiredFrameHeight is within the range
                isVariationAvailable = true;
              }
            }

            return (
              <button
                className={styles.option}
                key={index}
                disabled={!isVariationAvailable}
                onClick={() => {
                  changeFrameCopiedFrom(frame.sort!, selectableFrame.id!);
                  closeDropdown();
                }}
              >
                <div className={styles.left}>
                  {frameVariationData != undefined ? frameVariationData.icon() : <></>}
                </div>
                <div className={styles.right}>
                  <Text as={'span'}>
                    {t(selectableFrame.name)}
                  </Text>
                  {/*<Conditional condition={selectableFrame.priceCalculationStrategyId !== null}>*/}
                  {/*  {`€${selectableFrame.priceCalculationStrategy},-`}*/}
                  {/*</Conditional>*/}
                  {/*<Conditional condition={selectableFrame.priceCalculationStrategyId !== null}>*/}
                  {/*  {t(TranslationKeys.dormer.noPrice)}*/}
                  {/*</Conditional>*/}
                </div>
              </button>
            );
          })}
        </div>
      </div>
    </OutsideAlerter>
  );
};
