import {
  ProductWithDrawableObjectWithSpecification
} from './';

export enum RoofLightPlacement {
  AGAINST_WALL = 'AgainstWall',
  FREESTANDING = 'Freestanding'
}

export enum RoofLightTopType {
  LECTERN = "Lectern",
  GABLE = "Gable"
}

export enum RoofLightWindowType {
  GRILL = "Grill",
  WITHOUT_GRILL = "WithoutGrill",
}

export interface RoofLightSpecification extends Record<string, unknown>{
  name: string;
  roofLightPlacementType: RoofLightPlacement;
  roofLightTopType: RoofLightTopType
  roofLightWindowType: RoofLightWindowType
}
export type RoofLightProduct = ProductWithDrawableObjectWithSpecification<RoofLightSpecification>;
