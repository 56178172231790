import { put, select } from 'redux-saga/effects';
import {
  BaseProductPriceCalculationStrategy,
  CladdingProductPriceCalculationStrategy,
  RoofOverhangProductPriceCalculationStrategy,
  HomeConfigurationState,
  RoofTrimProductPriceCalculationStrategy,
  FrameHeaderProductPriceCalculationStrategy,
} from '@hec/models';
import {
  recalculatePrice,
  REDUCER_SET_PRODUCTS,
  REDUCER_SET_SELECTED_PRODUCTS,
} from '../../../';
import { HomeExtensionRootState } from '../../../';

export function* PriceCalculationHandler() {
  const state: HomeConfigurationState = yield select(
    (state: HomeExtensionRootState) => state.homeConfigurationReducer
  );
  const { selectedProducts, productsShadow } = state;

  const selectedIds = selectedProducts.map((p) => p.id);
  const selectedProductsDissection = productsShadow.filter((selectedProduct) =>
    selectedIds.includes(selectedProduct.id)
  );

  const recalculatedSelectedProducts = selectedProductsDissection
    .map(BaseProductPriceCalculationStrategy(state))
    .map(CladdingProductPriceCalculationStrategy(state))
    .map(RoofOverhangProductPriceCalculationStrategy(state))
    .map(RoofTrimProductPriceCalculationStrategy(state))
    .map(FrameHeaderProductPriceCalculationStrategy(state));

  const recalculatedProducts = productsShadow
    .map(BaseProductPriceCalculationStrategy(state))
    .map(CladdingProductPriceCalculationStrategy(state))
    .map(RoofOverhangProductPriceCalculationStrategy(state))
    .map(RoofTrimProductPriceCalculationStrategy(state))
    .map(FrameHeaderProductPriceCalculationStrategy(state));

  const price: number = recalculatedSelectedProducts.reduce((prev, current) => {
    return prev + current.priceExVat;
  }, 0);

  yield put({
    type: REDUCER_SET_PRODUCTS,
    data: { products: recalculatedProducts },
  });
  yield put({
    type: REDUCER_SET_SELECTED_PRODUCTS,
    data: { products: recalculatedSelectedProducts },
  });
  yield put(recalculatePrice(price));
}
