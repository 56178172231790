import React, { FunctionComponent } from 'react';
import { ClippedGeometry, ClippingContext } from '../ClippedGeometry';
import { Box } from '@react-three/drei';
import { Conditional, DEFAULT_ROOF_THICKNESS, DEFAULT_WALL_THICKNESS } from "@hec/components/v1";
import { Plane, Texture, Vector3 } from 'three';
import {
  IsRoofLightProduct,
  RoofLightPlacement,
  RoofLightProduct,
} from '@hec/models';
import { HomeExtensionDto } from '../HomeExtensionDto';
import { ClippingUtilities } from '../ClippingUtilities';
import { RoofLight } from './RoofLight';

export interface ClippedRoofInnerProps {
  homeExtensionDto: HomeExtensionDto;
  roofTexture: Texture;
  hasOverhang?: boolean;
}

const getClippingPlanes = (roofLightProduct: RoofLightProduct, zOffset: number): Plane[] => {
  const offset = roofLightProduct.drawableObject?.specification.roofLightPlacementType === RoofLightPlacement.AGAINST_WALL ? zOffset : 0;
  return roofLightProduct.drawableObject ?
    ClippingUtilities.transformClippingBox(ClippingUtilities.getClippingBox(
        new Vector3(
          roofLightProduct.drawableObject.width,
          1,
          roofLightProduct.drawableObject.depth)),
      new Vector3(0, 0, offset)): [];
}

export const ClippedRoofInner: FunctionComponent<ClippedRoofInnerProps> = ({ homeExtensionDto, hasOverhang, roofTexture }) => {
  const [widthInMeters, heightInMeters, depthInMeters] = homeExtensionDto.getMeasurements().toArray();

  const roofLightProduct = homeExtensionDto.getExtraByType(IsRoofLightProduct) as RoofLightProduct|undefined;
  const planeDepth = hasOverhang ? depthInMeters : depthInMeters - DEFAULT_WALL_THICKNESS ;

  const roofOrigin = (depthInMeters) / 2;
  const clippingPlanes = roofLightProduct && roofLightProduct.drawableObject ?
    getClippingPlanes(roofLightProduct, -(roofOrigin - roofLightProduct.drawableObject?.depth / 2)) : [];

  return (
    <>
      <ClippingContext.Provider value={{ clippingPlanes }}>
        <ClippedGeometry>
          {
            (ref, clippingPlanes) => {
              return (
                <Box
                  ref={ref}
                  args={[
                    widthInMeters - DEFAULT_WALL_THICKNESS,
                    DEFAULT_ROOF_THICKNESS,
                    planeDepth,
                  ]}
                  receiveShadow
                  castShadow
                  position={new Vector3(widthInMeters / 2, (heightInMeters - (DEFAULT_ROOF_THICKNESS / 2)) + 0.005, (planeDepth) / 2)}
                >
                  <meshStandardMaterial
                    attach="material-0"
                    map={roofTexture}
                  />
                  <meshStandardMaterial
                    attach="material-1"
                    map={roofTexture}
                  />
                  {/*top*/}
                  <meshStandardMaterial
                    attach="material-2"
                    map={roofTexture}
                    clipIntersection={true}
                    clippingPlanes={clippingPlanes}
                  />
                  {/*bottom*/}
                  <meshStandardMaterial
                    attach="material-3"
                    color={'#ffffff'}
                    clipIntersection={true}
                    clippingPlanes={clippingPlanes}
                  />
                  <meshStandardMaterial
                    attach="material-4"
                    map={roofTexture}
                  />
                  <meshStandardMaterial
                    attach="material-5"
                    map={roofTexture}
                    clipIntersection={true}
                    clippingPlanes={clippingPlanes}
                  />
                  <meshStandardMaterial
                    attach="material-6"
                    map={roofTexture}
                  />
                </Box>
              )
            }
          }
        </ClippedGeometry>
      </ClippingContext.Provider>
      <Conditional condition={roofLightProduct}>
        <RoofLight homeExtensionDto={homeExtensionDto} />
      </Conditional>
    </>
  );
};
