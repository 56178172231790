import { createSlice } from '@reduxjs/toolkit';
import {getSelectableFramesSucceededAction} from './Actions';
import {getSelectableFramesSucceededReducer,} from './Reducers';
import {selectableFramesState} from "./State";

export const selectableFramesSlice = createSlice({
  name: 'selectableFrames',
  initialState: selectableFramesState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSelectableFramesSucceededAction, getSelectableFramesSucceededReducer)
  },
});
