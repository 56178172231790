import React, {Suspense, useCallback, useRef} from "react";
import {RoutePaths, Routes} from "../routing";
import {Loader} from "@react-three/drei";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import styles from './App.module.scss';
import {
  getClientInformationSelector,
  getOrderSelector,
  getOrderInvoiceSelector,
  getSelectedConfigurationTemplateSelector,
  getUiStatusSelector,
  storeUiStatusAction,
  getOrderIsDirtySelector,
  getAppConfigurationSelector, getIsOrganisationSelector,
} from "@hec/dal";
import {
  CloseButton,
  Conditional,
  Drawer,
  Header,
  Modal,
  Navigation,
  Text,
  useWindowDimensions,
} from "@hec/components/v2";
import {DormerModalContentKey, DormerVisualizationSettings, TranslationKeys} from "@hec/models";
import classnames from "classnames";
import {fromMmToRenderingMeters, MEDIA_L} from "@hec/core";
import {ModalContentFactory} from "../modals";
import {Tooltip} from "react-tooltip";
import {useLocation} from "react-router-dom";
import {Dormer3dRenderer, Dormer2dRenderer} from "../components";
import {ClientConfigurationDormerSpecificSettingsClientVariableSettingsVisualizationType} from "@hec/api-dtos";
import {DrawerIcons} from "../components/";
import {Dormer2dRendererZoomWrapper} from "../components/Dormer2dRenderer/Dormer2dRendererZoomWrapper";
export function App() {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const appConfiguration = useSelector(getAppConfigurationSelector);

  // const notifications = useSelector(getNotificationsSelector);
  const selectedConfigurationTemplate = useSelector(getSelectedConfigurationTemplateSelector);
  const order = useSelector(getOrderSelector);
  const clientInformation = useSelector(getClientInformationSelector);
  const uiStatus = useSelector(getUiStatusSelector);
  const orderInvoice = useSelector(getOrderInvoiceSelector);
  const orderIsDirty = useSelector(getOrderIsDirtySelector);
  const isOrganisation = useSelector(getIsOrganisationSelector);


  const zoomInRef = useRef<() => void>(() => {});
  const zoomOutRef = useRef<() => void>(() => {});

  const setZoomInRef = useCallback((node: () => void) => {
    zoomInRef.current = node;
  }, []);

  const setZoomOutRef = useCallback((node: () => void) => {
    zoomOutRef.current = node;
  }, []);

  const handleZoomInClicked = () => {
    if (zoomInRef.current) {
      zoomInRef.current();
    }
  };

  const handleZoomOutClicked = () => {
    if (zoomOutRef.current) {
      zoomOutRef.current();
    }
  };

  const {windowWidth, orientation} = useWindowDimensions();
  const { pathname } = useLocation();

  if (clientInformation === null) {
    return <></>;
  }

  const cvSettings = clientInformation?.dormerSpecificSettings?.clientVariableSettings;

  const dormerVisualizationSettings: DormerVisualizationSettings = {
    visualizationType: cvSettings?.visualizationType ?? ClientConfigurationDormerSpecificSettingsClientVariableSettingsVisualizationType.Upright,
    sizeOpeningFrameRod: cvSettings?.sizeOpeningFrameRod ?? 0,
    sizeFramesSeparatorRod: cvSettings?.sizeFramesSeparatorRod ?? 0,
    sizeFrameSurroundingRod: cvSettings?.sizeFrameSurroundingRod ?? 0,
    offsetFrameGroupDepthNonPanel: fromMmToRenderingMeters(cvSettings?.offsetFrameGroupDepthNonPanel ?? 0),
    offsetFrameGroupDepthPanel: fromMmToRenderingMeters(cvSettings?.offsetFrameGroupDepthPanel ?? 0),
    offsetFrameDepthNonWindowVariation: fromMmToRenderingMeters(cvSettings?.offsetFrameDepthNonWindowVariation ?? 0),
    offsetFrameDepthWindowVariation: fromMmToRenderingMeters(cvSettings?.offsetFrameDepthWindowVariation ?? 0),
  } as DormerVisualizationSettings;



  return (<>
    <Suspense fallback={
      <Loader
        containerStyles={{background: 'white'}}
        barStyles={{background: 'black'}}
        dataStyles={{color: 'black'}}
      />
    }>
      <div className={styles.app} style={{backgroundColor: 'white'}}>
        <Modal
          isOpen={uiStatus.modalOpen}
          onClose={() => dispatch(storeUiStatusAction({modalOpen: false, modalContentKey: DormerModalContentKey.NONE, modalData: {}}))}
        >
          <ModalContentFactory modalKey={uiStatus.modalContentKey} uiStatus={uiStatus}/>
        </Modal>

        {/* TODO: */}
        {/*<Prompt*/}
        {/*  isOpen={configurationPrompt.active}*/}
        {/*  conflictedProducts={configurationPrompt.conflictedProducts}*/}
        {/*  onConfirm={() => {*/}
        {/*    if (configurationPrompt.limboKey) {*/}
        {/*      dispatch(*/}
        {/*        confirmConfigurationPrompt(configurationPrompt.limboKey)*/}
        {/*      );*/}
        {/*    }*/}
        {/*  }}*/}
        {/*  onCancel={() => {*/}
        {/*    if (configurationPrompt.limboKey) {*/}
        {/*      dispatch(*/}
        {/*        cancelConfigurationPrompt(configurationPrompt.limboKey)*/}
        {/*      );*/}
        {/*    }*/}
        {/*  }}*/}
        {/*/>*/}

        <div className={classnames(styles.layout)}>
          <div
            id="content-inner"
            className={classnames(styles['layout-inner'], styles.loaded, {
              [`${styles.fullscreen}`]: uiStatus.fullscreenMode,
            })}
          >
            <Header
              clientInformation={clientInformation}
              fullScreen={uiStatus.fullscreenMode}
              showCostAggregate={orderInvoice !== null}
              invoice={orderInvoice ?? undefined}
              orderIsDirty={orderIsDirty}
            />
            {((selectedConfigurationTemplate?.templateFaceConfiguration.faces != null) || order != null) &&
            ((pathname === RoutePaths.TechnicalPath || uiStatus.technicalMode) && dormerVisualizationSettings?.visualizationType !== ClientConfigurationDormerSpecificSettingsClientVariableSettingsVisualizationType.Slanted)
              ?
              <Dormer2dRendererZoomWrapper
                setZoomInRef={setZoomInRef}
                setZoomOutRef={setZoomOutRef}
              />
              :
              <Dormer3dRenderer
                configurationTemplate={selectedConfigurationTemplate!}
                order={order}
                visualizationSettings={dormerVisualizationSettings}
              />
            }
            <DrawerIcons
                className={classnames(styles['drawer-toggle'], {
                  [`${styles.closed}`]: uiStatus.fullscreenMode,
                })}
                zoomInClicked={handleZoomInClicked}
                zoomOutClicked={handleZoomOutClicked}
            />

            <Conditional
             condition={appConfiguration.sourceUrl != null || (appConfiguration.login?.refreshToken != null && appConfiguration.backofficeUrl != null)}
            >
              <CloseButton onClose={() => {
                const sourceUrl = appConfiguration.sourceUrl;

                if (sourceUrl !== undefined) {
                  window.location.href = sourceUrl;
                } else if (appConfiguration?.login?.refreshToken !== undefined && appConfiguration.backofficeUrl !== undefined) {
                  window.location.href = appConfiguration.backofficeUrl;
                }


              }} isBackgroundToggled={uiStatus.fullscreenMode}/>
            </Conditional>
            <Conditional condition={windowWidth >= MEDIA_L || orientation === 'landscape'}>
              <Navigation
                isPro={isOrganisation}
                routes={Routes}
                hidden={uiStatus.fullscreenMode}/>
            </Conditional>
            <Text
              className={classnames(styles.help, {
                [`${styles.hidden}`]: uiStatus.fullscreenMode,
              })}
              onClick={() => dispatch(storeUiStatusAction({modalOpen: true, modalContentKey: DormerModalContentKey.HELP, modalData: {}}))}
            >
              {t(TranslationKeys.generic.help, 'Hulp nodig?')}
            </Text>
          </div>
          <Drawer routes={Routes} isOpen={!uiStatus.fullscreenMode} onToggleFullscreen={() => dispatch(storeUiStatusAction({fullscreenMode: !uiStatus.fullscreenMode}))}/>
        </div>
      </div>
    </Suspense>
    <Tooltip id="help-tooltip" className={styles.helpTooltip}/>
  </>);
}
