import React from 'react';

export const TopHungWindowIcon = () => {
  return (<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
               viewBox="0 0 16 25">
      <path style={{fill: '#231F20'}} d="M0,0v25h16V0H0z M14.3,1.7v0.5L8,22.7L1.7,2.2V1.7H14.3z M1.7,5.1l5.6,18.2H1.7V5.1z M8.7,23.3
	l5.6-18.2v18.2H8.7z"/>
    </svg>
  );
}
