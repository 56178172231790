export * from './IsFrameProduct';
export * from './IsCladdingProduct';
export * from './IsSmallSize';
export * from './IsMediumSize';
export * from './IsLargeSize';
export * from './IsProductWithinWidthAndDepthThreshold';
export * from './IsProductWithSameColor';
export * from './IsProductWithSameName';
export * from './IsProductWithSameMaterial';
export * from './IsProductWithTheSameSpecifications';
export * from './IsExtraProduct';
export * from './IsRoofTrimProduct';
export * from './IsRoofOverhangProduct';
export * from './IsRoofLightProduct';
export * from './HasId';
export * from './IsFrameHeaderProduct';
export * from './IsFramHeaderProductForTextureId';
export * from './IsDrainPipeProducts';
export * from './IsFaucetProduct';
export * from './HasDrawableObject';
export * from './IsCompatibleRoofLightProduct';
