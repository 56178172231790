import React, { FunctionComponent } from 'react';
import { CubeTextureLoader, MultiplyOperation, Vector3 } from 'three';
import { HomeExtensionDto } from '../../HomeExtensionDto';
import { CdnUrlHelper } from '@hec/core';
import {
  DEFAULT_OVERHANG_SIDE_SIZE,
  DEFAULT_ROOF_EDGE_HEIGHT
} from "@hec/components/v1";
import { DEFAULT_OVERHANG_SIZE, IsRoofOverhangProduct, RoofOverhangProduct, RoofOverhangStyle } from '@hec/models';

export interface AluminumProps {
  homeExtensionDto: HomeExtensionDto;
  color?: string;
}

const cdnPublicRoot = CdnUrlHelper.getPublicRoot();
const cubeTextureLoader = new CubeTextureLoader();
const envTexture = cubeTextureLoader.load([
    cdnPublicRoot + "/px.jpg",
    cdnPublicRoot + "/nx.jpg",
    cdnPublicRoot + "/py.jpg",
    cdnPublicRoot + "/ny.jpg",
    cdnPublicRoot + "/pz.jpg",
    cdnPublicRoot + "/nz.jpg"
]);
export const Aluminum: FunctionComponent<AluminumProps> = ({ homeExtensionDto, color }) => {
  const [widthInMeters, heightInMeters, depthInMeters] = homeExtensionDto.getMeasurements().toArray();
  const roofHeight = .00005;
  const metalThickNess = 0.003;
  const roofOverhangProduct = homeExtensionDto.getExtraByType(IsRoofOverhangProduct) as RoofOverhangProduct|undefined;
  const overhangSize = (roofOverhangProduct && roofOverhangProduct.style === RoofOverhangStyle.FRONT_ONLY) ?
    DEFAULT_OVERHANG_SIZE :
    0;
  const sideSize = (roofOverhangProduct && roofOverhangProduct.style === RoofOverhangStyle.FRONT_ONLY) ?
    DEFAULT_OVERHANG_SIDE_SIZE :
    0;
  return (
    <>
      <group position={new Vector3(widthInMeters / 2, heightInMeters + (DEFAULT_ROOF_EDGE_HEIGHT / 2), (depthInMeters + (metalThickNess / 2) + overhangSize))}>
        <mesh>
          <boxGeometry args={[widthInMeters + (sideSize * 2), DEFAULT_ROOF_EDGE_HEIGHT, metalThickNess]} />
          <meshPhongMaterial
            shininess={.8}
            reflectivity={.8}
            envMap={!color ? envTexture : undefined}
            color={color}
            combine={MultiplyOperation}
            attach="material"
          />
        </mesh>
      </group>
      <group position={new Vector3(widthInMeters + (metalThickNess / 2) + sideSize, heightInMeters + (DEFAULT_ROOF_EDGE_HEIGHT / 2), (depthInMeters + overhangSize + metalThickNess) / 2)}>
        <mesh>
          <boxGeometry args={[metalThickNess, DEFAULT_ROOF_EDGE_HEIGHT, (depthInMeters + overhangSize + metalThickNess)]} />
          <meshPhongMaterial
            shininess={.8}
            reflectivity={.8}
            envMap={!color ? envTexture : undefined}
            color={color}
            combine={MultiplyOperation}
            attach="material"
          />
        </mesh>
      </group>
      <group position={new Vector3(-(metalThickNess / 2) - sideSize, heightInMeters + (DEFAULT_ROOF_EDGE_HEIGHT / 2), (depthInMeters + overhangSize + metalThickNess) / 2)}>
        <mesh>
          <boxGeometry args={[metalThickNess, DEFAULT_ROOF_EDGE_HEIGHT, (depthInMeters + overhangSize + metalThickNess)]} />
          <meshPhongMaterial
            shininess={.8}
            reflectivity={.8}
            envMap={!color ? envTexture : undefined}
            color={color}
            combine={MultiplyOperation}
            attach="material"
          />
        </mesh>
      </group>
    </>
  );
};
