import {ConfigurationTemplateDto} from "@hec/api-dtos";

export interface ConfigurationTemplateState {
  configurationTemplates: ConfigurationTemplateDto[];
  selectedConfigurationTemplateDto: ConfigurationTemplateDto | null;
}

export const configurationTemplateInitialState: ConfigurationTemplateState = {
  configurationTemplates: [],
  selectedConfigurationTemplateDto: null,
}
