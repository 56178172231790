import React, { FunctionComponent } from 'react';
import styles from './confirmation-dialog.module.scss';
import { useTranslation } from 'react-i18next';
import {TranslationKeys} from '@hec/models';
import { Conditional } from '../Conditional';
import { Text } from '../Typography';
import {ConfirmPromptModalData} from "@hec/dal";
import classnames from "classnames";
import {Button} from "@hec/components/v1";

export interface ConfirmationDialogProps {
  confirmPromptModalData: ConfirmPromptModalData;
  closeModal: () => void;
}

export const ConfirmationDialog: FunctionComponent<ConfirmationDialogProps> = ({ confirmPromptModalData, closeModal}) => {
  const { t } = useTranslation();

  return (
    <div>
      <Conditional>
        <Text className={styles.title}>{confirmPromptModalData.title}</Text>
      </Conditional>
      <Text as={'p'} className={classnames(styles.prompt)}>
        {t(confirmPromptModalData.message)}
      </Text>
      <div className={classnames(styles.actions)}>
        <Button type={'secondary'} size={'large'} onClick={closeModal}>{t(TranslationKeys.generic.cancel)}</Button>
        <Button type={'primary'} size={'large'} onClick={() => {

          confirmPromptModalData.onAccept();

          closeModal();

        }}>{t(TranslationKeys.generic.confirm)}</Button>
      </div>
    </div>
  );
};
