import React, { HTMLProps } from 'react';
import classnames from 'classnames';
import styles from './input.module.scss';

export const Input = React.forwardRef<HTMLInputElement, HTMLProps<HTMLInputElement>>(
  (props, ref) =>
    (<input
      {...props}
      className={classnames(styles.input, styles[props.type!])}
      ref={ref}
    />));
Input.defaultProps = {
  type: 'text'
}
