import { Camera } from './Camera';
import { Houses } from './Houses';
import { Floor } from './Floor';
import { Lights } from './Lights';
import { DrainPipe, Faucet, FrameHeader, Walls } from './Walls';
import { Roof } from './Roof';
import { FunctionComponent, Suspense } from 'react';
import { HomeExtensionDto } from './HomeExtensionDto';
import { Frame } from './Walls/Frame';
import { Conditional } from "@hec/components/v1";
import { IsDrainPipeProduct, IsFaucetProduct } from '@hec/models';
import { Garden } from './Garden';

export interface HomeExtensionProps {
  zoom?: number;
  minDistanceMultiplier?: number;
  homeExtensionDto: HomeExtensionDto;
  animate: boolean;
  isInitialPositionCallback: (() => void) | undefined;
}

export const HomeExtension: FunctionComponent<HomeExtensionProps> = ({
  homeExtensionDto,
  isInitialPositionCallback,
  minDistanceMultiplier,
  zoom,
  animate,
}) => {
  const isDevelopment = false; // process?.env?.NODE_ENV === 'development';

  const hasDrainPipe =
    homeExtensionDto.getExtraByType(IsDrainPipeProduct) !== undefined;
  const hasFaucet =
    homeExtensionDto.getExtraByType(IsFaucetProduct) !== undefined;

  return (
    <>
      <Floor />
      <Lights />

      <Suspense fallback={<></>}>
        <Garden homeExtensionDto={homeExtensionDto} />
        <Houses homeExtensionDto={homeExtensionDto} />
        <Walls homeExtensionDto={homeExtensionDto} />
        <Frame homeExtensionDto={homeExtensionDto} />
        <FrameHeader homeExtensionDto={homeExtensionDto} />
        <Roof homeExtensionDto={homeExtensionDto} />

        <Conditional condition={hasDrainPipe}>
          <DrainPipe homeExtensionDto={homeExtensionDto} />
        </Conditional>

        <Conditional condition={hasFaucet}>
          <Faucet homeExtensionDto={homeExtensionDto} />
        </Conditional>
      </Suspense>
      <Conditional condition={isDevelopment}>
        <axesHelper args={[100000]} />
      </Conditional>
      <Camera
        zoom={zoom}
        minDistanceMultiplier={minDistanceMultiplier}
        homeExtensionDto={homeExtensionDto}
        dolly={animate}
        isInitialPositionCallback={isInitialPositionCallback}
      />
    </>
  );
};
