import {SerializableValue, SurveyQuestionType} from "../";

export class SurveyAnswerViewModel {
  questionId: string;
  surveyQuestionType: SurveyQuestionType;
  value: SerializableValue | FileList | null;


  getNormalizedValueFromForm(): SerializableValue | null {
    if (this.value == null) {
      return null;
    }

    switch (this.surveyQuestionType) {
      case SurveyQuestionType.WholeNumber:
      case SurveyQuestionType.Decimal:
        if (typeof (this.value as unknown) === 'string') {
          const convertedValue = Number(this.value);
          this.value = !isNaN(convertedValue) ? convertedValue : null;
        }
        return {
          value: this.value
        };
      case SurveyQuestionType.Toggle:
      case SurveyQuestionType.Text:
      case SurveyQuestionType.MultilineText:
      case SurveyQuestionType.DropDown:
        return {
          value: this.value
        };
      case SurveyQuestionType.Date:
        if (this.value === '') {
          return null;
        }
        return {
          value: new Date(this.value.toString() + "T00:00:00Z").toISOString()
        };
      case SurveyQuestionType.File:
          return this.value;
      default:
          return null;
    }

    return null;
  }

  constructor(
    questionId: string,
    surveyQuestionType: SurveyQuestionType,
    value: SerializableValue | null,
  ) {
    this.questionId = questionId;
    this.surveyQuestionType = surveyQuestionType;
    this.value = value;
  }
}
