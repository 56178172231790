import React, { Fragment, FunctionComponent, PropsWithChildren } from 'react';

export interface ConditionalProps {
  condition?: boolean|unknown;
  fallback?: JSX.Element;
}

export const Conditional: FunctionComponent<PropsWithChildren<ConditionalProps>> =
  ({ condition, children, fallback }) => {
    return (function() {
      if (typeof condition !== 'undefined' && condition && children) {
        return <Fragment>{children}</Fragment>;
      }
      else if (fallback) {
        return fallback;
      }
      else {
        return <Fragment />;
      }
    })()
  };
