import {
  ClientInformationState,
  getClientInformationSucceededAction
} from "./";
import produce from "immer";


export const getClientInformationSucceededReducer = (state: ClientInformationState, action: ReturnType<typeof getClientInformationSucceededAction>) => {
  return produce(state, (draft) => {
    draft.clientInformation = action.payload.clientInformation
  });
}
