import {
  HomeConfigurationState, IsFrameProduct,
  PriceCalculationStrategy,
  Product,
  ProductType
} from '../';

export const CladdingProductPriceCalculationStrategy: PriceCalculationStrategy = (state: HomeConfigurationState) => {
  return (product: Product): Product => {
    if (product.productType === ProductType.CLADDING) {
      const selectedFrame = state.selectedProducts.find(IsFrameProduct);
      const clone = {...product};
      let totalSurface = (
        ((state.measurements.depth * state.measurements.height) * 2) +
        state.measurements.width * state.measurements.height
      );
      if (state.clientConfiguration.excludeFrameFromCladdingPriceCalculation && selectedFrame) {
        totalSurface -= selectedFrame.drawableObject.width * selectedFrame.drawableObject.height;
      }
      clone.priceExVat = product.priceExVat * totalSurface;
      return clone;
    }
    return product;
  }
}
