import { BaseService } from './BaseService';
import {
  AppConfiguration,
  ApplicationService,
  HomeExtensionClientConfiguration,
} from '@hec/models';
import axios, { AxiosInstance } from 'axios';

export class ClientConfigurationService
  extends BaseService
  implements ApplicationService
{
  private client: AxiosInstance;
  public static override serviceName = Symbol(
    'SERVICES.CLIENT_CONFIGURATION_SERVICE'
  );

  constructor() {
    super();
    this.client = axios.create({});
  }

  async getClientConfiguration(
    configurationId: string
  ): Promise<HomeExtensionClientConfiguration> {
    const response = await this.client.get(configurationId);

    if (response.status === 200) {
      return response.data as HomeExtensionClientConfiguration;
    }

    /* eslint-disable  @typescript-eslint/no-explicit-any */
    throw new (Error as any)(
      `ClientConfigurationService: getClientConfiguration resulted in ${response.status}: ${response.statusText}`
    );
  }

  configure(configuration: AppConfiguration) {
    this.checkConfiguration(configuration);

    this.client = axios.create({
      baseURL: `${configuration.baseUrl}/api/client-information`,
    });
  }

  getName(): symbol {
    return ClientConfigurationService.serviceName;
  }

  static getName(): symbol {
    return ClientConfigurationService.serviceName;
  }
}
