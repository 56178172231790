import { createLogger } from 'redux-logger';
import { AppConfiguration, HomeExtensionApplicationState } from '@hec/models';
import { INITIALIZE_APPLICATION, UPDATE_APP_CONFIGURATION } from '../';

export const loggerMiddleware = createLogger({
  predicate: (getState, action) => {
    let configuration: AppConfiguration;
    if (
      action.type === INITIALIZE_APPLICATION ||
      action.type === UPDATE_APP_CONFIGURATION
    ) {
      configuration = action.data;
    } else {
      configuration = (getState() as HomeExtensionApplicationState).applicationStateReducer
        .configuration;
    }
    return configuration.logging?.enableReduxLogging || false;
  },
});
