import { createAction } from '@reduxjs/toolkit';
import {OrderDto, PutCustomerInformationViewModel} from "@hec/api-dtos";

export const getOrderAction = createAction('getOrderAction');
interface GetOrderSucceededActionProps {
  order: OrderDto;
}
export const getOrderSucceededAction = createAction<GetOrderSucceededActionProps>('getOrderSucceededAction');

export const markOrderDirtyAction = createAction('markOrderDirtyAction')
export const debouncePutOrderAction = createAction('debouncePutOrderAction');
export const putOrderAction = createAction('putOrderAction');
interface PutOrderSucceededActionProps {
  orderDto: OrderDto;
}
export const putOrderSucceededAction = createAction<PutOrderSucceededActionProps>('putOrderSucceeded');
export const putCustomerInformationAction = createAction<PutCustomerInformationViewModel>('putCustomerInformationAction');
