export enum Severity {
  LOW,
  NORMAL,
  HIGH,
  HIGHEST,
}

/**
 * Taken from https://docs.microsoft.com/en-us/dotnet/api/microsoft.extensions.logging.loglevel?view=dotnet-plat-ext-5.0
 */
export enum LogLevel {
  CRITICAL = 'CRITICAL',
  DEBUG = 'DEBUG',
  ERROR = 'ERROR',
  INFORMATION = 'INFO',
  NONE = 'NONE',
  TRACE = 'TRACE',
  WARNINGS = 'WARNINGS',
}
