import { ModalContentKey, ViewType, FatalErrorKey } from '../../';

export interface AppSettings {
  fullscreenMode: boolean;
  viewType: ViewType;
  modalOpen: boolean;
  modalContentKey: ModalContentKey;
  modalData: unknown;
  fatalErrorKey?: FatalErrorKey;
}
