import { FunctionComponent } from 'react';
import {RoofLightProduct, TranslationKeys} from '@hec/models';
import { BaseSelectionItem } from '../BaseSelectionItem';
import { Text } from '../Typography';
import styles from '../FrameSelectionItem/frame-selection-item.module.scss';
import { Conditional } from '../Conditional';
import { CurrencyFormatter } from '@hec/utils';
import { useTranslation } from 'react-i18next';

export interface RoofLightSelectionItemProps {
  roofLightProduct: RoofLightProduct;
  isActive?: boolean;
  hidePrice?: boolean;
  click?: (item: RoofLightProduct) => void;
  onModalToggle?: (item: RoofLightProduct) => void;
  borderColor?: string;
}

export const RoofLightSelectionItem: FunctionComponent<RoofLightSelectionItemProps> = ({ roofLightProduct, hidePrice, onModalToggle, borderColor, click, isActive = false }) => {
  const { t } = useTranslation();
  const { drawableObject, priceExVat, name } = roofLightProduct;
  return (
    <BaseSelectionItem<RoofLightProduct>
      hidePrice={hidePrice}
      click={click}
      borderColor={borderColor}
      isActive={isActive}
      showImage={false}
      product={roofLightProduct}
    >
      <Text className={styles.description}>
        {
          t(
            name,
            {
              reportMissing: false,
              color: drawableObject ? t(drawableObject.color, drawableObject.color) : '',
              style: drawableObject ? t(`generic.roofLightWindowType.${drawableObject.specification.roofLightWindowType}`, drawableObject.specification.roofLightWindowType) : '',
            },
          )
        }
      </Text>
      <Conditional condition={!hidePrice}>
        <Text className={styles.price}>
          <Conditional condition={priceExVat > 0}>
            {CurrencyFormatter.format(priceExVat)}&nbsp;
          </Conditional>
          <Conditional condition={priceExVat === 0}>
            {t(TranslationKeys.generic.included)}&nbsp;
          </Conditional>
        </Text>
      </Conditional>
      {
        (onModalToggle && roofLightProduct.description) && (
          <Text
            className={styles.information}
            onClick={(e) => {
              e.stopPropagation();
              onModalToggle(roofLightProduct)
            }}
          >
            i
          </Text>
        )
      }
    </BaseSelectionItem>
  );
};
