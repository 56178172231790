import {FrameDto, FrameVariation, SectionFragmentDto, SectionFragmentType} from "@hec/api-dtos";
import {SimpleFrameContainer} from "./interfaces";

export const ToSimpleFrameContainer = (sfs: SectionFragmentDto[]): SimpleFrameContainer[] => {
  return sfs
    .filter(x=> x.sectionFragmentType === SectionFragmentType.Frame)
    .map(f => f as FrameDto).map((f)=> {
    return {
      width: f.width,
      height: f.height,
      type: f.sectionFragmentType,
      variation: f.variation!,
      isPanel: f.variation === FrameVariation.Panel,
      frame: f
    }
  })

}
