import React from 'react';

export const IconDrag = () => {
  return (
    <svg
      width="95px"
      height="50px"
      viewBox="0 0 95 50"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <polygon id="path-pl8146y5na-1" points="0 0 95 0 95 50 0 50"></polygon>
      </defs>
      <g id="MVP" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Desktop-HD-Copy-13"
          transform="translate(-352.000000, -343.000000)"
        >
          <g id="Group-5" transform="translate(320.000000, 343.000000)">
            <g id="Group-3" transform="translate(32.000000, 0.000000)">
              <mask id="mask-pl8146y5na-2" fill="white">
                <use xlinkHref="#path-pl8146y5na-1"></use>
              </mask>
              <g id="Clip-2"></g>
              <path
                d="M85.0881445,7.72272727 C76.6058401,3.07727273 62.9346273,0 47.4990952,0 C32.068087,0 18.3923503,3.07727273 9.91004591,7.72272727 C3.5087335,11.2318182 -0.00180955828,15.7772727 -0.00180955828,20.4545455 C-0.00180955828,25.1318182 3.5087335,29.6772727 9.91004591,33.1863636 C17.4016172,37.2863636 28.946599,40.1590909 42.1699461,40.7727273 L36.8498448,46.1181818 C35.972209,47.0090909 35.972209,48.4454545 36.8498448,49.3318182 C37.7365283,50.2227273 39.1706032,50.2227273 40.0527629,49.3318182 L49.1005543,40.2454545 C49.1865083,40.1590909 49.2272234,40.0454545 49.2950818,39.95 C49.3991314,39.8090909 49.5167527,39.6727273 49.589135,39.5090909 C49.8198537,38.95 49.8198537,38.3181818 49.589135,37.7636364 C49.5167527,37.5954545 49.3991314,37.4636364 49.2950818,37.3181818 C49.2272234,37.2227273 49.1865083,37.1136364 49.1005543,37.0272727 L40.0527629,27.9363636 C39.1706032,27.05 37.7365283,27.05 36.8498448,27.9363636 C35.972209,28.8272727 35.972209,30.2636364 36.8498448,31.1545455 L41.8939885,36.2181818 C29.683994,35.6 19.0211718,33 12.0769919,29.1954545 C7.4988095,26.6818182 4.52208613,23.8045455 4.52208613,20.4545455 C4.52208613,17.1045455 7.4988095,14.2272727 12.0769919,11.7136364 C20.0661917,7.33636364 32.9683423,4.54545455 47.4990952,4.54545455 C62.0298482,4.54545455 74.9365226,7.33636364 82.9211985,11.7136364 C87.5039048,14.2272727 90.4761043,17.1045455 90.4761043,20.4545455 C90.4761043,22.5590909 89.2229852,24.4909091 87.1827082,26.2863636 C83.4776377,29.5454545 77.2799006,32.2 69.6073735,33.9863636 C68.3949694,34.2681818 67.6394788,35.4909091 67.9154365,36.7136364 C68.2004419,37.9363636 69.412846,38.7 70.6342978,38.4136364 C79.1799368,36.4272727 86.0291148,33.3409091 90.1594316,29.7045455 C93.3442542,26.9045455 95,23.7318182 95,20.4545455 C95,15.7772727 91.4894569,11.2318182 85.0881445,7.72272727"
                id="Fill-1"
                fill="#FFFFFF"
                mask="url(#mask-pl8146y5na-2)"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
