import { takeLatest } from 'redux-saga/effects';
import {
  ADD_NOTIFICATION,
  INITIALIZE_APPLICATION,
  RAISE_ERROR,
  REQUEST_FAILURE,
} from '../../';
import {
  handleAddedNotification,
  handleInitializeApplication,
  handleRaisedError,
  handleRequestFail,
} from './Handlers';

export function* GenericSaga() {
  yield takeLatest(REQUEST_FAILURE, handleRequestFail);
  yield takeLatest(RAISE_ERROR, handleRaisedError);
  yield takeLatest(INITIALIZE_APPLICATION, handleInitializeApplication);
  yield takeLatest(ADD_NOTIFICATION, handleAddedNotification);
}
