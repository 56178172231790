import {SelectableFramesService, getSelectableFramesAction, getSelectableFramesSucceededAction,} from './index';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {
  getApplicationService,
  getOrderSelector,
  getSelectedConfigurationTemplateSelector,
  storeErrorAction
} from "../../";
import {ConfigurationTemplateDto, FrameDto, OrderDto} from "@hec/api-dtos";

function* handleGetSelectableFrames() {
  const service: SelectableFramesService = yield getApplicationService(SelectableFramesService);

  const selectedConfigurationTemplate: ConfigurationTemplateDto = yield select(getSelectedConfigurationTemplateSelector);
  const order: OrderDto | null = yield select(getOrderSelector);

  try {
    if (!selectedConfigurationTemplate) {
      yield put(storeErrorAction({
        error: new Error('No configuration template selected')
      }));
      return;
    }
    if (!order) {
      yield put(storeErrorAction({
        error: new Error('No order found')
      }));
      return;
    }

    const result: FrameDto[] = yield call([service, 'get'], selectedConfigurationTemplate.id, order.configurationCode);
    yield put(getSelectableFramesSucceededAction({ sectionFragmentsDto: result}));

  } catch (error) {
    yield put(storeErrorAction({error: error}));
  }
}


export function* SelectableFramesSaga() {
  yield takeLatest(getSelectableFramesAction, handleGetSelectableFrames);
}
