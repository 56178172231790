import { AnyAction } from 'redux';
import { put, select } from 'redux-saga/effects';
import { REDUCER_CANCEL_CONFIGURATION_PROMPT, unlimboActions } from '../../../';
import { HomeExtensionRootState } from '../../../';

export function* ConfigurationPromptConfirmationHandler(action: AnyAction) {
  const state: { [key: string]: AnyAction[] } = yield select((state: HomeExtensionRootState) => state.applicationStateReducer.actionLimbo);

  if (Object.prototype.hasOwnProperty.call(state, action.data.limboKey)) {
    for (const a of state[action.data.limboKey]) {
      yield put({ type: a.type, data: a.data });
    }
    yield put(unlimboActions(action.data.limboKey));
    yield put({ type: REDUCER_CANCEL_CONFIGURATION_PROMPT, data: action.data })
  }
}
