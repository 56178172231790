import React, { FunctionComponent, PropsWithChildren, useEffect, useState } from 'react';
import styles from './dropdown.module.scss';
import classnames from 'classnames';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Text } from '../Typography';

export interface DropdownProps {
  title: string;
  control?: (prevState: boolean) => void;
  className?: string;
  isOpen?: boolean;
  hasError?: boolean;
}

export const Dropdown: FunctionComponent<PropsWithChildren<DropdownProps>> = ({
                                                                                title,
                                                                                children,
                                                                                control,
                                                                                isOpen,
                                                                                className,
                                                                                hasError = false,
                                                                              }) => {
  const [isOpenInner, setIsOpenInner] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const isControlled = typeof isOpen === 'boolean';
  const currentOpenState = isControlled ? (isOpen as boolean) : isOpenInner;

  const toggleOpenState = () => {
    if (isControlled && control) {
      setIsClicked(true);
      control(!currentOpenState);
    } else {
      setIsOpenInner(!isOpenInner);
    }
  };

  useEffect(() => {
    if (isClicked) {
      const timer = setTimeout(() => setIsClicked(false), 600);
      return () => clearTimeout(timer);
    }
    return undefined; // Ensure all code paths return a value
  }, [isClicked]);

  return (
    <div
      className={classnames(styles.dropdown, className, {
        [styles.closed]: !currentOpenState,
        [styles.open]: currentOpenState,
        [styles['has-error']]: hasError,
        [styles['had-interaction']]: isClicked,
      })}
    >
      <div className={styles['title-container']} onClick={toggleOpenState}>
        <Text className={styles.title}>{title}</Text>
        <div className={styles.button}>
          {currentOpenState ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </div>
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};
