import {OrderState} from "./State";
import {updateMeasurementsAction} from "./FaceConfigurationActions";
import produce from "immer";

export const updateMeasurementsReducer = (state: OrderState, action: ReturnType<typeof updateMeasurementsAction>) => {
  return produce(state, (draft) => {
    if (!draft.order) {
      return;
    }
    if (action.payload.width !== null) {
      draft.order.faceConfiguration.width = action.payload.width;
    }
    if (action.payload.height !== null) {
      draft.order.faceConfiguration.height = action.payload.height;
    }
  });
}

