export enum ProductType {
  UNKNOWN = 'Unknown',
  CONSTRUCTION = 'Construction',
  CLADDING = 'Cladding',
  FRAME = 'Frame',
  ROOF_TRIM = 'RoofTrim',
  ROOF_OVERHANG = 'RoofOverhang',
  ROOF_LIGHT = 'RoofLight',
  FRAME_HEADER = 'FrameHeader',
  DRAIN_PIPE = 'DrainPipe',
  FAUCET = 'Faucet',
}
