import { FunctionComponent, CSSProperties } from 'react';
import { Product, TranslationKeys } from '@hec/models';
import styles from './costs-summary.module.scss';
import {
  getSummaryItemDisplayTexts,
  getTotalPrice,
} from './CostsSummaryUtilities';
import { useTranslation } from 'react-i18next';
import { CurrencyFormatter } from '@hec/utils';
import { Conditional } from '../Conditional';
import { Text } from '../Typography';
import classnames from 'classnames';

export interface CostsSummaryProps {
  products: Product[];
  extraInformation: string | null;
  hidePrice?: boolean;
  isInModal?: boolean;
  additionalHeader?: JSX.Element;
  columnTitle?: string;
  style?: CSSProperties;
  vatPercentage: number;
}

export const CostsSummary: FunctionComponent<CostsSummaryProps> = ({
  vatPercentage,
  products,
  extraInformation,
  additionalHeader,
  hidePrice = false,
  isInModal = false,
  columnTitle,
  style,
}: CostsSummaryProps) => {
  const { t } = useTranslation();

  const summaryItemsText = getSummaryItemDisplayTexts(products, t, hidePrice);

  const subTotalPrice = getTotalPrice(products);

  const divider = <div className={styles.summary__divider} />;

  const vatAmount = (vatPercentage / 100) * subTotalPrice;

  const summary: JSX.Element[] = summaryItemsText.map((item, index) => {
    return (
      <Text className={styles.row} key={`${item[0]}_${index}`}>
        <Text className={styles.column}>{item[0]}</Text>
        <Text className={styles.column}>{item[1]}</Text>
      </Text>
    );
  });

  return (
    <div className={styles.summary} style={style}>
      <Conditional condition={typeof additionalHeader !== 'undefined'}>
        <div className={styles.summary__head}>{additionalHeader}</div>
      </Conditional>
      <div className={styles.summary__content}>
        <Conditional
          condition={typeof columnTitle !== 'undefined' && columnTitle !== null}
        >
          <Text
            as={'h2'}
            className={classnames(styles.h2, {
              [`${styles['less-spacing']}`]: isInModal,
            })}
          >
            {columnTitle}
          </Text>
        </Conditional>
        {divider}
        {summary}
        {divider}
        <Conditional
          condition={!hidePrice}
          fallback={
            <Text className={styles.column}>
              {t(TranslationKeys.generic.priceOnInquiry)}
            </Text>
          }
        >
          <Conditional condition={vatAmount > 0}>
            <div className={`${styles.row} ${styles.summary__vatDetails}`}>
              <Text className={styles.column}>
                {t(TranslationKeys.generic.subTotal, 'Subtotaal indicatie')}
              </Text>
              <Text className={styles.column}>
                {CurrencyFormatter.format(subTotalPrice)}&nbsp;
              </Text>
            </div>
            <div className={`${styles.row} ${styles.summary__vatDetails}`}>
              <Text className={styles.column}>
                {t(TranslationKeys.generic.vatAmount, 'BTW indicatie') +
                  ` (${vatPercentage}%)`}
              </Text>
              <Text className={styles.column}>
                {CurrencyFormatter.format(vatAmount)}&nbsp;
              </Text>
            </div>
          </Conditional>
          <div className={`${styles.row} ${styles.summary__total}`}>
            <Text className={styles.column}>
              {t(TranslationKeys.generic.total, 'Totale prijs indicatie')}
            </Text>
            <Text className={styles.column}>
              {CurrencyFormatter.format(subTotalPrice + vatAmount)}&nbsp;
            </Text>
          </div>
        </Conditional>

        <Conditional
          condition={
            extraInformation !== null && typeof extraInformation !== 'undefined'
          }
        >
          <div className={styles['extra-information-container']}>
            <Text className={styles['extra-information-icon']}>i</Text>
            <Text as={'p'} className={styles['extra-information']}>
              {extraInformation}
            </Text>
          </div>
        </Conditional>
      </div>
    </div>
  );
};
