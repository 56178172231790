import React, { FunctionComponent } from 'react';
import styles from './help.module.scss';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailIcon from '@mui/icons-material/Mail';
import { HelpItem } from './HelpItem';
import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { HomeExtensionClientConfiguration, TranslationKeys } from '@hec/models';
import { Conditional } from '../Conditional';
import { Text } from '../Typography';

export interface HelpProps {
  clientConfiguration: HomeExtensionClientConfiguration;
  title?: string;
}

export const Help: FunctionComponent<HelpProps> = ({ clientConfiguration, title= 'Hulp nodig?' }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles['title-container']}>
        <Text className={styles.title}>{title}</Text>
      </div>
      <div className={styles.grid}>
        <Conditional condition={typeof clientConfiguration.contactPhoneNumber !== 'undefined' || typeof clientConfiguration.supportPhoneNumber !== 'undefined'}>
          <HelpItem
            icon={<LocalPhoneIcon />}
            title={t(TranslationKeys.generic.phone)}
            linkTitle={clientConfiguration.supportPhoneNumber ?? clientConfiguration.contactPhoneNumber as string}
            linkValue={`tel:${clientConfiguration.supportPhoneNumber ?? clientConfiguration.contactPhoneNumber}`}
          />
        </Conditional>
        <Divider orientation={'vertical'} flexItem />
        <Conditional condition={typeof clientConfiguration.contactEmailAddress !== 'undefined' || typeof clientConfiguration.supportEmailAddress !== 'undefined'}>
          <HelpItem
            icon={<MailIcon />}
            title={t(TranslationKeys.generic.email)}
            linkTitle={clientConfiguration.supportEmailAddress ?? clientConfiguration.contactEmailAddress as string}
            linkValue={`mailto:${clientConfiguration.supportEmailAddress ?? clientConfiguration.contactEmailAddress}`}
          />
        </Conditional>
      </div>
    </div>
  );
};
