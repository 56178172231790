import { AnyAction } from 'redux';
import { FrameProduct, Product } from '@hec/models';
import { pickSuitableFrameSizeHandler } from '../PickSuitableFrameSizeHandler';
import { put } from 'redux-saga/effects';
import { RECALCULATE_PRICE, REDUCER_SELECT_PRODUCT } from '../../../../';

export function* SelectFrameHandler(action: AnyAction) {
  let product: Product = action.data.product;
  const pickedFrame: FrameProduct|undefined = yield pickSuitableFrameSizeHandler(
    action.data.product.name,
    action.data.product.drawableObject.color,
    undefined,
    action.data.product.productionMaterialType);

  if (pickedFrame) {
    product = pickedFrame;
  }
  yield put({ type: REDUCER_SELECT_PRODUCT, data: { product }});
  yield put({ type: RECALCULATE_PRICE });
}
