import React, { FunctionComponent, memo, useEffect, useMemo, useRef } from 'react';
import { HomeExtensionDto } from '../../HomeExtensionDto';
import {
  CubeTextureLoader,
  CylinderGeometry,
  Matrix4, MeshPhongMaterial, MultiplyOperation,
  Vector3,
} from 'three';
import { degToRad } from 'three/src/math/MathUtils';
import { ZincFlatCorner } from './ZincFlatCorner';
import { ZincFlatStraight } from './ZincFlatStraight';
import {
  DEFAULT_OVERHANG_SIDE_SIZE,
  DEFAULT_ROOF_EDGE_HEIGHT,
  DEFAULT_WALL_THICKNESS
} from "@hec/components/v1";
import { CdnUrlHelper } from '@hec/core';
import { DEFAULT_OVERHANG_SIZE, IsRoofOverhangProduct, RoofOverhangProduct, RoofOverhangStyle } from '@hec/models';

export interface ZincFlatProps {
  homeExtensionDto: HomeExtensionDto;
}
const cdnPublicRoot = CdnUrlHelper.getPublicRoot();
const cubeTextureLoader = new CubeTextureLoader();
const envTexture = cubeTextureLoader.load([
  cdnPublicRoot + "/px.jpg",
  cdnPublicRoot + "/nx.jpg",
  cdnPublicRoot + "/py.jpg",
  cdnPublicRoot + "/ny.jpg",
  cdnPublicRoot + "/pz.jpg",
  cdnPublicRoot + "/nz.jpg"
]);

const MemoizedZincFlatStraight = memo(ZincFlatStraight);
const cornerCompensation = 0.15;
const xAxisDiff = 0.1;
const straightYCompensation = 0.067;

export const ZincFlat: FunctionComponent<ZincFlatProps> = ({ homeExtensionDto }) => {
  const [widthInMeters, heightInMeters, depthInMeters] = homeExtensionDto.getMeasurements().toArray();
  const roofOverhangProduct = homeExtensionDto.getExtraByType(IsRoofOverhangProduct) as RoofOverhangProduct|undefined;
  const overhangSize = (roofOverhangProduct && roofOverhangProduct.style === RoofOverhangStyle.FRONT_ONLY) ?
    DEFAULT_OVERHANG_SIZE - .003 :
    0;
  const overhangSideSize = (roofOverhangProduct && roofOverhangProduct.style === RoofOverhangStyle.FRONT_ONLY) ?
    DEFAULT_OVERHANG_SIDE_SIZE :
    0;
  const material = useMemo(() => new MeshPhongMaterial({
    shininess: .8,
    reflectivity: .8,
    envMap: envTexture,
    combine: MultiplyOperation,
    color: '#BAC4C8'
  }), []);
  const northGeometry = useRef<CylinderGeometry>(null);
  const eastGeometry = useRef<CylinderGeometry>(null);
  const westGeometry = useRef<CylinderGeometry>(null);

  useEffect(() => {
    if (northGeometry.current) {
      northGeometry.current.applyMatrix4( new Matrix4().makeRotationX( degToRad( 90 ) ) );
      northGeometry.current.applyMatrix4( new Matrix4().makeRotationY( degToRad( 90 ) ) );
    }
  }, [northGeometry, homeExtensionDto])

  useEffect(() => {
    if (eastGeometry.current) {
      // rotate it the right way for lookAt to work
      eastGeometry.current.applyMatrix4( new Matrix4().makeRotationX( degToRad( 90 ) ) );
    }
    if (westGeometry.current) {
      // rotate it the right way for lookAt to work
      westGeometry.current.applyMatrix4( new Matrix4().makeRotationX( degToRad( 90 ) ) );
    }
  }, []);

  const positionY = (heightInMeters + DEFAULT_ROOF_EDGE_HEIGHT * 2);
  const straightZAxisCompensation = (roofOverhangProduct ? DEFAULT_OVERHANG_SIZE - .02 : .275);
  return (
    <>
      {/*east side*/}
      {/*
        *
        * When the zincFlat should NOT compensate for overhang, subtract the size from the length
        * of the straights on the east and west side
        * position is flipped due to the rotation applied
      */}
      <MemoizedZincFlatStraight
        position={new Vector3(-xAxisDiff, positionY - straightYCompensation, -((depthInMeters / 2) + DEFAULT_WALL_THICKNESS / 2) + straightZAxisCompensation)}
        length={(depthInMeters - (roofOverhangProduct ? 0 : DEFAULT_WALL_THICKNESS)) - cornerCompensation}
        rotationInDeg={180}
        overhang={overhangSideSize}
        material={material}
      />
      {/*east corner*/}
      <ZincFlatCorner
        position={new Vector3(0, positionY - 0.092, depthInMeters + (overhangSize - 0.002))}
        material={material}
        hasOverhang={overhangSize > 0}
      />
      {/*north side*/}
      <MemoizedZincFlatStraight
        position={new Vector3(((depthInMeters + overhangSize) - DEFAULT_WALL_THICKNESS / 2) - .002, positionY - straightYCompensation, - widthInMeters / 2)}
        length={(widthInMeters - DEFAULT_WALL_THICKNESS * 2) - cornerCompensation * 2}
        rotationInDeg={270}
        material={material}
      />
      {/*west corner*/}
      <ZincFlatCorner
        rotationInDeg={90}
        hasOverhang={overhangSize > 0}
        position={new Vector3(widthInMeters, positionY - 0.092, depthInMeters + (overhangSize - 0.002))}
        material={material}
      />
      {/*west side*/}
      {/*
        *
        * When the zincFlat should NOT compensate for overhang, subtract the size from the length
        * of the straights on the east and west side
        *
      */}
      <MemoizedZincFlatStraight
        position={new Vector3(widthInMeters - xAxisDiff, positionY - straightYCompensation, ((depthInMeters / 2) + DEFAULT_WALL_THICKNESS / 2) - straightZAxisCompensation)}
        length={(depthInMeters - (roofOverhangProduct ? 0 : DEFAULT_OVERHANG_SIZE)) - cornerCompensation}
        rotationInDeg={0}
        overhang={overhangSideSize}
        material={material}
      />
    </>
  );
};

