import { AnyAction } from 'redux';
import produce from 'immer';
import merge from 'lodash.merge';
import {
  DEFAULT_PALETTE,
  HomeConfigurationState,
  Product,
  ProductionMaterialType,
  ProductType,
} from '@hec/models';
import {
  REDUCER_APPLY_CLIENT_CONFIGURATION,
  REDUCER_CANCEL_CONFIGURATION_PROMPT,
  REDUCER_CONFIRM_CONFIGURATION_PROMPT,
  REDUCER_GET_PRODUCTS_SUCCESS,
  REDUCER_PROMPT_CONFIGURATION_CHANGE,
  REDUCER_RECALCULATE_PRICE,
  REDUCER_REMOVE_PRODUCT,
  REDUCER_RESET_BASE_PRODUCT,
  REDUCER_SELECT_PRODUCT,
  REDUCER_SET_MEASUREMENTS,
  REDUCER_SET_PRODUCTS,
  REDUCER_SET_SELECTED_PRODUCTS,
} from '../Actions';
import {
  REDUCER_INITIALIZE_APPLICATION
} from '../../Actions'
import { DEFAULT_WALL_HEIGHT } from '../Constants';

export const HomeConfigurationReducerInitialState: HomeConfigurationState = {
  configurationPrompt: {
    active: false,
    conflictedProducts: [],
  },
  clientConfiguration: {
    ...DEFAULT_PALETTE,
    organisationId: null,
    name: 'Aanbouw configurator',
    pricePerCubicMeter: 0,
    excludeFrameFromCladdingPriceCalculation: false,
    maxWidth: 0,
    maxDepth: 0,
    minWidth: 0,
    minDepth: 0,
    extraInformation: null,
    leadPostedSuccessRedirectUrl: null,
    vatPercentage: 0,
    privacyPolicyUrl: null,
  },
  price: 0.0,
  products: [],
  productsShadow: [
    {
      id: 'BASE',
      name: 'BASE',
      description: '',
      priceExVat: 0,
      productType: ProductType.CONSTRUCTION,
      productionMaterialType: ProductionMaterialType.CONCRETE,
      translationKey: 'generic.baseProduct',
    },
  ],
  selectedProducts: [],
  measurements: {
    width: 4.5,
    depth: 2.0,
    height: DEFAULT_WALL_HEIGHT,
  },
};

export const HomeConfigurationReducer = (
  state = HomeConfigurationReducerInitialState,
  action: AnyAction
) => {
  switch (action.type) {
    case REDUCER_RECALCULATE_PRICE: {
      return produce(state, (draft) => {
        draft.price = action.data.totalPrice;
      });
    }
    case REDUCER_INITIALIZE_APPLICATION: {
      return produce(state, (draft) => {
        draft = merge(draft, action.data.homeConfiguration);
      });
    }
    case REDUCER_GET_PRODUCTS_SUCCESS: {
      return produce(state, (draft) => {
        draft.products = merge(draft.products, action.data.products);
        draft.productsShadow = [
          ...state.productsShadow,
          ...action.data.products,
        ];
      });
    }
    case REDUCER_SET_PRODUCTS: {
      return produce(state, (draft) => {
        draft.products = action.data.products;
      });
    }
    case REDUCER_SET_SELECTED_PRODUCTS: {
      return produce(state, (draft) => {
        draft.selectedProducts = action.data.products;
      });
    }
    case REDUCER_SELECT_PRODUCT: {
      return produce(state, (draft) => {
        const filtered = draft.selectedProducts.filter(
          (p) => p.productType !== action.data.product.productType
        );
        draft.selectedProducts = [...filtered, action.data.product];
      });
    }
    case REDUCER_REMOVE_PRODUCT: {
      return produce(state, (draft) => {
        draft.selectedProducts = draft.selectedProducts.filter(
          (p) => p.productType !== action.data.product.productType
        );
      });
    }
    case REDUCER_APPLY_CLIENT_CONFIGURATION: {
      return produce(state, (draft) => {
        draft.selectedProducts = action.data.selectedProducts;
        draft.clientConfiguration = action.data.clientConfiguration;
      });
    }
    case REDUCER_SET_MEASUREMENTS: {
      return produce(state, (draft) => {
        draft.measurements.depth = action.data.measurements.depth;
        draft.measurements.width = action.data.measurements.width;
      });
    }
    case REDUCER_PROMPT_CONFIGURATION_CHANGE: {
      return produce(state, (draft) => {
        draft.configurationPrompt.active = true;
        draft.configurationPrompt.limboKey = action.data.limboKey;
        draft.configurationPrompt.conflictedProducts =
          action.data.conflictedProducts;
      });
    }
    case REDUCER_CANCEL_CONFIGURATION_PROMPT:
    case REDUCER_CONFIRM_CONFIGURATION_PROMPT: {
      return produce(state, (draft) => {
        draft.configurationPrompt.active = false;
        draft.configurationPrompt.limboKey = undefined;
        draft.configurationPrompt.conflictedProducts = [];
      });
    }
    case REDUCER_RESET_BASE_PRODUCT: {
      return produce(state, (draft) => {
        const filtered = draft.selectedProducts.filter(
          (p) => p.productType !== ProductType.CONSTRUCTION
        );
        const sizeInSquareMeters =
          draft.measurements.width * draft.measurements.depth;
        const sizeInCubicMeters =
          sizeInSquareMeters * draft.measurements.height;
        const baseProduct: Product = {
          id: 'BASE',
          name: 'BASE',
          description: sizeInSquareMeters.toString(10),
          priceExVat:
            (draft.clientConfiguration.basePrice ?? 0) +
            sizeInCubicMeters * draft.clientConfiguration.pricePerCubicMeter,
          productType: ProductType.CONSTRUCTION,
          productionMaterialType: ProductionMaterialType.CONCRETE,
          translationKey: 'generic.baseProduct',
        };

        draft.selectedProducts = [baseProduct, ...filtered];
      });
    }
    default:
      return state;
  }
};
