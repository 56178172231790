import produce from 'immer';
import {getDormerMaterialsSucceededAction, selectDormerMaterialAction} from './Actions';
import {DormerMaterialsState} from './State';


export const getDormerMaterialsSucceededReducer = (state: DormerMaterialsState, action: ReturnType<typeof getDormerMaterialsSucceededAction>) => {
  return produce(state, (draft) => {
    draft.dormerMaterials = action.payload.dormerMaterials;
  });
}


export const selectDormerMaterialReducer = (state: DormerMaterialsState, action: ReturnType<typeof selectDormerMaterialAction>) => {
  return produce(state, (draft) => {
    const itemToSave = state.dormerMaterials.find(dormerMaterial => dormerMaterial.id == action.payload.dormerMaterialId);

    if (itemToSave) {
      draft.selectedDormerMaterialViewModel = itemToSave;
    }
  });
}
