import { createSlice } from '@reduxjs/toolkit';
import {getClientInformationSucceededAction} from './Actions';
import {clientInformationInitialState} from './State';
import {getClientInformationSucceededReducer} from './Reducers';

export const clientInformationSlice = createSlice({
  name: 'clientInformation',
  initialState: clientInformationInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getClientInformationSucceededAction, getClientInformationSucceededReducer);
  },
});
