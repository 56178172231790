import React, { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Route } from '@hec/models';
import styles from './navigation.module.scss';
import { Conditional } from '../Conditional';
import { Text } from '../Typography';

export interface NavigationProps {
  routes: Route[];
  hidden?: boolean;
  isPro: boolean;
}

const getActiveRoute = (routes: Route[], pathname: string): Route|undefined => {
  return routes.find((route) => route.path === pathname);
};

const getActiveRouteIndex = (route: Route, routes: Route[]): number|undefined => {
  const index = routes.indexOf(route);
  if (index >= 0) {
    return index + 1;
  }
  return;
};

export const Navigation: FunctionComponent<NavigationProps> = ({
  routes,
  hidden = false,
  isPro
}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const actualDisplayableRoutes = routes
    .filter((route) => typeof route.path !== 'undefined')
    .filter(
      (route) =>
        typeof route.displaySettings.displayInNavigation === 'undefined' ||
        route.displaySettings.displayInNavigation
    )
    .filter(x=> {
      if(!x.actorDefinedRoute) {
        return true;
      }

      if(x.actorDefinedRoute.showToPro && isPro) {
        return true;
      }

      return x.actorDefinedRoute.showToCustomer;
    })
  ;
  const activeRoute = getActiveRoute(actualDisplayableRoutes, pathname);
  const activeRouteIndex = activeRoute ? getActiveRouteIndex(activeRoute, actualDisplayableRoutes) : null;

  return (
    <div
      className={classnames(styles.navigation, {
        [`${styles.hidden}`]: hidden,
      })}
    >
      <Conditional condition={typeof activeRoute !== 'undefined'}>
        <div className={classnames(styles.identifiers)}>
          <Conditional condition={typeof activeRouteIndex === 'number' && activeRouteIndex < 10}>
            <Text className={styles.routeIndex}>0{(activeRouteIndex)}</Text>
          </Conditional>
          <Conditional condition={typeof activeRouteIndex === 'number' && activeRouteIndex >= 10}>
            <Text className={styles.routeIndex}>{(activeRouteIndex)}</Text>
          </Conditional>

          <Conditional
            condition={
              typeof activeRoute?.translationKey !== 'undefined' &&
              typeof activeRoute?.displaySettings.navigationTitleTranslationKey === 'undefined'}
          >
            <Text className={styles.routeName}>{t(activeRoute?.translationKey as string)}</Text>
          </Conditional>
          <Conditional
            condition={
              typeof activeRoute?.translationKey !== 'undefined' &&
              typeof activeRoute?.displaySettings.navigationTitleTranslationKey !== 'undefined'}
          >
            <Text className={styles.routeName}>{t(activeRoute?.displaySettings.navigationTitleTranslationKey as string)}</Text>
          </Conditional>
          <Conditional
            condition={
              typeof activeRoute?.displaySettings.navigationTitle !== 'undefined' &&
              typeof activeRoute?.displaySettings.navigationTitleTranslationKey === 'undefined'}
          >
            <Text className={styles.routeName}>{activeRoute?.displaySettings.navigationTitle}</Text>
          </Conditional>
          <Conditional
            condition={
              typeof activeRoute?.translationKey === 'undefined' &&
              typeof activeRoute?.name === 'string'
            }
          >
            <Text className={styles.routeName}>{t(activeRoute?.name ?? '')}</Text>
          </Conditional>
        </div>
      </Conditional>
      <ul>
        {actualDisplayableRoutes.map((route, index) => {
          const isActive = (route.path === pathname || activeRouteIndex && (index + 1) <= activeRouteIndex);
          return (
            <li
              key={route.path}
              style={isActive ? { backgroundColor: '#000000' } : {}}
              className={classnames(styles.navItem,
                {
                  [`${styles.active}`]: isActive,
                })
              }
            />
          )
        })}
      </ul>
    </div>
  );
};
