import { select, SelectEffect } from 'redux-saga/effects';
import { HomeExtensionApplicationState } from '@hec/models';
import { BaseService } from '../Services';

export function* getService(serviceRef: typeof BaseService): Generator<SelectEffect, typeof BaseService> {
  const services = yield select(
    (state: HomeExtensionApplicationState) =>
      state.applicationStateReducer.services
  );

  const service = (services as typeof BaseService[])
    .find((service) => service.getName() === serviceRef.getName());

  if (!service) {

    /* eslint-disable  @typescript-eslint/no-explicit-any */
    throw new (Error as any)('Unable to find service of type ' + serviceRef.getName().toString());
  }

  return service;
}
