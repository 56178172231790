import React, { CSSProperties, useState } from 'react';
import { Conditional, IconArrowLeft, IconArrowDown, Text } from '../index';
import styles from './form.module.scss';
import classNames from 'classnames';

export type SelectOption = {
  value: string;
  label: string;
};

export type FormSelectProps = {
  className?: string;
  options: SelectOption[];
  selected: string;
  name: string;
  onChange: (value: string) => void;
  style?: CSSProperties;
};

export const FormSelect = ({
  className,
  options,
  name,
  selected,
  style,
  onChange,
}: FormSelectProps) => {
  const [selectOpen, setSelect] = useState(false);
  const toggleSelectOpen = () => setSelect(!selectOpen);
  const setSelected = (value: string) => {
    setSelect(false);
    onChange(value);
  };

  return (
    <div
      style={style}
      className={
        classNames(
          className,
          styles.form__field,
          styles.select,
          {
            [`${styles['-active']}`]: selectOpen,
          },
        )
      }
    >
      <div onClick={toggleSelectOpen} className={styles.select__selected}>
        <Text>
          {options.find(({ value }) => value === selected)?.label ?? selected}
        </Text>
        <div className={styles.select__icon}>
          <IconArrowLeft />
        </div>
      </div>
      <div className={styles.select__options}>
        {options.map((option, index) => {
          return (
            <div
              key={index}
              onClick={() => { setSelected(option.value); }}
              className={
                classNames(
                  styles.select__option,
                  {
                    [`${styles['-selected']}`]: option.value === selected
                  }
                )
              }
            >
              <input
                type="radio"
                value={option.value}
                id={name + '-' + index}
                name={name}
                checked={option.value === selected}
                readOnly={true}
              />
              <label htmlFor={name + '-' + index}>{option.label}</label>
            </div>
          );
        })}
      </div>
    </div>
  );
};
