import { createAction } from '@reduxjs/toolkit';
import {ApplicationService} from "@hec/models";

interface AddServiceActionProps {
  services: ApplicationService[],
}
interface RemoveServiceActionProps {
  symbol: symbol;
}

export const addServiceAction = createAction<AddServiceActionProps>('addServiceAction');

export const removeServiceAction = createAction<RemoveServiceActionProps>('removeServiceAction');
