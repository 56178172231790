import React, { FunctionComponent, useMemo } from 'react';
import { useGLTF } from '@react-three/drei';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import {
  Material,
  Mesh,
  Vector3
} from 'three';
export interface ZincFlatCornerProps {
  position: Vector3;
  material?: Material;
  rotationInDeg?: number;
  hasOverhang?: boolean;
}

type GLTFResult = GLTF & {
  nodes: {
    mesh_0: Mesh,
  }
}
export const ZincFlatCorner: FunctionComponent<ZincFlatCornerProps> = ({ position, rotationInDeg = 0, material, hasOverhang = true }) => {
  const gltf = useGLTF(`https://cdn.aanbouwconfigurator.com/static-assets/generic/roof-trim/zinc-flat-corner${hasOverhang ? '-alternate' : ''}.glb`, true) as unknown as GLTFResult;
  const scene = gltf.scene.clone(true);
  const mesh = useMemo(() => scene.getObjectByName('mesh_0'), [scene]);

  if (scene) {
    const factor = (hasOverhang ? 1 : rotationInDeg / 360) * 2;
    scene.rotation.y = factor * Math.PI;
    const scale = new Vector3(1, 1, 1);
    if (rotationInDeg === 90 && hasOverhang) {
      scale.x *= -1;
    }
    scene.scale.multiply(scale);
  }

  if (mesh && material) {
    (mesh as Mesh).material = material;
  }
  return (
    <group
      position={position}
    >
      <primitive object={scene} />
    </group>
  );
};
