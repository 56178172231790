import React, { useState } from 'react';
import { TranslationKeys } from '@hec/models';
import { IconDrag, IconScroll } from '../index';
import styles from './explanation.module.scss';
import { useTranslation } from 'react-i18next';
import { Text } from '../Typography';

export const Explanation = () => {
  const [isOpen, setOpen] = useState(true);
  const { t } = useTranslation();

  window.setTimeout(() => {
    if (!isOpen) {
      setOpen(false);
    }
  }, 5000);

  const close = () => setOpen(false);

  return (
    <div
      className={`${styles.explanation} ${isOpen ? styles.open : ''}`}
      onClick={close}
    >
      <div className={styles.explanation__item}>
        <IconDrag />
        <Text as={'h3'} className={styles.title}>
          {t(TranslationKeys.other.explanation.clickAndDrag)}
        </Text>
        <p className={styles.text}>
          {t(TranslationKeys.other.explanation.allSides)}
        </p>
      </div>
      <div className={styles.explanation__item}>
        <IconScroll />
        <Text as={'h3'} className={styles.title}>
          {t(TranslationKeys.other.explanation.scroll)}
        </Text>
        <p className={styles.text}>
          {t(TranslationKeys.other.explanation.closerLook)}
        </p>
      </div>
    </div>
  );
};
