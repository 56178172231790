import { TranslationKeys } from '@hec/models';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './form-field.module.scss';
import classnames from 'classnames';
import { Conditional } from '../Conditional';
import { Text } from '../Typography';

export interface FormFieldProps {
  optional?: boolean;
  label?: string;
  className?: string;
  errors?: string[];
}

export const FormField: FunctionComponent<PropsWithChildren<FormFieldProps>> = ({
  optional,
  label,
  className,
  children,
  errors = [],
}) => {
  const { t } = useTranslation();
  return (
    <div className={
      classnames(
        styles['form-field'],
        className,
        {
          [`${styles['form-field-error']}`]: errors.length > 0
        }
      )
    }
    >
      <Conditional condition={typeof label !== 'undefined'}>
        <label>
          <Text>
            {label}
          </Text>
          <Conditional condition={typeof optional !== 'undefined' && optional}>
            <Text className={styles.optional}>
              ({t(TranslationKeys.generic.optional, 'optioneel')})
            </Text>
          </Conditional>
        </label>
      </Conditional>
      {children}
      {
        errors.map((error) => <Text style={{ color: 'red' }} key={error}>{error}</Text>)
      }
    </div>
  );
};
