import { AnyAction } from 'redux';
import { delay, put, select } from 'redux-saga/effects';
import {
  HomeExtensionApplicationState,
  Logger,
  LogLevel,
  NotificationType,
  Severity,
} from '@hec/models';
import { LogHandler } from '../../../';
import { AddNotification, RemoveNotification } from '../../../Actions';

export function* handleRaisedError(action: AnyAction) {
  const logLevel: LogLevel = yield select(
    (state: HomeExtensionApplicationState) =>
      state.applicationStateReducer.configuration.logging?.logLevel
  );
  const logger: Logger = yield select(
    (state: HomeExtensionApplicationState) =>
      state.applicationStateReducer.configuration.logging?.logger
  );

  const { severity, error } = action.data;

  if (severity === Severity.HIGHEST) {
    yield put(
      AddNotification(error.message, 1000, false, NotificationType.ERROR)
    );
    yield delay(1000);
    yield put(RemoveNotification(error.message));
  }

  LogHandler.handle(logger, logLevel, action.data.error);
}
