import { LogLevel, Logger } from '../../';

export interface AppConfiguration {
  element?: HTMLElement;
  logging?: {
    enableReduxLogging?: boolean;
    enableReduxDevtools?: boolean;
    logLevel?: LogLevel;
    logger?: Logger;
  };
  languageCode: string;
  baseUrl?: string;
  translationsUrl? : string;
  login?: {
    host?: string;
    realm?: string;
    clientId?: string;
    refreshToken?: string;
  }
  matomoUrl?: string;
  configurationId?: string;
  sourceUrl?: string;
  backofficeUrl?: string;
}
