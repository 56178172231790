import { Product } from './';

export enum RoofTrimStyle {
  BLACK_ALUMINUM = 'BlackAluminum',
  ALUMINUM = 'Aluminum',
  ZINC_FLAT = 'ZincFlat',
}

export interface RoofTrimProduct extends Product {
  style: RoofTrimStyle;
}
