import { createAction } from '@reduxjs/toolkit';
import { NotificationType } from '@hec/models';

type AddNotificationSucceededActionProps = AddNotificationActionProps
interface AddNotificationActionProps {
  message: string,
  duration: number,
  shouldBeTranslated: boolean,
  type: NotificationType
}
interface RemoveNotificationActionProps {
  message: string;
}

export const addNotificationAction = createAction<AddNotificationActionProps>('addNotificationAction');

export const addNotificationSucceededAction = createAction<AddNotificationSucceededActionProps>('addNotificationSucceededAction');
export const removeNotificationAction = createAction<RemoveNotificationActionProps>('removeNotificationAction');

