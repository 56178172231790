import React, { FunctionComponent } from 'react';

import {Conditional, DrawerToggle, TwoDimensionsToggle, ZoomInButton, ZoomOutButton} from "@hec/components/v2";
import {useDispatch, useSelector} from "react-redux";
import {getOrderSelector, getUiStatusSelector, storeUiStatusAction} from "@hec/dal";


export interface DrawerIconsProps {
  className?: string;
  zoomInClicked: () => void;
  zoomOutClicked: () => void;
}

export const DrawerIcons: FunctionComponent<DrawerIconsProps> = ({
  className,
  zoomInClicked,
  zoomOutClicked,
}) => {
  const uiStatus = useSelector(getUiStatusSelector);
  const dispatch = useDispatch();
  const drawerIsOpen= !uiStatus.fullscreenMode
  const order = useSelector(getOrderSelector);



  // const { zoomIn, zoomOut, resetTransform } = useControls();

  return (
    <div id={"drawer-icons"} className={className}>
      <DrawerToggle
        onClickFullScreen={() => dispatch(storeUiStatusAction({fullscreenMode: !uiStatus.fullscreenMode}))}
        open={drawerIsOpen}
      />
      <Conditional condition={order}>
        <div style={{right: `50px`, position: `absolute`}}>
          <TwoDimensionsToggle
            onClick={() => dispatch(storeUiStatusAction({technicalMode: !uiStatus.technicalMode}))}
            isTwoDimensions={uiStatus.technicalMode}
          />
        </div>
      </Conditional>

      <Conditional condition={uiStatus.technicalMode}>

        <div style={{right: `100px`, position: `absolute`}}>
          <ZoomInButton
            onClick={zoomInClicked}
          />
        </div>
        <div style={{right: `150px`, position: `absolute`}}>
          <ZoomOutButton
            onClick={zoomOutClicked}
          />
        </div>
      </Conditional>
    </div>
  );
};
