import { AnyAction } from 'redux';
import { put, select } from 'redux-saga/effects';
import {
  RECALCULATE_PRICE,
  REDUCER_REMOVE_PRODUCT,
  REDUCER_SELECT_PRODUCT,
} from '../../../../';
import { HomeExtensionRootState } from '../../../../';
import { CalculateCladdingPrice } from '../CalculateCladdingPrice';
import {
  CladdingProduct,
  HomeConfigurationState,
  IsCladdingProduct,
  IsFramHeaderProductForTextureId,
  Product,
} from '@hec/models';

export function* SelectCladdingHandler(action: AnyAction) {
  const excludeFrameFromCladdingPriceCalculation: boolean = yield select(
    (state: HomeExtensionRootState) =>
      state.homeConfigurationReducer.clientConfiguration
        .excludeFrameFromCladdingPriceCalculation
  );
  const { selectedProducts, products }: HomeConfigurationState = yield select(
    (state: HomeExtensionRootState) => state.homeConfigurationReducer
  );

  const currentlySelectedCladding = selectedProducts.find(IsCladdingProduct);
  const newCladding = action.data.product as CladdingProduct;

  const frameHeaderToUpdate = selectedProducts.find((sp) =>
    IsFramHeaderProductForTextureId(
      sp,
      currentlySelectedCladding?.textureMaterial?.id
    )
  );

  if (frameHeaderToUpdate) {
    const newFrameHeaderProduct = products.find((sp) =>
      IsFramHeaderProductForTextureId(sp, newCladding?.textureMaterial?.id)
    );
    if (newFrameHeaderProduct) {
      yield put({
        type: REDUCER_SELECT_PRODUCT,
        data: { product: newFrameHeaderProduct },
      });
    } else {
      yield put({
        type: REDUCER_REMOVE_PRODUCT,
        data: { product: frameHeaderToUpdate },
      });
    }
  }

  const product: Product = yield CalculateCladdingPrice(
    action.data.product,
    undefined,
    excludeFrameFromCladdingPriceCalculation
  );
  yield put({ type: REDUCER_SELECT_PRODUCT, data: { product } });

  yield put({ type: RECALCULATE_PRICE });
}
