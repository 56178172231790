import React, { FunctionComponent } from 'react';
import {FrameProduct, TranslationKeys} from '@hec/models';
import classNames from 'classnames';
import styles from './frame-selection-item.module.scss';
import { CurrencyFormatter } from '@hec/utils';
import { BaseSelectionItem } from '../BaseSelectionItem';
import { useTranslation } from 'react-i18next';
import { Conditional } from '../Conditional';
import { Text } from '../Typography';

export interface FrameSelectionItemProps {
  frameProduct: FrameProduct;
  isActive?: boolean;
  hidePrice?: boolean;
  click?: (item: FrameProduct) => void;
  onModalToggle?: (item: FrameProduct) => void;
  borderColor?: string;
}

export const FrameSelectionItem: FunctionComponent<FrameSelectionItemProps> =
  ({ borderColor, frameProduct, click, isActive = false, hidePrice = false, onModalToggle }) => {
    const { t } = useTranslation();
    const { drawableObject, priceExVat, name } = frameProduct;
    return (
      <BaseSelectionItem
        product={frameProduct}
        click={click}
        borderColor={borderColor}
        isActive={isActive}
        hidePrice={hidePrice}
        className={classNames(
          styles['frame-selection-item'],
          {
            [`${styles.active}`]: isActive
          }
        )}
      >
        <Text className={styles.description}>
          {t(name, { reportMissing: false, color: t(`generic.colors.${drawableObject.color}`, drawableObject.color) })}
        </Text>
        <Conditional condition={!hidePrice}>
          <Text className={styles.price}>
            <Conditional condition={priceExVat > 0}>
              {CurrencyFormatter.format(priceExVat)}&nbsp;
            </Conditional>
            <Conditional condition={priceExVat === 0}>
              {t(TranslationKeys.generic.included)}&nbsp;
            </Conditional>
          </Text>
        </Conditional>
        {
          (onModalToggle && frameProduct.description) && (
            <Text
              className={styles.information}
              onClick={(e) => {
                e.stopPropagation();
                onModalToggle(frameProduct)
              }}
            >
              i
            </Text>
          )
        }
      </BaseSelectionItem>
    )
  };
