import { Measurements } from '@hec/models';

export const SET_MEASUREMENTS = 'SET_MEASUREMENTS';
export const REDUCER_SET_MEASUREMENTS = 'REDUCER_SET_MEASUREMENTS';

export const setMeasurements = (measurements: Measurements) => ({
  type: SET_MEASUREMENTS,
  data: {
    measurements,
  }
});


