import React, { FunctionComponent, Suspense } from 'react';
import { Vector3 } from 'three';
import { useGLTF } from '@react-three/drei';
import { HomeExtensionDto } from '../HomeExtensionDto';
import { DrawableObject } from '@hec/models';

export interface FrameProps {
  homeExtensionDto: HomeExtensionDto;
}
export const Frame: FunctionComponent<FrameProps> = (
  {
    homeExtensionDto
  }
) => {
  const gltf = homeExtensionDto.getDrawableObject() ? useGLTF((homeExtensionDto.getDrawableObject() as DrawableObject).locationUrl) : null;
  const [extensionWidth, extensionHeight, extensionDepth] = homeExtensionDto.getMeasurements().toArray()
  let frameWidth = null;
  if (homeExtensionDto.getFrameMeasurements()) {
    frameWidth = homeExtensionDto.getFrameMeasurements()?.toArray()[0];
  }
  return (frameWidth && gltf) ? (
    <group position={new Vector3((extensionWidth - frameWidth) / 2, 0, extensionDepth)}>
      <Suspense fallback={<group />}>
        <primitive object={gltf.scene} />
      </Suspense>
    </group>
  ) : <group />
};
