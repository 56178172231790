import { FunctionComponent } from "react"

export const Lights: FunctionComponent = () => {
    return (
        <>
            <ambientLight intensity={0.6} />
            <directionalLight
                castShadow
                position={[4, 15, 15]}
                intensity={1.5}
                shadow-mapSize-width={1024}
                shadow-mapSize-height={1024}
                shadow-camera-far={50}
                shadow-camera-left={-10}
                shadow-camera-right={10}
                shadow-camera-top={10}
                shadow-camera-bottom={-10}
            />
        </>
    )
}

