import {
  FrameProduct,
  Product,
  ProductType,
  RoofLightProduct,
  TranslationKeys,
} from '@hec/models';
import { TFunction } from 'i18next';
import { CurrencyFormatter } from '@hec/utils';
import { PlacementTypeProduct } from '@hec/models';

const getSummaryItemDisplayPrice = (price: number, t: TFunction) => {
  const priceString =
    price > 0
      ? CurrencyFormatter.format(price)
      : t(TranslationKeys.generic.included);
  return priceString;
};

export const getSummaryItemDisplayTexts = (
  products: Array<Product>,
  t: TFunction,
  hidePrice: boolean
): (string | undefined)[][] =>
  products
    .filter((product) => {
      return !!product;
    })
    .map((product) => {
      if (!product) return [];
      let name = product.name;
      if (product.translationKey) {
        name = t(product.translationKey, { value: product.description });
      } else {
        let options = {};
        if (product.productType === ProductType.FRAME) {
          const color = (product as FrameProduct).drawableObject.color;
          options = {
            color: t(`generic.colors.${color.toLowerCase()}`, color),
          };
        }
        if (product.productType === ProductType.ROOF_LIGHT) {
          const color = (product as RoofLightProduct).drawableObject.color;
          const roofLightWindowType = (product as RoofLightProduct)
            .drawableObject.specification.roofLightWindowType;
          options = {
            color: t(color),
            style: t(`generic.roofLightWindowType.${roofLightWindowType}`),
          };
        }
        if (
          product.productType === ProductType.FAUCET ||
          product.productType === ProductType.DRAIN_PIPE
        ) {
          const placementType = (product as PlacementTypeProduct).placementType;
          options = {
            placementType: t(`generic.placementTypes.${placementType}`),
          };
        }
        name = t(name, name, options);
      }
      const price = !hidePrice
        ? getSummaryItemDisplayPrice(product.priceExVat, t)
        : undefined;
      return [name, price];
    });

export const getTotalPrice = (products: Array<Product>) =>
  products
    .filter((product) => {
      return !!product;
    })
    .map((product) => product.priceExVat)
    .reduce((a, b) => a + b, 0);
