import { call, spawn, all } from 'redux-saga/effects';
import {
  NotificationsSagas,
  ConfigurationTemplatesSagas,
  ClientInformationSagas,
  DormerMaterialsSagas,
  SelectableFramesSaga,
  OrderSagas,
  FrameSagas,
  OrderInvoicesSagas,
  FaceConfigurationSagas,
  SurveyQuestionsSagas,
} from "@hec/dal";

function* watchAllDormerSagas() {
  const sagas = [
    ClientInformationSagas,
    ConfigurationTemplatesSagas,
    DormerMaterialsSagas,
    SelectableFramesSaga,
    NotificationsSagas,
    OrderSagas,
    FrameSagas,
    FaceConfigurationSagas,
    OrderInvoicesSagas,
    SurveyQuestionsSagas,
  ];

  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            console.error(`Error on saga map for saga ${saga.name}`)
            console.error(e);
          }
        }
      })
    )
  );
}

export default watchAllDormerSagas;
