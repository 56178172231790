import {
  FrameVariationPanelIcon,
  FrameVariationWindowIcon,
  FrameVariationTiltAndTurnWindowLeftIcon,
  FrameVariationTiltAndTurnWindowRightIcon, TopHungWindowIcon, CenterPivotWindowIcon
} from "./SelectableFrameSelector/assets";
import {FrameVariation} from "@hec/api-dtos";

export interface FrameVariationData {
  variation: FrameVariation;
  icon: () => JSX.Element;
}

export const FRAME_VARIATION_DATA: FrameVariationData[] = [
  {
    variation: FrameVariation.Panel,
    icon: FrameVariationPanelIcon,
  },
  {
    variation: FrameVariation.Window,
    icon: FrameVariationWindowIcon,
  },
  {
    variation: FrameVariation.TiltAndTurnWindowLeft,
    icon: FrameVariationTiltAndTurnWindowLeftIcon,
  },
  {
    variation: FrameVariation.TiltAndTurnWindowRight,
    icon: FrameVariationTiltAndTurnWindowRightIcon,
  },
  {
    variation: FrameVariation.CenterPivotWindow,
    icon: CenterPivotWindowIcon,
  },
  {
    variation: FrameVariation.TopHungWindow,
    icon: TopHungWindowIcon,
  },

]
