import {
  DEFAULT_OVERHANG_SIDE_SIZE,
  DEFAULT_OVERHANG_SIZE,
  HomeConfigurationState,
  PriceCalculationStrategy,
  Product,
  ProductType,
} from '../';

export const RoofOverhangProductPriceCalculationStrategy: PriceCalculationStrategy =
  (state: HomeConfigurationState) => {
    return (product: Product) => {
      if (product.productType === ProductType.ROOF_OVERHANG) {
        if(product.priceExVat === 0) {
          return { ...product };
        }
        const sum = (
            (
              ((state.measurements.depth + DEFAULT_OVERHANG_SIZE) * 2) +
              ((2 * DEFAULT_OVERHANG_SIDE_SIZE) + state.measurements.width)
            ) *
            DEFAULT_OVERHANG_SIZE
          ) *
          product.priceExVat;

        product.priceExVat = sum;

        return { ...product };
      }
      return { ...product };

    };
  };
