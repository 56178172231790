export interface BaseClientInformation {
  organisationId: string | null,
  primaryColor: string;
  secondaryColor: string;
  logoUrl?: string;
  name: string;
  clientConfigurationId?: string;
  chamberOfCommerce?: string;
  address?: string;
  countryCode?: string;
  postalCode?: string;
  city?: string;
  contactPhoneNumber?: string;
  contactEmailAddress?: string;
  supportPhoneNumber?: string;
  supportEmailAddress?: string;
  extraInformation: string | null;
  vatPercentage: number;
  privacyPolicyUrl: string | null;
}

