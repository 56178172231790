import React, {useEffect, useMemo, useRef} from "react";
import {BufferGeometry, Float32BufferAttribute, Matrix4, Mesh} from "three";
import {degToRad} from "three/src/math/MathUtils";
import {DormerColorMaterial} from "./DormerColorMaterial";
import {AttachSide} from "./CladdingGlobalMaterial";
import {DormerColorViewModel} from "@hec/api-dtos";

interface CustomPrismProps {
  topWidth?: number;
  yHeight?: number;
  slantedSideWidth?: number;
  dormerColor?: DormerColorViewModel | null | undefined
}

interface TriangleSides {
  adjacent: number;
  opposite: number;
}

const hypotenuse = 30;
const angleAlpha = 30;
const angleBeta = 60;

//Right Triangle Side and Angle Calculator
export const RightTriangleSideandAngleCalculatorHelper = (hypotenuse: number, angleAlpha: number, angleBeta: number): TriangleSides => {
  // Ensure the angles sum up to 90 for a right triangle
  if (angleAlpha + angleBeta !== 90) {
    throw "The angles of a right triangle must sum up to 90 degrees.";
  }

  // Convert angles from degrees to radians for JavaScript trig functions
  const alphaRadians = angleAlpha * Math.PI / 180;
  const betaRadians = angleBeta * Math.PI / 180;

  // Calculate the lengths of the sides using trigonometry
  const adjacent = hypotenuse * Math.cos(alphaRadians);
  const opposite = hypotenuse * Math.sin(alphaRadians);

  // The returned object contains the lengths of the adjacent and opposite sides
  return { adjacent, opposite };
}

export const CustomPrismCalculationHelper = (hypotenuse: number): TriangleSides => RightTriangleSideandAngleCalculatorHelper(hypotenuse, angleAlpha, angleBeta);

/// They Y side here is the long straight side.
export const CalculateSideYForCustomPrism = (x: number) => {
  const result = RightTriangleSideandAngleCalculatorHelper(x, angleAlpha, angleBeta);

  return result.adjacent;
};

export const CustomPrism = ({topWidth = 5, yHeight = 1.744, slantedSideWidth = .2, dormerColor }: CustomPrismProps) => {
  const meshRef = useRef<Mesh>(null);


  const geometry = useMemo(() => {
    const geom = new BufferGeometry();

    // Base width calculated from the topWidth and slantHeight due to the angle constraints
    const baseWidth = topWidth + (yHeight / Math.sqrt(3));

    const vertices = new Float32BufferAttribute([
      // Base 1 (bottom)
      0, 0, 0,                                    // vertex 0, bottom left (90 degrees)
      baseWidth, 0, 0,                            // vertex 1, bottom right (90 degrees)
      baseWidth, yHeight, 0,                  // vertex 2, top right (120 degrees)
      baseWidth - topWidth, yHeight, 0,       // vertex 3, top left for slanted panel

      // Base 2 (top), same layout but translated up along the Z axis (depth of prism)
      0, 0, slantedSideWidth,                           // vertex 4, bottom left
      baseWidth, 0, slantedSideWidth,                   // vertex 5, bottom right
      baseWidth, yHeight, slantedSideWidth,         // vertex 6, top right
      baseWidth - topWidth, yHeight, slantedSideWidth, // vertex 7, top left for slanted panel
    ], 3);

    // Connect the vertices to form faces
    const indices = [
      // Bottom
      0, 2, 1, 0, 3, 2,
      // Top
      4, 5, 6, 4, 6, 7,
      // Sides correctly defined to ensure normals point outward
      0, 1, 5, 0, 5, 4,
      1, 2, 6, 1, 6, 5,
      2, 3, 7, 2, 7, 6,
      3, 0, 4, 3, 4, 7,
    ];


    geom.setIndex(indices);

    geom.setAttribute('position', vertices);
    geom.computeVertexNormals();

    geom.computeVertexNormals();
    return geom;
  }, [topWidth, yHeight, slantedSideWidth]); // Dependency array ensures geometry updates when props change

  useEffect(() => {
    if (meshRef.current) {
      meshRef.current.applyMatrix4( new Matrix4().makeRotationY( degToRad( 90 ) ) );
    }
  }, [meshRef]);

  const makeBasicMaterial = true

  return (
    <mesh
      ref={meshRef}
      geometry={geometry}
      receiveShadow={false}
      castShadow={false}
      position={[
        0,
        0,
        0]}
    >
      <DormerColorMaterial
        makeBasicMaterial={makeBasicMaterial}
        dormerColor={dormerColor}
        attachSide={AttachSide.west}
        width={slantedSideWidth}
        height={yHeight}
      />
      <DormerColorMaterial
        makeBasicMaterial={makeBasicMaterial}
        dormerColor={dormerColor}
        attachSide={AttachSide.east}
        width={slantedSideWidth}
        height={yHeight}
      />
      <DormerColorMaterial
        makeBasicMaterial={makeBasicMaterial}
        dormerColor={dormerColor}
        attachSide={AttachSide.up}
      />
      <DormerColorMaterial
        makeBasicMaterial={makeBasicMaterial}
        dormerColor={dormerColor}
        attachSide={AttachSide.down}
      />
      <DormerColorMaterial
        makeBasicMaterial={makeBasicMaterial}
        dormerColor={dormerColor}
        attachSide={AttachSide.north}
        width={slantedSideWidth}
        height={yHeight}
      />
      <DormerColorMaterial
        makeBasicMaterial={makeBasicMaterial}
        dormerColor={dormerColor}
        attachSide={AttachSide.south}
        width={slantedSideWidth}
        height={yHeight}
      />
    </mesh>
  );
};
