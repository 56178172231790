import React, { FunctionComponent } from 'react';
import { Palette, TranslationKeys } from '@hec/models';
import { Button } from '../Button';
import { useTranslation } from 'react-i18next';
import styles from './size.module.scss';
import { Text } from '../index';

interface SizeSelectorProps {
  onPlus?: () => void;
  onMinus?: () => void;
  palette?: Palette;
}

export const SizeSelector: FunctionComponent<SizeSelectorProps> = ({
  onPlus,
  onMinus,
  palette,
}: SizeSelectorProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles['flex-container']}>
      <div className={styles['flex-child']}>
        <Button
          type={'secondary'}
          size={'large'}
          as={<div />}
          disabled={onMinus == null}
          onClick={onMinus}
          className={styles.button}
        >
          <div className={styles['flex-container']}>
            <div className={styles['flex-child']}>
              <Text as={'p'}>
                {t(TranslationKeys.generic.makeSmaller, 'Verkleinen')}
              </Text>
            </div>
            <div className={styles['flex-child']}>
              <Text as={'h1'} className={styles.plusMinus}>
                -
              </Text>
            </div>
          </div>
        </Button>
      </div>
      <div className={styles['flex-child']}>
        <Button
          type={'secondary'}
          size={'large'}
          as={<div />}
          disabled={onPlus == null}
          onClick={onPlus}
          className={styles.button}
        >
          <div className={styles['flex-container']}>
            <div className={styles['flex-child']}>
              <Text as={'p'}>
                {t(TranslationKeys.generic.makeBigger, 'Vergroten')}
              </Text>
            </div>
            <div className={styles['flex-child']}>
              <Text as={'h1'} className={styles.plusMinus}>
                +
              </Text>
            </div>
          </div>
        </Button>
      </div>
    </div>
  );
};
