import { AppConfiguration, AppSettings } from '../';
import { ActionLoadingState } from '../';
import { ApplicationService, Notification } from '../';
import { AnyAction } from 'redux';

export interface ApplicationState {
  notifications: Notification[];
  loading: ActionLoadingState[];
  configuration: AppConfiguration;
  settings: AppSettings;
  services: ApplicationService[];
  actionLimbo: {
    [key: string]: AnyAction[]
  }
}
