import { useGLTF } from '@react-three/drei';
import React, { FunctionComponent } from 'react';
import { GroundDimensions } from './CalculateGroundDimensions';
import { Mesh } from 'three';

const cdnUrl =
  'https://cdn.aanbouwconfigurator.com/static-assets/generic/garden/outdoor_sofa.glb';

const champagneCdnUrl =
  'https://cdn.aanbouwconfigurator.com/static-assets/generic/garden/ignaz_champagne.glb';

interface OutsideSofaProps {
  groundDimensions: GroundDimensions;
}

export const OutsideSofa: FunctionComponent<OutsideSofaProps> = ({
  groundDimensions,
}: OutsideSofaProps) => {
  const {
    width,
    depth,
    tileWidth,
    grassWidth,
    grassDepth,
    zOffsetTile,
    xOffsetTile,
    zOffsetGrass,
    xOffsetGrass,
    westFenceDepth,
    zPositionWestFence,
    eastFenceDepth,
    zPositionEastFence,
    southFenceWidth,
    zPositionSouthFence,
  } = groundDimensions;

  const sofa = useGLTF(cdnUrl, true) as any;
  sofa.scene.traverse((o: any) => {
    if (o.name.startsWith('mesh')) (o as Mesh).castShadow = true;
    if (o.name.startsWith('mesh')) (o as Mesh).receiveShadow = true;
  });

  const sofaScene = sofa.scene;

  const champagne = useGLTF(champagneCdnUrl, true) as any;
  const champagneScene = champagne.scene;

  //

  return (
    <group position={[2.7, 0, depth + 5.5]}>
      <group rotation={[0, Math.PI / 2, 0]}>
        <primitive object={sofaScene} receiveShadow castShadow />
      </group>
      <group position={[-1, 0.2, -1.6]} scale={0.75}>
        <primitive object={champagneScene} receiveShadow castShadow />
      </group>
    </group>
  );
};
