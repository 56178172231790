import React, {FunctionComponent, useState} from "react";
import styles from './sidebarfooter.module.scss';
import {Button, Conditional, useWindowDimensions} from "@hec/components/v2";
// import {Link, useHistory} from "react-router-dom";
import {TranslationKeys} from "@hec/models";
import {MEDIA_L} from "@hec/core";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {useTranslation} from "react-i18next";
import {Tooltip} from "react-tooltip";
import {getContrastColor} from "@hec/utils"
import {ClientInformationViewModel} from "@hec/api-dtos";
import { useNavigate } from 'react-router-dom';

export interface SidebarFooterProps {
  prevRoutePath?: string;
  nextRoutePath?: string;
  nextRouteCTA? : string;
  onGoPrev?: (cancelNavigation: () => void) => void;
  onGoNext?: (cancelNavigation: () => void) => void;
  onGoNextConfirmMessage?: string;
  nextRouteError?: string;
  clientInformation: ClientInformationViewModel;
}

export const SidebarFooter: FunctionComponent<SidebarFooterProps> = ({prevRoutePath, nextRoutePath, nextRouteCTA, onGoPrev, onGoNext, onGoNextConfirmMessage, nextRouteError, clientInformation}) => {
  const { deviceType, windowWidth } = useWindowDimensions();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showNextConfirmMessage, setShowNextConfirmMessage] = useState(false);

  const backButtonType = deviceType === 'mobile' ||
    deviceType === 'tablet' ||
    windowWidth <= MEDIA_L
      ? 'minimal'
      : 'normal';


  const handlePrevClick = () => {
    let cancelNavigation = false;
    const cancel = () => {
      cancelNavigation = true;
    };

    if (onGoPrev) {
      onGoPrev(cancel);
    }

    if (!cancelNavigation) {
      navigate(prevRoutePath!);
    }
  };

  const handleNextClick = () => {
    let cancelNavigation = false;
    const cancel = () => {
      cancelNavigation = true;
    };

    if (onGoNext) {
      onGoNext(cancel);
    }

    if (!!onGoNextConfirmMessage) {
      setShowNextConfirmMessage(true);
      return;
    }

    if (!cancelNavigation) {
      if (!!nextRouteError && nextRouteError !== '') {
        // Handle the case with an error here
      } else {
        navigate(nextRoutePath!);
      }
    }
  };

  return (
    <div className={styles.actions}>
      <Conditional condition={clientInformation}>
        <div className={styles.actionButtonContainer}>
          <Conditional condition={typeof prevRoutePath === 'string'}>
            {/*<Link to={prevRoutePath!}>*/}
              <Button
                size={'large'}
                style={{
                  width: backButtonType === 'minimal' ? '48px' : '100%',
                  backgroundColor: clientInformation.secondaryColor,
                  color: clientInformation.primaryColor,
                }}
                as={<div />}
                onClick={() => {
                  handlePrevClick();
                }}
              >
                <Conditional condition={backButtonType === 'minimal'}>
                  <ArrowBackIosNewIcon />
                </Conditional>
                <Conditional condition={backButtonType === 'normal'}>
                  {t(TranslationKeys.generic.previousStep, 'Vorige stap')}
                </Conditional>
              </Button>
            {/*</Link>*/}
          </Conditional>
          <Conditional condition={typeof nextRoutePath === 'string'}>
            <Conditional condition={!!nextRouteError && nextRouteError !== ''}>
              <Button
                  size={'large'}
                  style={{
                    backgroundColor: clientInformation.primaryColor,
                    color: getContrastColor(clientInformation.primaryColor),
                  }}
                  as={<div />}
                  isDisabled={true}
                  className={styles.disabled}
                  data-tooltip-id="next-route-error-tooltip"
                  data-tooltip-content={nextRouteError}
              >
                {nextRouteCTA}
              </Button>
              <Tooltip id="next-route-error-tooltip" className={styles.nextRouteErrorTooltip}/>
            </Conditional>
            <Conditional condition={!nextRouteError}>
              {/*<Link to={nextRoutePath!}>*/}
                <Button
                    size={'large'}
                    style={{
                      backgroundColor: clientInformation.primaryColor,
                      color: getContrastColor(clientInformation.primaryColor),
                    }}
                    as={<div />}
                    onClick={() => {
                      handleNextClick();
                    }}
                >
                  {nextRouteCTA}
                </Button>
              {/*</Link>*/}
            </Conditional>
          </Conditional>
        </div>
      </Conditional>
    </div>
  )
};
