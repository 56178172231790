import { Product } from './Product';
import { TextureMaterial } from './TextureMaterial';

export enum RoofOverhangStyle {
  NONE = 'None',
  FRONT_ONLY = 'FrontOnly',
}

export interface RoofOverhangProduct extends Product {
  style: RoofOverhangStyle;
  textureMaterial?: TextureMaterial;
}
