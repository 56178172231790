import {Mesh, Object3D, Group, Vector3, Euler, Quaternion} from 'three';
import { CSG } from 'three-csg-ts';
import React, { useEffect, useRef } from 'react';
import { MeshProps, useThree } from '@react-three/fiber';

const logginEnabled = false;


function subtractMesh(mesh1: Mesh, mesh2: Mesh): Mesh {
  mesh1.updateMatrix();
  mesh2.updateMatrix();

  const bspMesh1 = CSG.fromMesh(mesh1);
  const bspMesh2 = CSG.fromMesh(mesh2);

  const subtractBsp = bspMesh1.subtract(bspMesh2);
  const result = CSG.toMesh(subtractBsp, mesh1.matrix);

  result.material = mesh1.material;
  result.castShadow = mesh1.castShadow;
  result.receiveShadow = mesh1.receiveShadow;

  // Applying transformation from mesh1 to the resulting mesh
  result.position.copy(mesh1.position);
  result.rotation.copy(mesh1.rotation);
  result.scale.copy(mesh1.scale);
  result.updateMatrix();
  mesh1.updateMatrix();

  return result;
}

function logMeshTransform(mesh: Mesh, description: string) {
  if (logginEnabled) {
    mesh.updateMatrixWorld();
    const position = new Vector3();
    const scale = new Vector3();
    const quaternion = new Quaternion();

    mesh.matrixWorld.decompose(position, quaternion, scale);
    const rotation = new Euler().setFromQuaternion(quaternion);

    console.log(`${description} - Position: ${position.toArray().toString()}, Rotation: ${rotation.toArray().toString()}, Scale: ${scale.toArray().toString()}`);
  }
}


function logGroupTransform(object: Object3D, description: string) {
  if (logginEnabled) {
    object.updateMatrixWorld();
    const position = new Vector3();
    const scale = new Vector3();
    const quaternion = new Quaternion();

    object.matrixWorld.decompose(position, quaternion, scale);
    const rotation = new Euler().setFromQuaternion(quaternion);

    console.log(`${description} Group - Position: ${position.toArray().toString()}, Rotation: ${rotation.toArray().toString()}, Scale: ${scale.toArray().toString()}`);
  }
}

function applyParentTransformations(mesh: Mesh, parent: Object3D | null) {
  if (parent) {
    logMeshTransform(mesh, "Before applying transformation");
    mesh.applyMatrix4(parent.matrix);
    logMeshTransform(mesh, "After applying transformation");
    if (parent.parent) {
      applyParentTransformations(mesh, parent.parent);
    }
  }
}

function extractMeshesFromGroup(group: Object3D): Mesh[] {
  const meshes: Mesh[] = [];

  group.updateMatrixWorld();
  logGroupTransform(group, "Parent");

  group.traverse(child => {
    if (child instanceof Mesh && child.geometry.attributes.position) {
      const meshClone = child.clone();
      applyParentTransformations(meshClone, child.parent);
      meshes.push(meshClone);
    }
  });

  return meshes;
}


interface CSGWrapperProps extends MeshProps {
  mesh1: JSX.Element;
  mesh2: JSX.Element;
}


export const CSGWrapper: React.FC<CSGWrapperProps> = ({ mesh1, mesh2 }): JSX.Element | null => {
  const mesh1Ref = useRef<Group | null>(null);
  const mesh2Ref = useRef<Group | null>(null); // Change type to Group
  const { scene } = useThree();

  useEffect(() => {
    const addedMeshes: Mesh[] = [];

    if (mesh1Ref.current && mesh2Ref.current) {
      const meshes1 = extractMeshesFromGroup(mesh1Ref.current);
      const meshes2 = extractMeshesFromGroup(mesh2Ref.current); // Extract meshes from mesh2 group as well

      meshes1.forEach(mesh1Child => {
        meshes2.forEach(mesh2Child => { // Loop through each mesh in mesh2
          const resultMesh = subtractMesh(mesh1Child, mesh2Child);

          scene.add(resultMesh);
          addedMeshes.push(resultMesh); // Keep track of the added mesh
        });
      });

      // Hide the original objects after performing CSG operations

      mesh1Ref.current.visible = false;

      mesh2Ref.current.visible = false;
    }

    // Cleanup function to remove added meshes, on rerender or dismount.
    return () => {
      addedMeshes.forEach(mesh => scene.remove(mesh));
    };

  }, [mesh1Ref, mesh2Ref, scene]);

  return (
    <>
      <group ref={mesh1Ref}>
        {React.Children.toArray(mesh1)}
      </group>
      <group ref={mesh2Ref}>
        {React.Children.toArray(mesh2)}
      </group>
    </>
  );
};
