import React, { Suspense} from 'react';
import {createRoot} from 'react-dom/client';
import {Navigate, Route, Routes} from 'react-router-dom';

import {App} from './app/App';
import {
  AppConfiguration,
  HEC_ERROR,
  HomeExtensionApplicationState, LogLevel,
  PublicConfiguratorApi,
  ReInitializeClassName
} from "@hec/models";
import {Store} from "redux";
import styles from './main.module.scss';
import i18NextInitialize from "@hec/i18n";
import {createInstance, MatomoProvider} from "@jonkoops/matomo-tracker-react";
import {Loader} from "@react-three/drei";
import {Provider} from "react-redux";
import {I18nextProvider} from "react-i18next";
import {
  getClientInformationAction,
  storeCriticalErrorAction,
  updateLoggingSettingsAction,
  UpdateLoggingSettingsActionProps
} from "@hec/dal";
import {DormerStoreContainer} from "./redux";
import {RoutePaths} from "./routing";
import {ConnectedErrorBoundary} from "@hec/components/v2";
import {MemoryRouter} from "react-router";
import {OfferingType} from "@hec/api-dtos";

declare global {
  interface Window {
    DormerConfigurator: PublicConfiguratorApi;
  }
}

const loginClientId = "login-app";
export class DormerConfigurator {
  private static instance: DormerConfigurator;
  private static _storeContainer: DormerStoreContainer;
  private static _store: Store;
  private readonly _element: HTMLElement;
  private static _configuration: AppConfiguration

  private static unloadCSS() {
    const links = document.querySelectorAll<HTMLLinkElement>('link[rel="stylesheet"]');
    links.forEach((link) => {
      const href = link.getAttribute('href');
      if (href !== null) {
        link.dataset.originalHref = href;
        link.removeAttribute('href');
      }
    });
  }

  private static getCookie(name: string): string | null {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(`${name}=`)) {
        return cookie.substring(name.length + 1);
      }
    }
    return null;

    // First, check if CookieStore API is available
    // if ('cookieStore' in window) {
    //   try {
    //     const cookie = await cookieStore.get(name);
    //     return cookie ? cookie.value : null;
    //   } catch (error) {
    //     console.error('Error retrieving cookie using CookieStore:', error);
    //     return null;
    //   }
    // } else {
    //   // Fallback to using document.cookie
    //   const cookies = document.cookie.split(';');
    //   for (let i = 0; i < cookies.length; i++) {
    //     const cookie = cookies[i].trim();
    //     if (cookie.startsWith(`${name}=`)) {
    //       return cookie.substring(name.length + 1);
    //     }
    //   }
    //   return null;
    // }
  }

  private static extractRefreshTokenFromCookie(): string | null {
    return this.getCookie('bearer');
  }

  public static init(configuration: AppConfiguration) {

    const referringURL = document.referrer;
    console.log("Referring URL:", referringURL);


    const baseUrl = configuration?.baseUrl ?? process.env.NX_BASE_URL;
    const matomoUrl = configuration?.matomoUrl ?? process.env.NX_MATOMO_URL;
    const translationsUrl = configuration?.translationsUrl ?? process.env.NX_TRANSLATIONS_URL;

    const loginHost = configuration?.login?.host ?? process.env.NX_LOGIN_HOSTNAME;
    const realm = configuration?.login?.realm ?? process.env.NX_LOGIN_REALM;
    const refreshToken = this.extractRefreshTokenFromCookie() ?? process.env.NX_LOGIN_REFRESH_TOKEN;


    configuration.baseUrl ??= baseUrl;
    configuration.matomoUrl ??= matomoUrl;

    configuration.login = {
      host: loginHost,
      realm,
      clientId: loginClientId,
      refreshToken
    }

    this._configuration = configuration;
    const storeContainer = new DormerStoreContainer(configuration);
    this._storeContainer = storeContainer;
    this._store = storeContainer.store;
    // dormerRootState = ReturnType<typeof storeContainer.store.getState>
    // this.dormerRootState = dormerRootState



    // Update logging configuration
    if (typeof configuration.logging !== 'undefined') {
      const updateLoggingSettingsPayload: UpdateLoggingSettingsActionProps = {};

      if ('enableReduxLogging' in configuration.logging) {
        updateLoggingSettingsPayload.enableReduxLogging = configuration.logging.enableReduxLogging;
      }
      if ('logLevel' in configuration.logging) {
        updateLoggingSettingsPayload.logLevel = configuration.logging.logLevel;
      }

      this._store.dispatch(updateLoggingSettingsAction(updateLoggingSettingsPayload));
    }

    // Get client information
    if (configuration.configurationId) {
      this._store.dispatch(getClientInformationAction({configurationId: configuration.configurationId, offeringType: OfferingType.ProductSubscriptionDormerConfigurator}));
    } else {
      this._store.dispatch(storeCriticalErrorAction({error: {message: 'No configuration id supplied'}}));
    }

    const elementToInitialize = configuration.element || document.getElementById('root');
    if (
      !this.instance ||
      elementToInitialize?.className === ReInitializeClassName
    ) {
      this.instance = new DormerConfigurator(elementToInitialize);
    }

    if (this.instance.element) {
      this.instance.applyStylesToDocument();
      setTimeout(() => {
        this.instance.render(matomoUrl ?? '', translationsUrl ? translationsUrl : baseUrl ? baseUrl : '');
        try {
          // const state = {};
          this.instance.listenForWindowEvents();
          if (document) {
            document.body.classList.add('pin-me');
            const htmlTag = document.querySelector('html');
            if (htmlTag) {
              htmlTag.classList.add('pin-me');
            }
          }
          // this.instance.store.dispatch(
          //   addNotificationAction({ message: "i am alive", duration: 1000, shouldBeTranslated: false, type: NotificationType.ERROR})
          // );
          // this._store.dispatch(
          //   InitializeApplication(
          //     {
          //       ...configuration,
          //       baseUrl: baseUrl,
          //       matomoUrl: matomoUrl
          //     },
          //       ApplicationType.Dormer,
          //     {dormerConfiguration: state}
          //   )
          // );
        } catch (e) {
          // this.instance.store.dispatch(raiseError(e as any));
        }
      }, 500);
    }
  }
  public static updateConfiguration(configuration: AppConfiguration) {
    // this.instance.store.dispatch(UpdateAppConfiguration(configuration));
  }

  public static close(maintainState = true, callback?: () => void) {
    if (sourceUrl !== undefined) {
      window.location.href = sourceUrl;
    } else if (this._configuration?.login?.refreshToken !== undefined && this._configuration.backofficeUrl !== undefined) {
      window.location.href = this._configuration.backofficeUrl;
    }




    if (this.instance.element) {
      const parent = this.instance.element.parentNode;
      if (parent) {
        parent.removeChild(this.instance.element);
        const newElement = document.createElement('div');
        newElement.id = 'root';
        newElement.className = ReInitializeClassName;
        parent.insertBefore(newElement, parent.childNodes[0]);
      }
    }
    if (document.body) {
      document.body.classList.remove('pin-me');
    }

    const htmlTag = document.querySelector('html');

    if (htmlTag) {
      htmlTag.classList.remove('pin-me');
    }

    if (!maintainState) {
      // this.instance.store = ConfigureStore();
    }
    if (callback) {
      callback();
    }
  }

  private get store(): Store {
    return DormerConfigurator._store;
  }

  private get element(): HTMLElement {
    return this._element;
  }

  private applyStylesToDocument(): void {
    this._element.classList.add(styles['configurator-init']);
  }

  private listenForWindowEvents() {
    window.addEventListener(HEC_ERROR, (event) => {
      console.log(event);
      // const eventData = (event as CustomEvent<ErrorEventData>).detail;
      // this.store.dispatch(raiseError(new (Error as any)(eventData.message)));
    });
  }

  private constructor(element: HTMLElement | null) {
    if (!element) {
      throw new Error('Cannot create configurator instance without an element');
    }
    // this._store = DormerStore;
    this._element = element;
  }


  private render(matomoUrl: string, translationsUrl: string): void {
    const state = (this.store.getState() as HomeExtensionApplicationState);

    const language = state?.applicationStateReducer?.configuration?.languageCode ?? 'nl_NL';

    const i18n = i18NextInitialize(translationsUrl, language);
    const root = createRoot(
      document.getElementById('root') as HTMLElement
    );

    const matomoTracker = createInstance({
      urlBase: matomoUrl,
      siteId: 1,
      configurations: {
        disableCookies: true,
        setSecureCookie: false,
        setRequestMethod: 'POST'
      }
    });

    root.render(
      <MatomoProvider value={matomoTracker}>
        <Suspense
          fallback={
            <Loader
              containerStyles={{background: 'white'}}
              barStyles={{background: 'black'}}
              dataStyles={{color: 'black'}}
            />
          }
        >
          <Provider store={this.store}>
            <ConnectedErrorBoundary>
              <I18nextProvider i18n={i18n}>
                <MemoryRouter>
                  <App/>
                  <Routes>
                    <Route path="/" element={<Navigate to={RoutePaths.TemplatePath}/>}/>
                  </Routes>
                </MemoryRouter>
              </I18nextProvider>
            </ConnectedErrorBoundary>
          </Provider>
        </Suspense>
      </MatomoProvider>
    );
  }
}


const currentUrl = new URL(window.location.href);



let configurationId = currentUrl.searchParams.get('configurationId') ?? undefined;
if ((configurationId ?? '').length === 0) {
  configurationId = undefined;
}
if(configurationId == undefined) {
  console.error('no configurationId found');
  window.location.href = '/404';
}

let baseUrl = document.getElementById('BASE_URL')?.textContent ?? undefined;
if ((baseUrl ?? '').length === 0) {
  baseUrl = undefined;
}
let matomoUrl = document.getElementById('MATOMO_URL')?.textContent ?? undefined;
if ((matomoUrl ?? '').length === 0) {
  matomoUrl = undefined;
}
let translationsUrl = document.getElementById('TRANSLATIONS_URL')?.textContent ?? undefined;
if ((translationsUrl ?? '').length === 0) {
  translationsUrl = undefined;
}
let loginHostname = document.getElementById('LOGIN_HOSTNAME')?.textContent ?? undefined;
if ((loginHostname ?? '').length === 0) {
  loginHostname = undefined;
}
let loginRealm = document.getElementById('LOGIN_REALM')?.textContent ?? undefined;
if ((loginRealm ?? '').length === 0) {
  loginRealm = undefined;
}

let backofficeUrl = document.getElementById('BACKOFFICE_URL')?.textContent ?? undefined;
if ((backofficeUrl ?? '').length === 0) {
  backofficeUrl = undefined;
}

let sourceUrl = currentUrl.searchParams.get('sourceUrl') ?? undefined;
if (sourceUrl) {
  sourceUrl = decodeURIComponent(sourceUrl);
}


const configuration: AppConfiguration = {
  logging: {
    enableReduxLogging: true,
    logLevel: LogLevel.INFORMATION,
  },
  languageCode: 'nl',
  baseUrl,
  translationsUrl,
  login: {
    host: loginHostname,
    realm: loginRealm ?? 'HEC',
    clientId: loginClientId,
  },
  matomoUrl,
  configurationId: configurationId,
  sourceUrl,
  backofficeUrl,
};


console.info(configuration);

DormerConfigurator.init(configuration);
