import React, { FunctionComponent } from 'react';
import styles from './cost-aggregate.module.scss';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ModalContentKey, Palette, TranslationKeys } from '@hec/models';
import { CurrencyFormatter } from '@hec/utils';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { ToggleModal } from '@hec/core';
import { Conditional } from '../Conditional';
import { Text } from '../Typography';

export interface CostAggregateProps {
  totalPrice: number;
  palette: Palette;
  hidden?: boolean;
  hidePrice?: boolean;
  modalOpen?: boolean;
}

export const CostAggregate: FunctionComponent<CostAggregateProps> = ({
  totalPrice,
  palette,
  hidden = false,
  hidePrice = false,
  modalOpen = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <>
      <div
        className={classnames(
          styles['cost-aggregate'],
          {
            [`${styles['-active']}`]: modalOpen,
            [`${styles.hidden}`]: hidden,
          }
        )}
        onClick={() => dispatch(ToggleModal(ModalContentKey.COST_SUMMARY))}
      >
        <Conditional
          condition={!hidePrice}
          fallback={<Text as={'h6'}>{t(TranslationKeys.generic.priceOnInquiry)}</Text>}
        >
          <Text as={'h6'}>{t(TranslationKeys.generic.indication, 'Prijsindicatie')}: </Text>
        </Conditional>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Conditional condition={!hidePrice}>
            <Text as={'h6'} style={{ color: palette.primaryColor }}>{CurrencyFormatter.format(totalPrice)}&nbsp;</Text>
          </Conditional>
          <Conditional condition={modalOpen}>
            <KeyboardArrowDown />
          </Conditional>
          <Conditional condition={!modalOpen}>
            <KeyboardArrowUp />
          </Conditional>
        </div>
      </div>
    </>
  );
};
