import { ProductWithTextureMaterialWithSpecification } from './';

export enum FrameHeaderStyle {
  NONE = 'None',
  BOARD = 'Board',
  BRICKLINTEL = 'BrickLintel',
}

export interface FrameHeaderSpecification extends Record<string, unknown> {
  forTextureMaterialId: string;
  frameHeaderStyle: FrameHeaderStyle;
}

export type FrameHeaderProduct =
  ProductWithTextureMaterialWithSpecification<FrameHeaderSpecification>;
