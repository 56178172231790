import {FaceDto, OrderDto} from "@hec/api-dtos";

export const getSouthFaceFromOrder = (order: OrderDto): FaceDto | null => {
  const southFace = order.faceConfiguration.faces.find((face: FaceDto) => face.side === 2);
  if (southFace) {
    return southFace;
  } else {
    return null;
  }
}
