import {FaceDto, FrameDto, SectionFragmentDto, SectionFragmentType} from "@hec/api-dtos";
import {getConfiguredFramesFromOrder, getSectionFragmentsFromOrderByType, getSouthFaceFromOrder} from "./Util";
import {DormerRootState} from "../DormerRootState";

export const getConfiguredFramesSelector = (state: DormerRootState): FrameDto[] => {
  const order = state.order.order;
  if (!order) {
    return [];
  }

  return getConfiguredFramesFromOrder(order);
}

export const getConfiguredSectionFragmentSelector = (state: DormerRootState, type: SectionFragmentType): SectionFragmentDto[] => {
  const order = state.order.order;
  if (!order) {
    return [];
  }

  return getSectionFragmentsFromOrderByType(order, type);
}

export const getSouthFaceSelector = (state: DormerRootState): FaceDto | null => {
  const order = state.order.order;
  if (!order) {
    return null;
  }

  return getSouthFaceFromOrder(order);
}
