import {GetOrderInvoiceViewModel } from '@hec/api-dtos';
import React, {FunctionComponent} from 'react';
import { InvoiceItem } from './InvoiceItem';
import {Text} from "../Typography";
import {useTranslation} from "react-i18next";
import styles from './InvoiceList.module.scss';
import {TranslationKeys} from "@hec/models";

interface InvoiceListProps {
  invoiceModel: GetOrderInvoiceViewModel;
  orderIsDirty: boolean;
}


export const InvoiceList: FunctionComponent<InvoiceListProps> = ({ invoiceModel, orderIsDirty }) => {
  const { t } = useTranslation();



  return (
    <div>
      <Text as={"h2"} className={styles.heading}>{t(TranslationKeys.dormer.pages.summaryPage.summary)}</Text>
      {invoiceModel.orderInvoiceViewModel.invoiceItems.map((item, index) => (
        <InvoiceItem key={index} item={item}/>
      ))}
      <div className={styles.summary}>
        <Text as={"h3"} className={styles.summaryText}>{t(TranslationKeys.dormer.pages.summaryPage.indication)}</Text>
        <Text as={"h4"} className={styles.summaryTotal}>{(orderIsDirty === true ? '~' : '') + invoiceModel.orderInvoiceViewModel.priceText}</Text>
      </div>
    </div>
  );
};

