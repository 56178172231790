export const MINIMUM_GARDEN_WIDTH = 4;
export const GARDEN_TILING_DEPTH = 6;
export const GRASS_DEPTH = 4;

export interface GroundDimensions {
  width: number;
  depth: number;
  tileWidth: number;
  tileDepth: number;
  grassWidth: number;
  grassDepth: number;
  xOffsetTile: number;
  zOffsetTile: number;
  xOffsetGrass: number;
  zOffsetGrass: number;
  westFenceDepth: number;
  eastFenceDepth: number;
  southFenceWidth: number;
  zPositionWestFence: number;
  zPositionEastFence: number;
  zPositionSouthFence: number;
}

export const calculateGroundWidth = (
  widthInMeters: number,
  depthInMeters: number
): GroundDimensions => {
  const result: GroundDimensions = {
    width: widthInMeters,
    depth: depthInMeters,
    tileWidth: 0,
    tileDepth: GARDEN_TILING_DEPTH,
    grassWidth: 0,
    grassDepth: GRASS_DEPTH,
    zOffsetTile: 0,
    xOffsetTile: 0,
    zOffsetGrass: 0,
    xOffsetGrass: 0,
    westFenceDepth: 0,
    zPositionWestFence: 0,
    eastFenceDepth: 0,
    zPositionEastFence: 0,
    southFenceWidth: 0,
    zPositionSouthFence: 0,
  };

  result.southFenceWidth = widthInMeters;

  const defaultFenceDepth = GARDEN_TILING_DEPTH + GRASS_DEPTH;
  const sideFencePosition = depthInMeters;
  result.zPositionWestFence = sideFencePosition;
  result.zPositionEastFence = sideFencePosition;
  result.zPositionSouthFence = defaultFenceDepth + depthInMeters;

  if (MINIMUM_GARDEN_WIDTH > widthInMeters) {
    result.tileDepth += depthInMeters * 2;

    result.zOffsetTile = -depthInMeters;
    result.tileWidth = MINIMUM_GARDEN_WIDTH;

    result.grassWidth = MINIMUM_GARDEN_WIDTH;
    result.southFenceWidth = MINIMUM_GARDEN_WIDTH;

    result.westFenceDepth = GARDEN_TILING_DEPTH + GRASS_DEPTH;
    result.eastFenceDepth = GARDEN_TILING_DEPTH + GRASS_DEPTH + depthInMeters;
    result.zPositionEastFence = 0;
  } else {
    result.tileWidth = widthInMeters;
    result.zOffsetTile = depthInMeters;

    result.grassDepth = GRASS_DEPTH;
    result.grassWidth = widthInMeters;

    result.westFenceDepth = defaultFenceDepth;
    result.eastFenceDepth = defaultFenceDepth;
  }

  result.zOffsetGrass = result.tileDepth + result.zOffsetTile;

  return result;
};
