import { Vector2, Vector3 } from 'three';
import { Wall } from './Wall';
import { DirectionEnum } from '../Enums';
import { FunctionComponent } from 'react';
import { HomeExtensionDto } from '../HomeExtensionDto';
import { FrameHeaderProduct, IsFrameHeaderProduct } from '@hec/models';
import { Conditional } from '../../Conditional';
import { FrameHeader } from './FrameHeader';

export interface WallsProps {
  homeExtensionDto: HomeExtensionDto;
}

export const Walls: FunctionComponent<WallsProps> = ({ homeExtensionDto }) => {
  return (
    <>
      {(Object.keys(DirectionEnum) as (keyof typeof DirectionEnum)[])
        .filter(
          (outerWallFacingDirection) =>
            outerWallFacingDirection !== DirectionEnum.South
        )
        .map((outerWallFacingDirection) => {
          const outerWallFacingDirectionEnum =
            outerWallFacingDirection as DirectionEnum;

          return (
            <Wall
                key={outerWallFacingDirection}
                outerWallFacingDirection={outerWallFacingDirectionEnum}
                homeExtensionDto={homeExtensionDto}
                doorway={
                  outerWallFacingDirection === DirectionEnum.North &&
                  homeExtensionDto.getFrameMeasurements()
                    ? (homeExtensionDto.getFrameMeasurements() as Vector2)
                    : undefined
                }
              />
          );
        })}
    </>
  );
};
