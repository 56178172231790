import { call, spawn, all } from 'redux-saga/effects';

function* watchAll(sagas: any[]) {
  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            console.error(e);
          }
        }
      })
    )
  );
}

export default watchAll;
