import React, { FunctionComponent } from 'react';
import styles from './drawer-toggle.module.scss';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import classnames from 'classnames';
import { Conditional } from '../Conditional';

export interface DrawerToggleProps {
  onClick?: () => void;
  open?: boolean;
  className?: string;
}

export const DrawerToggle: FunctionComponent<DrawerToggleProps> = ({
  onClick,
  open = false,
  className,
}) => {
  return (
    <div className={classnames(styles.toggle, className)} onClick={onClick}>
      <Conditional condition={typeof open !== 'undefined' && open}>
        <OpenInFullIcon />
      </Conditional>
      <Conditional condition={typeof open !== 'undefined' && !open}>
        <CloseFullscreenIcon />
      </Conditional>
    </div>
  );
};
