import { OrbitControls } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import { FunctionComponent, useEffect, useState } from 'react';
import { ANIMATION_LENGTH } from './Constants';
import { HomeExtensionDto } from './HomeExtensionDto';

interface CameraProps {
  homeExtensionDto: HomeExtensionDto;
  minDistanceMultiplier?: number;
  dolly?: boolean;
  zoom?: number;
  isInitialPositionCallback: (() => void) | undefined;
}

export const DEFAULT_ZOOM = 3;
const azimuthAngle = Math.PI / 2;
const maxPolar = Math.PI / 2 - Math.PI / 360;
export const Camera: FunctionComponent<CameraProps> = ({
  homeExtensionDto,
  minDistanceMultiplier = 1.5,
  zoom = DEFAULT_ZOOM,
  dolly = true,
  isInitialPositionCallback,
}) => {
  const { camera } = useThree();
  const [isInitialPosition, setIsInitialPosition] = useState<boolean>(true);
  const target = homeExtensionDto.getRelativeCameraPosition();
  const [widthInMeters, heightInMeters, depthInMeters] = target.toArray();
  const minDistance =
    widthInMeters > depthInMeters
      ? (widthInMeters / 2) * minDistanceMultiplier
      : (depthInMeters / 2) * minDistanceMultiplier;

  const isDevelopment = process?.env?.NODE_ENV === 'development';

  useEffect(() => {
    if (dolly && camera.position.length() > 6) {
      camera.position.sub(target).setLength(zoom).add(target);
    }
  }, [zoom, camera, dolly]);

  useEffect(() => {
    if (dolly && isInitialPosition) {
      setTimeout(() => {
        setIsInitialPosition(false);
      }, ANIMATION_LENGTH - 200);
    }
  });
  useEffect(() => {
    if (!isInitialPosition) {
      isInitialPositionCallback && isInitialPositionCallback();
    }
  }, [isInitialPosition]);

  useFrame((state) => {
    if (isInitialPosition && dolly) {
      const startPosition = zoom + 20;
      const l = Math.log(state.clock.getElapsedTime()) * 12;
      const x = -l * 1.6 + startPosition;
      const z = -l + startPosition;
      camera.position
        .sub(target)
        .setX(x * 0.4)
        .setZ(z * 0.4)
        .setY(1)
        .add(target);
    } else if (isInitialPosition && !dolly) {
      const startPosition = zoom + 20;
      const l = 12.0;
      const x = -l * 1.6 + startPosition;
      const z = -l + startPosition;
      camera.position
        .sub(target)
        .setX(x * 0.4)
        .setZ(z * 0.4)
        .setY(1)
        .add(target);
      setIsInitialPosition(false);
    }
    return null;
  });

  return (
    <group>
      <OrbitControls
        enableZoom={true}
        enableRotate={true}
        enableDamping={true}
        maxDistance={25}
        minDistance={minDistance}
        target={target}
        camera={camera}
        maxPolarAngle={isDevelopment ? undefined : maxPolar}
        minAzimuthAngle={isDevelopment ? undefined : -azimuthAngle}
        maxAzimuthAngle={isDevelopment ? undefined : azimuthAngle}
        enablePan={isDevelopment}
      />
    </group>
  );
};
