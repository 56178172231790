import React, { FunctionComponent } from 'react';
import styles from './combined-button.module.scss';
import classnames from 'classnames';

export interface Action {
  name: string;
  onClick?: () => void;
}
export interface CombinedButtonProps {
  actions: Action[];
  activeIndex?: number;
}

export const CombinedButton: FunctionComponent<CombinedButtonProps> = ({
  actions,
  activeIndex,
}) => {
  return (
    <div className={classnames(styles['combined-button'])}>
      {actions.map((action, index) => {
        return (
          <div
            key={index}
            className={classnames(styles.action, {
              [`${styles.active}`]: activeIndex === index,
            })}
            onClick={action.onClick}
          >
            {action.name}
          </div>
        );
      })}
    </div>
  );
};
