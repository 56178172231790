import { QuotationPersonalInformation } from "../RequestQuotation/QuotationPersonalInformation";

export interface RequestQuotationDto {
  clientConfigurationId: string;
  personalInformation: QuotationPersonalInformation;
  productIds: string[];
  depth: number;
  width: number;
  description?: string;
}
