import {BaseDalService} from "@hec/core";
import {AppConfiguration, ApplicationService} from "@hec/models";
import axios, {AxiosInstance} from "axios";
import {ConfigurationTemplateDto} from "@hec/api-dtos";

export class ConfigurationTemplateService extends BaseDalService implements ApplicationService {

  private client: AxiosInstance;
  public static override serviceName = Symbol('ConfigurationTemplateService');

  constructor() {
    super();
    this.client = axios.create({});
  }

  override configure(configuration: AppConfiguration) {
    this.client = axios.create({
      baseURL: `${configuration.baseUrl}/api/configurationTemplates`,
    });

    this.client.interceptors.request.use(async (config) => {
      config.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };
      return config;
    });

    this.useDateTimeConverters(this.client);
    this.useEnumConverters(this.client);
  }


  getName(): symbol {
    return ConfigurationTemplateService.serviceName;
  }

  static override getName(): symbol {
    return ConfigurationTemplateService.serviceName;
  }

  async get(organisationId: string): Promise<ConfigurationTemplateDto[]> {
    const response = await this.client.get(`?organisationId=${organisationId}`);


    if (response.status === 200) {
      return response.data as ConfigurationTemplateDto[];
    }

    throw new Error(`ConfigurationTemplateService: get resulted in ${response.status}: ${response.statusText}`);
  }
}
