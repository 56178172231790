import { AnyAction } from 'redux';
import { put, select } from 'redux-saga/effects';

import { REDUCER_CANCEL_CONFIGURATION_PROMPT, unlimboActions, HomeExtensionRootState } from '../../../'
export function* ConfigurationPromptCancellationHandler(action: AnyAction) {
  const state: { [key: string]: AnyAction[] } = yield select((state: HomeExtensionRootState) => state.applicationStateReducer.actionLimbo);

  if (Object.prototype.hasOwnProperty.call(state, action.data.limboKey)) {
    yield put(unlimboActions(action.data.limboKey));
    yield put({ type: REDUCER_CANCEL_CONFIGURATION_PROMPT, data: action.data })
  }
}
