import { Translations } from './Translations';
import {TranslationKeysDormer} from "./TranslationKeysDormer";

export const TranslationKeys: Translations = {
  other: {
    explanation: {
      allSides: 'other.explanation.allSides',
      clickAndDrag: 'other.explanation.clickAndDrag',
      closerLook: 'other.explanation.closerLook',
      scroll: 'other.explanation.scroll',
    },
  },
  modals: {
    promptModal: {
      title: 'modals.promptModal.title',
      content: 'modals.promptModal.content',
    },
    costSummaryModal: {
      title: 'modals.costSummaryModal.title',
    },
    helpModal: {
      title: 'modals.helpModal.title',
    },
    quotationModal: {
      title: 'modals.quotationModal.title',
      questionAreaTitle: 'modals.quotationModal.questionAreaTitle',
      questionPlaceHolder: 'modals.quotationModal.questionPlaceHolder',
      form: {
        firstName: 'modals.quotationModal.form.firstName',
        firstNameError: 'modals.quotationModal.form.firstNameError',
        lastName: 'modals.quotationModal.form.lastName',
        lastNameError: 'modals.quotationModal.form.lastNameError',
        email: 'modals.quotationModal.form.email',
        emailError: 'modals.quotationModal.form.emailError',
        address: 'modals.quotationModal.form.address',
        postalCode: 'modals.quotationModal.form.postalCode',
        city: 'modals.quotationModal.form.city',
        country: 'modals.quotationModal.form.country',
        phoneNumber: 'modals.quotationModal.form.phoneNumber',
        phoneNumberError: 'modals.quotationModal.form.phoneNumberError',
        chamberOfCommerceNumber:
          'modals.quotationModal.form.chamberOfCommerceNumber',
        companyName: 'modals.quotationModal.form.companyName',
      },
    },
    requestPdfModal: {
      title: 'modals.requestPdfModal.title',
      form: {
        email: 'modals.requestPdfModal.form.email',
        emailError: 'modals.requestPdfModal.form.emailError',
        cta: 'modals.requestPdfModal.form.cta',
      },
    },
  },
  fatalError: {
    somethingWentWrongHeader: 'fatalError.somethingWentWrongHeader',
    fatalErrorKeyPrefix: 'fatalError.fatalErrorKeyPrefix',
    fatalErrorKeyHintPrefix: 'fatalError.fatalErrorKeyHintPrefix',
    redirectSoonText: 'fatalError.redirectSoonText',
  },
  notifications: {
    quotationEmailNotSent: 'notifications.quotationEmailNotSent',
    quotationEmailSent: 'notifications.quotationEmailSent',
  },
  generic: {
    priceOnInquiry: 'generic.priceOnInquiry',
    previousStep: 'generic.previousStep',
    optional: 'generic.optional',
    description: 'generic.description',
    included: 'generic.included',
    subTotal: 'generic.subTotal',
    vatAmount: 'generic.vatAmount',
    total: 'generic.total',
    indication: 'generic.indication',
    depth: 'generic.depth',
    width: 'generic.width',
    help: 'generic.help',
    phone: 'generic.phone',
    email: 'generic.email',
    confirm: 'generic.confirm',
    cancel: 'generic.cancel',
    makeSmaller: 'generic.makeSmaller',
    makeBigger: 'generic.makeBigger',
  },
  materials: {
    wood: 'materials.wood',
    stone: 'materials.stone',
    synthetic: 'materials.synthetic',
    aluminium: 'materials.aluminium',
  },
  pages: {
    claddingPage: {
      name: 'pages.claddingPage.name',
      nextStep: 'pages.claddingPage.nextStep',
      sideDrawerTitle: 'pages.claddingPage.sideDrawerTitle',
    },
    measurementsPage: {
      name: 'pages.measurementsPage.name',
      nextStep: 'pages.measurementsPage.nextStep',
      sideDrawerTitle: 'pages.measurementsPage.sideDrawerTitle',
    },
    framesPage: {
      name: 'pages.framesPage.name',
      nextStep: 'pages.framesPage.nextStep',
      sideDrawerTitle: 'pages.framesPage.sideDrawerTitle',
    },
    extrasPage: {
      name: 'pages.extrasPage.name',
      nextStep: 'pages.extrasPage.nextStep',
      sideDrawerTitle: 'pages.extrasPage.sideDrawerTitle',
      roofTrimDropdown: 'pages.extrasPage.roofTrimDropdown',
      roofOverhangDropdown: 'pages.extrasPage.roofOverhangDropdown',
      roofLightStyleDropDown: 'pages.extrasPage.roofLightStyleDropDown',
      frameHeaderDropDown: 'pages.extrasPage.frameHeaderDropDown',
      drainPipeDropdown: 'pages.extrasPage.drainPipeDropdown',
      faucetDropdown: 'pages.extrasPage.faucetDropdown',
    },
    summaryPage: {
      name: 'pages.summaryPage.name',
      downloadPdfLink: 'pages.summaryPage.downloadPdfLink',
      cta: 'pages.summaryPage.cta',
      sideDrawerTitle: 'pages.summaryPage.sideDrawerTitle',
      requestPdf: 'pages.summaryPage.requestPdf',
    },
  },
  dormer: TranslationKeysDormer,
};
