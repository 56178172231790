import { DirectionEnum } from '../Enums';
import { DEFAULT_WALL_THICKNESS, TEXTURES_PER_METER_IN_LENGTH } from '../../index';
import { Plane, RepeatWrapping, Texture, Vector2, Vector3 } from 'three';
import { DEFAULT_WALL_HEIGHT } from '@hec/core';

export class WallUtilities {
  public static getWallPosition(
    originalPosition: Vector3,
    outerWallFacingDirection: DirectionEnum,
    width: number,
    depth: number
  ) {
    if (
      outerWallFacingDirection === DirectionEnum.South ||
      outerWallFacingDirection === DirectionEnum.West
    ) {
      return null;
    }

    // eslint-disable-next-line prefer-const
    let wallPosition = originalPosition.clone();

    if (outerWallFacingDirection === DirectionEnum.North) {
      wallPosition.setZ(depth - DEFAULT_WALL_THICKNESS);
    } else if (outerWallFacingDirection === DirectionEnum.East) {
      wallPosition.setX(width - DEFAULT_WALL_THICKNESS);
    }

    return wallPosition;
  }

  public static getWallLength(
    direction: DirectionEnum,
    width: number,
    depth: number
  ) {
    return direction === DirectionEnum.East || direction === DirectionEnum.West
      ? depth
      : width;
  }

  public static getClipPanes(doorway: Vector2) {
    const widthDiffFromMiddle = -(doorway.x / 2);
    const clipWestFromMiddle = widthDiffFromMiddle;
    const clipEastFromMiddle = widthDiffFromMiddle;
    const clipNorthFromMiddle = -1;
    const clipSouthFromMiddle = -1;
    const wallHeightMiddle = DEFAULT_WALL_HEIGHT / 2;
    const clipUpFromMiddle = wallHeightMiddle - doorway.y;
    const clipDownFromMiddle = -10;

    return [
      new Plane(new Vector3(1, 0, 0), clipWestFromMiddle),
      new Plane(new Vector3(0, 0, 1), clipNorthFromMiddle),
      new Plane(new Vector3(-1, 0, 0), clipEastFromMiddle),
      new Plane(new Vector3(0, 0, -1), clipSouthFromMiddle),
      new Plane(new Vector3(0, -1, 0), clipDownFromMiddle),
      new Plane(new Vector3(0, 1, 0), clipUpFromMiddle),
    ];
  }

  public static wrapTexture(
    texture: Texture,
    wallLength: number,
    offSet?: Vector2
  ): Texture {
    const mapRepeatY = TEXTURES_PER_METER_IN_LENGTH * DEFAULT_WALL_HEIGHT;
    const regularTexture = texture.clone();
    regularTexture.wrapS = RepeatWrapping;
    regularTexture.wrapT = RepeatWrapping;
    regularTexture.repeat.set(
      TEXTURES_PER_METER_IN_LENGTH * wallLength,
      mapRepeatY
    );
    if (offSet) {
      regularTexture.offset.set(offSet.x, offSet.y);
    }
    return regularTexture;
  }
}
