import React, { FunctionComponent, useEffect, useRef } from 'react';
import {
  BoxGeometry,
  Euler,
  Group, Material,
  Matrix4,
  Vector3
} from 'three';
import { WedgeGeometry } from '../';
import { degToRad } from 'three/src/math/MathUtils';

export interface ZincFlatStraightProps {
  position: Vector3;
  length: number;
  rotationInDeg: number;
  overhang?: number;
  material?: Material;
}

const heightInMeters = 0.05;
const boxWidthInMeters = .2;
const wedgeWidthAndHeightInMeters = 0.05;
export const ZincFlatStraight: FunctionComponent<ZincFlatStraightProps> = ({ position, length, rotationInDeg, material, overhang = 0 }) => {
  const cylinderPosition = position.clone().setX(position.x + (boxWidthInMeters - 2 * heightInMeters) + overhang).setY(position.y).setZ(position.z);
  const wedgePosition = (position.clone()).setX(position.x - .1).setY(position.y - .025).setZ(position.z + length / 2);
  const boxPosition = (position.clone()).setX(position.x + overhang / 2);
  const boxRef = useRef<BoxGeometry>(null);
  const groupRef = useRef<Group>(null);

  useEffect(() => {
    if (groupRef.current) {
      groupRef.current.applyMatrix4( new Matrix4().makeRotationY( degToRad( rotationInDeg ) ) );
    }
  }, [rotationInDeg]);
  return (
    <group ref={groupRef}>
      <group
        position={boxPosition}
      >
        <mesh material={material}>
          <boxGeometry
            args={[boxWidthInMeters + overhang, heightInMeters, length]}
            ref={boxRef}
          />
        </mesh>
      </group>
      <group position={wedgePosition}>
        <mesh rotation={new Euler(0, Math.PI, 0)} material={material}>
          <WedgeGeometry height={wedgeWidthAndHeightInMeters} width={wedgeWidthAndHeightInMeters} depth={length} />
        </mesh>
      </group>
      <group
        position={cylinderPosition}
      >
        <mesh rotation={new Euler(0.5*Math.PI, 0, 0)} material={material}>
          <cylinderGeometry
            args={[heightInMeters / 2, heightInMeters / 2, length, 16, 1, false]}
          />
        </mesh>
      </group>
    </group>
  );
};
