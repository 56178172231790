import styles from "./form.module.scss";
import {Button, FormField, Input, Text} from "@hec/components/v1";
import {ModalContentKey, QuotationRequestViewModel, TranslationKeys} from "@hec/models";
import React, {useState} from "react";
import {requestQuotation, HomeExtensionRootState, ToggleModal} from "@hec/core";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import { useMatomo } from "@jonkoops/matomo-tracker-react";

export const FormRequestPdf = () => {
  const { t } = useTranslation();
  const {
    primaryColor,
    secondaryColor
  } = useSelector((state: HomeExtensionRootState) => state.homeConfigurationReducer.clientConfiguration)
  const [emailAddress, setEmailAddress] = useState('');
  const [emailAddressError, setEmailAddressError] = useState(false);
  const dispatch = useDispatch();
  const { trackEvent, pushInstruction } = useMatomo();

  const onSubmit = async () => {
    try {
      const hasErrors = !emailAddress;
      setEmailAddressError(hasErrors);


      if (!hasErrors) {
        const quotation: QuotationRequestViewModel = {
          personalInformation: {
            emailAddress,
          },
        };


        pushInstruction('setUserId', emailAddress);
        trackEvent({
          category: 'interaction',
          action: 'request-pdf',
          href: window.document.location.href,
        });

        dispatch(requestQuotation(quotation));
        dispatch(ToggleModal(ModalContentKey.NONE));
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <form action="" className={styles.form} onClick={(e) => e.preventDefault()}>
      <Text as={'h4'} className={styles.title}>{t(TranslationKeys.modals.requestPdfModal.title)}</Text>
      <FormField label="Email"
                 errors={emailAddressError ? [t(TranslationKeys.modals.requestPdfModal.form.emailError, 'email is vereist')] : undefined}
      >
        <Input
          type="text"
          name="email"
          placeholder="J.Janssen@contoso.net"
          className={emailAddressError ? styles.error : ''}
          onChange={(e) => setEmailAddress((e.target as any).value)}
        />
      </FormField>
      <Button
        type={'cta'}
        size={'large'}
        palette={{
          primaryColor,
          secondaryColor,
        }}
        onClick={onSubmit}
      >
        {t(TranslationKeys.modals.requestPdfModal.form.cta, 'Aanvraag versturen')}
      </Button>

    </form>
  )
}
