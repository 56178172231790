import {ApplicationService} from "@hec/models";
import {PostOrderService} from "./PostOrderService";
import {PutCustomerInformationService} from "./PutCustomerInformationService";
import {PutOrderService} from "./PutOrderService";

export const requiredOrderServices = (): ApplicationService[] => {
  return [
    new PutCustomerInformationService(),
    new PostOrderService(),
    new PutOrderService(),
  ];
}
