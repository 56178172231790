import {
  HomeConfigurationState,
  PriceCalculationStrategy,
  Product,
  ProductionMaterialType,
  ProductType,
} from '../';

export const BaseProductPriceCalculationStrategy: PriceCalculationStrategy = (
  state: HomeConfigurationState
) => {
  return (product: Product) => {
    if (product.productType === ProductType.CONSTRUCTION) {
      const sizeInSquareMeters =
        state.measurements.width * state.measurements.depth;
      const sizeInCubicMeters = sizeInSquareMeters * state.measurements.height;
      return {
        id: 'BASE',
        name: 'BASE',
        description: sizeInSquareMeters.toString(10),
        priceExVat:
          (state.clientConfiguration.basePrice ?? 0) +
          sizeInCubicMeters * state.clientConfiguration.pricePerCubicMeter,
        productType: ProductType.CONSTRUCTION,
        productionMaterialType: ProductionMaterialType.CONCRETE,
        translationKey: 'generic.baseProduct',
      };
    }
    return { ...product };
  };
};
