import { raiseError, requestFailure } from '../../../';
import {
  HomeExtensionApplicationState,
  DefaultProductSelectionId,
  HomeConfigurationState,
  Product,
  QuotationRequestViewModel,
  RequestQuotationDto,
  Severity,
  TranslationKeys,
} from '@hec/models';
import { AnyAction } from 'redux';
import { call, put, select } from 'redux-saga/effects';
import axios, { AxiosError } from 'axios';
import { requestQuotationSuccess } from '../../..';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { QuotationService } from '../../../';
import { getService } from '../../../';

export function* RequestQuotationHandler(action: AnyAction) {
  try {
    const service: QuotationService = yield getService(QuotationService);
    const currentState: HomeConfigurationState = yield select(
      (state: HomeExtensionApplicationState) => state.homeConfigurationReducer
    );
    const products: Product[] = currentState.selectedProducts;

    const quotationRequest = action.data as QuotationRequestViewModel;

    const clientConfigurationId = currentState.clientConfiguration
      .clientConfigurationId as string;

    const defaultProductSelectionIds = Object.values(
      DefaultProductSelectionId
    ).map((x) => x.toString());

    const requestQuotationDto: RequestQuotationDto = {
      clientConfigurationId: clientConfigurationId,
      personalInformation: quotationRequest.personalInformation,
      productIds: products
        .filter(
          (x) =>
            x.id !== 'BASE' && defaultProductSelectionIds.indexOf(x.id) === -1
        )
        .map((x) => x.id),
      depth: currentState.measurements.depth,
      width: currentState.measurements.width,
      description: quotationRequest.description,
    };

    yield call([service, 'requestQuotation'], requestQuotationDto);
    yield put(requestQuotationSuccess((quotationRequest.personalInformation.phoneNumber ?? '').length != 0));
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      yield put(
        requestFailure(
          (error as AxiosError)?.response?.status ?? -1,
          (error as AxiosError)?.response?.statusText ?? 'none',
          action
        )
      );
      toast(
        i18next.t(TranslationKeys.notifications.quotationEmailNotSent) as string
      );
    } else {
      yield put(raiseError(error as Error, Severity.HIGH));
      toast(
        i18next.t(TranslationKeys.notifications.quotationEmailNotSent) as string
      );
    }
  }
}
