import React, { FunctionComponent } from "react"
import {DrawLine, DrawRectangle} from "../Utils";
import {OPENING_FRAME_ROD} from "../../../constants";

interface PanelProps {
  x: number,
  y: number,
  width: number,
  height: number,
}

export const TiltAndTurnWindowRight: FunctionComponent<PanelProps> = ({x, y, width, height}) => {

  return <>
    <DrawRectangle x={x} y={y} width={width} height={height}/>
    <DrawRectangle x={x + OPENING_FRAME_ROD} y={y + OPENING_FRAME_ROD} width={width - (OPENING_FRAME_ROD * 2)} height={height - (OPENING_FRAME_ROD * 2)} fill={'#EEF8FF'}/>
    <DrawLine x={x} y={y} points={[width, 0, 0, height / 2, width, height]} dash={[20, 20]}/>
    <DrawLine x={x} y={y} points={[0, height, width / 2, 0, width, height]} dash={[20, 20]}/>
  </>
}
