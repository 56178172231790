import { AnyAction } from 'redux';

export const LIMBO_ACTIONS = 'LIMBO_ACTIONS';
export const REDUCER_LIMBO_ACTIONS = 'REDUCER_LIMBO_ACTIONS';

export const limboActions = (limboKey: string, actions: AnyAction[]) => ({
  type: LIMBO_ACTIONS,
  data: {
    limboKey,
    actions
  }
})
