import {BaseClientInformation} from "../../";

export interface HomeExtensionClientConfiguration extends BaseClientInformation {
  pricePerCubicMeter: number;
  basePrice?: number;
  maxWidth: number;
  maxDepth: number;
  minWidth: number;
  minDepth: number;
  excludeFrameFromCladdingPriceCalculation: boolean;
  leadPostedSuccessRedirectUrl: string | null;
}
