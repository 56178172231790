import {
  FrameHeaderProduct,
  HomeConfigurationState,
  IsFrameProduct,
  PriceCalculationStrategy,
  Product,
  ProductType,
} from '../'; 

export const FrameHeaderProductPriceCalculationStrategy: PriceCalculationStrategy = (state: HomeConfigurationState) => {

  const frameProduct = state.selectedProducts.find(IsFrameProduct);

  return (product: Product): Product => {
    const clone = {...product};
    if ((product as FrameHeaderProduct).productType === ProductType.FRAME_HEADER && frameProduct && frameProduct.drawableObject.width > 0) {
      const price = product.priceExVat * (frameProduct.drawableObject.width * .5);
      clone.priceExVat = price;
    }
    return clone;
  }
}
