import * as THREE from 'three';
import React, { FunctionComponent, useEffect } from 'react';
import { Color, Vector2 } from 'three';
import { CSG } from 'three-csg-ts';
import { DEFAULT_WALL_THICKNESS } from "@hec/components/v1";
import { DEFAULT_WALL_HEIGHT } from '@hec/core';

export const DEFAULT_HOUSE_WIDTH = 5;

interface HouseProps {
  drawLowerWindows?: boolean;
  width?: number;
  color?: THREE.Color;
}

export const House: FunctionComponent<HouseProps> = ({
  drawLowerWindows = true,
  width = DEFAULT_HOUSE_WIDTH,
  color,
}: HouseProps) => {
  const scene = new THREE.Scene();

  const height = 7;
  const depth = 10;

  const houseColor = color ?? new Color('rgb(171, 171, 171)');
  const windowColor = color ?? new Color('rgb(160, 160, 160)');

  const windowWidth = 1;
  const windowHeight = 1.5;
  const windowDepth = DEFAULT_WALL_THICKNESS / 2;

  const house = new THREE.Mesh(
    new THREE.BoxGeometry(width, height, depth),
    new THREE.MeshStandardMaterial({
      // transparent: true,
      // opacity: 0.5,
      color: houseColor,
    })
  );

  const window = new THREE.Mesh(
    new THREE.BoxGeometry(windowWidth, windowHeight, windowDepth),
    new THREE.MeshStandardMaterial({ color: windowColor })
  );

  const distanceFromInside = 1;

  const window2 = window.clone();
  window.position.set(-distanceFromInside, 1, depth / 2 - windowDepth / 2);

  window2.position.set(distanceFromInside, 1, depth / 2 - windowDepth / 2);

  house.updateMatrix();
  window.updateMatrix();
  window2.updateMatrix();

  let houseResult = CSG.subtract(CSG.subtract(house, window), window2);

  let backPaneHeight = height;

  if (drawLowerWindows) {
    const windowLower1 = window.clone();
    const windowLower2 = window.clone();

    const yOffset = -2;

    windowLower1.position.set(
      -distanceFromInside,
      yOffset,
      depth / 2 - windowDepth / 2
    );
    windowLower2.position.set(
      distanceFromInside,
      yOffset,
      depth / 2 - windowDepth / 2
    );
    windowLower1.updateMatrix();
    windowLower2.updateMatrix();

    houseResult = CSG.subtract(
      CSG.subtract(houseResult, windowLower1),
      windowLower2
    );
  } else {
    backPaneHeight = backPaneHeight / 2;

    const roomHeight = DEFAULT_WALL_HEIGHT - DEFAULT_WALL_THICKNESS;
    const roomDepth = depth - DEFAULT_WALL_THICKNESS;
    const roomWidth = width - DEFAULT_WALL_THICKNESS * 2;

    const room = new THREE.Mesh(
      new THREE.BoxGeometry(roomWidth, roomHeight, roomDepth),
      new THREE.MeshStandardMaterial({
        // transparent: true,
        // opacity: 0.5,
        color: houseColor,
      })
    );

    room.position.set(
      0,
      roomHeight / 2 - height / 2,
      DEFAULT_WALL_THICKNESS / 2
    );

    room.updateMatrix();

    houseResult = CSG.subtract(houseResult, room);
  }

  scene.add(houseResult);
  return (
    <group position={[width / 2, height / 2, -(depth / 2)]}>
      <primitive object={scene} receiveShadow castShadow />
    </group>
  );
};
