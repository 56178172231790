import {BaseDalService} from "@hec/core";
import {select, SelectEffect} from "redux-saga/effects";
import {DormerRootState} from "../DormerRootState";
import {AppConfiguration, ApplicationService} from "@hec/models";

export function* getApplicationService(serviceRef: typeof BaseDalService): Generator<SelectEffect, typeof BaseDalService> {
  const services = yield select(getServicesSelector);

  const service = (services as typeof BaseDalService[])
    .find((service) => service.getName() === serviceRef.getName());

  if (!service) {
    throw new Error('Unable to find service of type ' + serviceRef.getName().toString());
  }

  return service;
}

export const getServicesSelector = (state: DormerRootState): ApplicationService[] => {
  return state.services.services;
}

export const getAppConfigurationSelector = (state: DormerRootState): AppConfiguration => {
  return state.services.appConfiguration!;
}


export const getIsOrganisationSelector = (state: DormerRootState): boolean => {
  return state.services?.appConfiguration?.login?.refreshToken != null;
}
