import React, { FunctionComponent } from 'react';
import {CladdingProduct, FrameProduct, Measurements, TranslationKeys} from '@hec/models';
import classNames from 'classnames';
import styles from './cladding-selection-item.module.scss';
import { CurrencyFormatter } from '@hec/utils';
import { BaseSelectionItem } from '../BaseSelectionItem';
import { useTranslation } from 'react-i18next';
import { Conditional } from '../Conditional';
import { Text } from '../Typography';

export interface CladdingSelectionItemProps {
  product: CladdingProduct;
  isActive?: boolean;
  measurements?: Measurements;
  hidePrice?: boolean;
  selectedFrameProduct?: FrameProduct;
  click?: (item: CladdingProduct) => void;
  onModalToggle?: (item: CladdingProduct) => void;
  borderColor?: string;
}

export const CladdingSelectionItem: FunctionComponent<CladdingSelectionItemProps> =
  ({ borderColor, product, click, isActive = false, hidePrice = false, onModalToggle }) => {
    const { t } = useTranslation();
    const { priceExVat, name } = product;

    return (
      <BaseSelectionItem
        product={product}
        click={click}
        borderColor={borderColor}
        isActive={isActive}
        hidePrice={hidePrice}
        className={classNames(
          styles['cladding-selection-item'],
          {
            [`${styles.active}`]: isActive
          }
        )}
      >
        <Text className={styles.description}>
          {t(name, { reportMissing: false })}
        </Text>
        <Conditional condition={!hidePrice}>
          <Text className={styles.price}>
            <Conditional condition={priceExVat > 0}>
              {CurrencyFormatter.format(priceExVat)}&nbsp;
            </Conditional>
            <Conditional condition={priceExVat === 0}>
              {t(TranslationKeys.generic.included)}&nbsp;
            </Conditional>
          </Text>
        </Conditional>
        {
          (onModalToggle && product.description) && (
            <Text
              className={styles.information}
              onClick={(e) => {
                e.stopPropagation();
                onModalToggle(product)
              }}
            >
              i
            </Text>
          )
        }
      </BaseSelectionItem>
    )
  };
