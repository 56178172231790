import { createSlice } from '@reduxjs/toolkit';
import { addServiceAction, removeServiceAction } from './Actions';
import {serviceInitialState} from './State';
import {
  addServiceReducer,
  removeServiceReducer,
} from './Reducers';
import {AppConfiguration, ApplicationService} from "@hec/models";



// ApplicationService
export const getServiceSlice = (initialServices: ApplicationService[], configuration: AppConfiguration) => createSlice({
  name: 'services',
  initialState: {...serviceInitialState, services: initialServices, appConfiguration: configuration},
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addServiceAction, addServiceReducer)
      .addCase(removeServiceAction, removeServiceReducer)
  },
});
