import React, { FunctionComponent, useState } from 'react';
import styles from './drawer.module.scss';
import { useLocation } from 'react-router-dom';
import { Route } from '@hec/models';
import { DrawerToggle } from './DrawerToggle';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Conditional } from '../Conditional';
import { Text } from '../Typography';
import {useMatomo} from "@jonkoops/matomo-tracker-react";

export interface DrawerProps {
  routes: Route[];
  isOpen?: boolean;
  onToggle?: () => void;
}

const getActiveRoute = (
  routes: Route[],
  pathname: string
): Route | undefined => {
  const activeRouteSearch = routes.filter((route) => route.path === pathname);
  if (activeRouteSearch.length > 0) {
    return activeRouteSearch[0];
  }
  return;
};

export const Drawer: FunctionComponent<DrawerProps> = ({
  routes,
  isOpen,
  onToggle,
}) => {
  const location = useLocation();
  const { pathname } = location;
  const activeRoute = getActiveRoute(routes, pathname);
  const [isOpenInner, setIsOpenInner] = useState<boolean>(true);
  const { t } = useTranslation();
  // const {trackPageView} = useMatomo();

  const open = () => {
    if (typeof isOpen !== 'undefined') {
      return isOpen;
    } else {
      return isOpenInner;
    }
  };


  // React.useEffect(() => {
  //   trackPageView({
  //     documentTitle: activeRoute?.name,
  //     href: window.document.location.href
  //   })
  // }, [activeRoute?.path, activeRoute?.name])

  return (
    <div
      className={classnames(styles.drawer, {
        [`${styles.open}`]: open(),
      })}
    >
      <Conditional condition={typeof onToggle !== 'undefined'}>
          <DrawerToggle
            className={styles['drawer-toggle']}
            onClick={() => {
              if (onToggle) {
                onToggle();
              } else {
                setIsOpenInner(!isOpenInner);
              }
            }}
            open={open()}
          />
      </Conditional>
      <div className={styles.clearfix} />
      <div className={styles.content}>
        <Conditional condition={!!activeRoute?.displaySettings.displayTitleInDrawer}>
          {/*<Text as={'h1'}  className={styles.title}>*/}
          <Text as={'h1'}  className={!!activeRoute?.displaySettings?.titleNoMarginBottom ? styles['title-no-margin-bottom'] : styles.title} style={!!activeRoute?.displaySettings?.centerTitle ? { textAlign: "center" } : {} }>
            {t(activeRoute?.name ?? '')}
          </Text>
        </Conditional>
        {activeRoute?.sideDrawerContent}
      </div>
    </div>
  );
};
