import React, {FunctionComponent} from "react";
import styles from './help-text.module.scss';

interface HelpTextProps {
  helpText?: string
}

export const HelpText: FunctionComponent<HelpTextProps> = ({ helpText}) => {
  if (helpText) {
    return (
      <p className={styles.helpText}>{helpText}</p>
    );
  } else {
    return (
      <></>
    );
  }
}
