import {BaseDalService} from "@hec/core";
import {AppConfiguration, ApplicationService} from "@hec/models";
import axios, {AxiosInstance} from "axios";
import {FrameDto} from "@hec/api-dtos";

export class SelectableFramesService extends BaseDalService implements ApplicationService {

  private client: AxiosInstance;
  public static override serviceName = Symbol('SelectableFramesService');

  constructor() {
    super();
    this.client = axios.create({});
  }

  override configure(configuration: AppConfiguration) {
    this.client = axios.create({
      baseURL: `${configuration.baseUrl}/api/SectionFragments`,
    });

    this.useDateTimeConverters(this.client);
    this.useEnumConverters(this.client);
  }


  getName(): symbol {
    return SelectableFramesService.serviceName;
  }

  static override getName(): symbol {
    return SelectableFramesService.serviceName;
  }

  async get(configurationTemplateId: string, orderConfigurationCode: string): Promise<FrameDto[]> {

    this.configureAuthInterceptor(this.client, orderConfigurationCode);

    const response = await this.client.get(`?ConfigurationTemplateId=${configurationTemplateId}&sectionFragmentType=Frame`);

    if (response.status === 200) {
      return response.data as FrameDto[];
    }

    throw new Error(`SelectableFramesService: get resulted in ${response.status}: ${response.statusText}`);
  }
}
