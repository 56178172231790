import React, { FunctionComponent } from 'react';
import styles from './help-modal.module.scss';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailIcon from '@mui/icons-material/Mail';
import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {TranslationKeys} from '@hec/models';
import { Conditional } from '../Conditional';
import { Text } from '../Typography';
import {ClientInformationViewModel} from "@hec/api-dtos";

export interface HelpModalProps {
  clientInformation: ClientInformationViewModel;
  title?: string;
}

export const HelpModal: FunctionComponent<HelpModalProps> = ({ clientInformation, title= 'Hulp nodig?' }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Text className={styles.title}>{title}</Text>
      <div className={styles.grid}>
        <Conditional condition={typeof clientInformation.contactPhoneNumber !== 'undefined' || typeof clientInformation.supportPhoneNumber !== 'undefined'}>
          <a className={styles.helpItem} href={`tel:${clientInformation.supportPhoneNumber ?? clientInformation.contactPhoneNumber}`}>
            <LocalPhoneIcon style={{fill: clientInformation.primaryColor}} />
            <Text className={styles.helpItemTitel}>{t(TranslationKeys.generic.phone)}</Text>
            <Text className={styles.helpItemLink}>{clientInformation.supportPhoneNumber ?? clientInformation.contactPhoneNumber as string}</Text>
          </a>
        </Conditional>
        <Divider orientation={'vertical'} flexItem />
        <Conditional condition={typeof clientInformation.contactEmailAddress !== 'undefined' || typeof clientInformation.supportEmailAddress !== 'undefined'}>
          <a className={styles.helpItem} href={`mailto:${clientInformation.supportEmailAddress ?? clientInformation.contactEmailAddress}`}>
            <MailIcon style={{fill: clientInformation.primaryColor}} />
            <Text className={styles.helpItemTitel}>{t(TranslationKeys.generic.email)}</Text>
            <Text className={styles.helpItemLink}>{clientInformation.supportEmailAddress ?? clientInformation.contactEmailAddress as string}</Text>
          </a>
        </Conditional>
      </div>
    </div>
  );
};
