import React, {FunctionComponent} from "react";
import styles from "./frame-display.module.scss";
import {Conditional, Text} from "@hec/components/v2";
import {useTranslation} from "react-i18next";
import {SelectableFrameSelector} from "../SelectableFrameSelector";
import Select from "react-select";
import {useSelector} from "react-redux";
import {FrameDto} from "@hec/api-dtos";
import {getClientInformationSelector, getFaceConfiguration, getSplinter, getStubbed} from "@hec/dal";
import {SizeConfigurationType} from "../../../models";
import {TranslationKeys} from "@hec/models";
import {ExpandFrameButton} from "../ExpandFrameButton";

interface SelectOption {
  value: string;
  label: string;
}

interface FrameDisplayProps {
  frame: FrameDto;
  frameCount: number;
  removeFrame: (sort: number) => void;
  changeFrameCopiedFrom: (sort: number, copiedFromId: string) => void;
  swapFrameOrder: (sort1: number, sort2: number) => void;
  updateFrameSize: (sort: number, width: number | null, height: number | null) => void;
  expandRequested?: () => void;
}

export const FrameDisplay: FunctionComponent<FrameDisplayProps> = ({
                                                                     frame,
                                                                     frameCount,
                                                                     removeFrame,
                                                                     changeFrameCopiedFrom,
                                                                     swapFrameOrder,
                                                                     updateFrameSize,
                                                                     expandRequested
}) => {
  const {t} = useTranslation();

  const clientInformation = useSelector(getClientInformationSelector);
  const faceConfiguration = useSelector(getFaceConfiguration);
  const splinter = useSelector(getSplinter);
  const stubbed = useSelector(getStubbed);

  if (clientInformation === null || faceConfiguration === null || splinter === null || stubbed === null) {
    return <></>;
  }

  const desiredFrameHeight = faceConfiguration.height! - (splinter.height * 2) - stubbed.height;

  // Determine width configuration type
  let widthConfigurationType: SizeConfigurationType;
  if (frame.supportedSizes.length > 0) {
    widthConfigurationType = SizeConfigurationType.Scaffolds;
  } else if (frame.maxHeight === null) {
    widthConfigurationType = SizeConfigurationType.FreeInput;
  } else if (frame.minWidth === frame.maxHeight) {
    widthConfigurationType = SizeConfigurationType.None;
  } else {
    widthConfigurationType = SizeConfigurationType.FreeInput;
  }

  let scaffoldAvailableOptions: SelectOption[] = [];
  let handleChangeScaffold;
  let selectedScaffoldOption;
  if (widthConfigurationType === SizeConfigurationType.Scaffolds) {
    scaffoldAvailableOptions = frame.supportedSizes
      .filter(supportedSize => supportedSize.height === null || supportedSize.height === desiredFrameHeight)
      .map(supportedSize => {
        return {
          value: supportedSize.id!,
          label: supportedSize.name,
        }
      });

    handleChangeScaffold = (option: SelectOption | null) => {
      if (option) {
        const supportedSize = frame.supportedSizes.find(supportedSize => supportedSize.id === option.value)!;
        updateFrameSize(frame.sort!, supportedSize.width, supportedSize.height);
      }
    };

    const selectedSupportedSize = frame.supportedSizes.find(supportedSize => {
      return frame.width === supportedSize.width && frame.height === supportedSize.height;
    });
    if (selectedSupportedSize) {
      selectedScaffoldOption = {
        value: selectedSupportedSize.id!,
        label: selectedSupportedSize.name,
      }
    } else {
      throw new Error('No selectedSupportedSize found');
    }
  }

  return (
    <div className={styles.frameDisplay}>
      <div className={styles.header}>
        <div className={styles.left}>
          <div className={styles.indexControls}>
            <button className={styles.upButton} disabled={frame.sort === 0} onClick={() => swapFrameOrder(frame.sort! -1, frame.sort!)}>
              <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 320 512">
                <path d="M182.6 137.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9S19 320.1 32 320.1h256c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z"/>
              </svg>
            </button>
            <button className={styles.downButton} disabled={frame.sort === frameCount-1} onClick={() => swapFrameOrder(frame.sort!, frame.sort! + 1)}>
              <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 320 512">
                <path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9S301 191.9 288 191.9L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"/>
              </svg>
            </button>
          </div>

          <div className={styles.widthControl}>
            <Conditional condition={widthConfigurationType === SizeConfigurationType.Scaffolds}>
              <Conditional condition={scaffoldAvailableOptions.length > 0}>
                <Select
                  isSearchable={true}
                  options={scaffoldAvailableOptions}
                  defaultValue={selectedScaffoldOption}
                  onChange={handleChangeScaffold}
                  // menuIsOpen={true}
                  styles={{
                    clearIndicator: (baseStyles) => ({
                      ...baseStyles,
                    }),
                    container: (baseStyles) => ({
                      ...baseStyles,
                    }),
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: 0,
                      border: state.isFocused ? '1px solid #949494 !important' : '1px solid #F2F2F2',
                      "&:hover": {
                        borderColor: '#d6d6d6',
                        backgroundColor: '#eeeeee',
                        color: '#000000',
                      },
                      backgroundColor: '#F2F2F2',
                      boxShadow: 'unset',
                      minWidth: 80,
                      minHeight: '30px',
                      cursor: 'text',
                      transition: 'all ease 60ms',
                      color: state.isFocused ? '#000000' :'#6a6a6a',
                    }),
                    dropdownIndicator: (baseStyles) => ({
                      ...baseStyles,
                      padding: '0 5px 0 0',
                    }),
                    group: (baseStyles) => ({
                      ...baseStyles,
                    }),
                    groupHeading: (baseStyles) => ({
                      ...baseStyles,
                    }),
                    indicatorsContainer: (baseStyles) => ({
                      ...baseStyles,
                    }),
                    indicatorSeparator: (baseStyles) => ({
                      ...baseStyles,
                      display: 'none',
                    }),
                    input: (baseStyles) => ({
                      ...baseStyles,
                      // color: '#000000',
                      fontWeight: 500,
                      margin: 0,
                      padding: 0,
                    }),
                    loadingIndicator: (baseStyles) => ({
                      ...baseStyles,
                    }),
                    loadingMessage: (baseStyles) => ({
                      ...baseStyles,
                    }),
                    menu: (baseStyles) => ({
                      ...baseStyles,
                      marginTop: 0,
                      borderRadius: 0,
                    }),
                    menuList: (baseStyles) => ({
                      ...baseStyles,
                      padding: 0,
                    }),
                    menuPortal: (baseStyles) => ({
                      ...baseStyles,
                    }),
                    multiValue: (baseStyles) => ({
                      ...baseStyles,
                    }),
                    multiValueLabel: (baseStyles) => ({
                      ...baseStyles,
                    }),
                    multiValueRemove: (baseStyles) => ({
                      ...baseStyles,
                    }),
                    noOptionsMessage: (baseStyles) => ({
                      ...baseStyles,
                      backgroundColor: '#F2F2F2',
                    }),
                    option: (baseStyles, state) => ({
                      ...baseStyles,
                      cursor: 'pointer',
                      backgroundColor: state.isSelected
                        ? `${clientInformation.primaryColor} !important`
                        : state.isFocused
                          ? `${clientInformation.secondaryColor} !important`
                          : '#F2F2F2 !important',
                    }),
                    placeholder: (baseStyles) => ({
                      ...baseStyles,
                    }),
                    singleValue: (baseStyles) => ({
                      ...baseStyles,
                      color: 'inherit',
                      fontWeight: 500,
                      margin: 0,
                    }),
                    valueContainer: (baseStyles) => ({
                      ...baseStyles,
                    }),
                  }}
                />
              </Conditional>
            </Conditional>
            <Conditional condition={widthConfigurationType === SizeConfigurationType.FreeInput}>
              <input
                type="number"
                className={styles.widthControlDynamic}
                value={frame.width}
                min={frame.minWidth === null ? 0 : frame.minWidth}
                max={frame.maxWidth === null ? undefined : frame.maxWidth}
                onChange={(e) => {
                  if (e.target.value !== '') {
                    updateFrameSize(frame.sort!, parseInt(e.target.value), null);
                  }
                }}
              />
            </Conditional>
          </div>
          <Text as={"span"}>
            {t(TranslationKeys.dormer.measurementUnit)}
          </Text>
        </div>
        <div className={styles.right}>
          <button className={styles.removeButton} onClick={() => removeFrame(frame.sort!)}>
            <svg height="14" viewBox="0 0 11 13" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.31964 0.449414L3.14286 0.8125H0.785714C0.351116 0.8125 0 1.17559 0 1.625C0 2.07441 0.351116 2.4375 0.785714 2.4375H10.2143C10.6489 2.4375 11 2.07441 11 1.625C11 1.17559 10.6489 0.8125 10.2143 0.8125H7.85714L7.68036 0.449414C7.54777 0.172656 7.27522 0 6.97813 0H4.02187C3.72478 0 3.45223 0.172656 3.31964 0.449414ZM10.2143 3.25H0.785714L1.30625 11.8574C1.34554 12.4998 1.86116 13 2.48237 13H8.51763C9.13884 13 9.65446 12.4998 9.69375 11.8574L10.2143 3.25Z"/>
            </svg>
          </button>
        </div>
      </div>
      <div className={styles.content}>
        <SelectableFrameSelector frame={frame} changeFrameCopiedFrom={changeFrameCopiedFrom}/>
        <Conditional condition={expandRequested !== undefined && frame.maxWidth !== frame.width}>
          <ExpandFrameButton expandRequested={expandRequested!}/>
        </Conditional>
      </div>
    </div>
  );
};
