
export const DEFAULT_WALL_THICKNESS = .2;
export const DEFAULT_ROOF_THICKNESS = .1;
export const DEFAULT_OVERHANG_SIDE_SIZE = .025;
export const DEFAULT_ROOF_EDGE_HEIGHT = 0.05;
export const ANIMATION_LENGTH = 3000;
export const DEFAULT_ZOOM = 3;
export const TEXTURES_PER_METER_IN_LENGTH = 1;
export const TEXTURES_PER_METER_TOTAL = TEXTURES_PER_METER_IN_LENGTH * TEXTURES_PER_METER_IN_LENGTH;

export const INSIDE_WALL_COLOR_STRING = 'white';
