import React, { FunctionComponent, PropsWithChildren, useEffect, useState } from 'react';
import styles from './dropdown.module.scss';
import classnames from 'classnames';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Text } from '../Typography';

export interface DropdownProps {
  title: string;
  control?: (prevState: boolean) => void;
  className?: string;
  isOpen?: boolean;
  hasError?: boolean;
}

export const Dropdown: FunctionComponent<PropsWithChildren<DropdownProps>> = ({
  title,
  children,
  control,
  isOpen,
  className,
  hasError = false,
}) => {
  const openDefinedFromOutside = typeof isOpen === 'boolean';
  const [isOpenInner, setIsOpenInner] = useState<boolean>(false);
  const [isClicked, setIsClicked] = useState<boolean>(false);

  const open = () => {
    if (openDefinedFromOutside) {
      return isOpen as boolean;
    } else {
      return isOpenInner;
    }
  };

  const toggle = () => {
    if (control && openDefinedFromOutside) {
      setIsClicked(true);
      control(open());
    } else {
      setIsOpenInner(!isOpenInner);
    }
  };

  useEffect(() => {
    if (isClicked) {
      setTimeout(() => setIsClicked(false), 600);
    }
  }, [isClicked, setIsClicked]);

  return (
    <div
      className={classnames(styles.dropdown, className, {
        [`${styles.closed}`]: !open(),
        [`${styles['has-error']}`]: hasError,
        [`${styles['had-interaction']}`]: isClicked,
      })}
    >
      <div className={styles['title-container']} onClick={toggle}>
        <Text className={styles.title}>{title}</Text>
        <div className={styles.button}>
          {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </div>
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};
