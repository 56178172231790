import { useEffect, useState, RefObject } from 'react';

interface Size {
  width: number;
  height: number;
}

export const useGetRefSize = (ref: RefObject<HTMLDivElement>): Size => {
  const [size, setSize] = useState<Size>({ width: 0, height: 0 });

  const updateSize = () => {
    if (ref.current) {
      setSize({
        width: ref.current.offsetWidth,
        height: ref.current.offsetHeight,
      });
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, [ref]);

  return size;
};
