
export interface TextureMaterialWithSpecification<T> extends TextureMaterial{
  specification: T;
}

export interface TextureMaterial {
  id: string;
  name: string;
  textureUrl: string;
  normalMapUrl: string;
  displacementMapUrl: string;
  productionMaterialType: string;
}
