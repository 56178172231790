import React, { PropsWithChildren } from 'react';
import {ModalContentKey, Product, TranslationKeys} from '@hec/models';
import classNames from 'classnames';
import styles from './base-selection-item.module.scss';
import { CurrencyFormatter } from '@hec/utils';
import { useTranslation } from 'react-i18next';
import { Conditional } from '../Conditional';
import { Text } from '../Typography';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { ToggleModal } from '@hec/core';

export interface BaseSelectionItemProps<T extends Product> {
  className?: string;
  product: T;
  isActive?: boolean;
  borderColor?: string;
  hidePrice?: boolean;
  click?: (item: T) => void;
  showImage?: boolean;
  options?: {};
}

export const BaseSelectionItem = <T extends Product>({
  product,
  isActive,
  click,
  children,
  className,
  borderColor = '#053630',
  hidePrice = false,
  showImage = true,
  options = {},
}: PropsWithChildren<BaseSelectionItemProps<T>>) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const openInformationModal = (product: Product) =>
    dispatch(ToggleModal(ModalContentKey.PRODUCT_DESCRIPTION, product));

  return (
    <div
      onClick={() => {
        click && click(product);
      }}
      style={isActive ? { border: `2px solid ${borderColor}` } : {}}
      className={classNames(styles['selection-item'], className)}
    >
      <Conditional
        condition={
          typeof product.imageUrl !== 'undefined' &&
          product.imageUrl !== null &&
          showImage
        }
      >
        <div className={styles['image-container']}>
          <img
            className={styles.image}
            loading="lazy"
            src={product.imageUrl}
            alt={`selection-image-${product.name}`}
          />
        </div>
      </Conditional>
      <Conditional
        condition={
          typeof product.imageUrl === 'undefined' ||
          product.imageUrl === null ||
          !showImage
        }
      >
        <div
          className={classnames(styles['checkbox-container'], {
            [`${styles.active}`]: isActive,
          })}
        >
          <Conditional condition={isActive}>
            <div className={styles['checkbox-active']} />
          </Conditional>
        </div>
      </Conditional>
      <div className={styles.information}>
        <Conditional
          condition={typeof children !== 'undefined'}
          fallback={
            <>
              <Text className={styles.description}>
                {t(product.name, { ...options, reportMissing: false })}
              </Text>
              <Conditional condition={!hidePrice}>
                <Text className={styles.price}>
                  <Conditional
                    condition={product.priceExVat > 0}
                    fallback={t(TranslationKeys.generic.included)}
                  >
                    {CurrencyFormatter.format(product.priceExVat)}
                  </Conditional>
                  &nbsp;
                </Text>
              </Conditional>
              <Conditional condition={product.description}>
                <Text
                  className={styles['extra-description']}
                  onClick={(e) => {
                    e.stopPropagation();
                    openInformationModal(product);
                    // onModalToggle(frameProduct);
                  }}
                >
                  i
                </Text>
              </Conditional>
            </>
          }
        >
          {children}
        </Conditional>
      </div>
    </div>
  );
};
