import {call, put, takeLatest} from "redux-saga/effects";
import {getClientInformationAction, getClientInformationSucceededAction} from "./Actions";
import {getApplicationService} from "../Services";
import {ClientInformationViewModel} from "@hec/api-dtos";
import {ClientInformationService} from "./Services";
import {getConfigurationTemplatesAction} from "../ConfigurationTemplates";
import {storeErrorAction} from "../Logging";

function* handleGetClientInformationSaga(action: ReturnType<typeof getClientInformationAction>) {
  const configurationId: string = action.payload.configurationId;

  const service: ClientInformationService = yield getApplicationService(ClientInformationService);

  try {
    const result: ClientInformationViewModel = yield call([service, 'get'], configurationId, action.payload.offeringType);

    yield put(getClientInformationSucceededAction({configurationId: configurationId, clientInformation: result, offeringType: action.payload.offeringType}));
    yield put(getConfigurationTemplatesAction());
  } catch (error) {
    yield put(storeErrorAction({error: error}));
  }
}

export function* ClientInformationSagas() {
  yield takeLatest(getClientInformationAction, handleGetClientInformationSaga);
}
