import { Product, ProductWithDepthAndWidthThreshold } from '../';

export function IsProductWithinWidthAndDepthThreshold(width: number, depth?: number) {
  return function(product: Product): product is ProductWithDepthAndWidthThreshold {
    const p = product as ProductWithDepthAndWidthThreshold;
    // TODO: Rewrite

    if(typeof depth === 'undefined') {
      return ((typeof p.minWidth !== 'undefined') &&
      (typeof p.maxWidth !== 'undefined') &&
      width >= p.minWidth && width <= p.maxWidth);
    } else {
      if(typeof p.maxDepth === 'undefined') {
        return ((typeof p.minWidth !== 'undefined') &&
          (typeof p.maxWidth !== 'undefined') &&
          (typeof p.minDepth !== 'undefined') &&
          width >= p.minWidth && width <= p.maxWidth &&
          depth >= p.minDepth)
      }
      return ((typeof p.minWidth !== 'undefined') &&
          (typeof p.maxWidth !== 'undefined') &&
          (typeof p.minDepth !== 'undefined') &&
          (typeof p.maxDepth !== 'undefined') &&
          width >= p.minWidth && width <= p.maxWidth &&
          depth >= p.minDepth && depth <= p.maxDepth);
    }
  }
}
