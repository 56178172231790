import classnames from 'classnames';
import React, { CSSProperties, FunctionComponent, PropsWithChildren, ReactElement } from 'react';
import styles from './button.module.scss';

export interface ButtonProps {
  children?: ReactElement | ReactElement[] | string | number | undefined;
  size?: 'small' | 'medium' | 'large';
  style?: CSSProperties;
  as?: ReactElement;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isDisabled?: boolean;
}

export const Button: FunctionComponent<ButtonProps & PropsWithChildren<any>> = ({
  children,
  onClick,
  className,
  isDisabled = false,
  size = 'medium',
  style,
  as = <button />,
  ...rest
}) => {
  const classNames = classnames(
    styles.button,
    styles[size],
    className,
    {
      [styles.disabled]: isDisabled,
    }
    );

  return React.cloneElement(as, { disabled: isDisabled, onClick, children, style: style, className: classNames, ...rest });
};
