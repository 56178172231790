import { createSlice } from '@reduxjs/toolkit';
import { getConfigurationTemplatesSucceededAction, selectConfigurationTemplateSucceededAction } from './Actions';
import { configurationTemplateInitialState } from './State';
import {
  getConfigurationTemplatesSucceededReducer,
  selectConfigurationTemplateSucceededReducer,
} from './Reducers';

export const configurationTemplateSlice = createSlice({
  name: 'configurationTemplates',
  initialState: configurationTemplateInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getConfigurationTemplatesSucceededAction, getConfigurationTemplatesSucceededReducer)
      .addCase(selectConfigurationTemplateSucceededAction, selectConfigurationTemplateSucceededReducer);
  },
});
