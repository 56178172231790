import {prepareUpdateMeasurementsAction, updateMeasurementsAction} from "./FaceConfigurationActions";
import {put, takeLatest} from "redux-saga/effects";
import {debouncePutOrderAction} from "./OrderActions";

function* prepareUpdateMeasurements(action: ReturnType<typeof prepareUpdateMeasurementsAction>) {
  yield put(updateMeasurementsAction({width: action.payload.width, height: action.payload.height}));
  yield put(debouncePutOrderAction());
}

export function* FaceConfigurationSagas() {
  yield takeLatest(prepareUpdateMeasurementsAction, prepareUpdateMeasurements);
}
