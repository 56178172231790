import { Product } from '../Application';
import { HomeExtensionClientConfiguration } from '../Configuration';

export interface HomeConfigurationState {
  price: number;
  productsShadow: Product[];
  products: Product[];
  selectedProducts: Product[];
  clientConfiguration: HomeExtensionClientConfiguration;
  configurationPrompt: {
    active: boolean;
    limboKey?: string;
    conflictedProducts: Product[];
  };
  measurements: {
    width: number;
    depth: number;
    height: number;
  };
}
