import {ClientInformationViewModel, ExtraInformationForKeyViewModel} from "@hec/api-dtos";
import {DormerRootState} from "../DormerRootState";

export const getClientInformationSelector = (state: DormerRootState): ClientInformationViewModel | null => {

  return state.clientInformation.clientInformation;
}


export const getExtraInformationForKeySelector = (state: DormerRootState, key: string): string | null => {
  return (state.clientInformation.clientInformation?.extraInformationForKeys ?? []).find((x: ExtraInformationForKeyViewModel) => (x.key ?? '').toLowerCase() === (key ?? '').toLowerCase())?.information ?? null;
}
