import {SurveyAnswerViewModel, SurveyQuestionViewModel} from "@hec/models";

export interface SurveyQuestionsState {
  surveyQuestionViewModels: SurveyQuestionViewModel[];
  holdingAnswers: SurveyAnswerViewModel[];
  submittedAnswers: SurveyAnswerViewModel[];
  loaded: boolean;
}

export const surveyQuestionsInitialState: SurveyQuestionsState = {
  surveyQuestionViewModels: [],
  holdingAnswers: [],
  submittedAnswers: [],
  loaded: false,
}
