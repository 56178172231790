import {BaseDalService} from "@hec/core";
import {AppConfiguration, ApplicationService} from "@hec/models";
import axios, {AxiosInstance} from "axios";
import {DormerMaterialViewModel} from "@hec/api-dtos";

export class DormerMaterialService extends BaseDalService implements ApplicationService {

  private client: AxiosInstance;
  public static override serviceName = Symbol('DormerMaterialService');

  constructor() {
    super();
    this.client = axios.create({});
  }

  override configure(configuration: AppConfiguration) {
    this.client = axios.create({
      baseURL: `${configuration.baseUrl}/api/ProductType/DormerMaterial`,
    });

    this.useDateTimeConverters(this.client);
    this.useEnumConverters(this.client);
  }


  getName(): symbol {
    return DormerMaterialService.serviceName;
  }

  static override getName(): symbol {
    return DormerMaterialService.serviceName;
  }

  async get(configurationTemplateId: string, orderConfigurationCode: string): Promise<DormerMaterialViewModel[]> {
    this.configureAuthInterceptor(this.client, orderConfigurationCode);

    const response = await this.client.get(`?ConfigurationTemplateId=${configurationTemplateId}`);


    if (response.status === 200) {
      return response.data as DormerMaterialViewModel[];
    }

    throw new Error(`DormerMaterialService: get resulted in ${response.status}: ${response.statusText}`);
  }
}
