import React, { FunctionComponent } from 'react';
import {FrameHeaderProduct, TranslationKeys} from '@hec/models';
import { BaseSelectionItem } from '../BaseSelectionItem';
import { Text } from '../Typography';
import styles from '../FrameSelectionItem/frame-selection-item.module.scss';
import { Conditional } from '../Conditional';
import { CurrencyFormatter } from '@hec/utils';
import { useTranslation } from 'react-i18next';

export interface FrameHeaderSelectionItemProps {
  frameHeaderProduct: FrameHeaderProduct;
  isActive?: boolean;
  hidePrice?: boolean;
  click?: (item: FrameHeaderProduct) => void;
  onModalToggle?: (item: FrameHeaderProduct) => void;
  borderColor?: string;
}

export const FrameHeaderSelectionItem: FunctionComponent<FrameHeaderSelectionItemProps> = ({ frameHeaderProduct, hidePrice, onModalToggle, borderColor, click, isActive = false }) => {
  const { t } = useTranslation();
  const { textureMaterial, priceExVat, name } = frameHeaderProduct;
  return (
    <BaseSelectionItem<FrameHeaderProduct>
      hidePrice={hidePrice}
      click={click}
      borderColor={borderColor}
      isActive={isActive}
      showImage={false}
      product={frameHeaderProduct}
    >
      <Text className={styles.description}>
        {
          t(name)
        }
      </Text>
      <Conditional condition={!hidePrice}>
        <Text className={styles.price}>
          <Conditional condition={priceExVat > 0}>
            {CurrencyFormatter.format(priceExVat)}&nbsp;
          </Conditional>
          <Conditional condition={priceExVat === 0}>
            {t(TranslationKeys.generic.included)}&nbsp;
          </Conditional>
        </Text>
      </Conditional>
      {
        (onModalToggle && frameHeaderProduct.description) && (
          <Text
            className={styles.information}
            onClick={(e) => {
              e.stopPropagation();
              onModalToggle(frameHeaderProduct)
            }}
          >
            i
          </Text>
        )
      }
    </BaseSelectionItem>
  );
};
