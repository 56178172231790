import { useSelector } from 'react-redux';
import {ApplicationService} from '@hec/models';
import {getServicesSelector} from "@hec/dal";
import {BaseDalService} from "@hec/core";

export const useService = (serviceRef: typeof BaseDalService) => {
  const services = useSelector(getServicesSelector)
  const service = services
    .find((service: ApplicationService) => service.getName() === serviceRef.getName());
  if (!service) {
    throw new Error('Unable to find service of type ' + serviceRef.getName().toString());
  }
  return service;
}
