import React, {FunctionComponent, memo, useEffect, useMemo, useRef} from "react";
import {FaceDto, SectionFragmentType} from "@hec/api-dtos";
import {CdnUrlHelper, fromMmToRenderingMeters, getTotalWidth, RoofOverhangViewModel} from "@hec/core";
import {CubeTextureLoader, CylinderGeometry, Matrix4, MeshPhongMaterial, MultiplyOperation, Vector3} from "three";
import {degToRad} from "three/src/math/MathUtils";
import {Conditional, ZincFlatCorner, ZincFlatStraight} from "@hec/components/v2";
import {DEFAULT_DORMER_DEPTH} from "../Dormer3dRenderer";

export interface RoofTrimProps {
  southFace: FaceDto;
  roofOverhang: RoofOverhangViewModel;
}


const cdnPublicRoot = CdnUrlHelper.getPublicRoot();
const cubeTextureLoader = new CubeTextureLoader();
const envTexture = cubeTextureLoader.load([
  cdnPublicRoot + "/px.jpg",
  cdnPublicRoot + "/nx.jpg",
  cdnPublicRoot + "/py.jpg",
  cdnPublicRoot + "/ny.jpg",
  cdnPublicRoot + "/pz.jpg",
  cdnPublicRoot + "/nz.jpg"
]);

const MemoizedZincFlatStraight = memo(ZincFlatStraight);
const cornerCompensation = 0.232;
const xAxisDiff = 0.1;
const roofTrimDepth = 0.110;

export const RoofTrim: FunctionComponent<RoofTrimProps> = ({southFace, roofOverhang}: RoofTrimProps) => {
  const southSectionFragments = southFace.sectionFragments;
  const stubbedSectionFragment = southSectionFragments.find(x => x.sectionFragmentType === SectionFragmentType.Stubbed);
  const stubbedWidth = fromMmToRenderingMeters(getTotalWidth(southFace)) + roofOverhang.stubbedWestOverhang + roofOverhang.stubbedEastOverhang;
  const stubbedHeight = fromMmToRenderingMeters(stubbedSectionFragment?.height ?? -1);
  const overhangSize = 0;
  const overhangSideSize = 0;
  const material = useMemo(() => new MeshPhongMaterial({
    shininess: .8,
    reflectivity: .8,
    envMap: envTexture,
    combine: MultiplyOperation,
    color: '#BAC4C8'
  }), []);
  const northGeometry = useRef<CylinderGeometry>(null);
  const eastGeometry = useRef<CylinderGeometry>(null);
  const westGeometry = useRef<CylinderGeometry>(null);

  useEffect(() => {
    if (northGeometry.current) {
      northGeometry.current.applyMatrix4(new Matrix4().makeRotationX(degToRad(90)));
      northGeometry.current.applyMatrix4(new Matrix4().makeRotationY(degToRad(90)));
    }
  }, [northGeometry, southFace])

  useEffect(() => {
    if (eastGeometry.current) {
      // rotate it the right way for lookAt to work
      eastGeometry.current.applyMatrix4(new Matrix4().makeRotationX(degToRad(90)));
    }
    if (westGeometry.current) {
      // rotate it the right way for lookAt to work
      westGeometry.current.applyMatrix4(new Matrix4().makeRotationX(degToRad(90)));
    }
  }, []);

  const yPositionOffset = .015;

  const straightDepth = 0.1;
  const depth = DEFAULT_DORMER_DEPTH;

  const corner = (rotation: number | undefined = undefined) => <ZincFlatCorner
    position={new Vector3(0, -0.025, 0)}
    material={material}
    hasOverhang={overhangSize > 0}
    rotationInDeg={rotation}
  />


  const southLength = (stubbedWidth) - cornerCompensation * 3;
  const eastAndWestLength = (depth - cornerCompensation);


  return (
    <group position={[-roofOverhang.stubbedWestOverhang, stubbedHeight + yPositionOffset, roofOverhang.stubbedSouthOverhang]}>
      {/*west side #RideForLife #2pac #NutoriousBIG #GroveStreetGang */}
      <Conditional condition={eastAndWestLength > 0}>
        <group
          position={[straightDepth, 0, -((depth / 2) + cornerCompensation)]}
        >
          <MemoizedZincFlatStraight
            position={new Vector3(0, 0, 0)}
            // position={new Vector3(-straightDepth,0, (depth /2) + cornerCompensation)}
            length={eastAndWestLength}
            rotationInDeg={180}
            overhang={overhangSideSize}
            material={material}
          />
        </group>
      </Conditional>
      {/*southwest corner*/}
      <group>
        {corner()}
      </group>
      {/*south side*/}
      <Conditional condition={southLength > 0}>
        <group position={[stubbedWidth / 2, 0, -straightDepth]}>
          <MemoizedZincFlatStraight
            position={new Vector3(0, 0, 0)}
            length={southLength}
            rotationInDeg={270}
            material={material}
          />
        </group>
      </Conditional>
      {/* east side*/}
      <Conditional condition={eastAndWestLength > 0}>
        <group
          position={[stubbedWidth - straightDepth, 0, -((depth / 2) + cornerCompensation)]}
        >
          <MemoizedZincFlatStraight
            position={new Vector3(0, 0, 0)}
            length={eastAndWestLength}
            rotationInDeg={0}
            overhang={overhangSideSize}
            material={material}
          />
        </group>
      </Conditional>
      {/*southeast corner*/}
      <group position={[stubbedWidth, 0, 0]}>
        {corner(90)}
      </group>
    </group>
  );
}
