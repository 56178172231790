import {BaseDalService} from "@hec/core";
import {AppConfiguration, ApplicationService} from "@hec/models";
import axios, {AxiosInstance} from "axios";
import {ClientInformationViewModel, OfferingType} from "@hec/api-dtos";

export class ClientInformationService extends BaseDalService implements ApplicationService {

  private client: AxiosInstance;
  public static override serviceName = Symbol('ClientInformationService');

  constructor() {
    super();
    this.client = axios.create({});
  }

  override configure(configuration: AppConfiguration) {
    this.client = axios.create({
      baseURL: `${configuration.baseUrl}/api/client-information`,
    });

    this.client.interceptors.request.use(async (config) => {
      config.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };
      return config;
    });

    this.useDateTimeConverters(this.client);
    this.useEnumConverters(this.client);
  }

  async get(
    configurationId: string,
    offeringType: OfferingType | undefined = undefined,
  ): Promise<ClientInformationViewModel> {
    let url = configurationId;

    if (offeringType) {
      url += `?offeringType=${offeringType}`
    }


    const response = await this.client.get(url);

    if (response.status === 200) {
      return response.data as ClientInformationViewModel;
    }

    throw new Error(`ClientInformationService: get resulted in ${response.status}: ${response.statusText}`);
  }

  getName(): symbol {
    return ClientInformationService.serviceName;
  }

  static override getName(): symbol {
    return ClientInformationService.serviceName;
  }
}
