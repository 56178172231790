import React, {FunctionComponent, useEffect, useMemo, useRef} from "react";
import {FaceDto, SectionFragmentDto, SectionFragmentType} from "@hec/api-dtos";
import {Box} from "@react-three/drei";
import {degreesToRadians, fromMmToRenderingMeters, getFrameHeight, getTotalHeightExcludingStubbed} from "@hec/core";
import {DEFAULT_DORMER_DEPTH} from "../index";
import {AttachSide, CladdingGlobalMaterial} from "./CladdingGlobalMaterial";
import {DormerColorMaterial} from "./DormerColorMaterial";
import {
  BoxGeometry,
  BufferGeometry, CylinderGeometry,
  DoubleSide,
  Float32BufferAttribute,
  FrontSide,
  Group,
  Material,
  Matrix4,
  Mesh, MeshPhongMaterial, MeshStandardMaterial
} from "three";
import * as THREE from 'three';
import {GroupProps} from "@react-three/fiber/dist/declarations/src/three-types";
import {degToRad} from "three/src/math/MathUtils";
import {CalculateSideYForCustomPrism, CustomPrism, CustomPrismCalculationHelper} from "./CustomPrism";

export interface SlantedCheeksProps {
  southFace:FaceDto;
  angle?: number;
}

export const SlantedCheeks: FunctionComponent<SlantedCheeksProps> = ({southFace, angle} :SlantedCheeksProps) => {
  const southSectionFragments = southFace?.sectionFragments ?? [];

  const cheek = southSectionFragments.find(x => x.sectionFragmentType === SectionFragmentType.Cheeks);

  const cheekWidth = fromMmToRenderingMeters(cheek?.width ?? 0);

  const cheekHeight = fromMmToRenderingMeters(getTotalHeightExcludingStubbed(southFace));

  const yHeight = CustomPrismCalculationHelper(cheekHeight).adjacent;

  const depth = DEFAULT_DORMER_DEPTH;

  return (
    <group>
      <group
        key={`cheeks-w_${cheekWidth}-h_${yHeight}`}
      >
        <CustomPrism
          yHeight={yHeight}
          slantedSideWidth={cheekWidth}
          dormerColor={cheek?.configuredDormerColor}
          topWidth={depth}
        />
      </group>
    </group>
  );
}

