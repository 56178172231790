import { AnyAction } from 'redux';
import {
  IsProductWithinWidthAndDepthThreshold,
  Measurements,
  Product,
  ProductionMaterialType,
  ProductType,
} from '@hec/models';
import { HomeExtensionRootState } from '../../../';
import { put, select } from 'redux-saga/effects';
import {
  RECALCULATE_PRICE,
  REDUCER_APPLY_CLIENT_CONFIGURATION,
} from '../../../';
export const getFirstOfProductType = (
  products: Product[],
  type: ProductType
): Product | undefined => {
  const filtered = products.filter((p) => p.productType === type);
  if (filtered.length > 0) {
    return filtered[0];
  }
  return;
};

export function* ClientConfigurationHandler(action: AnyAction) {
  const products: Product[] = yield select(
    (state: HomeExtensionRootState) => state.homeConfigurationReducer.products
  );
  const measurements: Measurements = yield select(
    (state: HomeExtensionRootState) => state.homeConfigurationReducer.measurements
  );

  const firstCladdingProduct = getFirstOfProductType(
    products,
    ProductType.CLADDING
  );
  const firstRoofTrimProduct = getFirstOfProductType(
    products,
    ProductType.ROOF_TRIM
  );
  const firstRoofOverhangProduct = getFirstOfProductType(
    products,
    ProductType.ROOF_OVERHANG
  );
  const firstFrameProduct = products.find(
    IsProductWithinWidthAndDepthThreshold(measurements.width)
  );
  const firstDrainPipeProduct = getFirstOfProductType(
    products,
    ProductType.DRAIN_PIPE
  );
  const firstRoofLightProduct = getFirstOfProductType(
    products,
    ProductType.ROOF_LIGHT
  );
  const firstFrameHeaderProduct = getFirstOfProductType(
    products,
    ProductType.FRAME_HEADER
  );
  const firstFaucetProduct = getFirstOfProductType(
    products,
    ProductType.FAUCET
  );

  const selectedProducts = [
    {
      id: 'BASE',
      name: 'BASE',
      description: '',
      priceExVat: 0,
      productType: ProductType.CONSTRUCTION,
      productionMaterialType: ProductionMaterialType.CONCRETE,
      translationKey: 'generic.baseProduct',
    },
  ];
  if (firstFrameProduct) {
    selectedProducts.push(firstFrameProduct);
  }
  if (firstCladdingProduct) {
    selectedProducts.push(firstCladdingProduct);
  }
  if (firstRoofTrimProduct) {
    selectedProducts.push(firstRoofTrimProduct);
  }
  if (firstRoofOverhangProduct) {
    selectedProducts.push(firstRoofOverhangProduct);
  }
  if (firstDrainPipeProduct) {
    selectedProducts.push(firstDrainPipeProduct);
  }
  if (firstRoofLightProduct) {
    selectedProducts.push(firstRoofLightProduct);
  }
  if (firstFrameHeaderProduct) {
    selectedProducts.push(firstFrameHeaderProduct);
  }
  if (firstFaucetProduct) {
    selectedProducts.push(firstFaucetProduct);
  }

  yield put({
    type: REDUCER_APPLY_CLIENT_CONFIGURATION,
    data: {
      selectedProducts,
      clientConfiguration: action.data.clientConfiguration,
    },
  });
  yield put({ type: RECALCULATE_PRICE });
}
