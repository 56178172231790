import {FunctionComponent} from "react";
import {MetalMaterial} from "./Materials";


export const FrameHandle: FunctionComponent = () => {
    const baseDepth = .01;
    const baseHeight = .07;
    const baseWidth = 0.025;

    const handleWidth = 0.13;
    const handleHeight = baseWidth;//0.02;
    const handleDepth = 0.01;

    const handleAttachmentDepth = 0.055


    const totalDepth = handleAttachmentDepth;


    const outerFrameDepth = 0.01;

    const material = <MetalMaterial/>

    // const material = <meshPhysicalMaterial
    //   color={'#808080'} // A gray color for the metal
    //   metalness={0.8}   // High metalness for a metallic look
    //   roughness={0.6}   // Increased roughness for a matte finish
    //   clearcoat={0.1}   // A slight clearcoat to give a subtle sheen
    //   reflectivity={0.5} // Adjust reflectivity for a realistic metal look
    // />;


  return (
      <group scale={1}>
        <group
          position={[0, 0, (outerFrameDepth + totalDepth) / 2]}
          rotation={[0, 0, 0]}
        >
          <group
            position={[0.0025 + (baseWidth / 2), 0, -(totalDepth / 2)]}
          >
            <mesh
            >
              <boxGeometry
                args={[baseWidth, baseHeight, baseDepth]}
              >
              </boxGeometry>
              {material}
            </mesh>
            <group
              position={[0, 0, (handleAttachmentDepth / 2)]}
            >
              <mesh rotation={[Math.PI / 2, 0, 0]}>
                <cylinderGeometry
                  args={[baseWidth / 2, baseWidth / 2, handleAttachmentDepth]}
                >
                </cylinderGeometry>
                {material}
              </mesh>

            </group>
            <group position={[(handleWidth / 2), 0, (handleAttachmentDepth - (handleDepth / 2))]}>
              <mesh>
                <boxGeometry
                  args={[handleWidth, handleHeight, handleDepth]}
                >

                </boxGeometry>
                {material}
              </mesh>
            </group>
          </group>
        </group>
      </group>
    )
  }

