import {AppConfiguration, ApplicationService } from '@hec/models';

export interface ServiceState {
  services: ApplicationService[];
  // For some reason typescript's transpiler is going to trip balls if we don't put "any" type here.
  /* eslint-disable @typescript-eslint/no-explicit-any */
  appConfiguration: AppConfiguration | any | undefined;
}

export const serviceInitialState: ServiceState = {
  services: [],
  appConfiguration: undefined,
}
