import { takeLatest } from 'redux-saga/effects';
import {
  ClientConfigurationHandler,
  ConfigurationPromptCancellationHandler,
  ConfigurationPromptConfirmationHandler,
  PriceCalculationHandler,
  RequestProductsHandler,
  RequestQuotationHandler,
  RequestQuotationSuccessHandler,
  SelectedProductHandler,
  SelectedProductsHandler,
} from './Handlers';
import { PersistStateHandler } from './Handlers/PersistStateHandler';
import {
  APPLY_CLIENT_CONFIGURATION,
  CANCEL_CONFIGURATION_PROMPT,
  CONFIRM_CONFIGURATION_PROMPT,
  GET_PRODUCTS,
  GET_PRODUCTS_SUCCESS,
  PERSIST_STATE,
  RECALCULATE_PRICE,
  REQUEST_QUOTATION,
  REQUEST_QUOTATION_SUCCESS,
  SELECT_PRODUCT,
  SELECT_PRODUCTS,
  SET_MEASUREMENTS,
} from '../../';
import { SetMeasurementsHandler } from './Handlers/SetMeasurementsHandler';
import { handleSuccessfulProductHandler } from './Handlers/Product';


export function* HomeConfigurationSaga() {
  yield takeLatest(PERSIST_STATE, PersistStateHandler);
  yield takeLatest(REQUEST_QUOTATION, RequestQuotationHandler);
  yield takeLatest(REQUEST_QUOTATION_SUCCESS, RequestQuotationSuccessHandler);
  yield takeLatest(GET_PRODUCTS, RequestProductsHandler);
  yield takeLatest(SELECT_PRODUCT, SelectedProductHandler);
  yield takeLatest(SELECT_PRODUCTS, SelectedProductsHandler);
  yield takeLatest(SET_MEASUREMENTS, SetMeasurementsHandler);
  yield takeLatest(CONFIRM_CONFIGURATION_PROMPT, ConfigurationPromptConfirmationHandler);
  yield takeLatest(CANCEL_CONFIGURATION_PROMPT, ConfigurationPromptCancellationHandler);
  yield takeLatest(APPLY_CLIENT_CONFIGURATION, ClientConfigurationHandler);
  yield takeLatest(RECALCULATE_PRICE, PriceCalculationHandler);
  yield takeLatest(GET_PRODUCTS_SUCCESS, handleSuccessfulProductHandler);
}
