import {FunctionComponent} from "react";
import {DormerColorViewModel, DormerMaterialViewModel, FaceDto, SectionFragmentType} from "@hec/api-dtos";
import {Box} from "@react-three/drei";
import {
  fromMmToRenderingMeters,
  getFrameHeight, getSplinterHeight, getSplinterWidth,
  getTotalSplinterWidth,
} from "@hec/core";
import {CladdingGlobalMaterial} from "./CladdingGlobalMaterial";
import {SPLINTER_DEPTH} from "../../../constants";
import {DormerColorMaterial} from "./DormerColorMaterial";

export interface FaceSplintersProps {
  face:FaceDto;
  overrideColor?: DormerColorViewModel;
}


export const FaceSplinters: FunctionComponent<FaceSplintersProps> = ({face, overrideColor}: FaceSplintersProps) => {


  const sectionFragments = face?.sectionFragments ?? [];
  const splinter = sectionFragments.find(x => x.sectionFragmentType === SectionFragmentType.Splinter);

  const splinterHeightData = getSplinterHeight(face);

  const splinterWidthData = getSplinterWidth(face);

  const splinterWidth = fromMmToRenderingMeters(splinterWidthData ?? 1);
  const splinterHeight = fromMmToRenderingMeters(splinterHeightData ?? 1);

  const totalWidth = fromMmToRenderingMeters(getTotalSplinterWidth(face));
  const totalHeightDontIncludeSplinterWidth = fromMmToRenderingMeters(getFrameHeight(face));

  return <group key={'splinter'} position={[(totalWidth / 2),(splinterHeight / 2),-(SPLINTER_DEPTH/2)]}>
    <group key={'left'} position={[-(totalWidth - splinterWidth) /2, ((totalHeightDontIncludeSplinterWidth + splinterHeight) / 2), 0]}>
      <Box
        args={[splinterWidth,totalHeightDontIncludeSplinterWidth,SPLINTER_DEPTH]}
        receiveShadow
        castShadow
      >
        <DormerColorMaterial
          dormerColor={overrideColor ?? null}
          width={splinterWidth}
          height={totalHeightDontIncludeSplinterWidth}
        />
      </Box>
    </group>

    <group key={'right'} position={[(totalWidth - splinterWidth) /2, ((totalHeightDontIncludeSplinterWidth + splinterHeight) / 2), 0]}>
      <Box
        args={[splinterWidth,totalHeightDontIncludeSplinterWidth,SPLINTER_DEPTH]}
        receiveShadow
        castShadow
      >
        <DormerColorMaterial
          dormerColor={overrideColor ?? null}
          width={splinterWidth}
          height={totalHeightDontIncludeSplinterWidth}
        />
      </Box>
    </group>
    <group key={'top'} position={[0,totalHeightDontIncludeSplinterWidth + splinterHeight,0]}>
      <Box
        args={[totalWidth,splinterHeight,SPLINTER_DEPTH]}
        receiveShadow
        castShadow
      >
        <DormerColorMaterial
          dormerColor={overrideColor ?? null}
          width={totalWidth}
          height={splinterHeight}
        />
      </Box>
    </group>
    <group key={'bottom'} position={[0, 0, 0]}>
      <Box
        args={[totalWidth,splinterHeight,SPLINTER_DEPTH]}
        receiveShadow
        castShadow
      >
        <DormerColorMaterial
          dormerColor={overrideColor ?? null}
          width={totalWidth}
          height={splinterHeight}
        />
      </Box>
    </group>
  </group>
}
