import React, {FunctionComponent} from 'react';
import styles from './drawer-toggle.module.scss';
import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import classnames from 'classnames';
import {Conditional} from '../Conditional';

export interface TwoDimensionsToggleProps {
  onClick?: () => void;
  isTwoDimensions?: boolean;
  className?: string;
}

export const TwoDimensionsToggle: FunctionComponent<TwoDimensionsToggleProps> = ({
  onClick,
  isTwoDimensions = false,
  className,
}) => {
  return (
    <div className={classnames(styles.toggle, className)} onClick={onClick}>
      <Conditional condition={!isTwoDimensions}>
        <DesignServicesIcon/>
      </Conditional>
      <Conditional condition={isTwoDimensions}>
        <ThreeDRotationIcon/>
      </Conditional>
    </div>
  );
};
