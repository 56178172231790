import React, {FunctionComponent, InputHTMLAttributes} from 'react'
import {useField, useFormikContext} from "formik";
import styles from './text-input.module.scss';
import sharedStyles from '../Shared/_shared-styles.module.scss';
import {HelpText, Label, ErrorDisplay} from "../Shared";

interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  required?: boolean;
  helpText?: string;
}

export const TextInput: FunctionComponent<TextInputProps> = ({ label, required, helpText, ...props }) => {
  const [field, meta] = useField(props.name);
  const formik = useFormikContext();

  return (
    <div className={styles.TextInputContainer}>
      <Label id={props.id} name={props.name} label={label} required={required}/>
      <input
        className={sharedStyles.input}
        type="text"
        {...field}
        {...props}
        id={props.id || props.name}
        onChange={(e) => {
          formik.handleChange(e);
          setTimeout(formik.submitForm, 0);
        }}
      />

      <ErrorDisplay meta={meta}/>

      <HelpText helpText={helpText}/>
    </div>
  );
};
