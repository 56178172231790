import { FunctionComponent } from 'react';
import { GroundDimensions } from './CalculateGroundDimensions';
import { getRandomColor } from '../Utilities';
import { useTexture } from '@react-three/drei';
import { RepeatWrapping } from 'three';

const tileCdnUrl =
  'https://cdn.aanbouwconfigurator.com/static-assets/generic/garden/tile.jpg';

interface TilePathProps {
  groundDimensions: GroundDimensions;
}

export const TilePath: FunctionComponent<TilePathProps> = ({
  groundDimensions,
}: TilePathProps) => {
  const {
    width,
    depth,
    tileWidth,
    grassWidth,
    grassDepth,
    zOffsetTile,
    xOffsetTile,
    zOffsetGrass,
    xOffsetGrass,
    westFenceDepth,
    zPositionWestFence,
    eastFenceDepth,
    zPositionEastFence,
    southFenceWidth,
    zPositionSouthFence,
  } = groundDimensions;

  const map = useTexture(tileCdnUrl);
  map.wrapS = RepeatWrapping;
  map.wrapT = RepeatWrapping;

  const tileHeightAndWidth = 0.5;
  const tilePertrude = 0.025;
  const tileDepth = 1;

  const tile = (
    <mesh
      receiveShadow
      castShadow
      position={[
        tileHeightAndWidth / 2,
        -tileHeightAndWidth / 2 + tilePertrude,
        tileDepth / 2,
      ]}
    >
      <boxGeometry args={[tileHeightAndWidth, tileHeightAndWidth, tileDepth]} />
      <meshBasicMaterial attach="material" map={map} />
    </mesh>
  );

  const distanceFromSide = 1;
  const distanceBetweenTiles = tileHeightAndWidth / 2;

  const firstTileDepth = zOffsetGrass + distanceBetweenTiles;
  const secondTileDepth = zOffsetGrass + distanceBetweenTiles * 2 + tileDepth;
  const thirdTileDepth =
    zOffsetGrass + distanceBetweenTiles * 3 + tileDepth * 2;

  return (
    <>
      <group position={[grassWidth - distanceFromSide, 0, firstTileDepth]}>
        {tile}
      </group>
      <group position={[grassWidth - distanceFromSide, 0, secondTileDepth]}>
        {tile}
      </group>
      <group position={[grassWidth - distanceFromSide, 0, thirdTileDepth]}>
        {tile}
      </group>
    </>
  );
};
