import { raiseError } from '@hec/core';
import { Severity } from '@hec/models';
import {
  FunctionComponent,
  useState,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { FormField } from '../FormField';
import { FormSelect } from '../FormSelect';
import { countries } from '@hec/models';
import styles from './autocomplete-adress.module.scss';
import { Conditional } from '../../';
import { PlacesAutoComplete, Suggestion } from '../PlacesAutoComplete';
import { Input } from '../Input';
import { Text, useService } from '../../';
import { DormerGeolocationService } from '@hec/core';

export interface AutoCompleteAddressProps {
  className?: string;
  addressLabel?: string;
  postalCodeLabel?: string;
  cityLabel?: string;
  countryLabel?: string;
  onChange?: (
    streetName?: string,
    postalCode?: string,
    city?: string,
    country?: string,
    twoLetterCountryCode?: string,
  ) => void;
}

export const AutoCompleteAddress: FunctionComponent<AutoCompleteAddressProps> =
  (
    {
      className,
      addressLabel = '',
      postalCodeLabel = '',
      cityLabel = '',
      countryLabel = '',
      onChange,
    }
  ) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [streetName, setStreetName] = useState<string|undefined>('');
  const geolocationService = useService(DormerGeolocationService) as DormerGeolocationService;
  const [postalCode, setPostalCode] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('Nederland');
  // TODO: Convert to ISO-3166 (three letter country code) HEC-106
  const [twoLetterCountryCode, setTwoLetterCountryCode] = useState('Nederland');
  const handleChange = (address?: string) => setStreetName(address);

  useEffect(() => {
    onChange && onChange(streetName, postalCode, city, country, twoLetterCountryCode);
  }, [streetName, postalCode, city, country, twoLetterCountryCode, onChange]);

  const handleSelect = async (suggestion?: Suggestion) => {
    if (!suggestion) { return; }
    try {
      const response = await geolocationService.getPlaceById(suggestion?.placeId);
      const results = response.results;
      const getResult = (type: string) => {
        return results.filter(
          (a) => a.types.indexOf(type) !== -1
        );
      };

      const streetNumber = getResult('street_number').length
        ? getResult('street_number')[0]['long_name']
        : '';
      const streetName = getResult('route').length
        ? getResult('route')[0]['long_name']
        : '';
      const postalcode = getResult('postal_code').length
        ? getResult('postal_code')[0]['long_name']
        : '';
      const city = getResult('locality').length
        ? getResult('locality')[0]['long_name']
        : '';
      const country = getResult('country').length
        ? getResult('country')[0]['long_name']
        : '';
      const twoLetterCountryCode = getResult('country').length
        ? getResult('country')[0]['short_name']
        : '';

      setPostalCode(postalcode);
      setStreetName(`${streetName}${streetNumber ? ' ' + streetNumber : ''}`);
      setCity(city);
      setCountry(country);
      setTwoLetterCountryCode(twoLetterCountryCode);
    } catch (error) {
      dispatch(raiseError(error as any, Severity.LOW));
    }
  };
  return (
    <div>
      <PlacesAutoComplete
        value={streetName}
        debounceAmount={500}
        fetchPredictionsCallback={(address) => geolocationService.predictAddress(address)}
        onChange={handleChange}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <FormField
            label={addressLabel}
            optional
          >
            <>
              <input
                {...(getInputProps({ placeholder: addressLabel, className: styles['autocomplete-input'],}))}
              />
              <div className={styles['autocomplete-dropdown-container']}>
                <Conditional condition={loading}>
                  <div>Loading...</div>
                </Conditional>
                {suggestions.map((suggestion, index) => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                      key={index}
                    >
                      <Text>{suggestion.description}</Text>
                    </div>
                  );
                })}
              </div>
            </>
          </FormField>
        )}
      </PlacesAutoComplete>
      <FormField
        label={cityLabel}
        optional
      >
        <Input
          value={city}
          type="text"
          placeholder={cityLabel}
          onChange={(e) => {
            const value = (e.target as HTMLInputElement).value;
            setCity(value);
          }}
        />
      </FormField>
      <FormField
        label={postalCodeLabel}
        optional
      >
        <Input
          value={postalCode}
          type="text"
          placeholder={postalCodeLabel}
          onChange={(e) => {
            const value = (e.target as HTMLInputElement).value;
            setPostalCode(value);
          }}
        />
      </FormField>
      <FormField
        label={countryLabel}
        optional
      >
        <FormSelect
          name="country"
          selected={country}
          onChange={(value) => {
            setCountry(value);
          }}
          options={countries}
        />
      </FormField>
    </div>
  );
};
