import { MeshStandardMaterialProps, useLoader } from '@react-three/fiber';
import React, { FunctionComponent, } from 'react';
import { TextureLoader, Vector2, Vector3, } from 'three';
import { DEFAULT_WALL_THICKNESS, INSIDE_WALL_COLOR_STRING, } from '../Constants';
import { DEFAULT_WALL_HEIGHT } from '@hec/core';
import { DirectionEnum } from '../Enums';
import { Box } from '@react-three/drei';
import { HomeExtensionDto } from '../HomeExtensionDto';
import { ClippedGeometry, ClippingContext } from '../ClippedGeometry';
import { WallUtilities } from './WallUtilities';

export interface WallProps {
  outerWallFacingDirection: DirectionEnum;
  doorway?: Vector2;
  homeExtensionDto: HomeExtensionDto;
}

const position = new Vector3(0, 0, 0);

export const Wall: FunctionComponent<WallProps> = ({
  outerWallFacingDirection,
  homeExtensionDto,
  doorway ,
}) => {

  const { textureUrl, displacementMapUrl, normalMapUrl } = homeExtensionDto.getTextureMaterial();
  const [dtoWidth, dtoHeight, dtoDepth] = homeExtensionDto.getMeasurements().toArray();
  const wallPosition = WallUtilities.getWallPosition(position, outerWallFacingDirection, dtoWidth, dtoDepth);
  const wallLength = WallUtilities.getWallLength(outerWallFacingDirection, dtoWidth, dtoDepth);
  const clippingPlanes = doorway ? WallUtilities.getClipPanes(doorway) : [];

  const isNorthFacingWall = outerWallFacingDirection === DirectionEnum.North;

  let width = 0;
  let depth = 0;
  let color = 'grey';
  let adjustedPosition = new Vector3(width / 2, 0, depth / 2);

  //Positioning and width+ depth specification
  switch (outerWallFacingDirection) {
    case DirectionEnum.North:
      width = wallLength;
      depth = DEFAULT_WALL_THICKNESS;
      color = 'blue';
      adjustedPosition.setX((dtoWidth / 2));
      adjustedPosition.setZ(DEFAULT_WALL_THICKNESS / 2);
      break;
    case DirectionEnum.East:
      width = DEFAULT_WALL_THICKNESS;
      depth = wallLength - DEFAULT_WALL_THICKNESS;
      adjustedPosition.setZ((dtoDepth / 2) - DEFAULT_WALL_THICKNESS / 2);
      adjustedPosition.setX(DEFAULT_WALL_THICKNESS / 2);
      color = 'red';
      break;
    case DirectionEnum.South:
      width = wallLength;
      depth = DEFAULT_WALL_THICKNESS;
      break;
    case DirectionEnum.West:
      width = DEFAULT_WALL_THICKNESS;
      depth = wallLength - DEFAULT_WALL_THICKNESS;
      adjustedPosition.setZ((dtoDepth / 2) - DEFAULT_WALL_THICKNESS / 2);
      adjustedPosition.setX(DEFAULT_WALL_THICKNESS / 2);
      break;
    default:
      console.error('wow wth');
      throw 'err';
      break;
  }

  if (wallPosition) {
    adjustedPosition = wallPosition.add(adjustedPosition);
  }

  const textureMap = useLoader(TextureLoader, textureUrl);
  // const displacementMap = useLoader(TextureLoader, displacementMapUrl);
  // const normalMap = useLoader(TextureLoader, normalMapUrl);

  const regularWallTexture = WallUtilities.wrapTexture(textureMap, wallLength);

  const materialPropsDefault: MeshStandardMaterialProps = {
    normalScale: new Vector2(0, 1),
    displacementScale: 0.0,
  };

  if (outerWallFacingDirection === DirectionEnum.East || outerWallFacingDirection === DirectionEnum.North) {
    regularWallTexture.offset = new Vector2(DEFAULT_WALL_THICKNESS + 0.1, 0);
  }
  const regularMaterialPropsDefault: MeshStandardMaterialProps = {
    map: regularWallTexture,
    normalMap: null,
    displacementMap: null,
    ...materialPropsDefault,
  };

  let materialPropsWest: MeshStandardMaterialProps;
  let materialPropsEast: MeshStandardMaterialProps;

  if (isNorthFacingWall) {
    // If the wall is north facing adjust the side of the wall textures so they match with the east and west facing wall

    const depthOffset = dtoDepth % 1;

    materialPropsEast = {
      map: WallUtilities.wrapTexture(regularWallTexture, DEFAULT_WALL_THICKNESS, new Vector2(depthOffset,0)),
    };
    materialPropsWest = {
      map: WallUtilities.wrapTexture(regularWallTexture, DEFAULT_WALL_THICKNESS, new Vector2(-(1-DEFAULT_WALL_THICKNESS), 0)),
    };

  } else {
    // Make sure inside walls are white
    materialPropsWest = regularMaterialPropsDefault;
    materialPropsEast = regularMaterialPropsDefault;
    switch (outerWallFacingDirection) {
      case DirectionEnum.East:
        materialPropsEast = {
          ...regularMaterialPropsDefault,
          map: null,
          normalMap: null,
        };
        break;
      case DirectionEnum.West:
        materialPropsWest = {
          ...regularMaterialPropsDefault,
          map: null,
          normalMap: null,
        };
        break;
      default:
        break;
    }
  }
  // This is a inside wall so it needs to be white
  const materialPropsSouth: MeshStandardMaterialProps = {
    ...regularMaterialPropsDefault,
    color: isNorthFacingWall ? INSIDE_WALL_COLOR_STRING : undefined,
    map: null,
    normalMap: null,
  };
  const materialPropsNorth: MeshStandardMaterialProps = {
    ...regularMaterialPropsDefault,
    clipIntersection: true,
    color: isNorthFacingWall ? INSIDE_WALL_COLOR_STRING : undefined
  };

  //These are never visible
  const materialPropsUp = regularMaterialPropsDefault;
  const materialPropsDown = regularMaterialPropsDefault;
  const dimensions: [width: number, height: number, depth: number] = [width, DEFAULT_WALL_HEIGHT, depth];
  return (
    <mesh position={adjustedPosition}>
      <ClippingContext.Provider value={{ clippingPlanes }}>
        <ClippedGeometry>
          {
            (ref, clippingPlanes) => (
              <Box
                ref={ref}
                args={dimensions}
                receiveShadow
                castShadow
                position={new Vector3(0, DEFAULT_WALL_HEIGHT / 2, 0)}
              >
                {/* West facing side */}
                <meshStandardMaterial
                  attach="material-0" {...materialPropsWest}
                />
                {/* East facing side */}
                <meshStandardMaterial
                  attach="material-1" {...materialPropsEast}
                />
                {/* Up facing side */}
                <meshStandardMaterial
                  attach="material-2" {...materialPropsUp}
                />
                {/* Down facing side */}
                <meshStandardMaterial
                  attach="material-3" {...materialPropsDown}
                />
                {/* North facing side */}
                <meshStandardMaterial
                  attach="material-4"
                  {...materialPropsNorth}
                  clippingPlanes={isNorthFacingWall ? clippingPlanes : null}
                  clipIntersection={true}
                />
                {/* South facing */}
                <meshStandardMaterial
                  attach="material-5"
                  {...materialPropsSouth}
                  clippingPlanes={isNorthFacingWall ? clippingPlanes : null}
                  clipIntersection={true}
                />
              </Box>
            )
          }
        </ClippedGeometry>
      </ClippingContext.Provider>
    </mesh>
  );
};



