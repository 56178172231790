import React, {FunctionComponent, useEffect, useRef, useState} from "react";
import {Text} from "react-konva";
import Konva from "konva";
import {MEASUREMENTS_LINE_LENGTH} from "../Constants";

interface DrawTextProps {
  x: number;
  y: number;
  text: string;
  alignText: 'BOTTOM' | 'RIGHT'
}

export const DrawText: FunctionComponent<DrawTextProps> = ({x, y, text, alignText}) => {
  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);

  const textRef = useRef<Konva.Text>(null);
  useEffect(() => {
    if (textRef.current !== null) {
      const textMeasurements = textRef.current.measureSize(text);

      if (alignText === 'BOTTOM') {
        setOffsetX(textMeasurements.width / 2);
        setOffsetY(-MEASUREMENTS_LINE_LENGTH);
      } else if (alignText === 'RIGHT') {
        setOffsetX(-MEASUREMENTS_LINE_LENGTH);
        setOffsetY((textMeasurements.height / 2));
      }
    }
  }, [alignText, text]);


  return <Text
    ref={textRef}
    x={x}
    y={y}
    text={text}
    fontSize={50}
    offsetX={offsetX}
    offsetY={offsetY}
  />
}
