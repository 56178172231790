import { Product } from '@hec/models';

export const SET_SELECTED_PRODUCTS = 'SET_SELECTED_PRODUCTS';
export const REDUCER_SET_SELECTED_PRODUCTS = 'REDUCER_SET_SELECTED_PRODUCTS';

export const setSelectedProducts = (products: Product[]) => ({
  type: SET_SELECTED_PRODUCTS,
  data: {
    products
  }
})
