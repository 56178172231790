import {SerializableMetaData, SerializableValue, SurveyQuestionType} from ".";

export class SurveyQuestionViewModel {
  id: string;
  label: string;
  surveyQuestionType: SurveyQuestionType;
  parameterName: string;
  defaultValue?: SerializableValue;
  metaData?: SerializableMetaData;
  index: number;
  forPro: boolean;
  configurationTemplateId: string;
  organisationId: string;

  constructor({
    id,
    label,
    surveyQuestionType,
    parameterName,
    metaData,
    defaultValue,
    index,
    forPro,
    configurationTemplateId,
    organisationId,
  }: {
    id: string,
    label: string,
    surveyQuestionType: SurveyQuestionType,
    parameterName: string,
    metaData?: SerializableMetaData,
    defaultValue?: SerializableValue,
    index: number,
    forPro: boolean,
    configurationTemplateId: string,
    organisationId: string
  }) {
    this.id = id;
    this.label = label;
    this.surveyQuestionType = surveyQuestionType;
    this.parameterName = parameterName;
    this.metaData = metaData;
    this.defaultValue = defaultValue;
    this.index = index;
    this.forPro = forPro;
    this.configurationTemplateId = configurationTemplateId;
    this.organisationId = organisationId;
  }
}
