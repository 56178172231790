import { ErrorEvent } from '@hec/models';

export const I18N_KEY_MISSING = 'error.i18n.key-missing';

export class KeyMissingErrorEvent extends ErrorEvent {
  public constructor(key: string) {
    super({
      key,
      errorType: I18N_KEY_MISSING,
      message: `${key} is missing from translations`,
    });
  }
}
