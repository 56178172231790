export enum SizeLabel {
  UNKNOWN = 'UNKNOWN',
  S = 'S',
  M = 'M',
  L = 'L',
  XL = 'XL',
  XXL = 'XXL',
  XL3 = 'XL3',
  XL4 = 'XL4',
  XL5 = 'XL5',
  XL6 = 'XL6',
  XL7 = 'XL7',
  XL8 = 'XL8',
  XL9 = 'XL9',
  XL10 = 'XL10',
  XL11 = 'XL11',
  XL12 = 'XL12',
  XL13 = 'XL13',
  XL14 = 'XL14',
  XL15 = 'XL15',
}
export interface DrawableObjectWithSpecification<T> extends DrawableObject{
  specification: T;
}

export interface DrawableObject {
  id: string;
  locationUrl: string;
  imageUrl: string;
  name: string;
  height: number;
  width: number;
  depth: number;
  productionMaterialType: string;
  sizeLabel: SizeLabel;
  color: string;
}
