import React, { CSSProperties, FunctionComponent, PropsWithChildren, SyntheticEvent } from 'react';
import styles from './text.module.scss';
import classnames from 'classnames';


export interface TextProps {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span' | 'p';
  className?: string;
  style?: CSSProperties;
  onClick?: (e: SyntheticEvent) => void;
}

export const Text: FunctionComponent<PropsWithChildren<TextProps>> = ({ as = 'span', children, className, style, onClick }) => {
  return (
    <div className={classnames(styles[as], className)} style={style} onClick={onClick}>
      {children}
    </div>
  );
};
