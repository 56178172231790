export const RoutePaths = {
  BasePath: '/',
  TemplatePath: '/template',
  MeasurementsPath: '/measurements',
  FramesPath: '/frames',
  MaterialPath: '/material',
  ExtrasPath: '/extras',
  FinishPath: '/finish',
  TechnicalPath: '/technical',
  CustomerSurveyPath: '/customer-survey',
  SummaryPath: '/summary',
};
