import { FunctionComponent, useState } from 'react';
import { Color } from 'three';
import { DEFAULT_HOUSE_WIDTH, House } from './House';
import { DEFAULT_WALL_THICKNESS, HomeExtensionDto } from "@hec/components/v1";
import { getRandomColor } from './Utilities';

interface HousesProps {
  homeExtensionDto: HomeExtensionDto;
}

export const Houses: FunctionComponent<HousesProps> = ({
  homeExtensionDto,
}: HousesProps) => {
  const curacaoMode = false;
  const [widthInMeters, heightInMeters, depthInMeters] = homeExtensionDto
    .getMeasurements()
    .toArray();

  const width = widthInMeters;

  const housesOnEitherSide = 10;

  const buildHousesForSide = (
    multiplier: number,
    startingPoint: number = 0
  ) => {
    return Array.from(Array(housesOnEitherSide).keys()).map((x) => {
      return (
        <group
          key={`left-${x}`}
          position={[
            startingPoint + multiplier * (x * DEFAULT_HOUSE_WIDTH),
            0,
            0,
          ]}
        >
          <House
            width={DEFAULT_HOUSE_WIDTH}
            color={curacaoMode ? getRandomColor() : undefined}
            drawLowerWindows
          />
        </group>
      );
    });
  };

  return (
    <>
      {buildHousesForSide(-1, -DEFAULT_HOUSE_WIDTH)}
      <group key={'yourhouse'}>
        <House key={'yourhouse'} drawLowerWindows={false} width={width} />
      </group>
      {buildHousesForSide(1, width)}
    </>
  );
};
