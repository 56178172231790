import { createAction } from '@reduxjs/toolkit';
import {DormerMaterialViewModel} from "@hec/api-dtos";
interface GetDormerMaterialSucceededActionProps {
  dormerMaterials: DormerMaterialViewModel[];
}

interface SelectDormerMaterialActionProps {
  dormerMaterialId: string;
}

export const getDormerMaterialsAction = createAction('getDormerMaterialsAction');
export const getDormerMaterialsSucceededAction = createAction<GetDormerMaterialSucceededActionProps>('getDormerMaterialsSucceededAction');

export const selectDormerMaterialAction = createAction<SelectDormerMaterialActionProps>('selectDormerMaterialAction');
