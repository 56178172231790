import {
  HomeConfigurationState,
} from '@hec/models';

export const LOCAL_STORAGE_KEY = "home-extension-configurator-state";
export const StateGenerator = (): HomeConfigurationState | null => {
  if (localStorage) {
    const item = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (item) {
      return JSON.parse(item);
    }
  }
  return null;
};
