import React, { FunctionComponent } from 'react';
import styles from './header.module.scss';
import { CostAggregate } from '../CostAggregate';
import { Conditional } from '../Conditional';
import classnames from 'classnames';
import {ClientInformationViewModel, GetOrderInvoiceViewModel} from "@hec/api-dtos";

export interface HeaderProps {
  clientInformation?: ClientInformationViewModel;
  invoice?: GetOrderInvoiceViewModel;
  fullScreen?: boolean;
  hidePrice?: boolean;
  showCostAggregate?: boolean;
  orderIsDirty: boolean;
}

export const Header: FunctionComponent<HeaderProps> = ({
                                                         orderIsDirty,
  clientInformation,
  invoice,
  fullScreen = false,
  hidePrice = false,
  showCostAggregate = true,

}) => (
  <div className={styles.header}>
    <a className={styles.backlinkContainer}>
      <Conditional
        condition={clientInformation && typeof clientInformation.logoUrl !== 'undefined'}
      >
        <div style={{ backgroundImage: `url('${clientInformation?.logoUrl}')`}} className={styles.logo} />
      </Conditional>
    </a>
    <Conditional condition={typeof invoice !== 'undefined' && showCostAggregate}>
      <div
        className={
          classnames(
            styles.costAggregate,
            {
              [`${styles.hidden}`]: fullScreen
            },
          )
        }
      >
        <CostAggregate
          hidden={fullScreen}
          hidePrice={hidePrice}
          invoice={invoice}
          orderIsDirty={orderIsDirty}
        />
      </div>
    </Conditional>
  </div>
);
