import React, {FunctionComponent, useState} from "react";
import styles from './finish.module.scss';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {SidebarFooter} from "@hec/components/v2";
import {RoutePaths} from "../../routing";
import {TranslationKeys} from "@hec/models";
import {getAppConfigurationSelector, getClientInformationSelector, getIsOrganisationSelector} from "@hec/dal";
import {ExtraInformationForKeyRenderer} from "../../components";

export const FinishSideBarContent: FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const clientInformation = useSelector(getClientInformationSelector);

  const isOrganisation = useSelector(getIsOrganisationSelector);

  if (clientInformation === null) {
    return <></>;
  }

  let nextRoutePath = '';
  let nextRouteCTA = '';

  if (isOrganisation) {
    nextRoutePath = RoutePaths.TechnicalPath;
    nextRouteCTA = t(TranslationKeys.dormer.pages.technicalPage.cta);
  } else {
    nextRoutePath = RoutePaths.CustomerSurveyPath;
    nextRouteCTA = t(TranslationKeys.dormer.pages.customerSurveyPage.cta);
  }

  return (
    <>
      <div className={styles.sections}>
        <ExtraInformationForKeyRenderer
          extraInformationKey={'FinishExtraInformation'}
        />
      </div>
      <SidebarFooter
        prevRoutePath={RoutePaths.MaterialPath}
        nextRoutePath={nextRoutePath}
        nextRouteCTA={nextRouteCTA}
        clientInformation={clientInformation}
      />
    </>
  );
};
