import {
  Product,
  ProductType,
  RoofLightProduct,
  RoofOverhangProduct,
  RoofTrimProduct,
} from '../';
import { DrainPipeProduct } from '../DrainPipeProduct';

export function IsExtraProduct(product: Product): product is Product {
  // extend where possible
  return (
    (product as RoofTrimProduct).productType === ProductType.ROOF_TRIM ||
    (product as RoofOverhangProduct).productType ===
      ProductType.ROOF_OVERHANG ||
    (product as RoofLightProduct).productType === ProductType.ROOF_LIGHT ||
    (product as RoofLightProduct).productType === ProductType.FRAME_HEADER ||
    (product as DrainPipeProduct).productType === ProductType.DRAIN_PIPE ||
    (product as DrainPipeProduct).productType === ProductType.FAUCET
  );
}
