import React, {FunctionComponent} from 'react';
import styles from './InvoiceItem.module.scss';
import {OrderInvoiceItemViewModel} from "@hec/api-dtos";
import {useTranslation} from "react-i18next";
import {TranslationKeys} from "@hec/models";

type InvoiceItemProps = {
  item: OrderInvoiceItemViewModel;
};

export const InvoiceItem: FunctionComponent<InvoiceItemProps> = ({ item }) => {
  const { t } = useTranslation();

  const itemName = `${item.quantity > 1 ? `${item.quantity} x ` : ''} ${item.name}`

  return (
    <div className={styles.invoiceItem}>
      <div className={styles.invoiceItemDetails}>
        <div className={`${styles.name} ${item.sort === 0 ? styles.heading : ''}`}>{itemName}</div>
        <div className={styles.price}>
          {item.priceText}
        </div>
      </div>

      {item.subItems.length > 0 && (
        <div className={styles.subItems}>
          {item.subItems.map((subItem, index) => (
            <InvoiceItem key={index} item={subItem} />
          ))}
        </div>
      )}
    </div>
  );
};
