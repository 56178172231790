import {
  Product,
  ProductWithDrawableObjectWithSpecification,
  RoofLightProduct,
  RoofLightSpecification
} from '../';

export function HasDrawableObject(product: Product): product is ProductWithDrawableObjectWithSpecification<RoofLightSpecification> {
  return !!(product as RoofLightProduct).drawableObject;
}




