import React, {FunctionComponent} from 'react';
import styles from './drawer-toggle.module.scss';
import classnames from 'classnames';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

export interface ZoomOutButtonProps {
  onClick?: () => void;
  className?: string;
}

export const ZoomOutButton: FunctionComponent<ZoomOutButtonProps> = ({
  onClick,
  className,
}) => {
  return (
    <div className={classnames(styles.toggle, className)} onClick={onClick}>
      <ZoomOutIcon/>
    </div>
  );
};
