import {SerializableMetaData} from "./SerializableMetaData";

export class DropdownSerializableMetaData extends SerializableMetaData {
  value?: string[];

  constructor(value?: string[]) {
    super();
    this.value = value;
  }
}
