import {FunctionComponent, ReactElement, useEffect, useRef, useState} from "react";

import Quill from 'quill';
import 'quill/dist/quill.snow.css'; // Import Quill stylesheet

export interface QuillRendererProps {
  content : string;
}

export const QuillRenderer: FunctionComponent<QuillRendererProps> = ({content}) => {
  const [html, setHtml] = useState('');

  useEffect(() => {
    const quill = new Quill(document.createElement('div'));
    const delta = JSON.parse(content); // Parse the string into a Delta object
    quill.setContents(delta);
    setHtml(quill.root.innerHTML);
  }, [content]);


  return <div dangerouslySetInnerHTML={{ __html: html }} />;
}
