import React, {FunctionComponent, useMemo} from "react";
import styles from './frames.module.scss';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Conditional, SidebarFooter, Text} from "@hec/components/v2";
import {RoutePaths} from "../../routing";
import {DormerClientInformation, DormerModalContentKey, TranslationKeys} from "@hec/models";
import {
  ConfirmPromptModalData,
  getClientInformationSelector,
  getConfiguredFramesSelector, getDisplaySizeInformation,
  getDormerTotalWidth,
  getFaceConfiguration, getIsOrganisationSelector,
  getSelectedConfigurationTemplateSelector,
  putOrderAction,
  storeUiStatusAction
} from "@hec/dal";
import {FramesConfigurator} from "../../components/FramesConfigurator";
import {ExtraInformationForKeyRenderer} from "../../components";
import {useNavigate} from "react-router-dom";
import {FrameTemplateSelector} from "../../components/FrameTemplateSelector/FrameTemplateSelector";
import {Button} from "@hec/components/v1";
import {getContrastColor} from "@hec/utils";

export const FramesSideBarContent: FunctionComponent = () => {


  const navigate = useNavigate();
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [displayAdvancedEditor, setDisplayAdvancedEditor] = React.useState<boolean>(false);


  const clientInformation = useSelector(getClientInformationSelector);
  const configuredFrames = useSelector(getConfiguredFramesSelector);
  const selectedConfigurationTemplate = useSelector(getSelectedConfigurationTemplateSelector);
  const currentTotalWidth = useSelector(getDormerTotalWidth);
  const faceConfiguration = useSelector(getFaceConfiguration);
  const isOrganisation = useSelector(getIsOrganisationSelector);
  const displaySizeInformation = useSelector(getDisplaySizeInformation);

  // const { primaryColor, secondaryColor, pricePerCubicMeter } =
  //   homeConfigurationReducer.clientConfiguration;
  const strictSizeWarningFeaturFlag: boolean = selectedConfigurationTemplate?.strictSizeWarning ?? false;

  if (clientInformation === null || selectedConfigurationTemplate === null || currentTotalWidth === null) {
    return <></>;
  }

  let nextRouteError = '';
  if (selectedConfigurationTemplate.maximumWidth !== null && currentTotalWidth > selectedConfigurationTemplate.maximumWidth) {
    nextRouteError = `${t(TranslationKeys.dormer.pages.framesPage.exceededSpace)} ${currentTotalWidth - selectedConfigurationTemplate.maximumWidth}${t(TranslationKeys.dormer.measurementUnit)}`;
  }

  const freeSpace = (faceConfiguration?.width ?? 0) - currentTotalWidth;

  return (
    <>
      <div className={styles.sections}>
        <ExtraInformationForKeyRenderer
          extraInformationKey={'FramesStepExtraInformation'}
        />

        <Conditional
          condition={!displayAdvancedEditor}
        >
          <FrameTemplateSelector/>

        </Conditional>


        <Conditional
          condition={displayAdvancedEditor}
        >
          <FramesConfigurator
            configuredFrames={configuredFrames}
            selectedConfigurationTemplate={selectedConfigurationTemplate}
            currentTotalWidth={currentTotalWidth}
          />

        </Conditional>

        <Text style={{paddingTop: '3px', paddingBottom: '5px'}}>
          {t(TranslationKeys.dormer.sizeInformation.totalDormerWidth)}: {displaySizeInformation.totalDormerWidth} mm<br/>
          {t(TranslationKeys.dormer.sizeInformation.totalFrameWidth)}: {displaySizeInformation.totalFrameWidth} mm
        </Text>

        <Conditional condition={isOrganisation}>
          <Button
            size={'large'}
            style={{
              backgroundColor: clientInformation.primaryColor,
              color: getContrastColor(clientInformation.primaryColor),
            }}
            as={<div />}
            // isDisabled={true}
            className={styles.disabled}
            data-tooltip-id="next-route-error-tooltip"
            data-tooltip-content={nextRouteError}
            onClick={() => setDisplayAdvancedEditor(!displayAdvancedEditor)}
          >
            {displayAdvancedEditor ? t(TranslationKeys.dormer.pages.framesPage.preSelectedButtonLabel) : t(TranslationKeys.dormer.pages.framesPage.customButtonLabel)}
          </Button>
        </Conditional>
      </div>
      <SidebarFooter
        prevRoutePath={RoutePaths.MeasurementsPath}
        nextRoutePath={RoutePaths.MaterialPath}
        nextRouteCTA={t(TranslationKeys.dormer.pages.materialPage.cta)}
        nextRouteError={nextRouteError}
        clientInformation={clientInformation}
        onGoNext={(cancelNavigation) => {
          if (freeSpace > 0 && strictSizeWarningFeaturFlag) {
            const modalData: ConfirmPromptModalData = {
              message: `U heeft nog ${freeSpace}mm over, weet u zeker dat u deze niet wil vullen?`,
              onAccept: () => {
                navigate(RoutePaths.MaterialPath)
                dispatch(putOrderAction());
              }
            }

            dispatch(storeUiStatusAction({
              modalOpen: true,
              modalContentKey: DormerModalContentKey.CONFIRMATION_PROMPT,
              modalData,

            }))
            cancelNavigation();
          } else {
            // TODO: Refactor when this get called:
            dispatch(putOrderAction());

          }
        }}
      />
    </>
  );
};
