import { createAction } from '@reduxjs/toolkit';
import {ConfigurationTemplateDto} from "@hec/api-dtos";

interface GetConfigurationTemplateSucceededActionProps {
  configurationTemplates: ConfigurationTemplateDto[];
}

interface SelectConfigurationTemplateSucceededActionProps {
  configurationTemplate: ConfigurationTemplateDto;
}

interface SelectConfigurationTemplateActionProps {
  configurationTemplateId: string;
}

export const getConfigurationTemplatesAction = createAction('getConfigurationTemplatesAction');
export const getConfigurationTemplatesSucceededAction = createAction<GetConfigurationTemplateSucceededActionProps>('getConfigurationTemplatesSucceededAction');

export const selectConfigurationTemplateAction = createAction<SelectConfigurationTemplateActionProps>('selectConfigurationTemplateAction');
export const selectConfigurationTemplateSucceededAction = createAction<SelectConfigurationTemplateSucceededActionProps>('selectConfigurationTemplateSucceededAction');
