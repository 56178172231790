import React, { FunctionComponent } from 'react';
import styles from './help.module.scss';
import { Text } from "@hec/components/v1";

export interface HelpItemProps {
  icon: JSX.Element;
  title: string;
  linkTitle: string;
  linkValue: string;
}

export const HelpItem: FunctionComponent<HelpItemProps> = ({ icon, title, linkTitle, linkValue }) => {
  return (
    <a className={styles.item} href={linkValue}>
      <div>
        {icon}
      </div>
      <Text className={styles['item-title']}>{title}</Text>
      <Text className={styles['item-link']}>{linkTitle}</Text>
    </a>
  );
};
