import React, { FunctionComponent } from 'react';
import { Action, CombinedButton } from '../CombinedButton';
import styles from './floating-button-section.module.scss';

export interface FloatingButtonSectionProps {
  actions: Action[];
  activeIndex?: number;
}

export const FloatingButtonSection: FunctionComponent<
  FloatingButtonSectionProps
> = ({ actions, activeIndex }) => {
  return (
    <div id="floating" className={styles['floating-button-section']}>
      <CombinedButton actions={actions} activeIndex={activeIndex} />
    </div>
  );
};
