import React from 'react';

export const IconScroll = () => {
  return (
    <svg
      width="53px"
      height="53px"
      viewBox="0 0 53 53"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <polygon
          id="path-sdbn_rutv0-1"
          points="0 0 20.3864226 0 20.3864226 20.3830438 0 20.3830438"
        ></polygon>
      </defs>
      <g id="MVP" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Desktop-HD-Copy-13"
          transform="translate(-683.000000, -341.000000)"
        >
          <g id="Group-4" transform="translate(630.000000, 341.000000)">
            <g id="Group-6" transform="translate(53.000000, 0.000000)">
              <g id="Group-3">
                <mask id="mask-sdbn_rutv0-2" fill="white">
                  <use xlinkHref="#path-sdbn_rutv0-1"></use>
                </mask>
                <g id="Clip-2"></g>
                <path
                  d="M19.7888549,16.9030119 L6.95902503,4.07933285 L18.3451586,4.07933285 C19.4687902,4.07933285 20.3813152,3.1667609 20.3813152,2.03626131 C20.3813152,0.912571957 19.4687902,0 18.3451586,0 L2.04228555,0 C1.76989001,0 1.50430436,0.0544819079 1.25914838,0.156635485 C0.76202652,0.36094264 0.3602431,0.755936472 0.155946446,1.25989412 C0.0537981189,1.50506271 -0.000680988847,1.77066201 -0.000680988847,2.03626131 L-0.000680988847,18.3467825 C-0.000680988847,19.4704718 0.918653955,20.3830438 2.04228555,20.3830438 C3.16591715,20.3830438 4.07163232,19.4704718 4.07163232,18.3467825 L4.07163232,6.96006373 L16.9014622,19.7837428 C17.3032456,20.1855469 17.827607,20.3830438 18.3451586,20.3830438 C18.8627101,20.3830438 19.3870715,20.1855469 19.7888549,19.7837428 C20.5856119,18.9937551 20.5856119,17.6998098 19.7888549,16.9030119"
                  id="Fill-1"
                  fill="#FFFFFF"
                  mask="url(#mask-sdbn_rutv0-2)"
                ></path>
              </g>
              <path
                d="M53,34.6532175 C53,33.5227179 52.0806651,32.6169562 50.9570335,32.6169562 C49.8334019,32.6169562 48.9208768,33.5227179 48.9208768,34.6532175 L48.9208768,46.0399363 L36.0978568,33.2162572 C35.29429,32.4126491 34.0072211,32.4126491 33.2104641,33.2162572 C32.420517,34.0062449 32.420517,35.3001902 33.2104641,36.0969881 L46.040294,48.9206671 L34.6541605,48.9206671 C33.5305289,48.9206671 32.6180038,49.8332391 32.6180038,50.9637387 C32.6180038,52.087428 33.5305289,53 34.6541605,53 L50.9570335,53 C51.2226191,53 51.4882048,52.9455181 51.7333607,52.8433645 C52.2372925,52.6390574 52.632266,52.2440635 52.8433726,51.7401059 C52.9455209,51.4949373 53,51.229338 53,50.9637387 L53,34.6532175 Z"
                id="Fill-4"
                fill="#FFFFFF"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
