import React from 'react';

export const FrameVariationTiltAndTurnWindowRightIcon = () => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 27">
      <path clipRule="evenodd" d="M1 26h20.95V1H1v25Z" stroke="#231F20" strokeWidth="1.831"/>
      <path d="M21.95 26 11.396 1 1 26l20.95-12.539L1 1" stroke="#231F20" strokeWidth=".915" strokeLinejoin="round"/>
    </svg>
  );
}
