import { AnyAction } from 'redux';
import { put, delay } from 'redux-saga/effects';
import {
  REDUCER_ADD_NOTIFICATION,
  REDUCER_REMOVE_NOTIFICATION,
} from '../../../Actions';

const OFFSET = 1000;
export function* handleAddedNotification(action: AnyAction) {
  const { message, shouldBeTranslated, duration } = action.data;
  yield put({
    type: REDUCER_ADD_NOTIFICATION,
    data: { message, shouldBeTranslated, duration },
  });
  yield delay(duration + OFFSET);
  yield put({ type: REDUCER_REMOVE_NOTIFICATION, data: { message } });
}
