import { raiseError, requestFailure, SetFatalErrorKey, setLoading } from '../../../';
import {
  FatalErrorKey,
  GetProductsByTypeQuery,
  Product,
  Severity,
} from '@hec/models';
import { AnyAction } from 'redux';
import { call, put } from 'redux-saga/effects';
import axios, { AxiosError } from 'axios';
import { getProductsSuccess } from '../../..';
import { ProductsService } from '../../../';
import { getService } from '../../../';

export function* RequestProductsHandler(action: AnyAction) {
  try {
    yield put(setLoading(true, action.type));
    const service: ProductsService = yield getService(ProductsService);
    const products: Product[] | null =
      yield call([service, 'getProductsByType'], action.data as GetProductsByTypeQuery);
    if (products) {
      yield put(getProductsSuccess(products));
    }
    else {
      yield put(SetFatalErrorKey(FatalErrorKey.NO_VALID_PRODUCTS));
    }
    yield put(setLoading(false, action.type));
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      yield put(
        requestFailure(
          (error as AxiosError)?.response?.status ?? -1,
          (error as AxiosError)?.response?.statusText ?? 'none',
          action
        )
      );
    } else {
      yield put(raiseError(error as Error, Severity.HIGH));
    }
  }
}
