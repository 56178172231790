import {FrameDto, FrameVariation} from "@hec/api-dtos";

export interface SimpleFrameContainer {
  width: number,
  // height: number,
  // type: SectionFragmentType,
  variation: FrameVariation,
  isPanel: boolean,
  frame: FrameDto
}

export interface ConsecutivePanelTypeGroup {
  models: SimpleFrameContainer[],
  width: number,
}
