import { useLoader } from '@react-three/fiber';
import { FunctionComponent } from 'react';
import {
  RepeatWrapping,
  Texture,
  TextureLoader,
  Vector2,
  Vector3,
} from 'three';
import {
  DEFAULT_WALL_THICKNESS,
  TEXTURES_PER_METER_IN_LENGTH,
} from '../Constants';
import { DEFAULT_WALL_HEIGHT } from '@hec/core';
import { DirectionEnum } from '../Enums';
import { HomeExtensionDto } from '../HomeExtensionDto';
import { WallUtilities } from './WallUtilities';
import { FrameHeaderProduct, IsFrameHeaderProduct } from '@hec/models';

export interface FrameHeaderProps {
  homeExtensionDto: HomeExtensionDto;
}

export const FrameHeader: FunctionComponent<FrameHeaderProps> = ({
  homeExtensionDto,
}) => {
  const position = new Vector3(0, 0, 0);
  const outerWallFacingDirection = DirectionEnum.North;

  const doorway = homeExtensionDto.getFrameMeasurements() as Vector2;

  const frameHeaderDepth = 0.001;
  const frameHeaderHeight = DEFAULT_WALL_HEIGHT - (doorway?.y ?? 0);
  const frameHeaderWidth = doorway?.x ?? 0;

  let frameHeaderProduct = homeExtensionDto.getExtraByType(
    IsFrameHeaderProduct
  ) as FrameHeaderProduct | undefined;

  if (frameHeaderProduct?.textureMaterial?.textureUrl == null) {
    frameHeaderProduct = undefined;
  }

  const map: Texture = useLoader(
    TextureLoader,
    frameHeaderProduct?.textureMaterial?.textureUrl ?? []
  ) as Texture;

  if (frameHeaderProduct === undefined) {
    return null;
  }

  const measurements = homeExtensionDto.getMeasurements();
  const dtoWidth = measurements.x;
  const dtoDepth = measurements.z;

  const wallPosition = WallUtilities.getWallPosition(
    position,
    outerWallFacingDirection,
    dtoWidth,
    dtoDepth
  );
  const wallLength = WallUtilities.getWallLength(
    outerWallFacingDirection,
    dtoWidth,
    dtoDepth
  );

  const width = wallLength;
  const depth = DEFAULT_WALL_THICKNESS;

  let adjustedPosition = new Vector3(width / 2, 0, depth / 2);

  adjustedPosition.setX(dtoWidth / 2);
  adjustedPosition.setZ(DEFAULT_WALL_THICKNESS + frameHeaderDepth / 2);
  adjustedPosition.setY(DEFAULT_WALL_HEIGHT - frameHeaderHeight / 2);

  if (wallPosition) {
    adjustedPosition = wallPosition.add(adjustedPosition);
  }

  map.wrapS = RepeatWrapping;
  map.wrapT = RepeatWrapping;

  const mapRepeatY = TEXTURES_PER_METER_IN_LENGTH * frameHeaderHeight;
  const mapRepeatX = TEXTURES_PER_METER_IN_LENGTH * frameHeaderWidth;

  map.repeat.set(mapRepeatX, mapRepeatY);

  const opacity = 0.0;

  return (
    <group position={adjustedPosition}>
      <mesh>
        <boxGeometry
          args={[frameHeaderWidth, frameHeaderHeight, frameHeaderDepth]}
        />
        <meshStandardMaterial
          displacementScale={0}
          color={'white'}
          opacity={opacity}
          transparent={true}
          clipIntersection={true}
          attach="material-0"
        />
        <meshStandardMaterial
          displacementScale={0}
          color={'white'}
          opacity={opacity}
          transparent={true}
          clipIntersection={true}
          attach="material-1"
        />
        <meshStandardMaterial
          displacementScale={0}
          color={'white'}
          opacity={opacity}
          transparent={true}
          clipIntersection={true}
          attach="material-2"
        />
        <meshStandardMaterial
          displacementScale={0}
          color={'white'}
          opacity={opacity}
          transparent={true}
          clipIntersection={true}
          attach="material-3"
        />
        <meshStandardMaterial
          map={map}
          displacementScale={0}
          roughness={0.8}
          transparent={true}
          clipIntersection={true}
          attach="material-4"
        />
        <meshStandardMaterial
          displacementScale={0}
          color={'white'}
          opacity={opacity}
          transparent={true}
          clipIntersection={true}
          attach="material-5"
        />
      </mesh>
    </group>
  );
};
