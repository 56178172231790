import React, { FunctionComponent } from 'react';
import { FrameProduct } from '@hec/models';
import styles from './frame-information.module.scss';

export interface FrameInformationProps {
  frameProduct: FrameProduct;
}

export const FrameInformation: FunctionComponent<FrameInformationProps> = ({ frameProduct }) => {
  return (
    <div className={styles['frame-information']}>
      <div className={styles['description-container']}>
        {frameProduct.description}
      </div>
    </div>
  );
};
