import _ from "lodash";
import React, {FunctionComponent, useEffect, useState} from "react";
import {ConfigurationTemplateDto, FaceConfigurationSectionFragmentCombinationDto} from "@hec/api-dtos";
import {useDispatch, useSelector} from "react-redux";
import {frameTemplateSelectedAction, getFaceConfiguration, getSelectedConfigurationTemplateSelector} from "@hec/dal";
import {Dropdown, OptionItem, OptionList} from "@hec/components/v2";
import {useTranslation} from "react-i18next";

interface FrameTemplateSelectorProps {
  // Prop has no purpose
  show?:boolean
}

export const FrameTemplateSelector: FunctionComponent<FrameTemplateSelectorProps> = ({ show}:FrameTemplateSelectorProps) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const selectedConfigurationTemplate: ConfigurationTemplateDto | null = useSelector(getSelectedConfigurationTemplateSelector);
  const faceConfigurationWidth = useSelector(getFaceConfiguration)?.width ?? null;
  const [selectedId, setSelectedId] = useState<string | null>(null);

  let selectableCombinations: FaceConfigurationSectionFragmentCombinationDto[] =
    _.clone((selectedConfigurationTemplate?.templateFaceConfiguration?.sectionFragmentCombinations ?? []));

  selectableCombinations = selectableCombinations.sort((a,b) => (b?.maxWidth  ?? 0) - (a?.maxWidth ?? 0));

  // TODO: Also account for the minWidth property in the filtering simular to how maxWidth is implemented
  const suitableForConfigurationWidth = selectableCombinations
    .filter(x => x.maxWidth >= (faceConfigurationWidth ?? 0) && x.minWidth <= (faceConfigurationWidth ?? Infinity))
    .sort((a, b) => b.maxWidth - a.maxWidth);
  const unSuitableForConfigurationWidth = selectableCombinations.filter(x => !(x.maxWidth >= (faceConfigurationWidth ?? 0) && x.minWidth <= (faceConfigurationWidth ?? Infinity)));;

  const defaultSelectedId = suitableForConfigurationWidth[0]?.id ?? null;

  useEffect(() => {
    const selectedCombo = (selectedConfigurationTemplate?.templateFaceConfiguration?.sectionFragmentCombinations ?? [])
      .find(x=> x.id === selectedId) ?? null;

    dispatch(frameTemplateSelectedAction(
      {selectedFaceConfigurationSectionFragmentCombinationDto: selectedCombo ?? suitableForConfigurationWidth[0]})
    );
  }, [dispatch, selectedId]);




  const createOptionItem = (x: FaceConfigurationSectionFragmentCombinationDto, disabled: boolean): OptionItem => ({
    id: x.id!,
    name: x.name,
    imageSvg: undefined,
    imageUrl: undefined,
    subTitle: `${x.minWidth} mm - ${x.maxWidth} mm`,
    disabled: disabled,
  });

  const items: OptionItem[] = [
    ...suitableForConfigurationWidth.map((i) => createOptionItem(i, false)),
    ...unSuitableForConfigurationWidth.map((i) => createOptionItem(i, true)),
  ];



  return <div>
    <Dropdown key={'FrameTemplateSelector'} title={t('Opties')} isOpen={true}>
      <OptionList
        items={items}
        selectedItem={selectedId ?? defaultSelectedId}
        setSelectedItem={(id) => suitableForConfigurationWidth.find(x => x.id === id) ? setSelectedId(id) : null}/>
    </Dropdown>
  </div>
}
