import { AnyAction } from 'redux';
import { put, select } from 'redux-saga/effects';
import { raiseError } from '../../../';
import { HomeExtensionApplicationState, HomeConfigurationState } from '@hec/models';
import { LOCAL_STORAGE_KEY } from '../../../';

export function* PersistStateHandler(action: AnyAction) {
  try {
    if (localStorage) {
      const currentState: HomeConfigurationState = yield select(
        (state: HomeExtensionApplicationState) => state.homeConfigurationReducer
      );
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(currentState));
    }
  } catch (e) {
    yield put(raiseError(e as Error));
  }
}
