import { Severity } from '@hec/models';

export const RAISE_ERROR = 'RAISE_ERROR';

export const raiseError = (
  error: Error,
  severity: Severity = Severity.NORMAL
) => ({
  type: RAISE_ERROR,
  data: {
    error,
    severity,
  },
});
