import React from 'react';

export const FrameVariationTiltAndTurnWindowLeftIcon = () => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 27">
      <path clipRule="evenodd" d="M1.072 26H22V1H1.072v25Z" stroke="#231F20" strokeWidth="1.831"/>
      <path d="M1.072 26 11.615 1 22 26 1.072 13.461 22 1" stroke="#231F20" strokeWidth=".915" strokeLinejoin="round"/>
    </svg>
  );
}
