import { AppConfiguration } from '@hec/models';
import { assert } from '@hec/utils';

export abstract class BaseService {
  protected static serviceName = Symbol("SERVICES.BASE_SERVICE");

  static getName(): symbol { return BaseService.serviceName; }

  protected checkConfiguration(configuration: AppConfiguration) {
    assert(typeof configuration.baseUrl !== null, "Base url has not been set");
    assert(typeof configuration.configurationId !== null, "Configuration id has not been set");
  }
}
