import React, { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Route, HomeExtensionApplicationState, Palette } from '@hec/models';
import styles from './navigation.module.scss';
import { Conditional } from '../Conditional';
import { Text } from '../Typography';

export interface NavigationProps {
  routes: Route[];
  state: HomeExtensionApplicationState;
  palette: Palette;
  hidden?: boolean;
}

const getActiveRoute = (routes: Route[], pathname: string): Route|undefined => {
  return routes.find((route) => route.path === pathname);
};

const getActiveRouteIndex = (route: Route, routes: Route[]): number|undefined => {
  const index = routes.indexOf(route);
  if (index >= 0) {
    return index + 1;
  }
  return;
};

export const Navigation: FunctionComponent<NavigationProps> = ({
  routes,
  state,
  hidden = false,
  palette,
}) => {
  const location = useLocation();
  const { pathname } = location;
  const actualDisplayableRoutes = routes
    .filter((route) => typeof route.path !== 'undefined')
    .filter(
      (route) =>
        typeof route.displaySettings.displayInNavigation === 'undefined' ||
        route.displaySettings.displayInNavigation
    );
  const activeRoute = getActiveRoute(actualDisplayableRoutes, pathname);
  const activeRouteIndex = activeRoute ? getActiveRouteIndex(activeRoute, actualDisplayableRoutes) : null;
  const { t } = useTranslation();
  const style = {
    color: palette.primaryColor,
  };
  return (
    <div
      className={classnames(styles.navigation, {
        [`${styles.hidden}`]: hidden,
      })}
    >
      <Conditional condition={typeof activeRoute !== 'undefined'}>
        <div className={classnames(styles.identifiers)}>
          <Conditional
            condition={typeof activeRouteIndex === 'number' && activeRouteIndex < 10}
          >
            <Text style={style} className={styles['route-index']}>0{(activeRouteIndex)}</Text>
          </Conditional>
          <Conditional
            condition={typeof activeRouteIndex === 'number' && activeRouteIndex >= 10}
          >
            <Text style={style} className={styles['route-index']}>{(activeRouteIndex)}</Text>
          </Conditional>
          <Conditional
            condition={
              typeof activeRoute?.translationKey !== 'undefined' &&
              typeof activeRoute?.displaySettings.navigationTitleTranslationKey === 'undefined'}
          >
            <Text style={style} className={styles['route-name']}>{t(activeRoute?.translationKey as string)}</Text>
          </Conditional>
          <Conditional
            condition={
              typeof activeRoute?.translationKey !== 'undefined' &&
              typeof activeRoute?.displaySettings.navigationTitleTranslationKey !== 'undefined'}
          >
            <Text style={style} className={styles['route-name']}>{t(activeRoute?.displaySettings.navigationTitleTranslationKey as string)}</Text>
          </Conditional>
          <Conditional
            condition={
              typeof activeRoute?.displaySettings.navigationTitle !== 'undefined' &&
              typeof activeRoute?.displaySettings.navigationTitleTranslationKey === 'undefined'}
          >
            <Text style={style} className={styles['route-name']}>{activeRoute?.displaySettings.navigationTitle}</Text>
          </Conditional>
          <Conditional
            condition={
              typeof activeRoute?.translationKey === 'undefined' &&
              typeof activeRoute?.name === 'string'
            }
          >
            <Text style={style} className={styles['route-name']}>{activeRoute?.name}</Text>
          </Conditional>
        </div>
      </Conditional>
      <ul>
        {actualDisplayableRoutes
          .map((route, index) => {
            const isActive = (route.path === pathname || activeRouteIndex && (index + 1) <= activeRouteIndex);
            return (
            <li
              key={route.path as string}
              style={isActive ? { backgroundColor: palette.primaryColor } : {}}
              className={
                classnames(
                  styles['nav-item'],
                  {
                    [`${styles.active}`]: isActive,
                  },
                )
              }
            />
          )
          })
        }
      </ul>
    </div>
  );
};
