import { useState, useEffect } from 'react';

interface WindowInformation {
  windowWidth: number;
  windowHeight: number;
  orientation: 'portrait' | 'landscape';
  deviceType: 'tablet' | 'mobile' | 'desktop';
}

const getDeviceType = (navigator: Navigator) => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  }
  else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return "mobile";
  }
  return "desktop";
};

function getWindow(): WindowInformation {
  return {
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    deviceType: getDeviceType(window.navigator),
    orientation: (window.innerHeight > window.innerWidth) ? 'portrait' : 'landscape'
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState<WindowInformation>(getWindow());

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindow());
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  return windowDimensions;
}
