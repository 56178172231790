import React, { FunctionComponent } from 'react';
import styles from './close-button.module.scss';
import classnames from 'classnames';

export interface CloseButtonProps {
  onClose?: () => void;
  isBackgroundToggled?: boolean;
}

export const CloseButton: FunctionComponent<CloseButtonProps> = ({ onClose, isBackgroundToggled = false }) => {
  return (
    <div
      className={
        classnames(
          styles.close,
          {
            [`${styles['bg-white']}`]: isBackgroundToggled,
          }
        )
      }
      onClick={onClose}
    >
      <span className={styles.line} />
      <span className={styles.line} />
    </div>
  );
};
