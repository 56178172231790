import { createSlice } from '@reduxjs/toolkit';
import {
  addNewFrameAction,
  getOrderSucceededAction,
  removeFrameAction,
  swapFrameOrderAction,
  updateMeasurementsAction,
  addNewFrameReducer,
  getOrderSucceededReducer,
  removeFrameReducer,
  swapFrameOrderReducer,
  updateMeasurementsReducer,
  updateFrameSizeAction,
  updateFrameSizeReducer,
  putOrderSucceededAction,
  putOrderSucceededReducer,
  updateSectionFragmentDormerColorsAction,
  updateSectionFragmentDormerColorsReducer,
  markOrderDirtyAction,
  markOrderDirtyReducer,
  frameTemplateSelectedReducer,
  frameTemplateSelectedAction,
  replaceOrderSectionFragmentsReducer, replaceOrderSectionFragmentsAction,
} from '../';
import {orderInitialState} from './State';

export const orderSlice = createSlice({
  name: 'order',
  initialState: orderInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOrderSucceededAction, getOrderSucceededReducer)
      .addCase(putOrderSucceededAction, putOrderSucceededReducer)
      .addCase(markOrderDirtyAction, markOrderDirtyReducer)
      .addCase(updateMeasurementsAction, updateMeasurementsReducer)
      .addCase(addNewFrameAction, addNewFrameReducer)
      .addCase(removeFrameAction, removeFrameReducer)
      .addCase(swapFrameOrderAction, swapFrameOrderReducer)
      .addCase(updateFrameSizeAction, updateFrameSizeReducer)
      .addCase(updateSectionFragmentDormerColorsAction, updateSectionFragmentDormerColorsReducer)
      .addCase(frameTemplateSelectedAction, frameTemplateSelectedReducer)
      .addCase(replaceOrderSectionFragmentsAction, replaceOrderSectionFragmentsReducer)
  },
});
