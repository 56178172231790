import React, {FunctionComponent, ReactNode, SelectHTMLAttributes} from 'react'
import {useField, useFormikContext} from "formik";
import styles from './select.module.scss';
import sharedStyles from '../Shared/_shared-styles.module.scss';
import {ErrorDisplay, HelpText, Label} from "../Shared";
import classNames from "classnames";

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  label: string;
  required?: boolean;
  helpText?: string;
  children?: ReactNode;
}

export const Select: FunctionComponent<SelectProps> = ({ label, required, helpText, children, ...props }) => {
  const [field, meta] = useField(props);
  const formik = useFormikContext();

  return (
    <div className={sharedStyles.container}>
      <Label id={props.id} name={props.name} label={label} required={required}/>
      <select
        className={classNames(sharedStyles.input, styles.input)}
        {...field}
        {...props}
        id={props.id || props.name}
        onChange={(e) => {
          formik.handleChange(e);
          setTimeout(formik.submitForm, 0);
        }}
      >
        {children}
      </select>


      <ErrorDisplay meta={meta}/>

      <HelpText helpText={helpText}/>
    </div>
  );
};
