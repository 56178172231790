import {RepeatWrapping, Texture, Vector2} from "three";

export const TEXTURES_PER_UNIT = 1;
export class WrappingUtility {
  public static wrapTexture(
    texture: Texture,
    width: number,
    height: number,
    offSet?: Vector2 | null
  ): Texture {
    const mapRepeatY = TEXTURES_PER_UNIT * height;
    const mapRepeatX = TEXTURES_PER_UNIT * width;
    const regularTexture = texture.clone();
    regularTexture.wrapS = RepeatWrapping;
    regularTexture.wrapT = RepeatWrapping;
    regularTexture.repeat.set(
      mapRepeatX,
      mapRepeatY
    );
    if (offSet) {
      regularTexture.offset.set(offSet.x, offSet.y);
    }
    return regularTexture;
  }
}
