import React, {FunctionComponent} from "react";
import styles from "./add-frame-button.module.scss";
import {useTranslation} from "react-i18next";
import {Button} from "@hec/components/v2";
import {useSelector} from "react-redux";
import {getClientInformationSelector} from "@hec/dal";
import {TranslationKeys} from "@hec/models";

interface AddFrameButtonProps {
  addNewFrame: () => void;
}
export const AddFrameButton: FunctionComponent<AddFrameButtonProps> = ({addNewFrame}) => {
  const { t } = useTranslation();

  const clientInformation = useSelector(getClientInformationSelector);

  if (clientInformation === null) {
    return <></>;
  }

  return (
    <Button
      className={styles.addFrameButton}
      onClick={addNewFrame}
      size={'large'}
      style={{
        backgroundColor: clientInformation.secondaryColor,
        color: clientInformation.primaryColor,
      }}
      as={<div />}
    >
      <>
        {t(TranslationKeys.dormer.pages.framesPage.addNewFrame, "Kozijn toevoegen")}
        <svg width="25" height="25" viewBox="0 0 25 25" fill={clientInformation.primaryColor} xmlns="http://www.w3.org/2000/svg">
          <path d="M19.0573 13.2344L19.0573 11.7656H13.2344L13.2344 5.94271L11.7656 5.94271L11.7656 11.7656H5.94271L5.94271 13.2344L11.7656 13.2344L11.7656 19.0573H13.2344L13.2344 13.2344L19.0573 13.2344Z"/>
        </svg>
      </>
    </Button>
  );
};
