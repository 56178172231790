import classnames from 'classnames';
import React, { CSSProperties, FunctionComponent, PropsWithChildren, ReactElement } from 'react';
import styles from './button.module.scss';
import { lighten, darken } from '@mui/material';
import { DEFAULT_PALETTE, Palette } from '@hec/models';
import { getContrastColorMulti } from '@hec/utils';

export interface ButtonProps {
  children?: ReactElement | ReactElement[] | string | number | undefined;
  size?: 'small' | 'medium' | 'large';
  type?: 'primary' | 'secondary' | 'cta';
  style?: CSSProperties;
  as?: ReactElement;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  palette?: Palette,
}

const getColor = (palette: Palette, type: 'primary' | 'secondary' | 'cta'): string => {
  switch (type) {
    case 'primary':
      return palette.primaryColor;
    case 'secondary':
      return lighten(palette.primaryColor, .8);
    case 'cta':
      return palette.secondaryColor;
  }
};

export const Button: FunctionComponent<ButtonProps> = ({
  children,
  onClick,
  className,
  disabled = false,
  size = 'medium',
  type = 'primary',
  style,
  palette = DEFAULT_PALETTE,
  as = <button />,
}) => {
  const primaryColor = getColor(palette, type);
  const styling: CSSProperties = {
    ...style,
    backgroundColor: primaryColor,
    color: getContrastColorMulti(primaryColor, type === 'cta' ? lighten(primaryColor, .8) : darken(primaryColor, .8)),
  };

  const classNames = classnames(
    styles.button,
    styles[size],
    styles[type],
    className,
    {
      [`${styles.disabled}`]: disabled,
    }
  );
  return React.cloneElement(as, { disabled, onClick, children, style: styling, className: classNames });
};
