import {TranslationsDormer} from "./TranslationsDormer";

export interface Translations {
  pages: {
    claddingPage: {
      name: string;
      sideDrawerTitle: string;
      nextStep: string;
    };
    measurementsPage: {
      name: string;
      sideDrawerTitle: string;
      nextStep: string;
    };
    framesPage: {
      name: string;
      sideDrawerTitle: string;
      nextStep: string;
    };
    extrasPage: {
      name: string;
      sideDrawerTitle: string;
      nextStep: string;
      roofTrimDropdown: string;
      roofOverhangDropdown: string;
      roofLightStyleDropDown: string;
      frameHeaderDropDown: string;
      drainPipeDropdown: string;
      faucetDropdown: string;
    };
    summaryPage: {
      name: string;
      downloadPdfLink: string;
      sideDrawerTitle: string;
      cta: string;
      requestPdf: string;
    };
  };
  notifications: {
    quotationEmailNotSent: string;
    quotationEmailSent: string;
  };
  modals: {
    promptModal: {
      title: string;
      content: string;
    };
    helpModal: {
      title: string;
    };
    costSummaryModal: {
      title: string;
    };
    quotationModal: {
      title: string;
      questionAreaTitle: string;
      questionPlaceHolder: string;
      form: {
        firstName: string;
        firstNameError: string;
        lastName: string;
        lastNameError: string;
        email: string;
        emailError: string;
        phoneNumber: string;
        phoneNumberError: string;
        address: string;
        postalCode: string;
        city: string;
        country: string;
        chamberOfCommerceNumber: string;
        companyName: string;
      };
    };
    requestPdfModal: {
      title: string;
      form: {
        email: string;
        emailError: string;
        cta: string;
      };
    };
  };
  fatalError: {
    somethingWentWrongHeader: string;
    fatalErrorKeyPrefix: string;
    fatalErrorKeyHintPrefix: string;
    redirectSoonText: string;
  };
  materials: {
    wood: string;
    stone: string;
    synthetic: string;
    aluminium: string;
  };
  generic: {
    previousStep: string;
    optional: string;
    description: string;
    included: string;
    subTotal: string;
    vatAmount: string;
    total: string;
    indication: string;
    depth: string;
    width: string;
    help: string;
    phone: string;
    email: string;
    confirm: string;
    cancel: string;
    priceOnInquiry: string;
    makeSmaller: string;
    makeBigger: string;
  };
  other: {
    explanation: {
      clickAndDrag: string;
      allSides: string;
      scroll: string;
      closerLook: string;
    };
  };
  dormer: TranslationsDormer
}
