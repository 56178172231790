import { addNotificationSucceededAction, removeNotificationAction, addNotificationAction } from './index';
import { delay, put, takeLatest } from 'redux-saga/effects';
import { NotificationType } from '@hec/models';

const OFFSET = 1000;
function* handleAddedNotification(action: ReturnType<typeof addNotificationAction>) {
  const { type, message, shouldBeTranslated, duration } = action.payload;

  yield put(addNotificationSucceededAction({ type, message, shouldBeTranslated, duration }));
  if (action.payload.type !== NotificationType.PERSISTENT) {
    yield delay(duration + OFFSET);
    yield put(removeNotificationAction({ message }));
  }
}

export function* NotificationsSagas() {
  yield takeLatest(addNotificationAction, handleAddedNotification);
}
