import { Product, RoofLightProduct } from '../';

export function IsCompatibleRoofLightProduct(
  product: Product,
  measurements: {
    width: number;
    depth: number;
  }
): product is RoofLightProduct {
  const roofLightProduct = product as RoofLightProduct;
  return (
    roofLightProduct.minWidth < measurements.width &&
    roofLightProduct.minDepth < measurements.depth
  );
}
