import React, {FunctionComponent} from "react";
import {Line} from "react-konva";
import {STROKE_WIDTH} from "../Constants";

interface DrawLineProps {
  x?: number;
  y?: number;
  points?: number[];
  stroke?: string;
  dash?: number[];
}

export const DrawLine: FunctionComponent<DrawLineProps> = ({x, y, points, stroke = 'black', dash}) => {

  return <Line
    {...(typeof x !== 'undefined' && {x})}
    {...(typeof y !== 'undefined' && {y})}
    {...(typeof points !== 'undefined' && {points})}
    stroke={stroke}
    strokeWidth={STROKE_WIDTH}
    {...(typeof dash !== 'undefined' && {dash})}
  />
}
