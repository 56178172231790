import React, { ReactElement, useState } from 'react';
import classnames from 'classnames';
import styles from './selection-list.module.scss';
import { CurrencyFormatter } from '@hec/utils';
import { Product } from '@hec/models';
import { SelectProductItemProps } from './SelectProductItemProps';
import { useTranslation } from 'react-i18next';
import { Conditional } from '../Conditional';
import { Text } from '../Typography';

type ProductComponentAndItemCompound<T extends Product> = {
  component: ReactElement<SelectProductItemProps<T>>;
  item: T;
};
export type ProductSelectionListIterator<T extends Product> = (
  item: T,
  index: number
) => ProductComponentAndItemCompound<T>;

export interface ProductSelectionListProps<T extends Product> {
  hideSubTotal?: boolean;
  items: Readonly<T>[];
  markedItems?: T[];
  activeItem?: T | ((items: T[]) => T | undefined);
  onSelect?: (item: T) => void;
  iterator?: ProductSelectionListIterator<T>;
  onMarkedClick?: (item: T) => void;
  activeBorderColor?: string;
}

export const ProductSelectionList = <T extends Product>({
  hideSubTotal = false,
  items,
  activeItem,
  markedItems = [],
  onSelect,
  iterator,
  onMarkedClick,
  activeBorderColor,
}: ProductSelectionListProps<T>) => {
  const [hoveredItem, setHoveredItem] = useState<T | undefined>();
  const { t } = useTranslation();
  const getItems = (iterator?: ProductSelectionListIterator<T>): ReactElement[] => {
    if (iterator) {
      return items.map(iterator).map(({ component, item }, index) => {
        let isActive = false;
        if (typeof activeItem === 'function') {
          isActive = activeItem(items)?.id === item.id;
        } else if (typeof activeItem === 'object') {
          isActive = activeItem.id === item.id;
        }
        const isMarked = markedItems?.indexOf(item) >= 0 && isActive;
        return (
          <li
            key={index}
            className={styles['list-item']}
            onClick={() => {
              if (isMarked && onMarkedClick) {
                onMarkedClick(item);
              }
            }}
          >
            {
              React.cloneElement(component as ReactElement<SelectProductItemProps<T>>, {
                isMarked,
                isActive,
                borderColor: activeBorderColor,
                click: onSelect,
                hover: setHoveredItem,
              })
            }
          </li>
        );
      });
    }
    return [];
  };

  return (
    <div className={classnames(styles.container)}>
      <div className={classnames(styles.list)}>{getItems(iterator)}</div>
      <div className={styles.description}>
        <Conditional
          condition={typeof hoveredItem !== 'undefined'}
        >
          <Text>{t(hoveredItem?.translationKey as string)}</Text>
          <Text className={styles.price}>
            {CurrencyFormatter.format(hoveredItem?.priceExVat as number)}
          </Text>
        </Conditional>
        <Conditional
          condition={
            typeof hoveredItem === 'undefined' &&
            typeof activeItem !== 'undefined'
          }
        >
          {/*<Text>{activeItem ? t((activeItem as T).translationKey) : ''}</Text>*/}
          <Text className={classnames(styles.price, { [`${styles.hidden}`]: hideSubTotal })}>
            {activeItem ? CurrencyFormatter.format((activeItem as T).priceExVat) : ''}
          </Text>
        </Conditional>
      </div>
    </div>
  );
};
