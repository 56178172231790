import {FrameDto} from "@hec/api-dtos";
import {DormerRootState} from "../DormerRootState";

export const getSelectableFramesSelector = (state: DormerRootState): FrameDto[] => {
  return state.selectableFrames.selectableFrames;
}

export const getDefaultFrameSelector = (state: DormerRootState): FrameDto | null => {
  if (state.selectableFrames.selectableFrames !== null) {
    return state.selectableFrames.selectableFrames
      .filter((frame: FrameDto) => frame.isDefaultForTemplate)[0];
  }
  return null;
}
