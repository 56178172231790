import React, {FunctionComponent} from "react";
import {Rect} from "react-konva";
import {STROKE_WIDTH} from "../Constants";

interface DrawRectangleProps {
  x: number;
  y: number;
  width: number;
  height: number;
  fill?: string;
  stroke?: string;
}

export const DrawRectangle: FunctionComponent<DrawRectangleProps> = ({x, y, width, height, fill = 'white', stroke = 'black'}) => {
  return <Rect
    x={x}
    y={y}
    width={width - STROKE_WIDTH}
    height={height - STROKE_WIDTH}
    fill={fill}
    stroke={stroke}
    strokeWidth={STROKE_WIDTH}
  />
}
