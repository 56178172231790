import { Conditional } from "@hec/components/v1";
import { CdnUrlHelper, DEFAULT_WALL_HEIGHT } from '@hec/core';
import {
  IsFrameHeaderProduct,
  FrameHeaderProduct,
  IsDrainPipeProduct,
  DrainPipeProduct,
  DrawableObject,
  ProductionMaterialType,
} from '@hec/models';
import { useGLTF } from '@react-three/drei';
import { useLoader } from '@react-three/fiber';
import { PlacementType } from '@hec/models';
import { FunctionComponent, Suspense, useMemo } from 'react';
import {
  CubeTextureLoader,
  Mesh,
  MeshPhongMaterial,
  MultiplyOperation,
  Vector3,
} from 'three';
import { DEFAULT_WALL_THICKNESS } from '../Constants';
import { DirectionEnum } from '../Enums';
import { HomeExtensionDto } from '../HomeExtensionDto';
import { WallUtilities } from './WallUtilities';

export interface DrainPipeProps {
  homeExtensionDto: HomeExtensionDto;
}

const cdnPublicRoot = CdnUrlHelper.getPublicRoot();
const cubeTextureLoader = new CubeTextureLoader();
const envTexture = cubeTextureLoader.load([
  cdnPublicRoot + '/px.jpg',
  cdnPublicRoot + '/nx.jpg',
  cdnPublicRoot + '/py.jpg',
  cdnPublicRoot + '/ny.jpg',
  cdnPublicRoot + '/pz.jpg',
  cdnPublicRoot + '/nz.jpg',
]);

export const DrainPipe: FunctionComponent<DrainPipeProps> = ({
  homeExtensionDto,
}) => {
  const metalMaterial = useMemo(
    () =>
      new MeshPhongMaterial({
        shininess: 0.8,
        reflectivity: 0.8,
        envMap: envTexture,
        combine: MultiplyOperation,
        color: '#BAC4C8',
      }),
    []
  );

  const position = new Vector3(0, 0, 0);
  const outerWallFacingDirection = DirectionEnum.North;


  const drainPipeProduct = homeExtensionDto.getExtraByType(
    IsDrainPipeProduct
  ) as DrainPipeProduct | undefined;

  const positionedLeft = drainPipeProduct?.placementType === PlacementType.LEFT || drainPipeProduct?.placementType === PlacementType.LEFT_AND_RIGHT;
  const positionedRight = drainPipeProduct?.placementType === PlacementType.RIGHT || drainPipeProduct?.placementType === PlacementType.LEFT_AND_RIGHT;


  const gltf = useGLTF(
    drainPipeProduct?.drawableObject?.locationUrl ?? [],
    true
  ) as import('three-stdlib').GLTF;

  if (drainPipeProduct?.drawableObject?.locationUrl == null) {
    return null;
  }

  const measurements = homeExtensionDto.getMeasurements();
  const dtoWidth = measurements.x;
  const dtoDepth = measurements.z;

  const wallPosition = WallUtilities.getWallPosition(
    position,
    outerWallFacingDirection,
    dtoWidth,
    dtoDepth
  );

  const drawableObject: DrawableObject = drainPipeProduct.drawableObject;

  if (drawableObject.productionMaterialType !== ProductionMaterialType.SYNTHETIC) {
    gltf.scene.traverse((o) => {
      if (o.name.startsWith('mesh')) (o as Mesh).material = metalMaterial;
    });
  }

  const fromsideOffset = 0.05;

  let leftPosition = new Vector3(fromsideOffset, 0, DEFAULT_WALL_THICKNESS);
  let rightPosition = new Vector3(
    dtoWidth - (fromsideOffset + drawableObject.width),
    0,
    DEFAULT_WALL_THICKNESS
  );

  if (wallPosition) {
    leftPosition = wallPosition.clone().add(leftPosition);
    rightPosition = wallPosition.clone().add(rightPosition);
  }
  const leftScene = gltf.scene;

  const rightScene = leftScene.clone();

  return (
    <>
      <Conditional condition={positionedLeft}>
        <group position={leftPosition}>
          <Suspense fallback={<group />}>
            <primitive object={leftScene} />
          </Suspense>
        </group>
      </Conditional>

      <Conditional condition={positionedRight}>
        <group position={rightPosition}>
          <Suspense fallback={<group />}>
            <primitive object={rightScene} />
          </Suspense>
        </group>
      </Conditional>
    </>
  );
};
