import React, {FunctionComponent, ReactElement} from "react";
import classnames from "classnames";
import styles from "./option-list.module.scss";
import classNames from "classnames";
import {Conditional} from "../Conditional";
import {Text} from "../Typography";
import {TranslationKeys} from "@hec/models";
import {CurrencyFormatter} from "@hec/utils";
import {useTranslation} from "react-i18next";
import {number} from "yup";


export interface OptionListProps {
  items: Readonly<OptionItem>[];
  selectedItem: string | null;
  setSelectedItem: (itemId: string) => void;
  primaryColor?: string;
}

export interface OptionItem {
  id: string;
  imageUrl?: string;
  imageSvg?: ReactElement;
  name: string;
  subTitle?: number | string;
  help?: string;
  disabled?: boolean;
}
export const OptionList: FunctionComponent<OptionListProps> = ({items, selectedItem, setSelectedItem, primaryColor = '#000000'}) => {
  const { t } = useTranslation();

  return (
    <ul className={classnames(styles.list)}>
      {items.map((item, index) => {
        const isActive = item.id === selectedItem;

        // dim the listitem if disabled is true, make it look like the opacity is very low
        return (
          <li
            key={index}
            className={classNames(styles.listItem, {[`${styles.listItemActive}`]: isActive})}
            style={{
              borderColor: isActive ? primaryColor : '',
              opacity: item.disabled === true ? 0.3 : 1
            }}
            onClick={() => {
              setSelectedItem(item.id)
            }}
          >
            <Conditional condition={typeof item.imageUrl === 'string'}>
              <img
                className={styles.image}
                loading="lazy"
                src={item.imageUrl}
                alt={`selection-image-${item.name}`}
              />
            </Conditional>
            <Conditional condition={typeof item.imageSvg !== 'undefined'}>
              {item.imageSvg}
            </Conditional>
            <Conditional condition={typeof item.imageUrl !== 'string' && typeof item.imageSvg === 'undefined'}>
              <Conditional condition={!isActive}>
                <div className={classnames(styles.checkboxContainer)}></div>
              </Conditional>
              <Conditional condition={isActive}>
                <div
                  className={classnames(styles.checkboxContainer)}
                  style={isActive ? {backgroundColor: primaryColor}: {}}
                >
                  <div className={styles.checkboxActive} />
                </div>
              </Conditional>
            </Conditional>

            <div className={styles.information}>
              <Text className={styles.name}>
                {t(item.name, {reportMissing: false})}
              </Text>
              <Text className={styles.price}>
              <Conditional condition={item.subTitle !== undefined}>
                <Conditional
                  condition={typeof item.subTitle === 'number' && !isNaN(item.subTitle as number) && (item.subTitle ?? 0) > 0}
                >
                  {CurrencyFormatter.format(item.subTitle as number ?? 0)}
                </Conditional>
                <Conditional
                  condition={typeof item.subTitle !== 'number'}
                >
                  {item.subTitle}
                </Conditional>
              </Conditional>
              </Text>
            </div>
            <Conditional condition={item.help}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 14 14"
                className={styles.help}
                data-tooltip-id="help-tooltip"
                data-tooltip-content={item.help}
                >
                <path fillRule="evenodd" clipRule="evenodd" d="M7 .333A6.67 6.67 0 0 0 .335 7a6.67 6.67 0 0 0 6.667 6.667A6.67 6.67 0 0 0 13.667 7 6.67 6.67 0 0 0 7.001.333Zm-.666 10v-4h1.333v4H6.334Zm0-6.666V5h1.333V3.667H6.334Z" fill="#000"/>
              </svg>
            </Conditional>
          </li>
        )
      })}
    </ul>
  );
};
