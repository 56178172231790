
import {BaseDalService} from "@hec/core"
import {AppConfiguration, ApplicationService} from "@hec/models";
import axios, {AxiosInstance, CancelTokenSource} from "axios";
import {GetOrderInvoiceViewModel} from "@hec/api-dtos";

export class OrderInvoiceService extends BaseDalService implements ApplicationService {

  private client: AxiosInstance;
  public static override serviceName = Symbol('OrderInvoiceService');
  private isPro = false;
  private cancelTokenSource: CancelTokenSource | undefined;

  constructor() {
    super();
    this.client = axios.create({});
  }

  override configure(configuration: AppConfiguration) {
    this.client = axios.create({
      baseURL: `${configuration.baseUrl}/api`,
    });

    this.isPro = configuration.login?.refreshToken != null;
  }

  getName(): symbol {
    return OrderInvoiceService.serviceName;
  }

  static override getName(): symbol {
    return OrderInvoiceService.serviceName;
  }

  async get(orderId: string, orderConfigurationCode: string): Promise<GetOrderInvoiceViewModel> {
    this.configureAuthInterceptor(this.client, orderConfigurationCode);

    let url = `/orders/${orderId}/invoice?forActors=Customer`;
    if (this.isPro) {
      url +=`&forActors=Organisation`;
    }


    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel('New order invoice request initiated');
    }

    this.cancelTokenSource = axios.CancelToken.source();

    const response = await this.client.get(url, {
      cancelToken: this.cancelTokenSource.token,
    });

    if (response.status === 200) {
      return response.data as GetOrderInvoiceViewModel;
    }

    throw new Error(`OrderInvoiceService: get resulted in ${response.status}: ${response.statusText}`);
  }
}
