import { select, SelectEffect } from 'redux-saga/effects';
import { HomeExtensionRootState } from '../../../';
import {
  FrameProduct,
  IsFrameProduct,
  Measurements,
  Product,
  ProductType
} from '@hec/models';
import { GetTotalSurfaceSize, GetTotalDrawableObjectSurface } from '@hec/utils';

export function* CalculateCladdingPrice(product: Product, selectedFrame?: FrameProduct, excludeFrameWithCalculation = false): Generator<SelectEffect, Product> {
  const measurements = yield select(
    (state: HomeExtensionRootState) => state.homeConfigurationReducer.measurements);
  const products = yield select(
    (state: HomeExtensionRootState) => state.homeConfigurationReducer.selectedProducts);

  let frame: FrameProduct|undefined = selectedFrame;
  if (!selectedFrame) {
    frame = (products as Product[]).find(IsFrameProduct);
  }
  const recalculatedProduct = {
    ...product,
  };
  if (product.productType === ProductType.CLADDING) {
    // (Side wall depth x Side wall height: 3m x 2) + (Front wall width x Front wall height 3m) - (window size) x extra cost
    const claddingProduct: Product = product;
    const sizeInSquareMeters = GetTotalSurfaceSize(measurements as Measurements);
    recalculatedProduct.priceExVat = sizeInSquareMeters * claddingProduct.priceExVat;
    if (frame && excludeFrameWithCalculation === true) {
      const sizeWithoutFrame = sizeInSquareMeters - GetTotalDrawableObjectSurface(frame.drawableObject);
      recalculatedProduct.priceExVat = sizeWithoutFrame * claddingProduct.priceExVat;
    }
  }
  return recalculatedProduct;
}
