import { CladdingProduct } from '@hec/models';

export const CALCULATE_CLADDING_PRICE = 'CALCULATE_CLADDING_PRICE';
export const REDUCER_CALCULATE_CLADDING_PRICE = 'REDUCER_CALCULATE_CLADDING_PRICE';

export const calculateCladdingPrice = (product: CladdingProduct) =>
  (
    {
      type: CALCULATE_CLADDING_PRICE,
      data: { product }
    }
  );
