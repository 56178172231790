import React, {FunctionComponent} from "react";
import sharedStyles from "../_shared-styles.module.scss";
import {FieldMetaProps} from "formik";

interface ErrorDisplayProps {
  meta: FieldMetaProps<any>;
}

export const ErrorDisplay: FunctionComponent<ErrorDisplayProps> = ({ meta }) => {
  if (meta.touched && meta.error) {
    return <div className={sharedStyles.error}>{meta.error}</div>;
  } else {
    return <></>;
  }
};
