import React, { useRef, useEffect, ReactNode } from "react";

function useOutsideAlerter(ref: React.RefObject<HTMLElement>, callback: () => void): void {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    }
    // Bind the event listener
    document.addEventListener("click", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref]);
}

interface OutsideAlerterProps {
  children: ReactNode;
  callback: () => void;
}

export const OutsideAlerter: React.FC<OutsideAlerterProps> = ({ children, callback }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(wrapperRef, callback);

  return <div ref={wrapperRef}>{children}</div>;
};

