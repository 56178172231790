import React, {ReactNode} from "react";
import {connect} from "react-redux";
import {LoggingState, storeErrorAction, StoreErrorActionProps} from "@hec/dal";
import {Dispatch} from "redux";

interface Props {
  children: ReactNode;
  dispatchMyAction: (payload: StoreErrorActionProps) => { payload: StoreErrorActionProps; type: string };
}
interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  // React life cycle method. If any error occurs during rendering phase, we update this state.
  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  // React life cycle method. This function gets invoked in commit phase (after rendering phase).
  componentDidCatch(error: any, errorInfo: any) {
    this.props.dispatchMyAction({error: error});
  }

  render() {
    // Optional, display a fallback on error
    // if (this.state.hasError) {
    //   return <>Oh no! Epic fail!</>
    // }

    return this.props.children;
  }
}


// This code allows us to bind the Redux store to a class based component. Allowing the component to dispatch actions.
const mapStateToProps = (state: LoggingState) => {
  return {
    // Map state to props if needed
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    dispatchMyAction: (payload: StoreErrorActionProps) => dispatch(storeErrorAction(payload)),
  };
};

export const ConnectedErrorBoundary = connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
