import { createSlice } from '@reduxjs/toolkit';
import { getOrderInvoiceSucceededAction } from './Actions';
import { orderInvoiceInitialState } from './State';
import {getOrderInvoicesSucceededReducer,} from './Reducers';

export const orderInvoiceSlice = createSlice({
  name: 'orderInvoices',
  initialState: orderInvoiceInitialState,
  reducers:{},
  extraReducers: (builder) => {
    builder
      .addCase(getOrderInvoiceSucceededAction, getOrderInvoicesSucceededReducer)
  },
});
