import {
  AppConfiguration,
  ApplicationService,
  RequestQuotationDto,
} from '@hec/models';
import axios, { AxiosInstance } from 'axios';
import { BaseService } from './BaseService';

export class QuotationService
  extends BaseService
  implements ApplicationService
{
  private client: AxiosInstance;
  public static override serviceName = Symbol('SERVICES.QUOTATION_SERVICE');

  constructor() {
    super();
    this.client = axios.create({});
  }

  configure(configuration: AppConfiguration) {
    this.checkConfiguration(configuration);

    this.client = axios.create({
      baseURL: `${configuration.baseUrl}/api`,
    });

    this.client.interceptors.request.use(async (config) => {
      config.headers = {
        'X-API-TOKEN': configuration.configurationId ?? '',
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };
      return config;
    });
  }

  getName(): symbol {
    return QuotationService.serviceName;
  }

  static getName(): symbol {
    return QuotationService.serviceName;
  }

  async requestQuotation(quotationRequest: RequestQuotationDto) {
    await this.client.post('leads', quotationRequest);
  }
}
