import React, {
  createContext,
  FunctionComponent,
  RefObject,
  useContext,
  useEffect, useRef, useState
} from 'react';
import { Matrix4, Mesh, Plane } from 'three';
import { useThree } from '@react-three/fiber';

export interface ClippingContextInterface {
  clippingPlanes: Plane[];
}

// This wait time is suitable for most cases. If we run into less equiped devices we can adjust
// this to accomodate.
export const RENDER_WAIT_TIME = 100;
export const ClippingContext = createContext<ClippingContextInterface>({ clippingPlanes: [] });

export interface ClippedGeometryProps {
  children: (ref:  RefObject<Mesh>, clippingPlanes: Plane[]) => JSX.Element;
  showHelpers?: boolean;
}

const redoClipping = (clippingPlanes: Plane[], matrixWorld: Matrix4) => {
  clippingPlanes.forEach((clipPlane: Plane) => {
    clipPlane.applyMatrix4(matrixWorld);
  });
}

export const ClippedGeometry: FunctionComponent<ClippedGeometryProps> = ({ showHelpers = false, children }) => {
  const ref = useRef<Mesh>(null);
  const { gl } = useThree();
  const { clippingPlanes } = useContext(ClippingContext);
  const [previousMatrixWorld, setPreviousMatrixWorld] = useState<Matrix4 | null>(null);

  gl.localClippingEnabled = true;

  useEffect(
    () => {
      if (!previousMatrixWorld) { return; }

      const timeout = setTimeout(() => {
        if (ref.current) {
          redoClipping(clippingPlanes, ref.current?.matrixWorld);
        }
      }, RENDER_WAIT_TIME);

      return () => {
        window.clearTimeout(timeout);
      }
    },
    [previousMatrixWorld, clippingPlanes]
  );

  useEffect(
    () => {
      if (ref.current) {
        setPreviousMatrixWorld(ref.current?.matrixWorld);
      }
    },
    [],
  );

  return (
    <>
      {children(ref, clippingPlanes)}
      {showHelpers && clippingPlanes.map((plane) => {

        return <planeHelper args={[plane, 1, 0xffff00]} size={15} />
      })}
    </>
  )
}
