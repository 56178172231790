import {FunctionComponent} from "react";
import {MeshStandardMaterialProps, useLoader} from "@react-three/fiber";
import {TextureLoader, Vector2} from "three";
import {WrappingUtility} from "@hec/components/v2";


export enum AttachSide {
  west = "material-1",
  east = "material-0",
  up = "material-2",
  down = "material-3",
  north = "material-5",
  south = "material-4",
}

interface CladdingGlobalMaterialProps {
  width?: number,
  height?: number,
  offSet?: Vector2 | null,
  attachSide?: AttachSide | null,
}


export const CladdingGlobalMaterial: FunctionComponent<CladdingGlobalMaterialProps> = ({attachSide = null, offSet = null, width = 1, height = 1} :CladdingGlobalMaterialProps) => {

  const imageUrl = 'https://cdn.aanbouwconfigurator.com/static-assets/generic/cladding/synthetic/synth_rabbat_vertical_anthracite/Texture.jpg';
  const textureMap = useLoader(TextureLoader, imageUrl);

  const wrapped = WrappingUtility.wrapTexture(textureMap, width, height, offSet)

  let materialPropsDefault: MeshStandardMaterialProps = {
    map: wrapped
  };

  if (attachSide != null) {
    materialPropsDefault = {
      ...materialPropsDefault,
      attach: attachSide.toString()
    }
  }

  return <meshStandardMaterial
    {...materialPropsDefault}
  />
}


export const AnthraciteGlobalMaterial: FunctionComponent = () => {
  return <meshStandardMaterial
    color={'#3c3c3c'} // Anthracite gray with a hint of green
    roughness={0.5}   // Moderate roughness for a realistic finish
    metalness={0.2}
  />
}
