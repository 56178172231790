import { AnyAction } from 'redux';
import { call } from 'redux-saga/effects';
import { ProductType } from '@hec/models';
import {
  SelectCladdingHandler,
  SelectFrameHandler,
  SelectRoofTrimHandler,
  SelectRoofOverhangHandler,
  SelectRoofLightHandler,
  SelectFrameHeaderHandler
} from './Product';
import { SELECT_PRODUCT } from '../../../';
import { SelectDrainPipeHandler } from './Product/SelectDrainPipeHandler';

export function* SelectedProductsHandler(action: AnyAction) {
  for (const product of action.data.products) {
    const { productType } = product;
    const action = { type: SELECT_PRODUCT, data: { product } };
    switch (productType) {
      case ProductType.FRAME:
        yield call(SelectFrameHandler, action);
        break;
      case ProductType.CLADDING:
        yield call(SelectCladdingHandler, action);
        break;
      case ProductType.ROOF_OVERHANG:
        yield call(SelectRoofOverhangHandler, action);
        break;
      case ProductType.ROOF_TRIM:
        yield call(SelectRoofTrimHandler, action);
        break;
      case ProductType.ROOF_LIGHT:
        yield call(SelectRoofLightHandler, action);
        break;
      case ProductType.FRAME_HEADER:
        yield call(SelectFrameHeaderHandler, action);
        break;
      case ProductType.DRAIN_PIPE:
        yield call(SelectDrainPipeHandler, action);
        break;
    }
  }
}
