import React, {FunctionComponent, useEffect} from 'react';
import {DormerModalContentKey, TranslationKeys} from '@hec/models';
import {useTranslation} from 'react-i18next';
import {Conditional, ConfirmationDialog, HelpModal, InvoiceList, RequestQuotationForm} from "@hec/components/v2";
import {useMatomo} from '@jonkoops/matomo-tracker-react';
import {useDispatch, useSelector} from "react-redux";
import {
  ConfirmPromptModalData,
  getClientInformationSelector,
  getOrderInvoiceAction,
  getOrderInvoiceSelector, getOrderIsDirtySelector, getOrderSelector, storeUiStatusAction,
  UiStatusState
} from "@hec/dal";
import {ExtraInformationForKeyRenderer} from "../components";

export interface ModalContentFactoryProps {
  modalKey: DormerModalContentKey;
  uiStatus: UiStatusState;
}

export const ModalContentFactory: FunctionComponent<ModalContentFactoryProps> = ({modalKey, uiStatus}) => {
  const {trackPageView} = useMatomo();
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const clientInformation = useSelector(getClientInformationSelector);
  const orderIsDirty = useSelector(getOrderIsDirtySelector);

  const orderInvoice = useSelector(getOrderInvoiceSelector);
  if (orderInvoice == null) {
    dispatch(getOrderInvoiceAction());
  }

  useEffect(() => {
    trackPageView({
      documentTitle: `modal.${modalKey}`,
      href: window.document.location.href,
    });
  }, [modalKey]);

  if (clientInformation === null) {
    return <></>;
  }

  return (
    <>
      <Conditional condition={modalKey === DormerModalContentKey.HELP}>
        <HelpModal
          title={t(TranslationKeys.modals.helpModal.title)}
          clientInformation={clientInformation}
        />
      </Conditional>
      <Conditional condition={modalKey === DormerModalContentKey.REQUEST_QUOTATION}>
        <RequestQuotationForm/>
        <ExtraInformationForKeyRenderer
          padding={'0px'}
          extraInformationKey={'RequestInvoiceExtraInformation'}
        />
      </Conditional>
      <Conditional condition={modalKey === DormerModalContentKey.COST_SUMMARY && orderInvoice != null}>
        <ExtraInformationForKeyRenderer
          extraInformationKey={'CostsSummaryTop'}
        />
        <InvoiceList orderIsDirty={orderIsDirty} invoiceModel={orderInvoice!}/>
        <ExtraInformationForKeyRenderer
          extraInformationKey={'CostsSummaryBottom'}
        />
      </Conditional>
      <Conditional condition={modalKey === DormerModalContentKey.CONFIRMATION_PROMPT && uiStatus.modalData != null}>
        <ConfirmationDialog
          confirmPromptModalData={uiStatus.modalData as ConfirmPromptModalData}
          closeModal={() => {
            dispatch(storeUiStatusAction({
              modalOpen: false,
              modalContentKey: DormerModalContentKey.NONE,
              modalData: {}
            }));
          }}
        />
      </Conditional>
    </>
  );
};
