import React, {FunctionComponent} from "react";
import styles from './summary.module.scss';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
  getClientInformationSelector,
  storeUiStatusAction,
  getOrderInvoiceSelector, getIsOrganisationSelector
} from "@hec/dal";
import {Conditional, SidebarFooter} from "@hec/components/v2";
import {RoutePaths} from "../../routing";
import {DormerModalContentKey, TranslationKeys} from "@hec/models";
import {InvoiceList} from "@hec/components/v2";
import {ExtraInformationForKeyRenderer} from "../../components";

export const SummarySideBarContent: FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const orderInvoice = useSelector(getOrderInvoiceSelector);
  const clientInformation = useSelector(getClientInformationSelector);
  const isOrganisation = useSelector(getIsOrganisationSelector);

  if (clientInformation === null) {
    return <></>;
  }

  return (
    <>
      <div className={styles.sections}>
        <ExtraInformationForKeyRenderer
          extraInformationKey={'SummaryStepExtraInformation'}
        />
        <Conditional condition={!!orderInvoice}>
          <ExtraInformationForKeyRenderer
            extraInformationKey={'CostsSummaryTop'}
          />
          <InvoiceList invoiceModel={orderInvoice!} orderIsDirty={false} />
          <ExtraInformationForKeyRenderer
            extraInformationKey={'CostsSummaryBottom'}
          />
        </Conditional>

      </div>

      <SidebarFooter
        // prevRoutePath={RoutePaths.FinishPath}
        prevRoutePath={isOrganisation ? RoutePaths.TechnicalPath : RoutePaths.CustomerSurveyPath}
        clientInformation={clientInformation}
        nextRoutePath={RoutePaths.SummaryPath}
        nextRouteCTA={t(TranslationKeys.dormer.requestQuotation)}
        onGoNext={() => {
          dispatch(storeUiStatusAction({modalOpen: true, modalContentKey: DormerModalContentKey.REQUEST_QUOTATION, modalData: {}}));
        }}
      />
    </>
  );
};
