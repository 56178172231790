import { createSlice } from '@reduxjs/toolkit';
import {loggingInitialState} from './State';
import {
  storeCriticalErrorAction,
  storeErrorAction,
  storeInfoMessageAction,
  storeWarningAction,
  updateLoggingSettingsAction
} from "./Actions";
import {
  storeCriticalErrorReducer,
  storeErrorReducer,
  storeInfoMessageReducer,
  storeWarningReducer,
  updateLogSettingsReducer
} from "./Reducers";

export const loggingSlice = createSlice({
  name: 'logging',
  initialState: loggingInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(storeInfoMessageAction, storeInfoMessageReducer)
      .addCase(storeWarningAction, storeWarningReducer)
      .addCase(storeErrorAction, storeErrorReducer)
      .addCase(storeCriticalErrorAction, storeCriticalErrorReducer)
      .addCase(updateLoggingSettingsAction, updateLogSettingsReducer);
  },
});
