import {DormerMaterialViewModel} from "@hec/api-dtos";

export interface DormerMaterialsState {
  dormerMaterials: DormerMaterialViewModel[];
  selectedDormerMaterialViewModel: DormerMaterialViewModel | null;
}

export const dormerMaterialsInitialState: DormerMaterialsState = {
  dormerMaterials: [],
  selectedDormerMaterialViewModel: null,
}
