import {
  HomeConfigurationState,
  PriceCalculationStrategy,
  Product,
  ProductType,
  RoofTrimProduct,
} from '../';

export const RoofTrimProductPriceCalculationStrategy: PriceCalculationStrategy =
  (state: HomeConfigurationState) => {
    return (product: Product): Product => {
      const clone = { ...product };
      if ((product as RoofTrimProduct).productType === ProductType.ROOF_TRIM) {
        const price =
          product.priceExVat *
          (state.measurements.depth * 2 + state.measurements.width);
        clone.priceExVat = price;
      }
      return clone;
    };
  };
