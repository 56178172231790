import { Product } from "@hec/models";

export const SELECT_PRODUCTS = 'SELECT_PRODUCTS';
export const REDUCER_SELECT_PRODUCTS = 'REDUCER_SELECT_PRODUCTS';

export const selectProducts = (products: Product[]) => ({
  type: SELECT_PRODUCTS,
  data: {
    products
  }
});
