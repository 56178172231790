import React, {FunctionComponent} from "react";
import styles from './extras.module.scss';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {SidebarFooter} from "@hec/components/v2";
import {RoutePaths} from "../../routing";
import {getClientInformationSelector} from "@hec/dal";
import {ExtraInformationForKeyRenderer} from "../../components";

export const ExtrasSideBarContent: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const clientInformation = useSelector(getClientInformationSelector);

  if (clientInformation === null) {
    return <></>;
  }

  return (
    <>
      <div className={styles.sections}>
        <ExtraInformationForKeyRenderer
          extraInformationKey={'ExtrasStepExtraInformation'}
        />

      </div>
      <SidebarFooter
        prevRoutePath={RoutePaths.MaterialPath}
        nextRoutePath={RoutePaths.SummaryPath}
        // nextRouteCTA={t(TranslationKeys.dormer.pages.templatePage.nextStep, 'Volgende stap: samenvatting')}
        clientInformation={clientInformation}
      />
    </>
  );
};
