import { ProductType } from './ProductType';
import { DrawableObject, DrawableObjectWithSpecification } from './DrawableObject';
import { TextureMaterial, TextureMaterialWithSpecification } from './TextureMaterial';

export enum ProductionMaterialType {
  UNKNOWN = 'Unknown',
  WOOD = 'Wood',
  SYNTHETIC = 'Synthetic',
  STONE = 'Stone',
  STEEL = 'Steel',
  CONCRETE = 'Concrete',
  PLASTER = 'Plaster',
  TRESPA = 'Trespa'
}

export interface ProductWithDepthAndWidthThreshold extends Product {
  minWidth: number;
  maxWidth: number;
  minDepth: number;
  maxDepth: number;
}

export interface ProductWithDrawableObjectWithSpecification<T> extends Product, ProductWithDepthAndWidthThreshold {
  drawableObject: DrawableObjectWithSpecification<T>
}

export interface ProductWithTextureMaterialWithSpecification<T> extends Product, ProductWithDepthAndWidthThreshold {
  textureMaterial: TextureMaterialWithSpecification<T>
}

export interface Product {
  id: string;
  name: string;
  description: string;
  translationKey: string;
  priceExVat: number;
  productType: ProductType;
  drawableObject?: DrawableObject;
  drawableObjectId?: string;
  textureMaterial?: TextureMaterial;
  productionMaterialType: ProductionMaterialType;
  imageUrl?: string;
}
