import React, {FunctionComponent} from "react";
import {DrawRectangle} from "../Utils";
import {Line} from "react-konva";
import {STROKE_WIDTH} from "../Constants";

interface PanelProps {
  x: number,
  y: number,
  width: number,
  height: number,
}

const lineGap = 100;
export const Panel: FunctionComponent<PanelProps> = ({x, y, width, height}) => {

  const numberOfLines = Math.floor(height / lineGap);
  const lines = Array.from({ length: numberOfLines }, (_, i) => i * lineGap + lineGap);

  return <>
      <Line
        key={'left'}
        x={x}
        y={y}
        points={[0, height, 0, 0]}
        stroke="black"
        strokeWidth={STROKE_WIDTH}
      />
    <Line
      key={'right'}
      x={x + width}
      y={y}
      points={[0, height, 0, 0]}
      stroke="black"
      strokeWidth={STROKE_WIDTH}
    />
  </>
}
