import { Color } from 'three';

const colors = [
  new Color('rgb(0, 0, 255)'),
  new Color('rgb(255, 0, 0)'),
  new Color('rgb(255, 255, 0)'),
  new Color('rgb(255, 102, 0)'),
  new Color('rgb(0, 255, 0)'),
  new Color('rgb(102, 0, 255)'),
  new Color('rgb(0, 0, 0)'),
];

export const getRandomColor = () =>
  colors[Math.floor(Math.random() * colors.length)];
