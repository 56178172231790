import {OrderDto} from "@hec/api-dtos";
import {DormerRootState} from "../DormerRootState";

export const getOrderSelector = (state: DormerRootState): OrderDto | null => {
  return state.order.order;
}

export const getOrderIsDirtySelector = (state: DormerRootState): boolean => {
  return state.order.isDirty ?? true;
}
