import { call, put } from 'redux-saga/effects';
import { HomeExtensionClientConfiguration, FatalErrorKey, Product } from '@hec/models';
import {
  ClientConfigurationService,
  ProductsService,
  getService,
  applyClientConfiguration,
  getProductsSuccess,
  raiseError,
  RECALCULATE_PRICE,
  resetBaseProduct,
  SetFatalErrorKey,
} from '../../..';

export function* handleClientConfiguration(configurationId: string) {
  try {
    const clientConfigurationService: ClientConfigurationService =
      yield getService(ClientConfigurationService);

    let configuration: HomeExtensionClientConfiguration | null
    try {
      configuration =
      yield call([clientConfigurationService, "getClientConfiguration"], configurationId);
    } catch (error) {

      /* eslint-disable  @typescript-eslint/no-explicit-any */
      const statusCode = (error as any).response?.status;
      if(statusCode === 404 || statusCode === 428) {
        yield put(SetFatalErrorKey(FatalErrorKey.CLIENT_CONFIGURATION_404));
      } else {
        yield put(SetFatalErrorKey(FatalErrorKey.NO_SERVER_CONNECTION));
      }
      return;
    }

    const service: ProductsService = yield getService(ProductsService);
    const products: Product[] | null =
      yield call([service, 'getProductsByType'], { includeAssets: true });

    if (products) {
      yield put(getProductsSuccess(products));
    }
    yield put(applyClientConfiguration(configuration as HomeExtensionClientConfiguration));
    yield put(resetBaseProduct());
    yield put({ type: RECALCULATE_PRICE });
  } catch (e) {
    yield put(raiseError(e as Error));
  }
}
