import React, {FunctionComponent, PropsWithChildren, useEffect, useRef} from "react";
import {GroupProps} from "@react-three/fiber";
import {Group, Material, Mesh} from "three";
import {Conditional} from "../../Conditional";
import {Text} from "@react-three/drei";

export interface GroupHelperProps extends GroupProps{
  axesLength?: number;
  showHelper?: boolean;

}

export const GroupHelper: FunctionComponent<PropsWithChildren<GroupHelperProps>> = ({position, axesLength, children, showHelper}) => {
  const groupRef = useRef<Group>(null);

  let axesArg1: number | undefined = undefined;
  let axesArg2: number | undefined = undefined;
  let axesArg3: number | undefined = undefined;
  if(position) {
    axesArg1 = (position as any)[0];
    axesArg2 = (position as any)[1];
    axesArg3 = (position as any)[2];
  }

    useEffect(() => {
      if(showHelper) {
          if (groupRef.current) {
            groupRef.current.children.forEach(c => {
              c.traverse(o => {
                if (o instanceof Mesh) {
                  const material = ((o as Mesh).material as Material);
                  material.transparent = true;
                  material.opacity = .8;
                }
              });
            });
          }
      }
    }, [groupRef]);


  return <mesh position={position}>
    <Conditional condition={showHelper}>
      <axesHelper args={[axesLength ?? .5 ]} />
      <Text
        anchorX={"left"}
        anchorY={"bottom"}
        color="red"
        fontSize={.1}
        maxWidth={200}
        lineHeight={1}
        letterSpacing={0.02}
        textAlign="center"
      >
        {axesArg1 ?? '-'}, {axesArg2 ?? '-'}, {axesArg3 ?? '-'}
      </Text>
    </Conditional>
    <group ref={groupRef}>
      {children}
    </group>
  </mesh>
}
