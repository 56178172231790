import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { Dormer2dRenderer } from "./Dormer2dRenderer";
import { TransformComponent, TransformWrapper, useControls } from "react-zoom-pan-pinch";

interface Dormer2dRendererZoomWrapperProps {
  setZoomInRef: (node: () => void) => void;
  setZoomOutRef: (node: () => void) => void;
}

export const Dormer2dRendererZoomWrapper: FunctionComponent<Dormer2dRendererZoomWrapperProps> = ({
                                                                                                   setZoomInRef,
                                                                                                   setZoomOutRef,
                                                                                                 }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [initialScale, setInitialScale] = useState<number | undefined>(undefined);

  useEffect(() => {
    const handleResize = () => {
      const container = containerRef.current;
      if (container) {
        const containerRect = container.getBoundingClientRect();
        const canvasWidth = 3840;
        const canvasHeight = 2160;

        const containerRatio = containerRect.width / containerRect.height;
        const canvasRatio = canvasWidth / canvasHeight;

        let scale;
        if (containerRatio > canvasRatio) {
          // Container is wider than canvas aspect ratio
          scale = containerRect.width / canvasWidth;
        } else {
          // Container is taller than canvas aspect ratio
          scale = containerRect.height / canvasHeight;
        }

        setInitialScale(scale);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial resize

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div ref={containerRef} style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
      {initialScale !== undefined && (
        <TransformWrapper
          initialScale={initialScale}
          minScale={0.1}
          maxScale={8}
          centerOnInit={true}
          centerZoomedOut={true}
          limitToBounds={false}
        >
          {({ zoomIn, zoomOut, resetTransform }) => (
            <>
              <ZoomControls
                zoomIn={zoomIn}
                zoomOut={zoomOut}
                resetTransform={resetTransform}
                setZoomInRef={setZoomInRef}
                setZoomOutRef={setZoomOutRef}
              />
              <TransformComponent
                wrapperStyle={{
                  width: '100%',
                  height: '100%',
                }}
              >
                <div style={{
                  width: '3840px',
                  height: '2160px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  <Dormer2dRenderer />
                </div>
              </TransformComponent>
            </>
          )}
        </TransformWrapper>
      )}
    </div>
  );
};

interface ZoomControlsProps extends Dormer2dRendererZoomWrapperProps {
  zoomIn: () => void;
  zoomOut: () => void;
  resetTransform: () => void;
}

const ZoomControls: FunctionComponent<ZoomControlsProps> = ({
                                                              setZoomInRef,
                                                              setZoomOutRef,
                                                            }) => {
  const { zoomIn, zoomOut, resetTransform } = useControls();

  const useZoomIn = () => {
    console.log('zoomIn');
    zoomIn();
  };

  const useZoomOut = () => {
    console.log('zoomOut');
    zoomOut();
  };

  useEffect(() => {
    setZoomInRef(useZoomIn);
    setZoomOutRef(useZoomOut);
  }, [useZoomIn, useZoomOut, zoomIn, zoomOut, setZoomInRef, setZoomOutRef]);

  return (
    <div className="tools">
      {/* Buttons for zoom controls can be added here */}
    </div>
  );
};
