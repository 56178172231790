import produce from 'immer';
import {getSelectableFramesSucceededAction} from './Actions';
import {SelectableFramesState} from "./State";

export const getSelectableFramesSucceededReducer = (state: SelectableFramesState, action: ReturnType<typeof getSelectableFramesSucceededAction>) => {
  return produce(state, (draft) => {
    draft.selectableFrames = action.payload.sectionFragmentsDto
      .filter(sectionFragment => sectionFragment.isUserSelectable && sectionFragment.sectionFragmentType === 7);
  });
}
