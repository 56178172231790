import {AppConfiguration, ApplicationService, SurveyQuestionViewModel} from "@hec/models";
import axios, {AxiosInstance} from "axios";
import {BaseDalService} from "@hec/core";

export class SurveyQuestionService extends BaseDalService implements ApplicationService {

  private client: AxiosInstance;
  public static override serviceName = Symbol('SurveyQuestionService');

  constructor() {
    super();
    this.client = axios.create({});
  }

  override configure(configuration: AppConfiguration) {
    this.client = axios.create({
      baseURL: `${configuration.baseUrl}/api`,
    });

    this.useDateTimeConverters(this.client);
    this.useEnumConverters(this.client);

    this.useBearerAuthentication(this.client, configuration);
  }


  getName(): symbol {
    return SurveyQuestionService.serviceName;
  }

  static override getName(): symbol {
    return SurveyQuestionService.serviceName;
  }

  async get(configurationTemplateId: string, configurationCode: string): Promise<SurveyQuestionViewModel[]> {
    this.configureAuthInterceptor(this.client, configurationCode);

    const response = await this.client.get(`/ConfigurationTemplates/${configurationTemplateId}/SurveyQuestions`);


    if (response.status === 200) {
      return response.data as SurveyQuestionViewModel[];
    }

    throw new Error(`SurveyQuestionService: get resulted in ${response.status}: ${response.statusText}`);
  }
}
