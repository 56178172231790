import { HomeExtensionApplicationState, HomeConfigurationState, TranslationKeys } from '@hec/models';
import i18next from 'i18next';
import { toast } from 'react-toastify';
import { AnyAction } from 'redux';
import { call, select } from 'redux-saga/effects';

function notifySuccess() {
  return new Promise<void>(() => {
    toast.success(i18next.t(TranslationKeys.notifications.quotationEmailSent) as string);
  });
}
export function* RequestQuotationSuccessHandler(action: AnyAction) {
  const currentState: HomeConfigurationState = yield select(
  (state: HomeExtensionApplicationState) => state.homeConfigurationReducer
  );

  const useLeadPostedSuccessRedirectUrl = action.data as boolean;

  const redirectUrl = currentState.clientConfiguration.leadPostedSuccessRedirectUrl;
  if(redirectUrl && useLeadPostedSuccessRedirectUrl) {
    window.location.href = redirectUrl;
  } else {
    yield call(notifySuccess);
  }
}
