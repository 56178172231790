import React, { FunctionComponent } from 'react';
import { Shape } from 'three';

export interface WedgeGeometryProps {
  width: number;
  height: number;
  depth: number;
}

export const WedgeGeometry: FunctionComponent<WedgeGeometryProps> = ({ width, height, depth }) => {
  const triangleShape = new Shape();
  triangleShape.moveTo(  0, 0 );
  triangleShape.lineTo(  width, 0 );
  triangleShape.lineTo( 0, height );
  triangleShape.lineTo(  0, 0 );
  const extrusionSettings = {
    depth,
    bevelEnabled: false,
  };
  return (
    <extrudeBufferGeometry args={[triangleShape, extrusionSettings]} />
  );
};
