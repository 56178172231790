import { combineReducers } from '@reduxjs/toolkit';
import {
  notificationSlice,
  getServiceSlice,
  requiredClientInformationServices,
  clientInformationSlice,
  uiStatusSlice,
  dormerMaterialsSlice,
  orderSlice,
  selectableFramesSlice,
  requiredDormerMaterialServices,
  requiredSelectableFramesServices,
  requiredOrderServices,
  orderInvoiceSlice,
  requiredOrderInvoiceServices,
  surveyQuestionSlice,
  requiredSurveyQuestionServices
} from "@hec/dal";
import {
  configurationTemplateSlice,
  requiredConfigurationTemplateServices
} from "@hec/dal";
import {AppConfiguration, ApplicationService} from "@hec/models";
import {loggingSlice} from "@hec/dal";
import {DormerGeolocationService} from "@hec/core";

const _requiredServiceSlices : ApplicationService[] = [
  ...requiredClientInformationServices(),
  ...requiredConfigurationTemplateServices(),
  ...requiredDormerMaterialServices(),
  ...requiredSelectableFramesServices(),
  ...requiredOrderServices(),
  ...requiredOrderInvoiceServices(),
  ...requiredSurveyQuestionServices(),
  new DormerGeolocationService(),
];

const getInitialServices = (configuration: AppConfiguration): ApplicationService[] => {
  const allSymbolStrings = _requiredServiceSlices.map(x => x.getName().toString());

  const uniqueSymbolStrings =  [...new Set(allSymbolStrings)];

  const result: ApplicationService[] = [];


  uniqueSymbolStrings.forEach((ss) => {
    const symbolStringInstance = _requiredServiceSlices.find(bds=> bds.getName().toString() === ss);
    if(symbolStringInstance) {
      symbolStringInstance.configure(configuration);
      result.push(symbolStringInstance);
    } else {
      throw new Error(`COULDN'T CONFIGURE SERVICE WITH SYMBOL NAME ${ss}`);
    }
  });

  return result;
}

export const DormerPizza = (configuration: AppConfiguration) => combineReducers(
  {
    clientInformation: clientInformationSlice.reducer,
    configurationTemplates: configurationTemplateSlice.reducer,
    dormerMaterials: dormerMaterialsSlice.reducer,
    selectableFrames: selectableFramesSlice.reducer,
    logging: loggingSlice.reducer,
    notifications: notificationSlice.reducer,
    order: orderSlice.reducer,
    services: getServiceSlice(getInitialServices(configuration), configuration).reducer,
    uiStatus: uiStatusSlice.reducer,
    orderInvoice: orderInvoiceSlice.reducer,
    surveyQuestions: surveyQuestionSlice.reducer,
  },
);
