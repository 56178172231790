import {OrbitControls, useHelper} from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import React, {FunctionComponent, useRef} from 'react';
import {DirectionalLight, DirectionalLightHelper} from "three";

interface CameraProps {
  dolly?: boolean;
  zoom?: number;
  isDevelopmentBuild?: boolean
  offSet?: { x: number, y: number, z: number}
}

export const DEFAULT_ZOOM = 3;
const azimuthAngle = Math.PI / 2;
const maxPolar = Math.PI / 2 - Math.PI / 360;


export const CameraRevised: FunctionComponent<CameraProps> = ({
  zoom = DEFAULT_ZOOM,
  dolly = true,
  isDevelopmentBuild = false,
                                                                offSet,
                                                       }) => {
  const { camera } = useThree();
  const minDistance = 0;

  const dirLight = useRef<DirectionalLight>(null);

  useHelper(isDevelopmentBuild ? dirLight : null, DirectionalLightHelper, 1, "red");


  return (
    <group>
      {/*<ambientLight intensity={2}/>*/}
      <ambientLight intensity={0.25}/>
      <directionalLight
        ref={dirLight}
        castShadow
        position={[5, 5, 5]}
        intensity={2.9}

        // This fixes shadows on touching meshes: https://discourse.threejs.org/t/pixel-shadows-between-adjacent-meshes/41951/3
        shadow-bias={-0.00006}
      />
      <OrbitControls
        enableZoom={true}
        enableRotate={true}
        enableDamping={true}
        maxDistance={25}
        minDistance={minDistance}
        // target={target}
        camera={camera}
        maxPolarAngle={isDevelopmentBuild ? undefined : maxPolar}
        minAzimuthAngle={isDevelopmentBuild ? undefined : -azimuthAngle}
        maxAzimuthAngle={isDevelopmentBuild ? undefined : azimuthAngle}
        enablePan={isDevelopmentBuild}
        target={offSet != null ? [offSet.x, offSet.y, offSet.z] : [0, 0, 0]}
      />
    </group>
  );
};
