import { RECALCULATE_PRICE, REDUCER_SELECT_PRODUCT } from '../../../../';
import { AnyAction } from 'redux';
import { put } from 'redux-saga/effects';

export function* SelectFaucetHandler(action: AnyAction) {
  yield put({
    type: REDUCER_SELECT_PRODUCT,
    data: { product: action.data.product },
  });
  yield put({ type: RECALCULATE_PRICE });
}
