import { composeWithDevTools } from 'redux-devtools-extension';
import { AppConfiguration, HomeExtensionApplicationState } from '@hec/models';
import { INITIALIZE_APPLICATION, UPDATE_APP_CONFIGURATION } from '../';
import { AnyAction } from 'redux';

export const DevtoolsEnhancer = composeWithDevTools({
  predicate: (state, action) => {
    let configuration: AppConfiguration;
    if (
      action.type === INITIALIZE_APPLICATION ||
      action.type === UPDATE_APP_CONFIGURATION
    ) {
      configuration = (action as AnyAction).data;
    } else {
      configuration = (state as unknown as HomeExtensionApplicationState)
        .applicationStateReducer.configuration;
    }
    return configuration.logging?.enableReduxDevtools || false;
  },
});
