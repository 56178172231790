export enum DefaultProductSelectionId {
  RoofOverhang = 'roof-overhang-1',
  RoofLight = 'roof-light-1',
  FrameHeader = 'frame-header-1',
  Faucet = 'faucet-1',
}


export const DEFAULT_OVERHANG_SIZE = .2;
export const DEFAULT_OVERHANG_SIDE_SIZE = .025;
