import { Product } from '@hec/models';

export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const REDUCER_GET_PRODUCTS_SUCCESS = 'REDUCER_GET_PRODUCTS_SUCCESS';

export const getProductsSuccess = (products: Product[]) => ({
  type: GET_PRODUCTS_SUCCESS,
  data: {
    products
  }
});

