import React, {FunctionComponent, InputHTMLAttributes} from "react";
import sharedStyles from "../_shared-styles.module.scss";
import styles from './label.module.scss';
import classNames from "classnames";
interface LabelProps extends InputHTMLAttributes<HTMLInputElement>  {
  required?: boolean;
  label: string;
  id?: string;
  name?: string;
  className?: string
}

export const Label: FunctionComponent<LabelProps> = ({id, name, label, required, className }) => {
  const classNamesResult = className ? classNames(styles.label, className) : styles.label;

  return (
    <label className={classNamesResult} htmlFor={id || name}>
      {label}

      {required && <span className={sharedStyles.required}>*</span>}
    </label>
  );
};
