import {BaseDalService} from "@hec/core";
import {AppConfiguration, ApplicationService} from "@hec/models";
import axios, {AxiosInstance} from "axios";
import {CustomerInformationDto, PutCustomerInformationViewModel} from "@hec/api-dtos";

export class PutCustomerInformationService extends BaseDalService implements ApplicationService {

  private client: AxiosInstance;
  public static override serviceName = Symbol('PutCustomerInformationService');

  constructor() {
    super();
    this.client = axios.create({});
  }

  override configure(configuration: AppConfiguration) {
    this.client = axios.create({
      baseURL: `${configuration.baseUrl}/api/`,
    });

    this.useDateTimeConverters(this.client);
    this.useEnumConverters(this.client);
  }


  getName(): symbol {
    return PutCustomerInformationService.serviceName;
  }

  static override getName(): symbol {
    return PutCustomerInformationService.serviceName;
  }

  async putCustomerInformation(customerInformation: PutCustomerInformationViewModel, orderConfigurationCode: string): Promise<CustomerInformationDto> {
    this.configureAuthInterceptor(this.client, orderConfigurationCode);

    const response = await this.client.put('CustomerInformation/', customerInformation);


    if (response.status === 200) {
      return response.data as CustomerInformationDto;
    }

    throw new Error(`PutCustomerInformationService: put resulted in ${response.status}: ${response.statusText}`);
  }
}
