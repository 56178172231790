import {SurveyAnswerViewModel, SurveyQuestionViewModel} from "@hec/models";
import {createAction} from "@reduxjs/toolkit";

interface GetSurveyQuestionsActionProps {
  configurationTemplateId: string;
}
export const getSurveyQuestionsAction = createAction<GetSurveyQuestionsActionProps>('GetSurveyQuestionsActionProps');


interface GetSurveyQuestionsSucceededActionProps {
  surveyQuestionViewModels: SurveyQuestionViewModel[];
}
export const getSurveyQuestionsSucceededAction = createAction<GetSurveyQuestionsSucceededActionProps>('GetSurveyQuestionsSucceededActionProps');



export interface PutSurveyAnswersActionProps {
  orderId: string;
  surveyAnswerViewModels: SurveyAnswerViewModel[];
}
export const putSurveyAnswersAction = createAction<PutSurveyAnswersActionProps>('PutSurveyAnswersAction');


export const holdSurveyAnswersAction = createAction<PutSurveyAnswersActionProps>('holdSurveyAnswersAction');


interface PutSurveyAnswersSucceededActionProps {
  surveyAnswerViewModels: SurveyAnswerViewModel[];
}
export const putSurveyAnswersSucceededAction = createAction<PutSurveyAnswersSucceededActionProps>('PutSurveyAnswersSucceededActionProps');
