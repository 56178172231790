import {
  getConfigurationTemplatesSucceededAction,
  selectConfigurationTemplateAction,
  getConfigurationTemplatesAction,
  ConfigurationTemplateService, selectConfigurationTemplateSucceededAction, getConfigurationTemplatesSelector
} from './index';

import {call, put, select, takeLatest} from 'redux-saga/effects';
import {getApplicationService, getClientInformationSelector, storeErrorAction} from "../../";
import {ClientInformationViewModel, ConfigurationTemplateDto} from "@hec/api-dtos";

function* handleGetConfigurationTemplates() {
  const service: ConfigurationTemplateService = yield getApplicationService(ConfigurationTemplateService);

  const clientInformation: ClientInformationViewModel = yield select(getClientInformationSelector);

  try {
    if (!clientInformation.organisationId) {
      yield put(storeErrorAction({
        error: new Error('No organisation id found')
      }));
      return;
    }

    const organisationId = clientInformation.organisationId;
    const result: ConfigurationTemplateDto[] = yield call([service, 'get'], organisationId);
    yield put(getConfigurationTemplatesSucceededAction({ configurationTemplates: result}));
  } catch (error) {
    yield put(storeErrorAction({error: error}));
  }
}

function* handleSelectConfigurationTemplate(action: ReturnType<typeof selectConfigurationTemplateAction>) {
  const configurationTemplates: ConfigurationTemplateDto[] = yield select(getConfigurationTemplatesSelector);
  const { configurationTemplateId } = action.payload;

  const itemToSave = configurationTemplates.find(x => x.id == configurationTemplateId);
  if (itemToSave) {
    yield put(selectConfigurationTemplateSucceededAction({configurationTemplate: itemToSave}));
  }
}

export function* ConfigurationTemplatesSagas() {
  yield takeLatest(getConfigurationTemplatesAction, handleGetConfigurationTemplates);
  yield takeLatest(selectConfigurationTemplateAction, handleSelectConfigurationTemplate);
}
