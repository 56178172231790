import React, { FunctionComponent } from 'react';
import { HomeExtensionDto } from '../../HomeExtensionDto';
import { Texture, TextureLoader, Vector3 } from 'three';
import {
  DEFAULT_OVERHANG_SIDE_SIZE,
  DEFAULT_ROOF_EDGE_HEIGHT,
  DEFAULT_WALL_THICKNESS
} from "@hec/components/v1";
import { DEFAULT_OVERHANG_SIZE, IsRoofOverhangProduct, RoofOverhangProduct, RoofOverhangStyle } from '@hec/models';
import { useLoader } from '@react-three/fiber';

export interface RoofOverhangProps {
  homeExtensionDto: HomeExtensionDto;
  roofTexture: Texture;
}

export const RoofOverhang: FunctionComponent<RoofOverhangProps> = ({ homeExtensionDto, roofTexture }) => {
  const [widthInMeters, heightInMeters, depthInMeters] = homeExtensionDto.getMeasurements().toArray();
  const roofOverhangProduct = homeExtensionDto.getExtraByType(IsRoofOverhangProduct) as RoofOverhangProduct|undefined;
  const overhangSize = (roofOverhangProduct && roofOverhangProduct.style === RoofOverhangStyle.FRONT_ONLY) ?
    DEFAULT_OVERHANG_SIZE :
    0;
  const wallThickness = DEFAULT_WALL_THICKNESS + overhangSize;

  const roofOverhangTexture =
    (
      roofOverhangProduct &&
      roofOverhangProduct.textureMaterial &&
      roofOverhangProduct.textureMaterial.textureUrl

    ) ?
      useLoader(TextureLoader, roofOverhangProduct.textureMaterial.textureUrl) :
      null;
  const height = DEFAULT_ROOF_EDGE_HEIGHT + DEFAULT_OVERHANG_SIZE;
  const sideWidth = DEFAULT_WALL_THICKNESS + DEFAULT_OVERHANG_SIDE_SIZE;
  return (
    <>
      <group position={new Vector3(((widthInMeters + (overhangSize * 2)) / 2) - overhangSize, (heightInMeters + DEFAULT_ROOF_EDGE_HEIGHT) - (height / 2), (depthInMeters + overhangSize / 2))}>
        <mesh>
          <boxGeometry args={[widthInMeters + 0.05, height, overhangSize]} />
          <meshStandardMaterial
            map={roofOverhangTexture}
            displacementScale={0}
            clipIntersection={true}
            attach="material-0"
          />
          <meshStandardMaterial
            map={roofOverhangTexture}
            displacementScale={0}
            clipIntersection={true}
            attach="material-1"
          />
          <meshStandardMaterial
            map={roofTexture}
            displacementScale={0}
            clipIntersection={true}
            attach="material-2"
          />
          <meshStandardMaterial
            map={roofOverhangTexture}
            displacementScale={0}
            clipIntersection={true}
            attach="material-3"
          />
          <meshStandardMaterial
            map={roofOverhangTexture}
            displacementScale={0}
            clipIntersection={true}
            attach="material-4"
          />
          <meshStandardMaterial
            map={roofTexture}
            displacementScale={0}
            clipIntersection={true}
            attach="material-5"
          />
          <meshStandardMaterial
            map={roofOverhangTexture}
            displacementScale={0}
            clipIntersection={true}
            attach="material-6"
          />
        </mesh>
      </group>
      <group position={new Vector3((widthInMeters + 0.0125) - DEFAULT_WALL_THICKNESS / 2, (heightInMeters + DEFAULT_ROOF_EDGE_HEIGHT) - (height / 2), (depthInMeters / 2))}>
        <mesh>
          <boxGeometry args={[sideWidth, height, depthInMeters]} />
          <meshStandardMaterial
            map={roofOverhangTexture}
            displacementScale={0}
            clipIntersection={true}
            attach="material-0"
          />
          <meshStandardMaterial
            map={roofTexture}
            displacementScale={0}
            clipIntersection={true}
            attach="material-1"
          />
          <meshStandardMaterial
            map={roofTexture}
            displacementScale={0}
            clipIntersection={true}
            attach="material-2"
          />
          <meshStandardMaterial
            map={roofOverhangTexture}
            displacementScale={0}
            clipIntersection={true}
            attach="material-3"
          />
          <meshStandardMaterial
            map={roofOverhangTexture}
            displacementScale={0}
            clipIntersection={true}
            attach="material-4"
          />
          <meshStandardMaterial
            map={roofOverhangTexture}
            displacementScale={0}
            clipIntersection={true}
            attach="material-5"
          />
          <meshStandardMaterial
            map={roofOverhangTexture}
            displacementScale={0}
            clipIntersection={true}
            attach="material-6"
          />
        </mesh>
      </group>
      {/* West splash protection */}
      <group position={new Vector3(-0.0125 + (DEFAULT_WALL_THICKNESS / 2), (heightInMeters + DEFAULT_ROOF_EDGE_HEIGHT) - (height / 2), (depthInMeters / 2))}>
        <mesh>
          <boxGeometry args={[sideWidth, height, depthInMeters]} />
          <meshStandardMaterial
            map={roofTexture}
            displacementScale={0}
            clipIntersection={true}
            attach="material-0"
          />
          <meshStandardMaterial
            map={roofOverhangTexture}
            displacementScale={0}
            clipIntersection={true}
            attach="material-1"
          />
          <meshStandardMaterial
            map={roofTexture}
            displacementScale={0}
            clipIntersection={true}
            attach="material-2"
          />
          <meshStandardMaterial
            map={roofOverhangTexture}
            displacementScale={0}
            clipIntersection={true}
            attach="material-3"
          />
          <meshStandardMaterial
            map={roofOverhangTexture}
            displacementScale={0}
            clipIntersection={true}
            attach="material-4"
          />
          <meshStandardMaterial
            map={roofOverhangTexture}
            displacementScale={0}
            clipIntersection={true}
            attach="material-5"
          />
          <meshStandardMaterial
            map={roofOverhangTexture}
            displacementScale={0}
            clipIntersection={true}
            attach="material-6"
          />
        </mesh>
      </group>
    </>
  );
};
