import { FunctionComponent } from 'react';
import { HomeExtensionDto } from "@hec/components/v1";
import { GardenTiling } from './GardenTiling';
import { Grass } from './Grass';
import { Fence } from './Fence';
import { calculateGroundWidth } from './CalculateGroundDimensions';
import { TilePath } from './TilePath';
import { WaterFeature } from './WaterFeature';
import { OutsideSofa } from './Sofa';

interface GardenProps {
  homeExtensionDto: HomeExtensionDto;
}

export const Garden: FunctionComponent<GardenProps> = ({
  homeExtensionDto,
}: GardenProps) => {
  const [widthInMeters, heightInMeters, depthInMeters] = homeExtensionDto
    .getMeasurements()
    .toArray();

  const groundDimensions = calculateGroundWidth(widthInMeters, depthInMeters);

  return (
    <>
      <group key={'garden'}>
        <GardenTiling groundDimensions={groundDimensions} />
        <Grass groundDimensions={groundDimensions} />
        <Fence groundDimensions={groundDimensions} />
        <TilePath groundDimensions={groundDimensions} />
        <WaterFeature groundDimensions={groundDimensions} />
        <OutsideSofa groundDimensions={groundDimensions} />
      </group>
    </>
  );
};
