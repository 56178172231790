import React, { FunctionComponent, PropsWithChildren } from 'react';
import styles from './modal.module.scss';
import { Conditional } from '../Conditional';
import { Text } from '../Typography';
import classnames from 'classnames';

export interface ModalProps {
  isOpen: boolean;
  onClose?: () => void;
  title?: string;
  className?: string;
}

export const Modal: FunctionComponent<PropsWithChildren<ModalProps>> = ({
  children,
  isOpen,
  onClose,
  title,
  className,
}) => {
  return (
    <div
      className={
        classnames(
          styles.modal,
          className,
          {
            [`${styles['-active']}`]: isOpen,
          }
        )
      }
    >
      <div className={styles.modal__container}>
        <Conditional condition={typeof title !== 'undefined'}>
          <Text as={'h2'} className={styles.modal__title}>{title}</Text>
        </Conditional>
        {children}
        <Conditional condition={typeof onClose !== 'undefined'}>
          <div className={styles.modal__cross} onClick={onClose}>
            <span className={styles.line}></span>
            <span className={styles.line}></span>
          </div>
        </Conditional>
      </div>
    </div>
  );
};
