import { AppConfiguration, FatalErrorKey, Severity } from '@hec/models';
import { AnyAction } from 'redux';
import { put } from 'redux-saga/effects';
import {
  APPLICATION_STATE_REDUCER_INITIALIZE_APPLICATION,
  raiseError,
  REDUCER_INITIALIZE_APPLICATION,
  SetFatalErrorKey, setLoading,
} from '../../../';
import { assert } from '@hec/utils';
import { handleClientConfiguration } from './handleClientConfiguration';

export function* handleInitializeApplication(action: AnyAction) {
  try {
    const passedConfiguration = action.data.appConfiguration as AppConfiguration;

    if(!passedConfiguration.configurationId) {
      yield put(SetFatalErrorKey(FatalErrorKey.NO_CLIENT_CONFIGURATION))
    }

    assert(
      !!passedConfiguration.configurationId,
      "No configuration id has been passed in configuration",
    );
    assert(
      !!passedConfiguration.baseUrl,
      "No base url has been passed",
    );

    yield put({
      type: REDUCER_INITIALIZE_APPLICATION,
      data: action.data,
    });
    yield put({
      type: APPLICATION_STATE_REDUCER_INITIALIZE_APPLICATION,
      data: action.data,
    });
    yield put(setLoading(true, action.type));
    yield handleClientConfiguration(passedConfiguration.configurationId);
  } catch (e) {
    yield put(raiseError(e as Error, Severity.HIGHEST));
  } finally {
    yield put(setLoading(false, action.type));
  }
}
