import { Product } from '@hec/models';

export const PROMPT_CONFIGURATION_CHANGE = 'PROMPT_CONFIGURATION_CHANGE';
export const REDUCER_PROMPT_CONFIGURATION_CHANGE = 'REDUCER_PROMPT_CONFIGURATION_CHANGE';

export const promptConfigurationChange =
  (
    limboKey: string,
    conflictedProducts: Product[],
    configuredWidth: number,
  ) => ({
    type: PROMPT_CONFIGURATION_CHANGE,
    data: {
      limboKey,
      conflictedProducts,
      configuredWidth,
    }
  })
