import React, {FunctionComponent} from "react";
import {CheeksDto, FaceDto, SectionFragmentSide, SectionFragmentType} from "@hec/api-dtos";
import {Box} from "@react-three/drei";
import {fromMmToRenderingMeters, getTotalHeightExcludingStubbed} from "@hec/core";
import {DEFAULT_DORMER_DEPTH} from "../index";
import {AttachSide} from "./CladdingGlobalMaterial";
import {DormerColorMaterial} from "./DormerColorMaterial";


export enum CheekSideRenderingObjectSide {
  west = 'west',
  east = 'east'
}

export interface CheeksProps {
  southFace:FaceDto;
  cheeksOverhang: number;
  cheekRenderingObjectSide: CheekSideRenderingObjectSide;
}

export const Cheeks: FunctionComponent<CheeksProps> = ({southFace, cheeksOverhang, cheekRenderingObjectSide} :CheeksProps) => {
  const southSectionFragments = southFace?.sectionFragments ?? [];

  const cheeks: CheeksDto[] = southSectionFragments.filter(x => x.sectionFragmentType === SectionFragmentType.Cheeks).map(x=> x as CheeksDto);


  const sideCheeks = cheeks.find(x=> x.cheekSide === SectionFragmentSide.EastPlusWest);

  let eastCheek = cheeks.find(x=> x.cheekSide === SectionFragmentSide.East) ?? sideCheeks;
  const eastCheekColorName = eastCheek?.configuredDormerColor?.name;
  const southCheek = cheeks.find(x=> x.cheekSide === SectionFragmentSide.South);
  const southCheekColorName = southCheek?.configuredDormerColor?.name;
  let westCheek = cheeks.find(x=> x.cheekSide === SectionFragmentSide.West) ?? sideCheeks;
  const westCheekColorName = westCheek?.configuredDormerColor?.name;

  const cheek = southCheek ?? eastCheek ?? westCheek;

  // If cheekside is West the east cheek should color with to the front side because it will be on th inside, same vice versa
  if(cheekRenderingObjectSide === CheekSideRenderingObjectSide.west) {
    eastCheek = southCheek;
  } else if (cheekRenderingObjectSide === CheekSideRenderingObjectSide.east) {
    westCheek = southCheek;
  }

  const cheekWidth = fromMmToRenderingMeters(cheek?.width ?? 0);

  const cheekHeight = fromMmToRenderingMeters(getTotalHeightExcludingStubbed(southFace));


  const cheeksOverhangDistance = fromMmToRenderingMeters(cheeksOverhang);

  const depth = DEFAULT_DORMER_DEPTH + cheeksOverhangDistance;


  return <group
    key={`cheeks-w_${cheekWidth}-h_${cheekHeight}-${eastCheekColorName}-${southCheekColorName}-${westCheekColorName}`}
    position={[
      cheekWidth / 2,
      cheekHeight /2,
      (-(depth / 2)) + cheeksOverhangDistance]}
  >
    <Box
      args={[cheekWidth,cheekHeight,depth]}
      receiveShadow
      castShadow
    >
      <DormerColorMaterial
        dormerColor={westCheek?.configuredDormerColor}
        attachSide={AttachSide.west}
        width={depth}
        height={cheekHeight}
      />
      <DormerColorMaterial
        dormerColor={eastCheek?.configuredDormerColor}
        attachSide={AttachSide.east}
        width={depth}
        height={cheekHeight}
      />
      <DormerColorMaterial
        dormerColor={cheek?.configuredDormerColor}
        attachSide={AttachSide.up}
      />
      <DormerColorMaterial
        dormerColor={cheek?.configuredDormerColor}
        attachSide={AttachSide.down}
      />
      <DormerColorMaterial
        dormerColor={cheek?.configuredDormerColor}
        attachSide={AttachSide.north}
        width={cheekWidth}
        height={cheekHeight}
      />
      <DormerColorMaterial
        dormerColor={southCheek?.configuredDormerColor}
        attachSide={AttachSide.south}
        width={cheekWidth}
        height={cheekHeight}
      />
    </Box>
  </group>
}
