import React, { FunctionComponent } from 'react';
import { Button, Conditional } from "@hec/components/v1";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Palette, TranslationKeys } from '@hec/models';
import { useTranslation } from 'react-i18next';

export interface BackButtonProps {
  palette?: Palette;
  type?: 'minimal' | 'normal'
}

export const BackButton: FunctionComponent<BackButtonProps> = ({ palette, type = 'normal' }) => {
  const { t } = useTranslation();
  return (
    <Button
      type={'secondary'}
      size={'large'}
      style={{
        width: type === 'minimal' ? '48px' : '100%'
      }}
      palette={palette}
      as={<div />}
    >
      <Conditional condition={type === 'minimal'}>
        <ArrowBackIosNewIcon />
      </Conditional>
      <Conditional condition={type === 'normal'}>
        {t(TranslationKeys.generic.previousStep, 'Vorige stap')}
      </Conditional>
    </Button>
  );
};
