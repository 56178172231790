import { AnyAction } from 'redux';

export const REQUEST_FAILURE = 'REQUEST_FAILURE';

export const requestFailure = (
  statusCode: number,
  reason: string,
  failedAction: AnyAction
) => ({
  type: REQUEST_FAILURE,
  data: {
    statusCode,
    reason,
    failedAction,
  },
});
