import React from 'react';

export const FrameVariationPanelIcon = () => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 27">
      <path clipRule="evenodd" d="M1 26h8V1H1v25Z" stroke="#1C1C1C" strokeWidth="1.831"/>
      <path fill="#1C1C1C" d="M1 1h8v25H1z"/>
    </svg>
  );
}
