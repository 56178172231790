import {OrderDto, SectionFragmentDto, SectionFragmentType} from "@hec/api-dtos";
import {getSouthFaceFromOrder} from "./GetSouthFaceFromOrder";
import {getSectionFragmentsFromOrder} from "./GetSectionFragmentsFromOrder";

export const getSectionFragmentsFromOrderByType = (order: OrderDto, sectionFragmentType: SectionFragmentType): SectionFragmentDto[] => {
  const sectionFragments = getSectionFragmentsFromOrder(order)
    .filter((sectionFragment) => sectionFragment.sectionFragmentType === sectionFragmentType)
    .sort((prev, next) => prev.sort! - next.sort!);
  return (sectionFragments as SectionFragmentDto[]);
}
