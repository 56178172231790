import { Product } from "@hec/models";

export const REMOVE_PRODUCT = 'REMOVE_PRODUCT';
export const REDUCER_REMOVE_PRODUCT = 'REDUCER_REMOVE_PRODUCT';

export const removeProduct = (product: Product) => ({
  type: REMOVE_PRODUCT,
  data: {
    product
  }
});
