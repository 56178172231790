import {ClientConfigurationDormerSpecificSettingsClientVariableSettingsVisualizationType} from "@hec/api-dtos";

export interface DormerVisualizationSettings {
  visualizationType: ClientConfigurationDormerSpecificSettingsClientVariableSettingsVisualizationType;
  sizeOpeningFrameRod: number;
  sizeFramesSeparatorRod: number;
  sizeFrameSurroundingRod: number;
  offsetFrameGroupDepthNonPanel: number;
  offsetFrameGroupDepthPanel: number;
  offsetFrameDepthNonWindowVariation: number;
  offsetFrameDepthWindowVariation: number;
}
