import i18next, { i18n } from 'i18next';
import HttpApi from 'i18next-http-backend';
import axios from 'axios';
import { KeyMissingErrorEvent } from './Events';

declare global {
  interface Window { missingKeys: string[]; }
}

const api = {
  getTranslations: async (path: string): Promise<unknown> => {
    const response = await axios.get(path);
    if (response.status === 200) {
      return response.data;
    }

    /* eslint-disable  @typescript-eslint/no-explicit-any */
    throw new (Error as any)(response.statusText);
  }
}
function i18NextInitialize(translationsUrl: string, language = 'nl_NL'): i18n {
  i18next
    .use(HttpApi)
    .init({
      backend: {
        loadPath: `${translationsUrl}/api/locales/{{lng}}/translations`,
        crossDomain: true,
        request: async (options, url, payload, callback) => {
          try {
            const response = await api.getTranslations(url);
            callback(null, {
              data: response as string,
              status: 200,
            });
          } catch (e) {
            console.error(e);
            callback(null,{
              status: 500,
              data: '',
            });
          }
        }
      },
      saveMissing: true,
      interpolation: {
        escapeValue: false,
        format: (value, rawFormat, lng) => {
          if (rawFormat) {
            const [format, ...additionalValues] = rawFormat
              .split(',')
              .map((v) => v.trim());
            switch (format) {
              case 'uppercase':
                return value.toUpperCase();
              case 'price':
                return Intl.NumberFormat(lng, {
                  style: 'currency',
                  currency: additionalValues[0],
                }).format(value);
            }
          }
        },
      },
      lng: language,
      fallbackLng: language,
      missingKeyNoValueFallbackToKey: false,
      missingKeyHandler: (lng, ns, key, fallbackValue, updateMissing, options) => {
        if (Object.prototype.hasOwnProperty.call(options, 'reportMissing') && !options.reportMissing) {
          return;
        }
        if (window) {
          if (process.env.NODE_ENV === 'production') {
            window.dispatchEvent(new KeyMissingErrorEvent(key));
          }

          if (!window.missingKeys) {
            window.missingKeys = [];
          }
          if (key && key.length > 0) {
            window.missingKeys = [...window.missingKeys, key];
          }
        }
      },
    });
  return i18next;
}

export default i18NextInitialize;
