import produce from 'immer';
import {
  addNotificationAction,
  removeNotificationAction,
} from './Actions';
import { NotificationState } from './State';

export const addNotificationReducer = (state: NotificationState, action: ReturnType<typeof addNotificationAction>) => {
  return produce(state, (draft) => {
      draft.notifications = [...(draft?.notifications ?? []), action.payload];
  });
}

export const removeNotificationReducer = (state: NotificationState, action: ReturnType<typeof removeNotificationAction>) => {
  return produce(state, (draft) => {
    draft.notifications = draft.notifications.filter(
      (n) => n.message !== action.payload.message
    );
  });
}
