import React, {FunctionComponent, useEffect, useState} from "react";
import {Input, TextArea } from "@hec/components/v1";
import {SurveyAnswerViewModel, SurveyQuestionType, SurveyQuestionViewModel} from "@hec/models";
import styles from './Survey.module.scss';

interface DisplaySurveyQuestionsProps {
  surveyQuestionViewModels: SurveyQuestionViewModel[];
  surveyAnswerViewModelsChanged: (vms: SurveyAnswerViewModel[]) => void
}

export const DisplaySurveyQuestions: FunctionComponent<DisplaySurveyQuestionsProps> = ({ surveyQuestionViewModels, surveyAnswerViewModelsChanged } : DisplaySurveyQuestionsProps) => {
  return <>
    <SurveyComponent
      questions={surveyQuestionViewModels}
      surveyAnswerViewModelsChanged={surveyAnswerViewModelsChanged}
    />
  </>;
};

interface Answers {
  [key: string]: any;
}

const SurveyComponent = ({ questions, surveyAnswerViewModelsChanged }: { questions: SurveyQuestionViewModel[], surveyAnswerViewModelsChanged: (vms: SurveyAnswerViewModel[]) => void }) => {
  const getDefaultValues = (questions: SurveyQuestionViewModel[]) => {
    return questions.reduce<Answers>((acc, question) => {
      let defaultValue = question.defaultValue ? (question.defaultValue as any).value : '';

      switch (question.surveyQuestionType) {
        case SurveyQuestionType.Date:
          // Date type to YYYY-MM-DD
          defaultValue = defaultValue ? new Date(defaultValue).toISOString().split('T')[0] : '';
          break;
        case SurveyQuestionType.Toggle:
          defaultValue = typeof defaultValue === 'boolean' ? defaultValue : false;
          break;
        case SurveyQuestionType.Text:
        case SurveyQuestionType.MultilineText:
          defaultValue = typeof defaultValue === 'string' ? defaultValue : '';
          break;
      }

      if (question.id && defaultValue !== null && defaultValue !== undefined) {
        acc[question.id] = defaultValue;
      }

      return acc;
    }, {});
  };


  const [answers, setAnswers] = useState<Answers>(getDefaultValues(questions));

  useEffect(() => {
    const keys = Object.keys(answers);
    const vms = keys.map((a: any) => {
      const vm = answers[a];
      const question = questions.find(e => e.id === a);



      return new SurveyAnswerViewModel(question!.id, question!.surveyQuestionType, vm);
    });

    surveyAnswerViewModelsChanged(vms);
  }, [answers]);

  const handleAnswerChange = (questionId: string, answer: any) => {
    setAnswers(prev => ({ ...prev, [questionId]: answer }));

  };

  const renderInputQuestion = (question: SurveyQuestionViewModel, type: string) => {
    const isDecimal = type === 'decimal';

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      // When isDecimal is false, prevent entering non-numeric characters and decimal points
      if (!isDecimal) {
        // Allow only numeric input
        const newValue = value.replace(/[^0-9]/g, '');
        handleAnswerChange(question.id, newValue);
      } else {
        // If decimal is allowed, handle normally
        handleAnswerChange(question.id, value);
      }
    };

    return (
      <div className={styles.inputGroup}>
        <label htmlFor={question.id}>{question.label}</label>
        <Input
          id={question.id}
          type="text" // Use text to control input manually
          className={styles.formControl}
          value={answers[question.id] ?? ''}
          onChange={handleChange}
        />
      </div>
    );
  };

  // const renderInputQuestion = (question: SurveyQuestionViewModel, type: string) => {
  //
  //   const isDecimal = type === 'decimal';
  //   const inputTypeForHtmlElement = isDecimal ? 'number' : type;
  //
  //   return (
  //     <div className={styles.inputGroup}>
  //       <label htmlFor={question.id}>{question.label}</label>
  //       <Input
  //         id={question.id}
  //         type={type}
  //         className={styles.formControl}
  //         value={answers[question.id] ?? ''}
  //         onChange={(e) => handleAnswerChange(question.id, (e.target as any).value)}
  //       />
  //     </div>
  //   );
  // };

  const renderCheckBoxInputQuestion = (question: SurveyQuestionViewModel) => {
    return (
      <div className={styles.checkboxInputGroup}>
        <input
          id={question.id}
          type="checkbox"
          className={styles.checkbox}
          checked={!!answers[question.id]}
          onChange={(e) => handleAnswerChange(question.id, e.target.checked)}
        />
        <label htmlFor={question.id} className={styles.checkboxLabel}>{question.label}</label>
      </div>
    );
  }


  const renderSelectQuestion = (question: SurveyQuestionViewModel) => {
    const options = question.metaData as { value: string[] };
    return (
      <div className={styles.inputGroup}>
        <label htmlFor={question.id}>{question.label}</label>
        <select
          id={question.id}
          className={styles.select}
          value={answers[question.id] ?? ''}
          onChange={(e) => handleAnswerChange(question.id, e.target.value)}
        >
          {options.value.map((option, index) => (
            <option value={option} key={index}>
              {option}
            </option>
          ))}
        </select>
      </div>
    );
  };

  const renderTextAreaQuestion = (question: SurveyQuestionViewModel) => {
    return (
      <div className={styles.inputGroup}>
        <label htmlFor={question.id}>{question.label}</label>
        <TextArea
          id={question.id}
          className={styles.formControl}
          value={answers[question.id] ?? ''}
          onChange={(e) => handleAnswerChange(question.id, (e.target as any).value)}
        />
      </div>
    );
  };


  const renderFileInputQuestion = (question: SurveyQuestionViewModel) => {
    return (
      <div className={styles.inputGroup}>
        <label htmlFor={question.id}>{question.label}</label>
        <input
          id={question.id}
          type="file"
          className={styles.fileInput}
          onChange={(e) => handleAnswerChange(question.id, e.target.files)}
        />
      </div>
    );
  }





  const renderQuestion = (question: SurveyQuestionViewModel) => {
    switch (question.surveyQuestionType) {
      case SurveyQuestionType.WholeNumber:
        return renderInputQuestion(question, 'number');
      case SurveyQuestionType.Decimal:
        return renderInputQuestion(question, 'decimal');
      case SurveyQuestionType.Toggle:
        return renderCheckBoxInputQuestion(question);
      case SurveyQuestionType.Date:
        return renderInputQuestion(question, 'date');
      case SurveyQuestionType.Text:
        return renderInputQuestion(question, 'text');
      case SurveyQuestionType.MultilineText:
        return renderTextAreaQuestion(question);
      case SurveyQuestionType.DropDown:
        return renderSelectQuestion(question);
      case SurveyQuestionType.File:
        return renderFileInputQuestion(question);
      default:
        return null;
    }
  };

  return (
    <div className={styles.surveyContainer}>
      {questions.map(question => (
        <div key={question.id} className={styles.surveyQuestion}>
          {renderQuestion(question)}
        </div>
      ))}
    </div>
  );
};
