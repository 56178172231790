import { CdnUrlHelper } from '@hec/core';
import { useLoader } from '@react-three/fiber';
import { FunctionComponent } from 'react';
import { RepeatWrapping, TextureLoader, Vector2, Vector3 } from 'three';
import {
  DEFAULT_OVERHANG_SIDE_SIZE,
  DEFAULT_ROOF_EDGE_HEIGHT,
  DEFAULT_WALL_THICKNESS,
  TEXTURES_PER_METER_IN_LENGTH,
} from '../Constants';
import { HomeExtensionDto } from '../HomeExtensionDto';
import {
  DEFAULT_OVERHANG_SIZE,
  IsRoofOverhangProduct,
  IsRoofTrimProduct,
  RoofOverhangProduct,
  RoofOverhangStyle,
  RoofTrimProduct,
  RoofTrimStyle,
} from '@hec/models';
import { Conditional } from "@hec/components/v1";
import { Aluminum, ZincFlat } from './RoofTrim';
import { RoofOverhang } from './RoofOverhang';
import { ClippedRoofInner } from './ClippedRoofInner';

export interface RoofProps {
  homeExtensionDto: HomeExtensionDto;
}

const cdnPublicRoot = CdnUrlHelper.getPublicRoot();
const centerOfTexture = new Vector2(0.5, 0.5);

export const Roof: FunctionComponent<RoofProps> = ({ homeExtensionDto }) => {
  const [widthInMeters, heightInMeters, depthInMeters] = homeExtensionDto
    .getMeasurements()
    .toArray();
  const roofTrimProduct = homeExtensionDto.getExtraByType(IsRoofTrimProduct) as
    | RoofTrimProduct
    | undefined;
  const roofOverhangProduct = homeExtensionDto.getExtraByType(
    IsRoofOverhangProduct
  ) as RoofOverhangProduct | undefined;
  const repeatMaxX = TEXTURES_PER_METER_IN_LENGTH * widthInMeters;
  const repeatMaxY = TEXTURES_PER_METER_IN_LENGTH * depthInMeters;
  const roofTexture = useLoader(
    TextureLoader,
    cdnPublicRoot + '/roof/Texture.jpg'
  );
  const edgeRoofTexture = roofTexture.clone();
  edgeRoofTexture.matrixAutoUpdate = false;
  edgeRoofTexture.matrix.identity().rotate(90);
  edgeRoofTexture.center = centerOfTexture;
  edgeRoofTexture.wrapT = RepeatWrapping;
  edgeRoofTexture.repeat.set(repeatMaxX, repeatMaxY);
  roofTexture.wrapS = RepeatWrapping;
  roofTexture.wrapT = RepeatWrapping;
  roofTexture.repeat.set(repeatMaxX, repeatMaxY);

  const overhangSize =
    roofOverhangProduct &&
    roofOverhangProduct.style === RoofOverhangStyle.FRONT_ONLY
      ? DEFAULT_OVERHANG_SIZE
      : 0;
  const overhangSideSize =
    roofOverhangProduct &&
    roofOverhangProduct.style === RoofOverhangStyle.FRONT_ONLY
      ? DEFAULT_OVERHANG_SIDE_SIZE
      : 0;
  const wallThickness = DEFAULT_WALL_THICKNESS + overhangSize;
  return (
    <>
      {/* Inner roof */}
      <ClippedRoofInner
        homeExtensionDto={homeExtensionDto}
        roofTexture={roofTexture}
        hasOverhang={
          roofOverhangProduct &&
          roofOverhangProduct.style === RoofOverhangStyle.FRONT_ONLY
        }
      />
      {/* North Splash protection */}
      <Conditional
        condition={
          !roofOverhangProduct ||
          roofOverhangProduct.style === RoofOverhangStyle.NONE
        }
      >
        <group
          position={
            new Vector3(
              (widthInMeters + overhangSize * 2) / 2 - overhangSize,
              heightInMeters + DEFAULT_ROOF_EDGE_HEIGHT / 2,
              depthInMeters + overhangSize - wallThickness / 2
            )
          }
        >
          <mesh>
            <boxGeometry
              args={[
                widthInMeters,
                DEFAULT_ROOF_EDGE_HEIGHT,
                DEFAULT_WALL_THICKNESS + overhangSize,
              ]}
            />
            <meshStandardMaterial
              map={roofTexture}
              displacementScale={0}
              clipIntersection={true}
              attach="material-0"
            />
            <meshStandardMaterial
              map={roofTexture}
              displacementScale={0}
              clipIntersection={true}
              attach="material-1"
            />
            <meshStandardMaterial
              map={roofTexture}
              displacementScale={0}
              clipIntersection={true}
              attach="material-2"
            />
            <meshStandardMaterial
              map={roofTexture}
              displacementScale={0}
              clipIntersection={true}
              attach="material-3"
            />
            <meshStandardMaterial
              map={roofTexture}
              displacementScale={0}
              clipIntersection={true}
              attach="material-4"
            />
            <meshStandardMaterial
              map={roofTexture}
              displacementScale={0}
              clipIntersection={true}
              attach="material-5"
            />
          </mesh>
        </group>
        <group
          position={
            new Vector3(
              widthInMeters - DEFAULT_WALL_THICKNESS / 2,
              heightInMeters + DEFAULT_ROOF_EDGE_HEIGHT / 2,
              depthInMeters / 2 - DEFAULT_WALL_THICKNESS / 2
            )
          }
        >
          <mesh>
            <boxGeometry
              args={[
                DEFAULT_WALL_THICKNESS + overhangSideSize,
                DEFAULT_ROOF_EDGE_HEIGHT,
                depthInMeters - DEFAULT_WALL_THICKNESS,
              ]}
            />
            <meshStandardMaterial
              map={edgeRoofTexture}
              displacementScale={0}
              roughness={0.8}
              clipIntersection={true}
              attach="material"
            />
          </mesh>
        </group>
        <group
          position={
            new Vector3(
              DEFAULT_WALL_THICKNESS / 2,
              heightInMeters + DEFAULT_ROOF_EDGE_HEIGHT / 2,
              depthInMeters / 2 - DEFAULT_WALL_THICKNESS / 2
            )
          }
        >
          <mesh>
            <boxGeometry
              args={[
                DEFAULT_WALL_THICKNESS,
                DEFAULT_ROOF_EDGE_HEIGHT,
                depthInMeters - DEFAULT_WALL_THICKNESS,
              ]}
            />
            <meshStandardMaterial
              map={edgeRoofTexture}
              displacementScale={0}
              roughness={0.8}
              clipIntersection={true}
              attach="material"
            />
          </mesh>
        </group>
      </Conditional>
      <Conditional
        condition={
          roofTrimProduct && roofTrimProduct.style === RoofTrimStyle.ALUMINUM
        }
      >
        <Aluminum homeExtensionDto={homeExtensionDto} />
      </Conditional>
      <Conditional
        condition={
          roofTrimProduct &&
          roofTrimProduct.style === RoofTrimStyle.BLACK_ALUMINUM
        }
      >
        <Aluminum homeExtensionDto={homeExtensionDto} color={'#000000'} />
      </Conditional>
      <Conditional
        condition={
          roofTrimProduct && roofTrimProduct.style === RoofTrimStyle.ZINC_FLAT
        }
      >
        <ZincFlat homeExtensionDto={homeExtensionDto} />
      </Conditional>
      <Conditional
        condition={
          roofOverhangProduct &&
          roofOverhangProduct.style === RoofOverhangStyle.FRONT_ONLY
        }
      >
        <RoofOverhang
          homeExtensionDto={homeExtensionDto}
          roofTexture={roofTexture}
        />
      </Conditional>
    </>
  );
};
