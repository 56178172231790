// We are custom assigning to window
/* eslint-disable @typescript-eslint/no-explicit-any */
import { PACKAGE_BUGFIX_VERSION, PACKAGE_MAJOR_VERSION, PACKAGE_MINOR_VERSION } from "../Constants";

export class CdnUrlHelper {
  static getPublicRoot() {
    const hostname = (window as any)?.process?.env?.CDN_HOST_NAME ?? 'cdn.aanbouwconfigurator.com';
    const cdnRootUrl = `https://${hostname}`;
    const developerName = (window as any)?.process?.env?.DEVELOPER_NAME;
    if(developerName) {
      return `${cdnRootUrl}/developers/${developerName}/development/public`;
    } else {
      //TODO: if release/acceptance/production only use major version
      return `${cdnRootUrl}/v${PACKAGE_MAJOR_VERSION}.${PACKAGE_MINOR_VERSION}.${PACKAGE_BUGFIX_VERSION}/public`;
    }
  }
}