import {SurveyQuestionService} from "./SurveyQuestionService";
import {ApplicationService} from "@hec/models";
import {SurveyAnswerService} from "./SurveyAnswerService";

export const requiredSurveyQuestionServices = (): ApplicationService[] => {
  return [
    new SurveyQuestionService(),
    new SurveyAnswerService()
  ];
}
