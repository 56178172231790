import {DormerModalContentKey, FatalErrorKey} from "@hec/models";

export interface ConfirmPromptModalData {
  onAccept: () => void;
  message: string;
  title?: string;
}

export interface UiStatusStateBase {
  fullscreenMode?: boolean;
  technicalMode?: boolean;
  modalOpen?: boolean;
  modalContentKey?: DormerModalContentKey;
  modalData?: unknown;
  fatalErrorKey?: FatalErrorKey;
}
export interface UiStatusState extends UiStatusStateBase {
  fullscreenMode: boolean;
  technicalMode: boolean;
  modalOpen: boolean;
  modalContentKey: DormerModalContentKey;
  modalData: unknown;
  fatalErrorKey?: FatalErrorKey;
}

export const uiStatusInitialState: UiStatusState = {
  fullscreenMode: false,
  modalOpen: false,
  technicalMode: false,
  modalContentKey: DormerModalContentKey.NONE,
  modalData: {},
}

