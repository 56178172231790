export const countryCodes = [
  {
    value: '+30',
    label: '+30',
  },
  {
    value: '+31',
    label: '+31',
  },
  {
    value: '+32',
    label: '+32',
  },
  {
    value: '+33',
    label: '+33',
  },
  {
    value: '+350',
    label: '+350',
  },
  {
    value: '+351',
    label: '+351',
  },
  {
    value: '+352',
    label: '+352',
  },
  {
    value: '+353',
    label: '+353',
  },
  {
    value: '+354',
    label: '+354',
  },
  {
    value: '+355',
    label: '+355',
  },
  {
    value: '+356',
    label: '+356',
  },
  {
    value: '+357',
    label: '+357',
  },
  {
    value: '+358',
    label: '+358',
  },
  {
    value: '+359',
    label: '+359',
  },
  {
    value: '+36',
    label: '+36',
  },
  {
    value: '+37',
    label: '+37',
  },
  {
    value: '+370',
    label: '+370',
  },
  {
    value: '+371',
    label: '+371',
  },
  {
    value: '+372',
    label: '+372',
  },
  {
    value: '+373',
    label: '+373',
  },
  {
    value: '+374',
    label: '+374',
  },
  {
    value: '+375',
    label: '+375',
  },
  {
    value: '+376',
    label: '+376',
  },
  {
    value: '+377',
    label: '+377',
  },
  {
    value: '+378',
    label: '+378',
  },
  {
    value: '+379',
    label: '+379',
  },
  {
    value: '+380',
    label: '+380',
  },
  {
    value: '+381',
    label: '+381',
  },
  {
    value: '+382',
    label: '+382',
  },
  {
    value: '+383',
    label: '+383',
  },
  {
    value: '+385',
    label: '+385',
  },
  {
    value: '+386',
    label: '+386',
  },
  {
    value: '+387',
    label: '+387',
  },
  {
    value: '+389',
    label: '+389',
  },
  {
    value: '+39',
    label: '+39',
  },
  {
    value: '+40',
    label: '+40',
  },
  {
    value: '+41',
    label: '+41',
  },
  {
    value: '+420',
    label: '+420',
  },
  {
    value: '+421',
    label: '+421',
  },
  {
    value: '+423',
    label: '+423',
  },
  {
    value: '+43',
    label: '+43',
  },
  {
    value: '+44',
    label: '+44',
  },
  {
    value: '+45',
    label: '+45',
  },
  {
    value: '+46',
    label: '+46',
  },
  {
    value: '+47',
    label: '+47',
  },
  {
    value: '+48',
    label: '+48',
  },
  {
    value: '+49',
    label: '+49',
  },
];
