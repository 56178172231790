import {select} from 'redux-saga/effects';
import {HomeExtensionRootState} from '../../../';
import {
  HomeConfigurationState,
  ProductionMaterialType,
  IsProductWithSameColor,
  IsProductWithSameMaterial,
  IsRoofLightProduct,
  IsProductWithinWidthAndDepthThreshold,
  IsProductWithTheSameSpecifications,
  RoofLightSpecification,
  RoofLightProduct,
  DefaultProductSelectionId,
} from '@hec/models';

export function* pickSuitableRoofLightSizeHandler(
  specification?: RoofLightSpecification,
  material?: ProductionMaterialType,
  color?: string,
  width?: number,
  depth?: number) {
  let innerWidth: number;
  const {
    measurements,
    products,
    selectedProducts
  }: HomeConfigurationState = yield select((state: HomeExtensionRootState) => state.homeConfigurationReducer);
  if (width) {
    innerWidth = width;
  } else {
    innerWidth = measurements.width;
  }

  const currentRoofLight = selectedProducts.find(IsRoofLightProduct);

  if (currentRoofLight?.id === DefaultProductSelectionId.RoofLight) {
    return currentRoofLight;
  }

  return products.filter(IsRoofLightProduct)
    .filter(IsProductWithinWidthAndDepthThreshold(innerWidth, depth))
    .filter(specification ? IsProductWithTheSameSpecifications<RoofLightSpecification, RoofLightProduct>(specification) : (p) => true)
    .filter(material ? IsProductWithSameMaterial(material) : (p) => true)
    .find(IsProductWithSameColor(color));
}

