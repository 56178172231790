import {DormerMaterialService, getDormerMaterialsAction, getDormerMaterialsSucceededAction} from './index';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {
  getApplicationService, getOrderSelector,
  getSelectedConfigurationTemplateSelector, storeErrorAction,
} from "../../";
import {ConfigurationTemplateDto, DormerMaterialViewModel, OrderDto} from "@hec/api-dtos";

function* handleGetDormerMaterials() {
  const service: DormerMaterialService = yield getApplicationService(DormerMaterialService);

  const selectedConfigurationTemplate: ConfigurationTemplateDto = yield select(getSelectedConfigurationTemplateSelector);
  const order: OrderDto | null = yield select(getOrderSelector);

  try {
    if (!selectedConfigurationTemplate) {
      yield put(storeErrorAction({
        error: new Error('No configuration template selected')
      }));
      return;
    }
    if (!order) {
      yield put(storeErrorAction({
        error: new Error('No order found')
      }));
      return;
    }

    const result: DormerMaterialViewModel[] = yield call([service, 'get'], selectedConfigurationTemplate.id, order.configurationCode);
    yield put(getDormerMaterialsSucceededAction({ dormerMaterials: result}));

  } catch (error) {
    yield put(storeErrorAction({error: error}));
  }
}

export function* DormerMaterialsSagas() {
  yield takeLatest(getDormerMaterialsAction, handleGetDormerMaterials);
}
