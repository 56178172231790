import { useState, useEffect } from 'react';

export interface WindowInformation {
  width: number;
  height: number;
  orientation: 'portrait' | 'landscape';
  deviceType: 'tablet' | 'mobile' | 'desktop';
}

const deviceType = (navigator: Navigator) => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  }
  else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return "mobile";
  }
  return "desktop";
};

function getWindow(): WindowInformation {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
    deviceType: deviceType(window.navigator),
    orientation: (height > width) ? 'portrait' : 'landscape'
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState<WindowInformation>(getWindow());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindow());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
