import {fromMmToRenderingMeters, getWidthOfFrames} from "@hec/core";
import {ConsecutivePanelTypeGroup, SimpleFrameContainer} from "./interfaces";

// Group ez models by variation, for example if multiple that are not panels are present they should be grouped together-
// As long as they are not interrupted by one that is a panel.
export const GroupByConsecutivePanelType = (models: SimpleFrameContainer[], forceSeparateGroups = false): ConsecutivePanelTypeGroup[] => {
  const result: ConsecutivePanelTypeGroup[] = [];
  let currentGroup: SimpleFrameContainer[] = [];
  let currentIsPanel = models[0].isPanel;

  models.forEach((model, index) => {
    if (model.isPanel === currentIsPanel || (forceSeparateGroups && currentGroup.length === 0)) {
      currentGroup.push(model);
    } else {
      const groupWidth = fromMmToRenderingMeters(getWidthOfFrames(currentGroup.map(x => x.frame)));
      result.push({models: currentGroup, width: groupWidth});
      currentGroup = [model];
      currentIsPanel = model.isPanel;
    }

    if (index === models.length - 1) {
      const groupWidth = fromMmToRenderingMeters(getWidthOfFrames(currentGroup.map(x => x.frame)));
      result.push({models: currentGroup, width: groupWidth});
    }
  });

  return result.filter(x=> x.models.length > 0);
};
