import { Product } from "@hec/models";

export const SELECT_PRODUCT = 'SELECT_PRODUCT';
export const REDUCER_SELECT_PRODUCT = 'REDUCER_SELECT_PRODUCT';

export const selectProduct = (product: Product) => ({
  type: SELECT_PRODUCT,
  data: {
    product
  }
});
