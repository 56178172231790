import { RouteProps } from 'react-router';
import { ReactElement } from 'react';
import { HomeExtensionApplicationState } from '../State';

export interface ActorDefinedRoute {
  showToPro: boolean;
  showToCustomer: boolean;
}


export interface RouteDisplaySettings {
  displayTitleInDrawer: boolean;
  titleNoMarginBottom?: boolean;
  displayInNavigation?: boolean;
  navigationTitleTranslationKey?: string;
  navigationTitle?: string;
  centerTitle?: boolean;
}

export interface Route extends RouteProps {
  name?: string;
  displaySettings: RouteDisplaySettings;
  disabled?: boolean | ((state: HomeExtensionApplicationState) => boolean);
  sideDrawerContent?: ReactElement;
  translationKey?: string;
  actorDefinedRoute?: ActorDefinedRoute | undefined;
}
