import React, {FunctionComponent} from "react";
import {FrameDto, FrameVariation} from "@hec/api-dtos";
import {fromMmToRenderingMeters} from "@hec/core";
import {Conditional} from "@hec/components/v2";
import {FrameHandle} from "./FrameHandle";
import {OPENING_FRAME_ROD, FRAME_SURROUNDING_ROD_THICKNESS} from "../../../../constants";
import {DormerColorMaterial} from "../DormerColorMaterial";
import {Box} from "@react-three/drei";
import {Base3dProps} from "../Base3dProps";

interface SingularFrameProps extends Base3dProps{
  frame: FrameDto
  amountOfFrames: number;
}

const _randomColor = false;

export const SingularOpeningWindowTypeFrame: FunctionComponent<SingularFrameProps> = ({frame, amountOfFrames, visualizationSettings }: SingularFrameProps) => {
  const frameSurroundingRodThickness = fromMmToRenderingMeters(FRAME_SURROUNDING_ROD_THICKNESS);

  const frameRabbetSideThickness = fromMmToRenderingMeters(OPENING_FRAME_ROD)
  const frameRabbetSideDepth = frameRabbetSideThickness;

  const heightAmountToSubtractForSurroundingRods = frameSurroundingRodThickness * 2;
  const widthAmountToSubtractForSurroundingRods = ((frameSurroundingRodThickness / amountOfFrames) * 2);

  const frameHeight = fromMmToRenderingMeters(frame?.height ?? 0) - heightAmountToSubtractForSurroundingRods;
  const frameWidth = fromMmToRenderingMeters(frame?.width ?? 0) - widthAmountToSubtractForSurroundingRods;

  const verticalRodHeight = frameHeight - frameRabbetSideThickness * 2;
  const yVerticalRodOffset = frameRabbetSideThickness;


  const windowPreventClippingOffset = 0.0001;
  const windowHeight = frameHeight - windowPreventClippingOffset;
  const windowWidth = frameWidth - windowPreventClippingOffset;
  const windowDepth = 0.001;


  const variation: FrameVariation = frame.variation!;

  const isLeftHandleSide = variation === FrameVariation.TiltAndTurnWindowLeft;
  const isRightHandleSide = variation === FrameVariation.TiltAndTurnWindowRight;



  const yOffset = heightAmountToSubtractForSurroundingRods / 2;

  const depthOffset = variation === FrameVariation.Window ? visualizationSettings.offsetFrameDepthWindowVariation : visualizationSettings.offsetFrameDepthNonWindowVariation ;

  return <group position={[0, 0, depthOffset]}>
    <group position={[0, yOffset, frameRabbetSideDepth]}>
      <group key={`frame-${frame.sort}-left`} position={[0, 0, -(frameRabbetSideDepth / 2)]}>
        <mesh position={[(frameRabbetSideThickness / 2), yVerticalRodOffset + (verticalRodHeight / 2), 0]}>
          <Box
            args={[frameRabbetSideThickness, verticalRodHeight, frameRabbetSideDepth]}
            receiveShadow
            castShadow
          >
            <DormerColorMaterial
              dormerColor={frame?.configuredDormerColor}
              width={frameRabbetSideThickness}
              height={verticalRodHeight}
              randomColor={_randomColor}
            />
          </Box>
        </mesh>
      </group>
      <group key={`frame-${frame.sort}-right`} position={[frameWidth, 0, -(frameRabbetSideDepth / 2)]}>
        <mesh position={[-(frameRabbetSideThickness / 2), yVerticalRodOffset + (verticalRodHeight / 2), 0]}>
          <Box
            args={[frameRabbetSideThickness, verticalRodHeight, frameRabbetSideDepth]}
            receiveShadow
            castShadow
          >
            <DormerColorMaterial
              dormerColor={frame?.configuredDormerColor}
              width={frameRabbetSideThickness}
              height={verticalRodHeight}
              randomColor={_randomColor}
            />
          </Box>
        </mesh>
      </group>

      <group key={`frame-${frame.sort}-top`} position={[0, frameHeight, 0]}>
        <mesh position={[frameWidth / 2, -(frameRabbetSideThickness / 2), -(frameRabbetSideDepth / 2)]}>
          <Box
            args={[frameWidth, frameRabbetSideThickness, frameRabbetSideDepth]}
            receiveShadow
            castShadow
          >
            <DormerColorMaterial
              dormerColor={frame?.configuredDormerColor}
              width={frameWidth}
              height={frameRabbetSideThickness}
              randomColor={_randomColor}
            />
          </Box>
        </mesh>
      </group>
      <group key={`frame-${frame.sort}-bottom`} position={[0, frameRabbetSideThickness, 0]}>
        <mesh position={[frameWidth / 2, -(frameRabbetSideThickness / 2), -(frameRabbetSideDepth / 2)]}>
          <Box
            args={[frameWidth, frameRabbetSideThickness, frameRabbetSideDepth]}
            receiveShadow
            castShadow
          >
            <DormerColorMaterial
              dormerColor={frame?.configuredDormerColor}
              width={frameWidth}
              height={frameRabbetSideThickness}
              randomColor={_randomColor}
            />
          </Box>
        </mesh>
      </group>

      {/* WINDOW */}
      <group key={'window'}>

        <mesh position={[windowWidth / 2, windowHeight / 2, -(frameRabbetSideDepth / 2)]}>
          <boxBufferGeometry
            args={[windowWidth, windowHeight, windowDepth]}
          />
          <meshPhysicalMaterial
            color="#add8e6"
            transmission={0.9}  // controls the transparency
            roughness={0}       // a smoother surface for a clearer glass
            reflectivity={0.9}  // high reflectivity for glass-like appearance
            clearcoat={1}       // adds a clear coating on the material
            clearcoatRoughness={0} // smooth clearcoat for a more realistic glass
            // you can add more properties as needed
          />
        </mesh>
      </group>

      <Conditional condition={isRightHandleSide || isLeftHandleSide}>
        <group position={
          isLeftHandleSide ?
            [(frameRabbetSideThickness / 2) - .0125, windowHeight / 2, -frameRabbetSideDepth] :
            [frameWidth - ((frameRabbetSideThickness / 2) - .0125), windowHeight / 2, -frameRabbetSideDepth]
        }>
          <group scale={
            isLeftHandleSide ?
              [1, 1, -1] :
              [-1, 1, -1]
          }>
            <FrameHandle/>
          </group>
        </group>
      </Conditional>
    </group>

  </group>
}


