import { GetProductsByTypeQuery } from '@hec/models';

export const GET_PRODUCTS = 'GET_PRODUCTS';
export const REDUCER_GET_PRODUCTS = 'REDUCER_GET_PRODUCTS';

export const getProducts = (getProductsByTypeQuery?: GetProductsByTypeQuery) => ({
  type: GET_PRODUCTS,
  data: getProductsByTypeQuery
});

