import { getContrastRatio, getLuminance } from '@mui/material';

export const getContrastColorMulti = (foreground: string, background: string): string => {
  const contrast = getContrastRatio(foreground, background);
  const luminance = getLuminance(foreground);
  const black = '#000000';
  const white = '#ffffff';
  if (contrast > 9) {
    return luminance > 0.6 ? black : white;
  }
  else if (contrast < 3) {
    return luminance > 0.6 ? black : white;
  }
  return background;
};
