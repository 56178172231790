import React, {FunctionComponent} from 'react';
import styles from './drawer-toggle.module.scss';
import classnames from 'classnames';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

export interface ZoomInButtonProps {
  onClick?: () => void;
  className?: string;
}

export const ZoomInButton: FunctionComponent<ZoomInButtonProps> = ({
  onClick,
  className,
}) => {
  return (
    <div className={classnames(styles.toggle, className)} onClick={onClick}>
      <ZoomInIcon/>
    </div>
  );
};
