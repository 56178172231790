import React, {FunctionComponent, Ref, useRef} from "react";
import styles from "./Dormer2dRenderer.module.scss";
import {useSelector} from "react-redux";
import {
  getCheeks,
  getConfiguredFramesSelector,
  getFaceConfiguration, getFrameSurroundingRod,
  getOrderSelector,
  getSelectedConfigurationTemplateSelector,
  getSplinter,
  getStubbed
} from "@hec/dal";
import {Layer, Stage} from "react-konva";
import {DrawLine, DrawRectangle, DrawText, useGetRefSize} from "./Utils";
import {
  ConfigurationTemplateModelType,
  FrameVariation,
  SectionFragmentType,
  Side
} from "@hec/api-dtos";
import {ROOF_TRIM_HEIGHT} from "../../constants";
import {useWindowDimensions} from "@hec/components/v2";
import {
  getRoofOverhang,
  getSplinterHeight,
  getStubbedHeight,
  getTotalWidth,
  MEDIA_L,
  ROOF_OVERHANG_OFFSET
} from "@hec/core";
import {MEASUREMENTS_LINE_LENGTH} from "./Constants";
import {
  ConsecutivePanelTypeGroup,
  GroupByConsecutivePanelType,
  SimpleFrameContainer,
  ToSimpleFrameContainer
} from "../DormerRenderingUtilities";
import {Panel, TiltAndTurnWindowLeft, Window} from "./Frames";
import {TiltAndTurnWindowRight} from "./Frames/TiltAndTurnWindowRight";
import {TransformComponent, TransformWrapper} from "react-zoom-pan-pinch";

export const CANVAS_TECHNICAL_FRONT_ID = 'dormer-canvas-TECHNICAL-FRONT';

interface FramePositionGroup {
  width: number;
  framesPositions: FramePosition[];
}

interface FramePosition {
  x: number;
  width: number;
  frame: any;
  drawRodBefore: boolean;
  drawRodAfter: boolean;
}

const calculateFramePositions = (
  groupedByVariation: ConsecutivePanelTypeGroup[],
  splinterWidth: number,
  cheeksWidth: number,
  roofOverhangWestWidth: number,
  frameRodThickness: number,
): FramePositionGroup[] => {
  const startX = roofOverhangWestWidth + splinterWidth + cheeksWidth;
  const framesPositions: FramePosition[] = [];

  let groupX = startX;

  const result = groupedByVariation.map((panelGroup: ConsecutivePanelTypeGroup, panelGroupIndex: number) => {

    let frameX = groupX;

    const groupFramesPositions: FramePosition[] = panelGroup.models.map((sfc: SimpleFrameContainer, frameIndex: number) => {
      const isFirstFrame = frameIndex === 0;
      const isLastFrame = panelGroup.models.length - 1 === frameIndex;
      const isOneButLastFrame = panelGroup.models.length - 2 === frameIndex;
      const isOnlyFrame = panelGroup.models.length === 1;
      const frame = sfc.frame;
      const isWindow = frame.variation === FrameVariation.Window;

      if (isFirstFrame) {
        frameX += frameRodThickness;
      }

      let widthOfRabbetsWithinPanelGroup = frameRodThickness;
      if (groupedByVariation.length === 1) {
        widthOfRabbetsWithinPanelGroup += frameRodThickness;
      } else if (isOnlyFrame) {
        // Used to be devivided by two with trial and error but i didn't see no difference anymore.
        widthOfRabbetsWithinPanelGroup += frameRodThickness;
      } else if (isLastFrame) {
        // No clue why this needs be times 4
        widthOfRabbetsWithinPanelGroup += frameRodThickness * 4;
      }

      const widthOffset = widthOfRabbetsWithinPanelGroup / (panelGroup.models.length);
      const frameWidthExcludingRabbets = frame.width - widthOffset;

      const framePosition = {
        x: frameX,
        width: frameWidthExcludingRabbets,
        frame,
        drawRodBefore: isWindow && !isFirstFrame,
        drawRodAfter: isWindow && isOneButLastFrame,
      };

      framesPositions.push(framePosition);

      frameX += frameWidthExcludingRabbets;

      return framePosition;
    });

    const panelGroupWidth = panelGroup.models.reduce((acc, pos) => acc + pos.width, 0)

    groupX += panelGroupWidth;

    const result: FramePositionGroup = {
      width: panelGroupWidth,
      framesPositions: groupFramesPositions
    }
    return result;
  });

  const framesTotalWidth = framesPositions.reduce((acc, pos) => acc + pos.width, 0);
  const totalUsedWidth = framesTotalWidth + (cheeksWidth * 2) + (splinterWidth * 2);
  const spareSpace = 0;//dormerWidth - totalUsedWidth;

  return result;
};

interface Dormer2dRendererProps {
  // canvasDivRef: Ref<HTMLDivElement>
}

export const Dormer2dRenderer: FunctionComponent<Dormer2dRendererProps> = ({
                                                                             // canvasDivRef
} :Dormer2dRendererProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const canvasSize = useGetRefSize(containerRef);
  const {windowWidth, orientation} = useWindowDimensions();

  const configurationTemplate = useSelector(getSelectedConfigurationTemplateSelector);
  const faceConfiguration = useSelector(getFaceConfiguration);
  const stubbed = useSelector(getStubbed);
  const cheeks = useSelector(getCheeks);
  const splinter = useSelector(getSplinter);
  const frameSurroundingRod = useSelector(getFrameSurroundingRod);

  const frameRodThickness = frameSurroundingRod?.width ?? 0;

  const overhangDto = configurationTemplate?.configurationTemplateDormerOverhang;
  // const roofOverhang = overhangDto == null ? null : getRoofOverhang(overhangDto!);

  let configuredFrames = useSelector(getConfiguredFramesSelector);

  const order = useSelector(getOrderSelector);

  // TODO: REMOVE TEMP FIX FOR HEC-549
  const southFace = order?.faceConfiguration?.faces?.find(x => x.side === Side.South);
  const faceConfigurationHeight = order?.faceConfiguration.height ?? 0;

  configuredFrames = configuredFrames.map(e => {
    if (e.height) {
      const reservedHeight = getSplinterHeight(southFace) * 2 + getStubbedHeight(southFace);
      const desiredHeight = faceConfigurationHeight - reservedHeight;

      if (e.sectionFragmentType === SectionFragmentType.Frame && e.height !== desiredHeight) {
        e = {
          ...e,
          height: desiredHeight
        };
      }
    }
    return e;
  });

  // END: REMOVE TEMP FIX FOR HEC-549

  const simpleFrameContainers: SimpleFrameContainer[] = ToSimpleFrameContainer(configuredFrames);
  const groupedByVariation: ConsecutivePanelTypeGroup[] = GroupByConsecutivePanelType(simpleFrameContainers);

  if (!configurationTemplate || !faceConfiguration || !stubbed || !cheeks || !splinter) {
    return <></>;
  }

  const dormerWidth = getTotalWidth(southFace)!;

  const overStekSize = configurationTemplate.modelType === ConfigurationTemplateModelType.Oncoming ? (ROOF_OVERHANG_OFFSET * 2) : 0;
  const fullDormerBottomWidth = dormerWidth;

  const dormerHeight = faceConfiguration.height!;


  const stubbedWestOverhang = (overhangDto?.stubbedWestOverhang ?? 0);
  const stubbedEastOverhang = (overhangDto?.stubbedEastOverhang ?? 0);
  const stubbedWidth = stubbedWestOverhang + fullDormerBottomWidth + stubbedEastOverhang;

  const roofTrimWidth = stubbedWidth;
  const roofTrimHeight = ROOF_TRIM_HEIGHT;
  const roofTrimX = 0;
  const roofTrimY = 0;

  const stubbedHeight = stubbed.height - roofTrimHeight;
  const stubbedX = 0;

  const stubbedY = roofTrimHeight;

  const cheeksWidth = cheeks.width;
  const cheeksHeight = dormerHeight - roofTrimHeight - stubbedHeight;

  const leftCheekX = stubbedWestOverhang;
  const leftCheekY = roofTrimHeight + stubbedHeight;

  const rightCheekX = stubbedWestOverhang + fullDormerBottomWidth - cheeks.width;

  const rightCheekY = leftCheekY;

  const bottomAndTopSplinterWidth = dormerWidth - (cheeksWidth * 2);
  const leftAndRightSplinterHeight = dormerHeight - roofTrimHeight - stubbedHeight;
  const splinterX = leftCheekX + cheeksWidth;
  const splinterY = roofTrimHeight + stubbedHeight;

  const framesPositionsGroups = calculateFramePositions(groupedByVariation, splinter.width, cheeks.width, stubbedWestOverhang, frameRodThickness);
  const spareSpace = 0;

  const frameGroupY = roofTrimHeight + stubbedHeight + splinter.width + frameRodThickness;

  const measurementsGap = 100;
  const rightMeasurementsDetailedX = fullDormerBottomWidth + stubbedWestOverhang + stubbedEastOverhang + measurementsGap;
  const bottomMeasurementsDetailedY = dormerHeight + measurementsGap;
  const rightMeasurementsTotalX = fullDormerBottomWidth + stubbedWestOverhang + stubbedEastOverhang + measurementsGap + MEASUREMENTS_LINE_LENGTH + measurementsGap;
  const bottomMeasurementsTotalY = dormerHeight + measurementsGap + MEASUREMENTS_LINE_LENGTH + measurementsGap;


  const groupMeasurementsGap = measurementsGap;
  const bottomMeasurementsDetailedGroupY = bottomMeasurementsTotalY + groupMeasurementsGap * 2 + MEASUREMENTS_LINE_LENGTH;

  const contentWidth = stubbedWidth + measurementsGap + MEASUREMENTS_LINE_LENGTH + measurementsGap + MEASUREMENTS_LINE_LENGTH * 2;
  const contentHeight = dormerHeight + measurementsGap + MEASUREMENTS_LINE_LENGTH + measurementsGap + MEASUREMENTS_LINE_LENGTH * 2 + groupMeasurementsGap + MEASUREMENTS_LINE_LENGTH * 2;

  const uiWidth = 0;

  const defaultScaleFactor = 0.98;

  let minimumScaleX = 0;
  if (windowWidth > MEDIA_L || orientation === 'landscape') {
    minimumScaleX = ((canvasSize.width - uiWidth) * defaultScaleFactor) / contentWidth;
  } else {
    minimumScaleX = (canvasSize.width * defaultScaleFactor) / contentWidth;
  }
  const minimumScaleY = (canvasSize.height * defaultScaleFactor) / contentHeight;
  const scaleAmount = 1;
  const stageScale = (minimumScaleX < minimumScaleY ? minimumScaleX : minimumScaleY) * scaleAmount;

  const scaledWidth = contentWidth * stageScale;
  const scaledHeight = contentHeight * stageScale;
  const stageX = (canvasSize.width - scaledWidth) / 2;
  const stageY = (canvasSize.height - scaledHeight) / 2;

  const stageWidth = canvasSize.width * scaleAmount;
  const stageHeight = canvasSize.height * scaleAmount;

  return <div id={"2d-canvas-div"}>
    <div ref={containerRef} className={styles.container}>
      <Stage x={stageX} y={stageY} scaleX={stageScale} scaleY={stageScale} width={stageWidth}
             height={stageHeight}
             id={CANVAS_TECHNICAL_FRONT_ID}
      >
        <Layer>
          {/* RoofTrim */}
          <DrawRectangle x={roofTrimX} y={roofTrimY} width={roofTrimWidth} height={roofTrimHeight}/>{/* RoofTrim */}

          {/* Stubbed */}
          <DrawRectangle x={stubbedX} y={stubbedY} width={stubbedWidth} height={stubbedHeight}/>{/* Stubbed */}
          <DrawLine x={rightMeasurementsDetailedX} y={0}
                    points={[0, 0, MEASUREMENTS_LINE_LENGTH, 0]}/>{/* Right stubbed start */}
          <DrawText x={rightMeasurementsDetailedX} y={(roofTrimHeight + stubbedHeight) / 2}
                    text={`${roofTrimHeight + stubbedHeight}`} alignText={"RIGHT"}/>{/* Right stubbed height text */}
          <DrawLine x={rightMeasurementsDetailedX} y={roofTrimHeight + stubbedHeight}
                    points={[0, 0, MEASUREMENTS_LINE_LENGTH, 0]}/>{/* right stubbed end */}

          {/*Left cheek */}
          <DrawRectangle x={leftCheekX} y={leftCheekY} width={cheeksWidth} height={cheeksHeight}/>{/* Left cheek */}
          <DrawLine x={leftCheekX} y={bottomMeasurementsDetailedY}
                    points={[0, 0, 0, MEASUREMENTS_LINE_LENGTH]}/>{/* Bottom cheek start */}
          <DrawText x={leftCheekX + (cheeksWidth / 2)} y={bottomMeasurementsDetailedY} text={`${cheeksWidth}`}
                    alignText={"BOTTOM"}/>{/* Bottom left cheek width text */}

          {/* Right cheek */}
          <DrawRectangle x={rightCheekX} y={rightCheekY} width={cheeksWidth}
                         height={cheeksHeight}/>{/* Right cheek */}
          <DrawLine x={rightCheekX} y={bottomMeasurementsDetailedY}
                    points={[0, 0, 0, MEASUREMENTS_LINE_LENGTH]}/>{/* Bottom cheek start */}
          <DrawText x={rightCheekX + (cheeksWidth / 2)} y={bottomMeasurementsDetailedY} text={`${cheeksWidth}`}
                    alignText={"BOTTOM"}/>{/* Bottom right cheek width text */}

          {/* Splinter outside line */}
          <DrawRectangle x={splinterX} y={splinterY} width={bottomAndTopSplinterWidth}
                         height={leftAndRightSplinterHeight}/>{/* Splinter */}
          {/* Splinter inside line */}
          <DrawRectangle x={splinterX + splinter.width}
                         y={splinterY + splinter.height}
                         width={bottomAndTopSplinterWidth - (splinter.width * 2)}
                         height={leftAndRightSplinterHeight - (splinter.height * 2)}/>{/* Splinter */}

          {/* Splinter measurements lines*/}
          <DrawLine x={splinterX} y={bottomMeasurementsDetailedY}
                    points={[0, 0, 0, MEASUREMENTS_LINE_LENGTH]}/>{/* Bottom splinter start, aka end of starting cheek */}

          <DrawLine x={rightMeasurementsDetailedX} y={splinterY}
                    points={[0, 0, MEASUREMENTS_LINE_LENGTH, 0]}/>{/* Right splinter top start */}
          <DrawLine x={rightMeasurementsDetailedX} y={splinterY + splinter.height}
                    points={[0, 0, MEASUREMENTS_LINE_LENGTH, 0]}/>{/* Right splinter top end */}
          <DrawLine x={rightMeasurementsDetailedX}
                    y={splinterY + splinter.height + (dormerHeight - stubbed.height - (splinter.width * 2))}
                    points={[0, 0, MEASUREMENTS_LINE_LENGTH, 0]}/>{/* Right splinter bottom start */}
          <DrawText x={rightMeasurementsDetailedX}
                    y={splinterY + (splinter.height + (dormerHeight - stubbed.height - (splinter.width * 2))) / 2}
                    text={`${dormerHeight - stubbed.height - (splinter.width * 2)}`}
                    alignText={"RIGHT"}/>{/* Right frame height text */}
          {/* Splinter visual lines */}

          {/* Frames */}
          {framesPositionsGroups.map((fpGroup, index) => {
              const drawnFrames = fpGroup.framesPositions.map((pos, index) => {
                const {x, width, frame, drawRodBefore, drawRodAfter} = pos;

                let windowX = x;

                let innerWidth = width;
                if (drawRodBefore) {
                  innerWidth -= frameRodThickness;
                  windowX += frameRodThickness;
                }

                if (drawRodAfter) {
                  innerWidth -= frameRodThickness;
                }

                return (
                  <React.Fragment key={`frames-${index}`}>
                    <DrawText x={x + width / 2} y={bottomMeasurementsDetailedY} text={`${frame.width}`}
                              alignText={"BOTTOM"}/>
                    {(frame.variation === FrameVariation.Panel && innerWidth > 10) && (
                      <Panel x={x} y={frameGroupY} width={innerWidth} height={frame.height - (frameRodThickness * 2)}/>
                    )}
                    {frame.variation === FrameVariation.TiltAndTurnWindowRight && (
                      <TiltAndTurnWindowLeft x={x} y={frameGroupY} width={innerWidth}
                                             height={frame.height - (frameRodThickness * 2)}/>
                    )}
                    {frame.variation === FrameVariation.TiltAndTurnWindowLeft && (
                      <TiltAndTurnWindowRight x={x} y={frameGroupY} width={innerWidth}
                                              height={frame.height - (frameRodThickness * 2)}/>
                    )}
                    {frame.variation === FrameVariation.Window && (
                      <Window x={windowX} y={frameGroupY} width={innerWidth}
                              height={frame.height - (frameRodThickness * 2)}/>
                    )}
                  </React.Fragment>
                );
              });

              // No need to draw group information for panels
              if (fpGroup.framesPositions.find(x => x.frame.variation !== FrameVariation.Panel) === undefined) {
                return <></>
              }

              const goupBottomLineX = (fpGroup.framesPositions[0].x ?? 0) - frameRodThickness;
              const groupHorizontalLine = <DrawLine x={goupBottomLineX} y={bottomMeasurementsDetailedGroupY}
                                                    points={[0, 0, fpGroup.width, 0]}/>

              const verticalLineMiddel = bottomMeasurementsDetailedGroupY - MEASUREMENTS_LINE_LENGTH / 2;
              const groupLeftLine = <DrawLine x={goupBottomLineX} y={verticalLineMiddel}
                                              points={[0, 0, 0, MEASUREMENTS_LINE_LENGTH]}/>;

              const groupRightLine = <DrawLine x={goupBottomLineX + fpGroup.width} y={verticalLineMiddel}
                                               points={[0, 0, 0, MEASUREMENTS_LINE_LENGTH]}/>;
              const groupBottomText = <DrawText x={goupBottomLineX + fpGroup.width / 2} y={verticalLineMiddel}
                                                text={`${fpGroup.width}`}
                                                alignText={"BOTTOM"}/>


              // <DrawLine x={splinterX + splinter.width} y={splinterX + splinter.width}
              //           points={[fpGroup.width, 0, 0, 0]}/>;

              return <React.Fragment key={`group-${index}`}>
                {drawnFrames}
                {groupLeftLine}
                {groupHorizontalLine}
                {groupRightLine}
                {groupBottomText}
              </React.Fragment>

            }
          )
          }

          {/* Spare space frame */}
          {/*{spareSpace > 0 && (*/}
          {/*  <>*/}
          {/*    <Panel x={framesPositions[framesPositions.length - 1].x + framesPositions[framesPositions.length - 1].width} y={frameGroupY} width={spareSpace} height={dormerHeight - stubbed.height - (splinter.width * 2)}/>*/}
          {/*    <DrawLine x={framesPositions[framesPositions.length - 1].x + framesPositions[framesPositions.length - 1].width + spareSpace} y={bottomMeasurementsDetailedY}*/}
          {/*              points={[0, 0, 0, MEASUREMENTS_LINE_LENGTH]}/>/!* Bottom frame end *!/*/}
          {/*    <DrawText x={framesPositions[framesPositions.length - 1].x + framesPositions[framesPositions.length - 1].width + (spareSpace / 2)} y={bottomMeasurementsDetailedY} text={`${spareSpace}`}*/}
          {/*              alignText={"BOTTOM"}/>/!* Spare space width text *!/*/}
          {/*  </>*/}
          {/*)}*/}

          {/* Measurements detailed */}
          <DrawLine x={leftCheekX} y={bottomMeasurementsDetailedY + MEASUREMENTS_LINE_LENGTH / 2}
                    points={[0, 0, dormerWidth, 0]}/>{/* Full width detailed */}
          <DrawLine x={rightMeasurementsDetailedX + MEASUREMENTS_LINE_LENGTH / 2} y={0}
                    points={[0, 0, 0, dormerHeight]}/>{/* Full height detailed */}
          <DrawLine x={leftCheekX + dormerWidth} y={bottomMeasurementsDetailedY}
                    points={[0, 0, 0, MEASUREMENTS_LINE_LENGTH]}/>{/* Last vertical line detailed */}
          <DrawLine x={rightMeasurementsDetailedX} y={splinterY + leftAndRightSplinterHeight}
                    points={[0, 0, MEASUREMENTS_LINE_LENGTH, 0]}/>{/* Last horizontal line detailed */}

          {/* Measurements total */}
          <DrawLine x={leftCheekX} y={bottomMeasurementsTotalY + MEASUREMENTS_LINE_LENGTH / 2}
                    points={[0, 0, dormerWidth, 0]}/>{/* Full width total */}
          <DrawLine x={rightMeasurementsTotalX + MEASUREMENTS_LINE_LENGTH / 2} y={0}
                    points={[0, 0, 0, dormerHeight]}/>{/* Full height total */}
          <DrawLine x={leftCheekX} y={bottomMeasurementsTotalY}
                    points={[0, 0, 0, MEASUREMENTS_LINE_LENGTH]}/>{/* First vertical line total */}
          <DrawLine x={rightMeasurementsTotalX} y={0}
                    points={[0, 0, MEASUREMENTS_LINE_LENGTH, 0]}/>{/* First horizontal line total */}
          <DrawLine x={leftCheekX + dormerWidth} y={bottomMeasurementsTotalY}
                    points={[0, 0, 0, MEASUREMENTS_LINE_LENGTH]}/>{/* Last vertical line total */}
          <DrawLine x={rightMeasurementsTotalX} y={splinterY + leftAndRightSplinterHeight}
                    points={[0, 0, MEASUREMENTS_LINE_LENGTH, 0]}/>{/* Last horizontal line total */}
          <DrawText x={rightMeasurementsTotalX} y={dormerHeight / 2} text={`${dormerHeight}`}
                    alignText={"RIGHT"}/>{/* Right total height text */}
          <DrawText x={leftCheekX + (dormerWidth / 2)} y={bottomMeasurementsTotalY} text={`${dormerWidth}`}
                    alignText={"BOTTOM"}/>{/* Bottom total height text */}
        </Layer>
      </Stage>
    </div>

  </div>;
};
