import { Vector2, Vector3 } from 'three';
import { DEFAULT_WALL_HEIGHT, DEFAULT_WIDTH, DEFAULT_DEPTH } from '@hec/core';
import {
  CladdingProduct,
  DrawableObject,
  FrameProduct,
  Product,
  TextureMaterial
} from '@hec/models';

export class HomeExtensionDto {
  readonly _measurements: Vector3;
  readonly _frameMeasurements: Vector2|null = null;
  constructor(
    private readonly _width: number = DEFAULT_WIDTH,
    private readonly _depth: number = DEFAULT_DEPTH,
    private readonly _height: number = DEFAULT_WALL_HEIGHT,
    private readonly claddingObject: CladdingProduct,
    private readonly frameObject?: FrameProduct,
    private readonly extras: Product[] = []
  ) {
    if (frameObject) {
      this._frameMeasurements = new Vector2(frameObject.drawableObject?.width, frameObject.drawableObject?.height);
    }
    this._measurements = new Vector3(_width, _height, _depth)
  }

  public getMeasurements () {
    return this._measurements.clone();
  }

  public getTextureMaterial() {
    return this.claddingObject.textureMaterial as TextureMaterial
  }

  public getFrameMeasurements() {
    if (this._frameMeasurements) {
      return this._frameMeasurements.clone();
    }
    return null;
  }
  public getDrawableObject() {
    if (this.frameObject) {
      return this.frameObject.drawableObject as DrawableObject;
    }
    return null;
  }

  public filterExtraByType(predicate: (product: Product) => boolean) {
    return this.extras.filter(predicate);
  }
  public getExtraByType(predicate: (product: Product) => boolean) {
    return this.extras.find(predicate);
  }
  public getRelativeCameraPosition() {
    const clone = this._measurements.clone();
    clone.setX(this._measurements.x / 2);
    clone.setY(this._measurements.y / 2);
    clone.setZ(this._measurements.z / 2);
    return clone;
  }
}
