import { Product } from '@hec/models';

export const SET_PRODUCTS = 'SET_PRODUCTS';
export const REDUCER_SET_PRODUCTS = 'REDUCER_SET_PRODUCTS';

export const setProducts = (products: Product[]) => ({
  type: SET_PRODUCTS,
  data: {
    products
  }
})
