import {
  CheeksDto,
  DormerColorViewModel,
  FaceDto,
  SectionFragmentSide,
  SectionFragmentType,
  StubbedDto
} from "@hec/api-dtos";
import React, {FunctionComponent} from "react";
import {Box} from "@react-three/drei";
import {CdnUrlHelper, fromMmToRenderingMeters, getTotalWidth, RoofOverhangViewModel} from "@hec/core";
import {DEFAULT_DORMER_DEPTH} from "../index";
import {AnthraciteGlobalMaterial, AttachSide, CladdingGlobalMaterial} from "./CladdingGlobalMaterial";
import {useLoader} from "@react-three/fiber";
import {RepeatWrapping, TextureLoader} from "three";
import {TEXTURES_PER_METER_IN_LENGTH} from "../../../constants";
import {DormerColorMaterial} from "./DormerColorMaterial";
import {CheekSideRenderingObjectSide} from "./Cheeks";

export interface StubbedProps {
  southFace: FaceDto;
  roofOverhang: RoofOverhangViewModel;
  overrideColor?: DormerColorViewModel
}

const cdnPublicRoot = CdnUrlHelper.getPublicRoot();

export const Stubbed: FunctionComponent<StubbedProps> = ({overrideColor, southFace, roofOverhang}: StubbedProps) => {
  const southSectionFragments = southFace.sectionFragments;
  const stubbedSectionFragment = southSectionFragments.find(x => x.sectionFragmentType === SectionFragmentType.Stubbed);

  const stubbedSectionFragments: StubbedDto[] = southSectionFragments.filter(x => x.sectionFragmentType === SectionFragmentType.Stubbed).map(x=> x as StubbedDto);

  const stubbed = stubbedSectionFragments.find(x=> x.stubbedSide === SectionFragmentSide.EastPlusSouthPlusWest);

  const cheeks: CheeksDto[] = southSectionFragments.filter(x => x.sectionFragmentType === SectionFragmentType.Cheeks).map(x=> x as CheeksDto);

  const cheek = cheeks.find(x=> x.cheekSide === SectionFragmentSide.South) ?? cheeks.find(x=> x);




  const overHangSides = roofOverhang.stubbedWestOverhang + roofOverhang.stubbedEastOverhang;

  const stubbedHeight = fromMmToRenderingMeters(stubbedSectionFragment?.height ?? -1);


  const stubbedWidth = fromMmToRenderingMeters(getTotalWidth(southFace)) + overHangSides;

  const depth = DEFAULT_DORMER_DEPTH;


  const roofTexture = useLoader(
    TextureLoader,
    cdnPublicRoot + '/roof/Texture.jpg'
  );

  const repeatMaxX = TEXTURES_PER_METER_IN_LENGTH * stubbedWidth;
  const repeatMaxY = TEXTURES_PER_METER_IN_LENGTH * depth;

  roofTexture.wrapS = RepeatWrapping;
  roofTexture.wrapT = RepeatWrapping;
  roofTexture.repeat.set(repeatMaxX, repeatMaxY);

  const roofLeatherHeight = .0001;


  return (<group key={'stubbed'} position={[-roofOverhang.stubbedWestOverhang, 0, roofOverhang.stubbedSouthOverhang]}>
    <group position={[(stubbedWidth / 2), stubbedHeight + roofLeatherHeight, -(depth / 2)]}>
      <Box
        args={[stubbedWidth, roofLeatherHeight, depth]}
      >
        <meshStandardMaterial
          map={roofTexture}
          displacementScale={0}
          clipIntersection={true}
        />
      </Box>
    </group>

    <group position={[(stubbedWidth / 2), (stubbedHeight / 2), -(depth / 2)]}>
      <Box
        args={[stubbedWidth, stubbedHeight, depth]}
        receiveShadow
        castShadow
      >
        <DormerColorMaterial
          dormerColor={stubbed?.configuredDormerColor}
          attachSide={AttachSide.west}
          width={depth}
          height={stubbedHeight}
        />,
        <DormerColorMaterial
          dormerColor={stubbed?.configuredDormerColor}
          attachSide={AttachSide.east}
          width={depth}
          height={stubbedHeight}
        />,
        <CladdingGlobalMaterial
          attachSide={AttachSide.up}
        />,
        <DormerColorMaterial
          dormerColor={cheek?.configuredDormerColor}
          attachSide={AttachSide.down}
        />,
        <DormerColorMaterial
          dormerColor={cheek?.configuredDormerColor}
          attachSide={AttachSide.north}
          width={stubbedWidth}
          height={stubbedHeight}
        />,
        <DormerColorMaterial
          dormerColor={stubbed?.configuredDormerColor}
          attachSide={AttachSide.south}
          width={stubbedWidth}
          height={stubbedHeight}
        />
      </Box>
    </group>

    <group position={[(stubbedWidth / 2), .05, -(depth / 2)]}>
      <Box
        args={[stubbedWidth - 0.01, 0.01, depth - 0.01]}
        receiveShadow
        castShadow
      >
        <meshStandardMaterial/>
      </Box>
    </group>


    )
  </group>)
}
