import produce from 'immer';
import {getConfigurationTemplatesSucceededAction, selectConfigurationTemplateSucceededAction} from './Actions';
import {ConfigurationTemplateState} from './State';


export const getConfigurationTemplatesSucceededReducer = (state: ConfigurationTemplateState, action: ReturnType<typeof getConfigurationTemplatesSucceededAction>) => {
  return produce(state, (draft) => {
    const configurationTemplates = action.payload.configurationTemplates;

    const sortedConfigurationTemplates= configurationTemplates.sort((a,b) => {
      return (b.modifiedOn ?? b.createdOn).getTime() - (a.modifiedOn ?? a.createdOn).getTime()
    });

    draft.configurationTemplates = sortedConfigurationTemplates;
    if (sortedConfigurationTemplates.length > 0) {
      draft.selectedConfigurationTemplateDto = sortedConfigurationTemplates[0];
    }
  });
}

export const selectConfigurationTemplateSucceededReducer = (state: ConfigurationTemplateState, action: ReturnType<typeof selectConfigurationTemplateSucceededAction>) => {
  return produce(state, (draft) => {
    draft.selectedConfigurationTemplateDto = action.payload.configurationTemplate;
  });
}
