import React from 'react';

export const CenterPivotWindowIcon = () => {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
         x="0px" y="0px"
         viewBox="0 0 16 25">
<g>

		<rect x="4.1" y="6.3" transform="matrix(0.496 -0.8683 0.8683 0.496 -3.521 7.4216)" style={{fill: '#231F20'}} width="1"
          height="0.9"/>

  <rect x="6.1" y="2.8" transform="matrix(0.496 -0.8683 0.8683 0.496 0.5752 7.3944)" style={{fill: '#231F20'}} width="1"
        height="0.9"/>

  <rect x="5.1" y="4.5" transform="matrix(0.4969 -0.8678 0.8678 0.4969 -1.4753 7.401)" style={{fill: '#231F20'}} width="1"
        height="0.9"/>

  <rect x="3.1" y="8.1" transform="matrix(0.496 -0.8683 0.8683 0.496 -5.5691 7.4353)" style={{fill: '#231F20'}} width="1"
        height="0.9"/>

  <rect x="2.1" y="9.9" transform="matrix(0.496 -0.8683 0.8683 0.496 -7.6171 7.4487)" style={{fill: '#231F20'}} width="1"
        height="0.9"/>

  <rect x="11.1" y="6.3" transform="matrix(0.8585 -0.5128 0.5128 0.8585 -1.856 6.8908)" style={{fill: '#231F20'}}
        width="0.9" height="1"/>

  <rect x="13.2" y="9.8" transform="matrix(0.8585 -0.5128 0.5128 0.8585 -3.356 8.4596)" style={{fill: '#231F20'}}
        width="0.9" height="1"/>

  <rect x="12.2" y="8" transform="matrix(0.8585 -0.5128 0.5128 0.8585 -2.6061 7.6749)" style={{fill: '#231F20'}} width="0.9"
        height="1"/>

  <rect x="9" y="2.8" transform="matrix(0.8584 -0.5129 0.5129 0.8584 -0.3557 5.3235)" style={{fill: '#231F20'}} width="0.9"
        height="1"/>

  <rect x="10.1" y="4.5" transform="matrix(0.8585 -0.5128 0.5128 0.8585 -1.1061 6.1061)" style={{fill: '#231F20'}}
        width="0.9" height="1"/>
  <path style={{fill: '#231F20'}} d="M0,0v25h16V0H0z M7.1,1.7l0.7,0.4L8,1.7h0l0.3,0.5L9,1.8l0-0.1h5.3v10l-0.2,0.1l0.2,0.4V13L8,23.3h0
		L1.8,12.5l0.4-0.7l-0.5-0.3V1.7H7.1z M1.7,14L7,23.3H1.7V14z M9,23.3l5.3-8.6v8.6H9z"/>
</g>
</svg>);
}
