import {
  CriticalError,
  Error,
  InfoMessage,
  Log,
  LoggingState,
  storeCriticalErrorAction,
  storeErrorAction,
  storeInfoMessageAction,
  storeWarningAction, updateLoggingSettingsAction,
  Warning
} from "./";
import produce from "immer";

const MAXIMUM_LOG_COUNT = 500;
const extractGenericPayload = (log: Log, action: ReturnType<typeof storeInfoMessageAction>) => {
  if ('message' in action.payload) {
    log.message = action.payload.message;
  }
  if ('fileName' in action.payload) {
    log.fileName = action.payload.fileName;
  }
  if ('lineNumber' in action.payload) {
    log.lineNumber = action.payload.lineNumber;
  }
  if ('columnNumber' in action.payload) {
    log.columnNumber = action.payload.columnNumber;
  }
}

const enforceMaximumLogCount = (logs: Log[]) => {
  if (logs.length > MAXIMUM_LOG_COUNT) {
    logs.shift();
  }
}

export const storeInfoMessageReducer = (state: LoggingState, action: ReturnType<typeof storeInfoMessageAction>) => {
  return produce(state, (draft) => {
    const log: InfoMessage = {level: 'info'};

    extractGenericPayload(log, action);

    draft.infoMessages.push(log);
    enforceMaximumLogCount(draft.infoMessages);

    if (state.settings.logLevel === 'INFO') {

      // console.log(action.payload.message);
    }
  });
}

export const storeWarningReducer = (state: LoggingState, action: ReturnType<typeof storeWarningAction>) => {
  return produce(state, (draft) => {
    const log: Warning = {level: 'warning'};

    extractGenericPayload(log, action);

    draft.warnings.push(log);
    enforceMaximumLogCount(draft.infoMessages);

    if (state.settings.logLevel === 'INFO' || state.settings.logLevel === 'WARNING') {
      console.warn(action.payload.message);
    }
  });
}
export const storeErrorReducer = (state: LoggingState, action: ReturnType<typeof storeErrorAction>) => {
  return produce(state, (draft) => {
    const log: Error = {level: 'error'};

    extractGenericPayload(log, action);

    log.originalError = action.payload.error;
    if ('message' in action.payload.error) {
      log.message = action.payload.error.message;
    }
    if ('stack' in action.payload.error) {
      log.stack = action.payload.error.stack;
    }
    if ('name' in action.payload.error) {
      log.errorType = action.payload.error.name;
    }
    if ('fileName' in action.payload.error) {
      log.fileName = action.payload.error.fileName;
    }
    if ('lineNumber' in action.payload.error) {
      log.lineNumber = action.payload.error.lineNumber;
    }
    if ('columnNumber' in action.payload.error) {
      log.columnNumber = action.payload.error.columnNumber;
    }

    draft.errors.push(log);

    console.error(action.payload.error);
  });
}
export const storeCriticalErrorReducer = (state: LoggingState, action: ReturnType<typeof storeCriticalErrorAction>) => {
  return produce(state, (draft) => {
    const log: CriticalError = {level: 'critical'};

    extractGenericPayload(log, action);

    log.originalError = action.payload.error;
    if ('message' in action.payload.error) {
      log.message = action.payload.error.message;
    }
    if ('stack' in action.payload.error) {
      log.stack = action.payload.error.stack;
    }
    if ('name' in action.payload.error) {
      log.errorType = action.payload.error.name;
    }
    if ('fileName' in action.payload.error) {
      log.fileName = action.payload.error.fileName;
    }
    if ('lineNumber' in action.payload.error) {
      log.lineNumber = action.payload.error.lineNumber;
    }
    if ('columnNumber' in action.payload.error) {
      log.columnNumber = action.payload.error.columnNumber;
    }

    draft.criticalErrors.push(log);
    console.error(action.payload.error);
  });
}

export const updateLogSettingsReducer = (state: LoggingState, action: ReturnType<typeof updateLoggingSettingsAction>) => {
  return produce(state, (draft) => {
    if ('enableReduxLogging' in action.payload && typeof action.payload.enableReduxLogging === 'boolean') {
      draft.settings.enableReduxLogging = action.payload.enableReduxLogging;
    }
    if ('logLevel' in action.payload && typeof action.payload.logLevel === 'string') {
      draft.settings.logLevel = action.payload.logLevel;
    }
  });
}
