import React, {FunctionComponent, InputHTMLAttributes} from 'react'
import {useField, useFormikContext} from "formik";
import styles from './checkbox.module.scss';
import sharedStyles from '../Shared/_shared-styles.module.scss';
import {ErrorDisplay, HelpText, Label} from "../Shared";

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  required?: boolean;
  helpText?: string;
}

export const Checkbox: FunctionComponent<CheckboxProps> = ({label, required, helpText, ...props }) => {
  const [field, meta] = useField({ ...props, type: 'checkbox' });
  const formik = useFormikContext();

  return (
    <div className={sharedStyles.container}>
      <div className={styles.inputContainer}>
        <input
          className={styles.input}
          type={'checkbox'}
          {...props}
          {...field}
          id={props.id || props.name}
          onChange={(e) => {
            formik.handleChange(e);
            setTimeout(formik.submitForm, 0);
          }}
        />
        <Label id={props.id} name={props.name} label={label} required={required}/>
      </div>

      <ErrorDisplay meta={meta}/>

      <HelpText helpText={helpText}/>
    </div>
  );
};
