import React, { FunctionComponent } from 'react';
import { Modal } from '../Modal';
import { FrameProduct, Product, ProductType, RoofLightProduct, TranslationKeys } from '@hec/models';
import { useTranslation } from 'react-i18next';
import { Button } from '../Button';
import styles from './prompt.module.scss';
import classnames from 'classnames';
import { Conditional } from '../Conditional';
import { Text } from '../Typography';
import { TFunction } from 'i18next';

export interface PromptProps {
  isOpen?: boolean;
  conflictedProducts?: Product[];
  onConfirm?: () => void;
  onCancel?: () => void;
}

const translationFactory = (t: TFunction, product: Product): string => {
  const { productType, name } = product;
  switch (productType) {
    case ProductType.FRAME:
      return `${t(name, { color : t((product as FrameProduct).drawableObject?.color ?? '')})} - ${t((product as FrameProduct).drawableObject?.productionMaterialType ?? '')}`;
    case ProductType.ROOF_LIGHT:
      return `${t(name, { color: t((product as FrameProduct).drawableObject?.color ?? ''), style: t(`generic.roofLightWindowType.${(product as RoofLightProduct).drawableObject?.specification?.roofLightWindowType ?? ''}`)})}`
    default: return t(name)
  }
}
export const Prompt: FunctionComponent<PromptProps> =
  (
    {
      isOpen = false,
      conflictedProducts = [],
      onCancel,
      onConfirm,
    },
  ) => {
    const { t } = useTranslation();
    return (
      <Modal isOpen={isOpen} className={styles.modal}>
        <Text className={classnames(styles.title)}>
          {t(TranslationKeys.modals.promptModal.title)}
        </Text>
        <Text as={'p'} className={classnames(styles.prompt)}>
          {t(TranslationKeys.modals.promptModal.content)}
        </Text>
        <ul className={classnames(styles.list)}>
          {
            conflictedProducts.map((product) => {
              const { name, imageUrl } = product;
              const translatedName = translationFactory(t, product);
              return (
                <li
                  key={name}
                  className={classnames(styles['list-item'])}
                >
                  <Conditional condition={typeof imageUrl !== 'undefined'}>
                    <img src={imageUrl}  alt={translatedName}/>
                  </Conditional>
                  <Text className={styles['list-item-text']}>{translatedName}</Text>
                </li>
              )
            })
          }
        </ul>
        <div className={classnames(styles.actions)}>
          <Button type={'secondary'} size={'large'} onClick={onCancel}>{t(TranslationKeys.generic.cancel)}</Button>
          <Button type={'primary'} size={'large'} onClick={onConfirm}>{t(TranslationKeys.generic.confirm)}</Button>
        </div>
      </Modal>
    );
  };
