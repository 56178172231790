import { useSelector } from 'react-redux';
import { BaseService } from '@hec/core';
import { HomeExtensionApplicationState } from '@hec/models';

export const useService = (serviceRef: typeof BaseService) => {
  const services = useSelector((state: HomeExtensionApplicationState) => state.applicationStateReducer.services)
  const service = services
    .find((service) => service.getName() === serviceRef.getName());
  if (!service) {
    throw new (Error as any)('Unable to find service of type ' + serviceRef.getName().toString());
  }
  return service;
}
