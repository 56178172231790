import { createAction } from '@reduxjs/toolkit';

interface StoreLogActionProps {
  message?: string;
  fileName?: string | null;
  lineNumber?: string | null;
  columnNumber?: string | null;
}

type StoreInfoMessageActionProps = StoreLogActionProps

type StoreWarningActionProps = StoreLogActionProps

/* eslint-disable  @typescript-eslint/no-explicit-any */
export interface StoreErrorActionProps extends StoreLogActionProps {
  error: any;
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
interface StoreCriticalErrorActionProps extends StoreLogActionProps {
  error: any;
}

export interface UpdateLoggingSettingsActionProps {
  enableReduxLogging?: boolean;
  logLevel?: string;
}

export const storeInfoMessageAction = createAction<StoreInfoMessageActionProps>('storeInfoMessage');
export const storeWarningAction = createAction<StoreWarningActionProps>('storeWarning');
export const storeErrorAction = createAction<StoreErrorActionProps>('storeError');
export const storeCriticalErrorAction = createAction<StoreCriticalErrorActionProps>('storeCriticalError');
export const updateLoggingSettingsAction = createAction<UpdateLoggingSettingsActionProps>('updateLoggingSettings');
