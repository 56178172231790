import produce from 'immer';
import {
  addServiceAction,
  removeServiceAction,
} from './Actions';
import { ServiceState } from './State';

export const addServiceReducer = (state: ServiceState, action: ReturnType<typeof addServiceAction>) => {
  return produce(state, (draft) => {
      draft.services = [...(draft?.services ?? []), ...action.payload.services];
      // draft.appConfiguration = state.appConfiguration!;
  });
}


export const removeServiceReducer = (state: ServiceState, action: ReturnType<typeof removeServiceAction>) => {
  return produce(state, (draft) => {
    draft.services = draft.services.filter(
      (n) => n.getName() !== action.payload.symbol
    );
    // draft.appConfiguration = state.appConfiguration!;
  });
}
