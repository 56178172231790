import { QuillRenderer } from "@hec/components/v2";
import {FunctionComponent} from "react";
import {useSelector} from "react-redux";
import {DormerRootState, getExtraInformationForKeySelector} from "@hec/dal";


export interface ExtraInformationForKeyRendererProps {
  extraInformationKey: string
  padding?: string
}

export const ExtraInformationForKeyRenderer: FunctionComponent<ExtraInformationForKeyRendererProps> = ({extraInformationKey, padding = '20px'}) => {
  const content = useSelector((state: DormerRootState) => getExtraInformationForKeySelector(state, extraInformationKey));

  if (content === null) {
    return <></>
  }

const spacing = '15px';


  return (<div style={{marginTop:spacing, marginBottom:spacing, padding: spacing, backgroundColor: '#EEEEEE'}}>
      <QuillRenderer content={content}/>
  </div>);
}
