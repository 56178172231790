import {UiStatusState, storeUiStatusAction} from "./";
import produce from "immer";

export const storeUiStatusReducer = (state: UiStatusState, action: ReturnType<typeof storeUiStatusAction>) => {
  return produce(state, (draft) => {
    if ('fullscreenMode' in action.payload) {
      draft.fullscreenMode = action.payload.fullscreenMode!
    }
    if ('modalOpen' in action.payload) {
      draft.modalOpen = action.payload.modalOpen!
    }
    if ('modalContentKey' in action.payload) {
      draft.modalContentKey = action.payload.modalContentKey!
    }
    if ('modalData' in action.payload) {
      draft.modalData = action.payload.modalData
    }
    if ('fatalErrorKey' in action.payload) {
      draft.fatalErrorKey = action.payload.fatalErrorKey
    }
    if ('technicalMode' in action.payload) {
      draft.technicalMode = action.payload.technicalMode!
    }
  });
}
