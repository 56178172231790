import {getLuminance} from "@mui/material";

export const getContrastColor = (color: string): string => {
  const luminance = getLuminance(color);
  if (luminance > 5) {
    return '#000000';
  } else {
    return '#ffffff';
  }
}
