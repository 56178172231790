import 'react-toastify/dist/ReactToastify.min.css';
import React, { FunctionComponent, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { Notification, NotificationType } from '@hec/models';
import { TFunction } from 'react-i18next';
import styles from './notification-container.module.scss';
import {useMatomo} from "@jonkoops/matomo-tracker-react";

export interface NotificationContainerProps {
  notifications: Notification[];
  translator: TFunction;
}

function onlyUnique<T extends any>(value: T, index: number, self: T[]) {
  return (
    self.map((i) => JSON.stringify(i)).indexOf(JSON.stringify(value)) === index
  );
}

function showNotification(
  message: string,
  duration: number,
  notificationType: NotificationType
) {
  switch (notificationType) {
    case NotificationType.INFO:
      toast(message, { autoClose: duration });
      break;
    case NotificationType.ERROR: {
      toast.error(message, { autoClose: duration });
      break;
    }
  }
}


export const NotificationContainer: FunctionComponent<NotificationContainerProps> =
  ({ notifications, translator }) => {
    const {trackPageView} = useMatomo();

    useEffect(() => {
      if (notifications.length > 0) {
        notifications.filter(onlyUnique).forEach((n) => {
          let message = n.message;
          if (n.shouldBeTranslated) {
            message = translator(n.message);
          }
          showNotification(message, n.duration, n.type);
        });
      }
    }, [notifications]);



    return (
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        bodyClassName={styles['toast']}
        bodyStyle={{
          fontStyle: '"Prompt", sans-serif!important'
        }}

      />
    );
  };
