import { Plane, Vector3 } from "three";

export class ClippingUtilities {
  public static getClippingBox(dimensions: Vector3): Plane[] {
    return [
      new Plane( new Vector3( -1, 0, 0 ), -(dimensions.x / 2) ), // x-positive
      new Plane( new Vector3( 1, 0, 0 ), -(dimensions.x / 2) ), // x-negative

      new Plane( new Vector3( 0, -1, 0 ), -(dimensions.y / 2) ),  // y-positive
      new Plane( new Vector3( 0, 1, 0 ), -(dimensions.y / 2) ), // y-negative


      new Plane( new Vector3( 0, 0, -1 ), -(dimensions.z / 2) ),  // z-positive
      new Plane( new Vector3( 0, 0, 1 ), -(dimensions.z / 2) ), // z-negative
    ]
  }

  public static transformPlane(plane: Plane, transformValue: number, index: number): Plane {
    const isEven = (n: number) => n % 2 == 0;
    if (transformValue > 0 || transformValue < 0) {
      if (isEven(index)) {  // should transform on the positive end
        plane.set(plane.normal, plane.constant + transformValue);
      } else {  // should transform on the negative end
        plane.set(plane.normal, plane.constant - transformValue);
      }
    }
    return plane;
  }

  public static transformClippingBox(planes: Plane[], diff: Vector3): Plane[] {

    return planes.map((plane, index) => {
      const isX = index === 0 || index === 1;
      const isY = index === 2 || index === 3;
      const isZ = index === 4 || index === 5;

      if (isX) {
        const { x } = diff;
        return ClippingUtilities.transformPlane(plane, x, index);
      }
      else if (isY) {
        const { y } = diff;
        return ClippingUtilities.transformPlane(plane, y, index);
      } else if (isZ) {
        const { z } = diff;
        return ClippingUtilities.transformPlane(plane, z, index);
      } else {
        return plane;
      }
    })
  }
}
