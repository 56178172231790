import React, {FunctionComponent, useEffect, useMemo, useState} from "react";
import styles from './measurements.module.scss';
import {SidebarFooter, Number, Select, Conditional, Text} from "@hec/components/v2";
import {RoutePaths} from "../../routing";
import {TranslationKeys} from "@hec/models";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Form, Formik} from "formik";
import * as Yup from 'yup';
import {
  frameTemplateSelectedAction,
  getClientInformationSelector, getDisplaySizeInformation, getFaceConfiguration,
  getOrderSelector,
  getSelectedConfigurationTemplateSelector, prepareUpdateMeasurementsAction
} from "@hec/dal";
import {SizeConfigurationType} from "../../models";
import {ExtraInformationForKeyRenderer} from "../../components";
import {ConfigurationTemplateDto, FaceConfigurationSectionFragmentCombinationDto, Side} from "@hec/api-dtos";
import {getSplinterWidth, getWidthOfStaticSections} from "@hec/core";
import _ from "lodash";

export const MeasurementsSideBarContent: FunctionComponent = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const clientInformation = useSelector(getClientInformationSelector);
  const order = useSelector(getOrderSelector);
  const selectedConfigurationTemplate = useSelector(getSelectedConfigurationTemplateSelector);
  const faceConfiguration = useSelector(getFaceConfiguration);
  const displaySizeInformation = useSelector(getDisplaySizeInformation);


  // This auto updates the preview to the biggest configuration supported.
  let selectableCombinations: FaceConfigurationSectionFragmentCombinationDto[] =
    _.clone((selectedConfigurationTemplate?.templateFaceConfiguration?.sectionFragmentCombinations ?? []));

  useEffect(() => {

    selectableCombinations = selectableCombinations.sort((a,b) => (b?.maxWidth  ?? 0) - (a?.maxWidth ?? 0));

    const suitableForConfigurationWidth = selectableCombinations
      .filter(x => x.maxWidth >= (faceConfiguration?.width ?? 0) && x.minWidth <= (faceConfiguration?.width ?? Infinity))
      .sort((a, b) => b.maxWidth - a.maxWidth);

    dispatch(frameTemplateSelectedAction(
      {selectedFaceConfigurationSectionFragmentCombinationDto: suitableForConfigurationWidth[0]})
    );
  }, [faceConfiguration?.width, dispatch]);

  if (clientInformation === null || order === null || selectedConfigurationTemplate === null || faceConfiguration === null) {
    return <></>;
  }

  // Split supportedSizes to width and height
  const widthSupportedSizes = selectedConfigurationTemplate.supportedSizes.filter(supportedSize => supportedSize.width !== null);
  const heightSupportedSizes = selectedConfigurationTemplate.supportedSizes.filter(supportedSize => supportedSize.height !== null);

  // console.log({widthSupportedSizes, heightSupportedSizes})

  // Determine width configuration type
  let widthConfigurationType: SizeConfigurationType;
  if (widthSupportedSizes.length > 0) {
    widthConfigurationType = SizeConfigurationType.Scaffolds;
  } else if (selectedConfigurationTemplate.maximumWidth === null) {
    widthConfigurationType = SizeConfigurationType.FreeInput;
  } else if (selectedConfigurationTemplate.minimumWidth === selectedConfigurationTemplate.maximumWidth) {
    widthConfigurationType = SizeConfigurationType.None;
  } else {
    widthConfigurationType = SizeConfigurationType.FreeInput;
  }
  // Determine height configuration type
  let heightConfigurationType: SizeConfigurationType;
  if (heightSupportedSizes.length > 0) {
    heightConfigurationType = SizeConfigurationType.Scaffolds;
  } else if (selectedConfigurationTemplate.maximumHeight === null) {
    heightConfigurationType = SizeConfigurationType.FreeInput;
  } else if (selectedConfigurationTemplate.minimumHeight === selectedConfigurationTemplate.maximumHeight) {
    heightConfigurationType = SizeConfigurationType.None;
  } else {
    heightConfigurationType = SizeConfigurationType.FreeInput;
  }


  // Validation schema
  const validationSchema = {
    width: Yup.number(),
    height: Yup.number(),
  };
  if (widthConfigurationType === SizeConfigurationType.Scaffolds) {
    validationSchema.width = Yup.number();
  } else if (widthConfigurationType === SizeConfigurationType.FreeInput) {
    if (selectedConfigurationTemplate.minimumWidth !== null) {
      validationSchema.width = validationSchema.width.min(selectedConfigurationTemplate.minimumWidth, ({min}) => t(TranslationKeys.dormer.pages.measurementsPage.widthMinimumError, {min}));
    }
    if (selectedConfigurationTemplate.maximumWidth !== null) {
      validationSchema.width = validationSchema.width.max(selectedConfigurationTemplate.maximumWidth, ({max}) => t(TranslationKeys.dormer.pages.measurementsPage.widthMaximumError, {max}));
    }
  }

  if (heightConfigurationType === SizeConfigurationType.Scaffolds) {
    validationSchema.height = Yup.number();
  } else if (heightConfigurationType === SizeConfigurationType.FreeInput) {
    if (selectedConfigurationTemplate.minimumHeight !== null) {
      validationSchema.height = validationSchema.height.min(selectedConfigurationTemplate.minimumHeight, ({min}) => t(TranslationKeys.dormer.pages.measurementsPage.heightMinimumError, {min}));
    }
    if (selectedConfigurationTemplate.maximumHeight !== null) {
      validationSchema.height = validationSchema.height.max(selectedConfigurationTemplate.maximumHeight, ({max}) => t(TranslationKeys.dormer.pages.measurementsPage.heightMaximumError, {max}));
    }
  }

  // Scaffolds
  interface ScaffoldOption {
    value: number;
    label: string;
  }

  let scaffoldOptionsWidth: ScaffoldOption[] = [];
  let scaffoldOptionsHeight: ScaffoldOption[] = [];

  if (widthConfigurationType === SizeConfigurationType.Scaffolds) {
    scaffoldOptionsWidth = widthSupportedSizes
      .filter(scaffoldOptionWidth => {
        if (scaffoldOptionWidth.height !== null) {
          return scaffoldOptionWidth.height === faceConfiguration.height;
        } else {
          return true;
        }
      })
      .map(widthSupportedSize => {
        return {
          value: widthSupportedSize.width!,
          label: widthSupportedSize.name,
        }
      });
  }
  if (heightConfigurationType === SizeConfigurationType.Scaffolds) {
    scaffoldOptionsHeight = heightSupportedSizes
      .filter(scaffoldOptionHeight => {
        if (scaffoldOptionHeight.width !== null) {
          return scaffoldOptionHeight.width === faceConfiguration.width;
        } else {
          return true;
        }
      })
      .map(heightSupportedSize => {
        return {
          value: heightSupportedSize.height!,
          label: heightSupportedSize.name,
        }
      });
  }

  // console.log({scaffoldOptionsWidth, scaffoldOptionsHeight})

  const initialValues = {
    width: faceConfiguration.width,
    height: faceConfiguration.height,
  };


  return (
    <>
      <div className={styles.sections}>
        <ExtraInformationForKeyRenderer
          extraInformationKey={'MeasurementsStepExtraInformation'}
        />
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={Yup.object(validationSchema)}
          onSubmit={(values, {setSubmitting}) => {
            setSubmitting(false);

            const width = values.width === null ? null : values.width;
            dispatch(prepareUpdateMeasurementsAction({
              width,
              height: values.height === null ? null : values.height,
            }));

          }}
        >
          {formik => (
            <Form>
              <Conditional condition={widthConfigurationType === SizeConfigurationType.FreeInput}>
                <Number name="width"
                        step="1"
                        min={selectedConfigurationTemplate.minimumWidth!}
                        max={selectedConfigurationTemplate.maximumWidth!}
                        label={t(TranslationKeys.dormer.pages.measurementsPage.widthHeader)}
                />
              </Conditional>
              <Conditional condition={widthConfigurationType === SizeConfigurationType.Scaffolds}>
                <Select name='width' label={t(TranslationKeys.dormer.pages.measurementsPage.widthHeader)}>
                  {scaffoldOptionsWidth?.map((option, index) => {
                    return <option key={index} value={option.value}>{option.label}</option>
                  })}
                </Select>
              </Conditional>
              <Conditional condition={heightConfigurationType === SizeConfigurationType.FreeInput}>
                <Number name="height"
                        step="1"
                        min={selectedConfigurationTemplate.minimumHeight!}
                        max={selectedConfigurationTemplate.maximumHeight!}
                        label={t(TranslationKeys.dormer.pages.measurementsPage.heightHeader)}
                />
              </Conditional>
              <Conditional condition={heightConfigurationType === SizeConfigurationType.Scaffolds}>
                <Select name='height' label={t(TranslationKeys.dormer.pages.measurementsPage.heightHeader)}>
                  {scaffoldOptionsHeight?.map((option, index) => {
                    return <option key={index} value={option.value}>{option.label}</option>
                  })}
                </Select>
              </Conditional>
            </Form>
          )}
        </Formik>
        <Text>
          {t(TranslationKeys.dormer.sizeInformation.totalDormerWidth)}: {displaySizeInformation.totalDormerWidth} mm<br/>
          {t(TranslationKeys.dormer.sizeInformation.totalFrameWidth)}: {displaySizeInformation.totalFrameWidth} mm
        </Text>
      </div>
      <SidebarFooter
        prevRoutePath={RoutePaths.TemplatePath}
        nextRoutePath={RoutePaths.FramesPath}
        nextRouteCTA={t(TranslationKeys.dormer.pages.framesPage.cta)}
        clientInformation={clientInformation}
      />
    </>
  );
};
