import { createSlice } from '@reduxjs/toolkit';
import {storeUiStatusAction} from './Actions';
import {uiStatusInitialState} from './State';
import {storeUiStatusReducer} from './Reducers';

export const uiStatusSlice = createSlice({
  name: 'uiStatus',
  initialState: uiStatusInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(storeUiStatusAction, storeUiStatusReducer)
  },
});
