export interface ErrorEventData {
  errorType: string;
  message: string;
  [key: string]: string;
}

export const HEC_ERROR = 'hec.error';

export class ErrorEvent extends CustomEvent<ErrorEventData> {
  public constructor(eventData: ErrorEventData) {
    super(HEC_ERROR, { detail: eventData });
  }
}
