import {BaseDalService} from "@hec/core";
import {AppConfiguration, ApplicationService} from "@hec/models";
import axios, {AxiosInstance, CancelTokenSource} from "axios";
import {OrderDto} from "@hec/api-dtos";
import { MD5 } from 'crypto-js';
import {HashObject} from "@hec/utils";


export class PutOrderService extends BaseDalService implements ApplicationService {

  private lastPutHash: string | undefined;
  private client: AxiosInstance;
  public static override serviceName = Symbol('PutOrderService');
  private cancelTokenSource: CancelTokenSource | undefined;

  constructor() {
    super();
    this.client = axios.create({});
  }

  override configure(configuration: AppConfiguration) {
    this.client = axios.create({
      baseURL: `${configuration.baseUrl}/api`,
    });

    this.useDateTimeConverters(this.client);
    this.useEnumConverters(this.client);
  }


  getName(): symbol {
    return PutOrderService.serviceName;
  }

  static override getName(): symbol {
    return PutOrderService.serviceName;
  }

  hashObject(obj: any): string {
    return HashObject(obj);
  }



  async putOrder(order: OrderDto, orderConfigurationCode: string): Promise<PutOrderResult | null> {
    this.configureAuthInterceptor(this.client, orderConfigurationCode);

    const curOrderHash = this.hashObject(order);

    if (curOrderHash === this.lastPutHash) {
      return {
        order,
        modified: false,
      };
    }

    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel('New order request initiated');
    }

    this.cancelTokenSource = axios.CancelToken.source();
    this.lastPutHash = curOrderHash;

    try {
      const response = await this.client.put(`/orders/${order.id}`, order, {
        cancelToken: this.cancelTokenSource.token,
      });

      if (response.status === 200) {
        return {
          order: response.data as OrderDto,
          modified: true,
        };
      }

      throw new Error(`PutOrderService: putOrder resulted in ${response.status}: ${response.statusText}`);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Order Request canceled due newer one start');
        return null;
      } else {
        throw error;
      }
    }
  }
}

export interface PutOrderResult {
  order: OrderDto;
  modified: boolean;
}
