import { AnyAction } from 'redux';
import {
  DefaultProductSelectionId,
  FatalErrorKey,
  FaucetProduct,
  FrameHeaderProduct,
  IsCladdingProduct,
  IsFaucetProduct,
  IsFrameHeaderProduct,
  IsFrameProduct,
  IsRoofLightProduct,
  IsRoofOverhangProduct,
  IsRoofTrimProduct,
  ProductionMaterialType,
  ProductType,
  RoofOverhangStyle,
} from '@hec/models';
import { put } from 'redux-saga/effects';
import {
  SetFatalErrorKey,
  REDUCER_GET_PRODUCTS_SUCCESS,
} from '../../../../';

export function* handleSuccessfulProductHandler(action: AnyAction) {
  const { products: productsFromService } = action.data;
  const claddingProducts = productsFromService.filter(IsCladdingProduct);
  const frameProducts = productsFromService.filter(IsFrameProduct);
  const roofTrimProducts = productsFromService.filter(IsRoofTrimProduct);

  if (
    claddingProducts.length === 0 ||
    frameProducts.length === 0 ||
    roofTrimProducts.length === 0
  ) {
    yield put(SetFatalErrorKey(FatalErrorKey.NO_VALID_PRODUCTS));
  }

  const products = [];
  const hasRoofOverhangProducts = productsFromService.find(
    IsRoofOverhangProduct
  );
  const hasRoofLightProducts = productsFromService.find(IsRoofLightProduct);
  const hasFrameHeaderProducts = productsFromService.find(IsFrameHeaderProduct);
  const hasFaucetProducts = productsFromService.find(IsFaucetProduct);

  if (hasRoofOverhangProducts) {
    products.push({
      id: DefaultProductSelectionId.RoofOverhang,
      name: 'products.roofOverhang.noOverhang',
      translationKey: 'products.roofOverhang.noOverhang',
      productType: ProductType.ROOF_OVERHANG,
      productionMaterialType: ProductionMaterialType.UNKNOWN,
      priceExVat: 0,
      style: RoofOverhangStyle.NONE,
    });
  }
  if (hasRoofLightProducts) {
    products.push({
      id: DefaultProductSelectionId.RoofLight,
      name: 'products.roofLight.noRoofLight',
      translationKey: 'products.roofLight.noRoofLight',
      productType: ProductType.ROOF_LIGHT,
      productionMaterialType: ProductionMaterialType.UNKNOWN,
      priceExVat: 0,
    });
  }
  if (hasFrameHeaderProducts) {
    products.push({
      id: DefaultProductSelectionId.FrameHeader,
      name: 'products.frameHeader.noFrameHeader',
      translationKey: 'products.frameHeader.noFrameHeader',
      productType: ProductType.FRAME_HEADER,
      productionMaterialType: ProductionMaterialType.UNKNOWN,
      priceExVat: 0,
      textureMaterial: {
        specification: {},
      },
    } as FrameHeaderProduct);
  }
  if (hasFaucetProducts) {
    products.push({
      id: DefaultProductSelectionId.Faucet,
      name: 'products.faucet.noFaucet',
      translationKey: 'products.faucet.noFaucet',
      productType: ProductType.FAUCET,
      productionMaterialType: ProductionMaterialType.UNKNOWN,
      priceExVat: 0,
    } as FaucetProduct);
  }

  products.push(...productsFromService);
  yield put({
    type: REDUCER_GET_PRODUCTS_SUCCESS,
    data: { products: products },
  });
}
