import React, { FunctionComponent, HTMLProps } from 'react';
import classnames from 'classnames';
import styles from '../Input/input.module.scss';

export const TextArea: FunctionComponent<HTMLProps<HTMLTextAreaElement>> = (props) => {
  return (
    <textarea
      {...props}
      className={classnames(styles['text-area'], props.className)}
    />
  )
};
