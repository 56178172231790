import produce, {current} from 'immer';
import {getOrderSucceededAction, putOrderSucceededAction} from './';
import {OrderState} from './State';
import {getConfiguredFramesFromOrder} from "./Util";

export const getOrderSucceededReducer = (state: OrderState, action: ReturnType<typeof getOrderSucceededAction>) => {
  return produce(state, (draft) => {
    draft.order = action.payload.order;
  });
}

export const putOrderSucceededReducer = (state: OrderState, action: ReturnType<typeof putOrderSucceededAction>) => {
  return produce(state, (draft) => {
    if (!draft.order) {
      return;
    }

    const payloadSectionFragments = action.payload.orderDto.faceConfiguration.faces[0].sectionFragments!;
    const problempayloadSectionFragments =  payloadSectionFragments.filter(x=> x.id == null && x.tempId == null);
    if(problempayloadSectionFragments.length > 0) {
      console.log(problempayloadSectionFragments);
      const asdfsd = problempayloadSectionFragments;
    }

    const existingFrames = getConfiguredFramesFromOrder(draft.order);
    const newFrames = getConfiguredFramesFromOrder(action.payload.orderDto);
    existingFrames.forEach(existingFrame => {
      const matchingFrame = newFrames.find(newFrame => newFrame.sort === existingFrame.sort);
      if (matchingFrame?.id != null) {
        existingFrame.tempId = null;
        existingFrame.id = matchingFrame.id;
      }
    });


    const resultstate = current(draft);
    const noIdErr = resultstate!.order!.faceConfiguration.faces[0].sectionFragments!.filter(x=> x.id == null && x.tempId == null);

    if(noIdErr.length > 0) {
      console.log(noIdErr);
      const asdfsd = noIdErr;
    }


    draft.isDirty = false;
  });
}


export const markOrderDirtyReducer = (state: OrderState) => {
  return produce(state, (draft) => {
    draft.isDirty = true;
  });
}
