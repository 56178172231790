import React, {FunctionComponent} from "react";
import styles from "./expand-frame-button.module.scss";
import {useTranslation} from "react-i18next";
import {Button} from "@hec/components/v2";
import {useSelector} from "react-redux";
import {getClientInformationSelector} from "@hec/dal";
import {TranslationKeys} from "@hec/models";

interface ExpandFrameButtonProps {
  expandRequested: () => void;
}

export const ExpandFrameButton: FunctionComponent<ExpandFrameButtonProps> = ({expandRequested}) => {
  const { t } = useTranslation();

  const clientInformation = useSelector(getClientInformationSelector);

  if (clientInformation === null) {
    return <></>;
  }

  return (
    <Button
      className={styles.addFrameButton}
      onClick={expandRequested}
      size={'large'}
      style={{
        backgroundColor: clientInformation.secondaryColor,
        color: clientInformation.primaryColor,
      }}
      as={<div />}
    >
      <>
        {t(TranslationKeys.dormer.pages.framesPage.expandFrame, "Kozijn vergroten")}

        <svg style={{paddingLeft: '5px'}} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill={clientInformation.primaryColor}
             viewBox="0 0 512 512">
          <path
            d="M336 32c-8.8 0-16 7.2-16 16s7.2 16 16 16h89.4L256 233.4 86.6 64H176c8.8 0 16-7.2 16-16s-7.2-16-16-16H48c-8.8 0-16 7.2-16 16V176c0 8.8 7.2 16 16 16s16-7.2 16-16V86.6L233.4 256 64 425.4V336c0-8.8-7.2-16-16-16s-16 7.2-16 16V464c0 8.8 7.2 16 16 16H176c8.8 0 16-7.2 16-16s-7.2-16-16-16H86.6L256 278.6 425.4 448H336c-8.8 0-16 7.2-16 16s7.2 16 16 16H464c8.8 0 16-7.2 16-16V336c0-8.8-7.2-16-16-16s-16 7.2-16 16v89.4L278.6 256 448 86.6V176c0 8.8 7.2 16 16 16s16-7.2 16-16V48c0-8.8-7.2-16-16-16H336z"/>
        </svg>
      </>
    </Button>
  );
};
