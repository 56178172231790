import {AppConfiguration, ApplicationType} from '@hec/models';

export const INITIALIZE_APPLICATION = 'INITIALIZE_APPLICATION';
export const REDUCER_INITIALIZE_APPLICATION = 'REDUCER_INITIALIZE_APPLICATION';
export const APPLICATION_STATE_REDUCER_INITIALIZE_APPLICATION =
  'APPLICATION_STATE_REDUCER_INITIALIZE_APPLICATION';

export const InitializeApplication = (
  appConfiguration: AppConfiguration,
  appType: ApplicationType = ApplicationType.HomeExtension,
  dataChildren: any | null = null,
) => ({
  type: INITIALIZE_APPLICATION,
  data: {
    appConfiguration,
    ...(dataChildren ?? {}),
  },
});
