import {
  AppConfiguration,
  ApplicationService,
  Product,
  ProductType,
} from '@hec/models';
import axios, { AxiosInstance } from 'axios';
import { BaseService } from './BaseService';

export interface GetProductsByTypeQuery {
  type?: ProductType;
  includeAssets?: boolean;
}

export class ProductsService extends BaseService implements ApplicationService {
  private client: AxiosInstance;
  public static override serviceName = Symbol('SERVICES.PRODUCTS_SERVICE');

  constructor() {
    super();
    this.client = axios.create({});
  }

  configure(configuration: AppConfiguration) {
    this.checkConfiguration(configuration);

    this.client = axios.create({
      baseURL: `${configuration.baseUrl}/api/client-configuration/${configuration.configurationId}/products`,
    });

    this.client.interceptors.request.use(async (config) => {
      config.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };
      return config;
    });
  }

  getName(): symbol {
    return ProductsService.serviceName;
  }

  static getName(): symbol {
    return ProductsService.serviceName;
  }

  async getProductsByType(query?: GetProductsByTypeQuery): Promise<Product[]> {
    /* eslint-disable */
    const response = await this.client.get('/', {
      params: { type: query?.type, ['include-assets']: query?.includeAssets },
    });
    /* eslint-enable */
    return [...response.data] as Product[];
  }
}
