import {
  AppConfiguration,
  ApplicationService,
  GeocoderStatus,
} from '@hec/models';
import axios, { AxiosInstance } from 'axios';
import { AutocompletePrediction } from 'react-places-autocomplete';
import {BaseDalService} from "./BaseDalService";

export class DormerGeolocationService extends BaseDalService implements ApplicationService {
  private client: AxiosInstance;
  public static override serviceName = Symbol('SERVICES.GEOLOCATION_SERVICE');

  constructor() {
    super();
    this.client = axios.create({});
  }

  configure(configuration: AppConfiguration) {
    this.checkConfiguration(configuration);

    this.client = axios.create({
      baseURL: `${configuration.baseUrl}/api`,
    });

    this.client.interceptors.request.use(async (config) => {
      config.headers = {
        'X-API-TOKEN': configuration.configurationId ?? '',
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };
      return config;
    });
  }

  getName(): symbol {
    return DormerGeolocationService.serviceName;
  }

  static getName(): symbol {
    return DormerGeolocationService.serviceName;
  }

  async predictAddress(
    address?: string
  ): Promise<{ status: GeocoderStatus; results: AutocompletePrediction[] }> {
    const response = await this.client.get('places', { params: { address } });
    if (response.status === 200) {
      return {
        status: response.data.status,
        results: response.data.predictions,
      };
    }
    return { status: response.data.status, results: [] };
  }

  async getPlaceById(
    placeId: string
  ): Promise<{
    status: GeocoderStatus;
    results: google.maps.GeocoderAddressComponent[];
  }> {
    const response = await this.client.get(`places/${placeId}`);
    if (response.status === 200) {
      return {
        status: response.data.status,
        results: response.data.result.address_components,
      };
    }
    return { status: response.data.status, results: [] };
  }
}
