import {FunctionComponent} from "react";
import {MeshStandardMaterialProps, useLoader} from "@react-three/fiber";
import {DoubleSide, TextureLoader, Vector2} from "three";
import {WrappingUtility} from "@hec/components/v2";
import {getRandomColor} from "@hec/components/v1";

import {DormerColorViewModel} from "@hec/api-dtos";
import {AttachSide} from "./CladdingGlobalMaterial";
import {MeshBasicMaterialProps} from "@react-three/fiber/dist/declarations/src/three-types";

interface DormerColorMaterialProps {
  dormerColor: DormerColorViewModel | null | undefined,
  width?: number,
  height?: number,
  offSet?: Vector2 | null,
  attachSide?: AttachSide | null,
  randomColor?: boolean,
  makeBasicMaterial?: boolean;
}



export const DormerColorMaterial: FunctionComponent<DormerColorMaterialProps> = ({dormerColor, attachSide = null, offSet = null, width = 1, height = 1, randomColor, makeBasicMaterial = false} :DormerColorMaterialProps) => {
  if(attachSide === AttachSide.south) {
    const asd = 1;
  }

  const useImage = dormerColor?.textureUrl != null;

  const imageUrl = dormerColor?.textureUrl;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const textureMap =  imageUrl ? useLoader(TextureLoader, imageUrl ?? '') : null;

  const attachSideValue = attachSide == null ? undefined : attachSide.toString();
  if(randomColor == true) {
    return <meshStandardMaterial
        color={getRandomColor()}
        attach={attachSideValue}
    />
  }


  if (useImage && textureMap) {
    const wrapped = WrappingUtility.wrapTexture(textureMap, width, height, offSet)

    let materialPropsDefault: MeshStandardMaterialProps | MeshBasicMaterialProps = {
      map: wrapped,
      side: DoubleSide
    };

    if (attachSide != null) {
      materialPropsDefault = {
        ...materialPropsDefault,
        attach: attachSideValue,
        side: DoubleSide
      }
    }
    if(makeBasicMaterial) {
      return <meshBasicMaterial
        {...(materialPropsDefault as MeshBasicMaterialProps)}
      />
    }

    return <meshStandardMaterial
      {...(materialPropsDefault as MeshStandardMaterialProps)}
      // key={`${dormerColor?.id}-${width}-${height}-${offSet}-${attachSide}-`}
    />
  }
  if(!useImage && dormerColor?.colorCode != null) {
    if (makeBasicMaterial) {
      return <meshBasicMaterial
        color={`#${dormerColor.colorCode}`}
        side={DoubleSide}
      />
    }
    return <meshStandardMaterial
      attach={attachSide != null ? attachSide.toString() : undefined}
      color={`#${dormerColor.colorCode}`}
      roughness={0.5}   // Moderate roughness for a realistic finish
      metalness={0.2}
      side={attachSide != null ? undefined : DoubleSide}
    />

    // const value = <meshStandardMaterial
    //   attach={"material-5"}
    //   color={`#383E42`}
    //   roughness={0.5}   // Moderate roughness for a realistic finish
    //   metalness={0.2}
    //   side={undefined}
    // />
  }


  if (makeBasicMaterial) {
    return <meshBasicMaterial
      color={'#3c3c3c'} // Anthracite gray with a hint of green
    />
  }

  return <AnthraciteMaterial/>
}


const AnthraciteMaterial: FunctionComponent = () => {
  return <meshStandardMaterial
    color={'#3c3c3c'} // Anthracite gray with a hint of green
    roughness={0.5}   // Moderate roughness for a realistic finish
    metalness={0.2}
  />
}
