import { AnyAction } from 'redux';
// import {
//   APPLICATION_STATE_REDUCER_INITIALIZE_APPLICATION,
//   CHANGE_VIEW_TYPE,
//   REDUCER_ADD_NOTIFICATION, REDUCER_LIMBO_ACTIONS,
//   REDUCER_REMOVE_NOTIFICATION, REDUCER_UNLIMBO_ACTIONS,
//   SET_LOADING,
//   TOGGLE_FULLSCREEN,
//   TOGGLE_MODAL,
//   UPDATE_APP_CONFIGURATION,
//   SET_FATAL_ERROR_KEY,
// } from '../../Actions';
import {
  APPLICATION_STATE_REDUCER_INITIALIZE_APPLICATION,
  CHANGE_VIEW_TYPE,
  REDUCER_ADD_NOTIFICATION, REDUCER_LIMBO_ACTIONS,
  REDUCER_REMOVE_NOTIFICATION, REDUCER_UNLIMBO_ACTIONS,
  SET_LOADING,
  TOGGLE_FULLSCREEN,
  TOGGLE_MODAL,
  SET_FATAL_ERROR_KEY,
} from '../../Actions';
import {
  ActionLoadingState,
  ApplicationState,
  LogLevel,
  ModalContentKey,
  ViewType,
} from '@hec/models';
import { current, produce } from 'immer';
import merge from 'lodash.merge';
import {
  ClientConfigurationService,
  QuotationService,
  ProductsService,
  GeolocationService
} from '../../HomeExtension/Services';


//TODO: Refactor so this can be elsewhere
const quotationService = new QuotationService();
const clientConfigurationService = new ClientConfigurationService();
const productsService = new ProductsService();
const geolocationService = new GeolocationService();

export const ApplicationStateReducerInitialState: ApplicationState = {
  notifications: [],
  loading: [],
  configuration: {
    languageCode: 'nl_NL',
    logging: {
      enableReduxLogging: false,
      enableReduxDevtools: false,
      logLevel: LogLevel.ERROR,
      logger: console,
    },
    baseUrl: process.env.NX_BASE_URL,
  },
  settings: {
    fullscreenMode: true,
    viewType: ViewType.Default,
    modalOpen: false,
    modalContentKey: ModalContentKey.NONE,
    modalData: {},
  },
  actionLimbo: {},
  services: [
    quotationService,
    clientConfigurationService,
    productsService,
    geolocationService,
  ]
};

export const ApplicationStateReducer = (
  state = ApplicationStateReducerInitialState,
  action: AnyAction
) => {
  switch (action.type) {
    case SET_LOADING: {
      return produce(state, (draft) => {
        if (action.data.loading) {
          draft.loading.push({ ...action.data });
        } else {
          draft.loading = draft.loading.filter((item: ActionLoadingState) => {
            return item.actionType !== action.data.actionType;
          });
        }
      });
    }
    // TODO: find out if necessary, if so make sure its not  ;)
    // case UPDATE_APP_CONFIGURATION:
    case APPLICATION_STATE_REDUCER_INITIALIZE_APPLICATION: {
      return produce(state, (draft) => {
        const configuration = merge(
          draft.configuration,
          action.data.appConfiguration
        );
        draft.configuration = configuration;
        draft.services.forEach((service) => service.configure(current(configuration)));
      });
    }
    case TOGGLE_FULLSCREEN: {
      return produce(state, (draft) => {
        draft.settings.fullscreenMode = !draft.settings.fullscreenMode;
      });
    }
    case CHANGE_VIEW_TYPE: {
      return produce(state, (draft) => {
        draft.settings.viewType = action.data.viewType;
      });
    }
    case TOGGLE_MODAL: {
      return produce(state, (draft) => {
        draft.settings.modalContentKey = action.data.modalContentKey;
        draft.settings.modalData = action.data.data;
        draft.settings.modalOpen = !draft.settings.modalOpen;
      });
    }
    case SET_FATAL_ERROR_KEY: {
      return produce(state, (draft) => {
        draft.settings.fatalErrorKey = action.data.fatalErrorKey;
      });
    }
    case REDUCER_ADD_NOTIFICATION: {
      return produce(state, (draft) => {
        draft.notifications = [...draft.notifications, action.data];
      });
    }
    case REDUCER_REMOVE_NOTIFICATION: {
      return produce(state, (draft) => {
        draft.notifications = draft.notifications.filter(
          (n) => n.message !== action.data.message
        );
      });
    }
    case REDUCER_LIMBO_ACTIONS: {
      return produce(state, (draft) => {
        draft.actionLimbo[action.data.limboKey] = action.data.actions;
      });
    }
    case REDUCER_UNLIMBO_ACTIONS: {
      return produce(state, (draft) => {
        delete draft.actionLimbo[action.data.limboKey];
      });
    }
    default:
      return state;
  }
};
