import React from 'react';
import styles from './spinner.module.scss';
import classnames from "classnames";

interface SpinnerProps {
  size: number; // Size in px
}


export const Spinner: React.FC<SpinnerProps> = ({ size }) => {
  const segmentSize = size * 0.2; // Example: 10% of the spinner size
  const translateValue = size * 0.5 - segmentSize / 2; // Adjusting to ensure segments start from the center

  return (
    <div
      className={styles.spinner}
      style={{
        width: `${size}px`,
        height: `${size}px`,
      }}
    >
      {Array.from({ length: 12 }).map((_, index) => (
        <div
          key={index}
          className={classnames(styles.segment, styles[`segment-${index + 1}`])}
          style={{
            width: `${segmentSize}px`,
            height: `${segmentSize}px`,
            left: `50%`, // Centering horizontally
            top: `50%`, // Centering vertically
            margin: `-${segmentSize / 2}px 0 0 -${segmentSize / 2}px`, // Adjust margin to truly center the segment
            transform: `rotate(${30 * index}deg) translate(${translateValue}px)`,
          }}
        ></div>
      ))}
    </div>
  );
};
