import { ViewType } from '@hec/models';

export const CHANGE_VIEW_TYPE = 'TOGGLE_VIEW_TYPE';

export const changeViewType = (viewType: ViewType) => ({
  type: CHANGE_VIEW_TYPE,
  data: {
    viewType,
  },
});
