import {OrderDto} from "@hec/api-dtos";

export interface OrderState {
  order: OrderDto | null;
  isDirty: boolean;
}

export const orderInitialState: OrderState = {
  order: null,
  isDirty: false,
}
