import {AxiosError} from "axios";

export interface Log {
  message?: string | null;
  level: 'info' | 'warning' | 'error' | 'critical';
  fileName?: string | null;
  lineNumber?: string | null;
  columnNumber?: string | null;
}

export type InfoMessage = Log;
export type Warning = Log;

export interface Error extends Log {
  originalError?: unknown | null;
  stack?: string | null;
  errorType?: string | null;
}

export type CriticalError = Error;

export interface AxiosErrorLog extends Error {
  originalError?: AxiosError;
  baseUrl: string,
}

export interface LogSettings {
  enableReduxLogging: boolean;
  logLevel: string;
}
export interface LoggingState {
  settings: LogSettings,
  infoMessages: Log[],
  warnings: Log[],
  errors: Error[],
  criticalErrors: Error[],
}

export const loggingInitialState: LoggingState = {
  settings: {
    enableReduxLogging: false,
    logLevel: 'INFO',
  },
  infoMessages: [],
  warnings: [],
  errors: [],
  criticalErrors: [],
}
